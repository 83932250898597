import apiHelper from '../../utils/apiHelpers';
import moment from 'moment';
import { Parser } from 'json2csv';

export const requestsToExport = async requests => {
  requests = requests.sort((a, b) => {
    return a.productRequestRows.length > b.productRequestRows.length ? 1 : -1;
  });

  let resultRequests = Object.values(
    requests.reduce((groupedRequests, request) => {
      if (
        request.productRequestRows.length &&
        request.productRequestRows[0].product
      ) {
        const productId = request.productRequestRows[0].product.id;
        if (groupedRequests[productId]) {
          groupedRequests[productId].push(request);
        } else {
          groupedRequests[productId] = [request];
        }
      }

      return groupedRequests;
    }, {}),
  );

  resultRequests = resultRequests.flat();

  // Input titles row
  let csv =
    '"Order Count";"Order #";"Delivery #";"SoldTO ID";"Material";"Qnty";"Bill No";"Import Date";"Sale Date";"Print Date";"SHIPTO Name";"HOME PHONE";"SHIPTO E-Mail";"SHIPTO Street";"SHIPTO Street2";"SHIPTO City";"SHIPTO Region Description";"SHIPTO Country";"SHIPTO Postal";"Ship Charge";"Value";"provider";"state_postal_code";"Order Total";"weight";"Weight Date";"size";"cancelled";"MOBILE PHONE";"Shipping Instructions";"SHIPTO District";"Ext Val";"Sent to Provider";"Expedition Date";"Invoice";"Observations"' +
    '\n';

  const allBoxes = (
    await apiHelper.getProducts({
      where: { category: 'box' },
      withDeleted: true,
    })
  ).data;

  for (let request of resultRequests) {
    let weightPackages = request.packages.reduce(
      (totalWeightPackage, packageRequest) => {
        if (packageRequest.weight) {
          return totalWeightPackage + Number(packageRequest.weight);
        }
        return totalWeightPackage;
      },
      0,
    );

    let weightWithPackages = weightPackages + Number(request.weight);

    let requestBoxSize;
    if (request.size) {
      requestBoxSize = allBoxes.find(box => box.id == request.size);
    }

    request.productRequestRows.map((productRequestRow, index) => {
      // Previously, we would map an object and process it with json2csv,
      // but the performance was very poor (almost 2 minutes to process about 2000 rows)
      csv += index == 0 ? '"1";' : '"0";';
      csv += '"' + request.order_number + '";';
      csv += '"' + request.delivery_number + '";';
      csv += '"' + request.sold_to_id + '";';
      csv += '"' + productRequestRow.material + '";';
      csv += '"' + productRequestRow.quantity + '";';
      csv += '"' + request.bill_number + '";';
      csv += '"' + moment(request.import_date).format('DD/MM/YYYY') + '";';
      csv += '"' + moment(request.sale_date).format('DD/MM/YYYY') + '";';
      csv += '"' + moment(request.print_date).format('DD/MM/YYYY') + '";';
      csv += '"' + request.ship_to_name + '";';
      csv += '"' + (request.home_phone ? request.home_phone : '') + '";';
      csv += '"' + request.ship_to_email + '";';
      csv += '"' + request.ship_to_street + '";';
      csv += '"' + request.ship_to_street_2 + '";';
      csv += '"' + request.ship_to_city + '";';
      csv += '"' + request.ship_to_region + '";';
      csv += '"' + request.ship_to_country + '";';
      csv +=
        '"' +
        (request.ship_to_postal_code && request.ship_to_postal_code != null
          ? request.ship_to_postal_code.postalCode
          : 'Código postal no registrado') +
        '";';
      csv += '"' + request.ship_charge + '";';
      csv += '"' + request.value + '";';
      csv +=
        '"' +
        (request.ship_to_postal_code &&
        request.ship_to_postal_code != null &&
        request.ship_to_postal_code.provider
          ? request.ship_to_postal_code.provider.name
          : request.provider && request.provider.name
          ? request.provider.name
          : 'Sin proveedor asociado') +
        '";';
      csv += '"' + request.state_postal_code + '";';
      csv += '"' + request.order_total + '";';
      csv += '"' + (index == 0 ? weightWithPackages : 0) + '";';
      csv += '"' + moment(request.weight_hours).format('DD/MM/YYYY') + '";';
      csv +=
        '"' +
        (requestBoxSize
          ? `${requestBoxSize.description} (${requestBoxSize.weight * 1000}gr)`
          : '') +
        '";';
      csv += '"' + (request.cancelled ? 'SI' : 'NO') + '";';
      csv += '"' + (request.mobile_phone ? request.mobile_phone : '') + '";';
      csv +=
        '"' +
        (request.shipping_instructions ? request.shipping_instructions : '') +
        '";';
      csv +=
        '"' + (request.ship_to_district ? request.ship_to_district : '') + '";';
      csv += '"' + (request.extra_value ? request.extra_value : '0') + '";';
      csv +=
        '"' +
        (request.sent_to_provider_date && request.sent_to_provider_date !== null
          ? moment(request.sent_to_provider_date).format('DD/MM/YYYY')
          : 'No enviado') +
        '";';
      csv +=
        '"' +
        (request.last_picked_date && request.last_picked_date !== null
          ? moment(request.last_picked_date).format('DD/MM/YYYY')
          : 'Sin expedicion') +
        '";';
      csv +=
        '"' +
        (request.idLabelsOfAPI
          ? request.idLabelsOfAPI
          : request.invoice
          ? request.invoice.number_invoice
          : 'Sin nota de entrega') +
        '";';
      csv +=
        '"' +
        (request.observations ? request.observations : 'Sin observaciones') +
        '";';
      csv += '\n'; // Line break
    });
  }

  return csv;
};

export const requestsToExportSAP = async requests => {
  const fields = ['delivery', 'order_number', 'provider', 'sent_to_provider'];
  const opts = { fields, delimiter: ';' };
  let outputRequests = [];

  for (let request of requests) {
    outputRequests[request.id] = {
      delivery: request.delivery_number,
      order_number: request.order_number,
      provider:
        request.ship_to_postal_code &&
        request.ship_to_postal_code != null &&
        request.ship_to_postal_code.provider
          ? request.ship_to_postal_code.provider.name
          : request.provider
          ? request.provider.name
          : 'Sin proveedor asociado',
      sent_to_provider:
        request.sent_to_provider_date && request.sent_to_provider_date !== null
          ? moment(request.sent_to_provider_date).format('DD/MM/YYYY')
          : 'no enviado',
    };
  }
  const parser = new Parser(opts);
  const csv = parser.parse(outputRequests);
  return csv;
};

export const requestsToExportBoxes = async requests => {
  const fields = ['size', 'qnty'];

  let boxesReport = {};
  let outputBoxes = [];

  const allBoxes = (
    await apiHelper.getProducts({
      where: { category: 'box' },
      withDeleted: true,
    })
  ).data;

  for (let request of requests) {
    console.table([request.id, request.size]);
    let isValidSize = allBoxes.find(item => item.id === request.size);
    if (request.size !== null && request.size !== 0 && isValidSize) {
      outputBoxes.push(request.size);
    }
  }

  outputBoxes.forEach(box => {
    boxesReport[box] = (boxesReport[box] || 0) + 1;
  });
  let sameBoxes = Object.keys(boxesReport);

  outputBoxes = sameBoxes.map(res => {
    let boxInfo = allBoxes.find(item => item.id === Number(res));
    return {
      size: `${boxInfo.description} (${boxInfo.weight * 1000}gr)`,
      qnty: boxesReport[res],
    };
  });
  const opts = { fields, delimiter: ';' };
  const parser = new Parser(opts);
  const csv = parser.parse(outputBoxes);
  return csv;
};

export const requestsToExportProducts = async requests => {
  const fields = ['sku', 'description', 'qnty'];
  let outputProducts = [];

  let productRequestRows = [];
  for (let request of requests) {
    request.productRequestRows.map(productRequestRow => {
      productRequestRows.push(productRequestRow);
    });
  }

  for (let index = 0; index < productRequestRows.length; index++) {
    let sameProducts = await productRequestRows.filter(
      productRow =>
        productRow.product.id === productRequestRows[index].product.id,
    );

    let quantity = 0;
    sameProducts.map(product => (quantity = quantity + product.quantity));

    for (let productRow of sameProducts) {
      outputProducts[productRow.product.id] = {
        sku: productRow.product.code,
        description: productRow.product.description,
        qnty: quantity,
      };
    }
  }
  const opts = { fields, delimiter: ';' };
  const parser = new Parser(opts);
  const csv = parser.parse(outputProducts);

  return csv;
};

export const requestsToExportProductsOutOfStock = async requests => {
  const fields = ['sku', 'description', 'qnty', 'inStock'];
  let outputProducts = [];

  let productRequestRows = [];
  for (let request of requests) {
    request.productRequestRows
      .filter(productRequestRow => {
        return productRequestRow.product.inStock === false;
      })
      .map(productRequestRow => {
        productRequestRows.push(productRequestRow);
      });
  }

  for (let index = 0; index < productRequestRows.length; index++) {
    let sameProducts = await productRequestRows.filter(
      productRow =>
        productRow.product.id === productRequestRows[index].product.id,
    );

    let quantity = 0;
    sameProducts.map(product => (quantity = quantity + product.quantity));

    for (let productRow of sameProducts) {
      outputProducts[productRow.product.id] = {
        sku: productRow.product.code,
        description: productRow.product.description,
        qnty: quantity,
        inStock: productRow.product.inStock ? 'Yes' : 'No',
      };
    }
  }
  const opts = { fields, delimiter: ';' };
  const parser = new Parser(opts);
  const csv = parser.parse(outputProducts);

  return csv;
};

export const requestsToExportReturnedProducts = async requests => {
  //no debe incluir devoluciones cuya causa sea «Duplicado» o «Error de código postal».
  requests = requests.filter(request => {
    return request.returnRequestRows.some(returnRequestRow => {
      return (
        returnRequestRow.returnRequest.reason !== 'Duplicado' &&
        returnRequestRow.returnRequest.reason !== 'Devolución por CP erróneo'
      );
    });
  });

  //Este listado debe abrir una fila por cada SKU y lote a devolver, y debe incluir además la descripción y la cantidad total de unidades.

  const fields = ['sku', 'lote', 'description', 'qnty'];
  let outputProducts = [];

  let productRequestRows = [];
  for (let request of requests) {
    for (let index = 0; index < request.productRequestRows.length; index++) {
      let newRow = request.productRequestRows[index];
      newRow.lote = request.returnRequestRows[index].lote;
      productRequestRows.push(newRow);
    }
  }

  let sameProduct = {};

  productRequestRows.forEach(row => {
    let key = `${row.productId}-${row.lote}`;
    if (sameProduct[key]) {
      row.quantity = row.quantity + sameProduct[key].qnty;
      sameProduct[key] = {
        sku: row.product.code,
        lote: row.lote,
        description: row.product.description,
        qnty: row.quantity,
      };
    } else {
      sameProduct[key] = {
        sku: row.product.code,
        lote: row.lote,
        description: row.product.description,
        qnty: row.quantity,
      };
    }
  });

  outputProducts = Object.values(sameProduct);

  const opts = { fields, delimiter: ';' };
  const parser = new Parser(opts);
  const csv = parser.parse(outputProducts);

  return csv;
};

export const customersQualifiedForGift = async clients => {
  const fields = ['sold_to_id', 'ship_to_name', 'with_gift', 'order_number'];

  let outputClientsData = clients.map(client => {
    return {
      sold_to_id: client.sold_to_id,
      ship_to_name: client.ship_to_name,
      with_gift: client.with_gift ? 'Recibió regalo' : 'No recibió regalo',
      order_number: client.productRequest
        ? `${client.productRequest.order_number}`
        : '-',
    };
  });

  const opts = { fields, delimiter: ';' };
  const parser = new Parser(opts);
  const csv = parser.parse(outputClientsData);

  return csv;
};
