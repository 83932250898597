import React from 'react';
import Select from 'react-select';

export default class SelectReturnReason extends React.Component {
  constructor(props) {
    super(props);

    this.options = [
      { value: 'Anulado por NS', label: 'Anulado por NS' },
      { value: 'Rechazado por el cliente', label: 'Rechazado por el cliente' },
      {
        value: 'Devolución por CP erróneo',
        label: 'Devolución por CP erróneo',
      },
      { value: 'Imposible entrega', label: 'Imposible entrega' },
      {
        value: 'Devolución por falta de información',
        label: 'Devolución por falta de información',
      },
      {
        value: 'Código postal no corresponde al Operador',
        label: 'Código postal no corresponde al Operador',
      },
      { value: 'Duplicado', label: 'Duplicado' },
      { value: 'Otro', label: 'Otro' },
    ];
  }

  onChangeWrapper(value) {
    let event = { target: {} };
    event.target.name = this.props.name ? this.props.name : 'select-nameless';
    event.target.value = value ? value.value : null;
    event.target.type = 'react-select';
    this.props.onChange(event);
  }
  render() {
    let value = this.props.value;

    if (value) {
      value = this.options.find(option => option.value === value);
    }

    return (
      <Select
        className={this.props.selectClassName}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={value || ''}
        isRequired={this.props.required}
        options={this.options}
        isClearable={true}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
      />
    );
  }
}
