import React from 'react';
import apiHelper from '../../utils/apiHelpers';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import RequestsFilters from '../requests/RequestsFilters';
import RequestsBarChartOfWeightHours from './RequestBarChartOfWeightHours';
import RequestsBarChartOfWeightDate from './RequestBarChartOfWeightDate';
import RequestsBarChartOfWeightByUser from './RequestBarChartOfWeightByUser';
import { withAlert } from 'react-alert';
import { CSVLink } from 'react-csv';
import RequestsBarChartOfPickedDate from './RequestBarChartOfPickedDate';
import RequestsBarChartOfImportDate from './RequestBarChartOfImportDate';
import moment from 'moment';
import 'moment/locale/es';
import { FiltersCacheContext } from '../common/FiltersCacheContext';
import Loading from '../common/Loading';
import './RequestReport.css';
import RequestsBarChartOfPostalCodeError from './RequestBarChartOfPostalCodeError';
import RequestBarChartOfBoxesUsed from './RequestBarChartOfBoxesUsed';
import RequestBarChartOfProductsXRquests from './RequestBarChartOfProductsXRquests';
import RequestBarChartOfProductsSentToProvider from './RequestBarChartOfProductsSentToProvider';
import RequestBarChartOfSentToProviderHours from './RequestBarChartOfSentToProviderHours';
import RequestBarChartOfLastPickedHours from './RequestsBarChartOfLastPickedHours';
import RequestsBarChartOfImportHours from './RequestsBarChartOfImportHours';
import config from '../../config/config';
import {
  requestsToExport,
  requestsToExportBoxes,
  requestsToExportProducts,
  requestsToExportReturnedProducts,
  requestsToExportSAP,
  requestsToExportProductsOutOfStock,
} from './ExportReportHelpers';
const qs = require('qs');

class RequestsReports extends React.Component {
  download = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      requests: {},
      requestDataWeightDate: [],
      requestDataWeightHours: [],
      requestDataWeightUser: [],
      requestDataPickedDate: [],
      requestDataPickedHours: [],
      requestDataImportDate: [],
      requestDataImportHours: [],
      requestDataPostalCodeError: [],
      requestDataBoxesUsed: [],
      requestDataProductsXRequests: [],
      requestDataProductsSentToProvider: [],
      requestDataSentToProviderHours: [],
      CSVdata: '',
      CSVtitle: '',
      filters: null,
      loading: false,
      loadingExportReports: false,
      loadingExports: {
        export: false,
        exportSAP: false,
        exportBoxes: false,
        exportProducts: false,
        exportReturnedProducts: false,
      },
    };
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getRequests();
    });
  }

  async exportRequests() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { export: true },
    });

    let csv = await requestsToExport(
      this.state.requests.filteredRequestforExport,
    );

    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { export: false },
        CSVdata: csv,
        CSVtitle: 'Reporte de pedidos.csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async exportSapRequests() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { export: true },
    });

    let csv = await requestsToExportSAP(
      this.state.requests.filteredRequestforExport,
    );

    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { exportSAP: false },
        CSVdata: csv,
        CSVtitle: 'Reporte de pedidos (SAP).csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async exportBoxesBySize() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { exportBoxes: true },
    });

    let csv = await requestsToExportBoxes(
      this.state.requests.filteredImportedForExport,
    );
    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { exportBoxes: false },
        CSVdata: csv,
        CSVtitle: 'Reporte de total de cajas.csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async exportTotalProducts() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { exportProducts: true },
    });

    let csv = await requestsToExportProducts(
      this.state.requests.filteredImportedForExport,
    );
    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { exportProducts: false },
        CSVdata: csv,
        CSVtitle: 'Reporte de total de productos.csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async exportProductsOutOfStock() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { exportProducts: true },
    });

    let csv = await requestsToExportProductsOutOfStock(
      this.state.requests.filteredImportedForExport,
    );
    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { exportProducts: false },
        CSVdata: csv,
        CSVtitle: 'Reporte de productos sin stock.csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async exportYearRequestReport() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { exportReturnedProducts: true },
    });
    let csv = await apiHelper.getExportYearRequestReport();
    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { exportProducts: false },
        CSVdata: csv.data,
        CSVtitle: 'Reporte Anual de Pedidos.csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async export12MonthsSentToProviderCount() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { exportReturnedProducts: true },
    });
    let csv = await apiHelper.get12MonthsSentToProviderCount();
    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { exportProducts: false },
        CSVdata: csv.data,
        CSVtitle: 'Reporte-EnviadosAProveedor.csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async exportTotalReturnedProducts() {
    this.setState({
      loadingExportReports: true,
      loadingExports: { exportReturnedProducts: true },
    });

    let csv = await requestsToExportReturnedProducts(
      this.state.requests.filteredImportedForExport,
    );
    this.setState(
      {
        loadingExportReports: false,
        loadingExports: { exportReturnedProducts: false },
        CSVdata: csv,
        CSVtitle: 'Reporte de total de productos devueltos.csv',
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  async getRequests() {
    this.setState({ loadingExportReports: true });
    const { filters } = this.state;
    let filter = {};

    try {
      filter = this.validateEmptyFilters(filters);
      const filtersWithPaging = {
        ...filter,
      };

      const [cacheFilters, setCacheFilters] = this.context;
      await setCacheFilters({ ...cacheFilters, ['RequestsFilters']: filters });

      this.setState({ loading: true });
      const requests = await apiHelper.getRequests(filtersWithPaging, true);
      let importRequest = requests.data;
      let weightRequest = requests.data;
      let lastPickedRequest = requests.data;
      let sentToProviderRequest = requests.data;
      let filteredRequestforExport = requests.data;
      let filteredImportedForExport = requests.data;

      if (filter.where.max_import_date) {
        let max_import_date = moment(filter.where.max_import_date);
        importRequest = importRequest.filter(request =>
          moment(request.import_date).isSameOrBefore(max_import_date),
        );
        weightRequest = weightRequest.filter(request =>
          moment(request.weight_hours).isSameOrBefore(max_import_date),
        );
        sentToProviderRequest = sentToProviderRequest.filter(request =>
          moment(request.sent_to_provider_date).isSameOrBefore(max_import_date),
        );
        lastPickedRequest = lastPickedRequest.filter(request =>
          moment(request.last_picked_date).isSameOrBefore(max_import_date),
        );
        filteredRequestforExport = filteredRequestforExport.filter(
          request =>
            moment(request.import_date).isSameOrBefore(max_import_date) ||
            moment(request.weight_hours).isSameOrBefore(max_import_date) ||
            moment(request.sent_to_provider_date).isSameOrBefore(
              max_import_date,
            ) ||
            moment(request.last_picked_date).isSameOrBefore(max_import_date),
        );
        filteredImportedForExport = filteredImportedForExport.filter(request =>
          moment(request.import_date).isSameOrBefore(max_import_date),
        );
      }

      if (filter.where.min_import_date) {
        let min_import_date = moment(filter.where.min_import_date);
        importRequest = importRequest.filter(request =>
          moment(request.import_date).isSameOrAfter(min_import_date),
        );
        weightRequest = weightRequest.filter(request =>
          moment(request.weight_hours).isSameOrAfter(min_import_date),
        );
        sentToProviderRequest = sentToProviderRequest.filter(request =>
          moment(request.sent_to_provider_date).isSameOrAfter(min_import_date),
        );
        lastPickedRequest = lastPickedRequest.filter(request =>
          moment(request.last_picked_date).isSameOrAfter(min_import_date),
        );
        filteredRequestforExport = filteredRequestforExport.filter(
          request =>
            moment(request.import_date).isSameOrAfter(min_import_date) ||
            moment(request.weight_hours).isSameOrAfter(min_import_date) ||
            moment(request.sent_to_provider_date).isSameOrAfter(
              min_import_date,
            ) ||
            moment(request.last_picked_date).isSameOrAfter(min_import_date),
        );
        filteredImportedForExport = filteredImportedForExport.filter(request =>
          moment(request.import_date).isSameOrAfter(min_import_date),
        );
      }

      this.setState({
        requests: {
          importRequest,
          weightRequest,
          sentToProviderRequest,
          lastPickedRequest,
          filteredRequestforExport,
          filteredImportedForExport,
        },
        loading: false,
      });

      let requestsBarChartWeightHours = weightRequest.reduce(
        (obj, requestData, index) => {
          if (!requestData.weight_hours) return obj;
          let formattedDate = moment(requestData.weight_hours).format('HH:00');
          if (!obj[formattedDate]) {
            obj[formattedDate] = {
              weight_hours: formattedDate,
              total_requests: 0,
            };
          }

          obj[formattedDate].total_requests++;
          return obj;
        },
        {},
      );

      let dataForWeightHours = Object.values(requestsBarChartWeightHours).sort(
        (a, b) => (a.weight_hours > b.weight_hours ? 1 : -1),
      );

      this.setState({
        requestDataWeightHours: dataForWeightHours,
      });

      let requestsBarChartWeightDate = weightRequest.reduce(
        (obj, requestData, index) => {
          if (!requestData.weight_hours) return obj;
          let formattedDate = moment(requestData.weight_hours);
          if (!obj[formattedDate.format('YYYY-MM-DD')]) {
            obj[formattedDate.format('YYYY-MM-DD')] = {
              weight_hours: formattedDate.toDate(),
              total_requests: 0,
            };
          }
          obj[formattedDate.format('YYYY-MM-DD')].total_requests++;
          return obj;
        },
        {},
      );
      this.setState({
        requestDataWeightDate: Object.values(requestsBarChartWeightDate),
      });

      let requestsBarChartWeightUser = weightRequest.reduce(
        (obj, requestData, index) => {
          if (!requestData.user_weight_id) return obj;
          let user = requestData.user_weight_id;
          if (!obj[user.id]) {
            obj[user.id] = {
              username: user.username,
              total_requests: 0,
            };
          }
          obj[user.id].total_requests++;
          return obj;
        },
        {},
      );
      this.setState({
        requestDataWeightUser: Object.values(requestsBarChartWeightUser),
      });

      let requestsBarChartPickedDate = lastPickedRequest.reduce(
        (obj, requestData, index) => {
          let formattedDate = moment(requestData.last_picked_date).format(
            'YYYY-MM-DD',
          );
          if (!obj[formattedDate]) {
            obj[formattedDate] = {
              last_picked_date: formattedDate,
              total_requests: 0,
            };
          }

          obj[formattedDate].total_requests++;
          return obj;
        },
        {},
      );

      this.setState({
        requestDataPickedDate: Object.values(requestsBarChartPickedDate),
      });

      let requestsBarChartPickedHours = lastPickedRequest.reduce(
        (obj, requestData, index) => {
          let formattedDate = moment(requestData.last_picked_date).format(
            'HH:00',
          );
          if (!obj[formattedDate]) {
            obj[formattedDate] = {
              last_picked_hour: formattedDate,
              total_requests: 0,
            };
          }

          obj[formattedDate].total_requests++;
          return obj;
        },
        {},
      );

      let dataForPickedHours = Object.values(requestsBarChartPickedHours).sort(
        (a, b) => (a.last_picked_hour > b.last_picked_hour ? 1 : -1),
      );

      this.setState({
        requestDataPickedHours: dataForPickedHours,
      });

      let requestsBarChartImportDate = importRequest.reduce(
        (obj, requestData, index) => {
          let formattedDate = moment(requestData.import_date).format(
            'YYYY-MM-DD',
          );
          if (!obj[formattedDate]) {
            obj[formattedDate] = {
              import_date: formattedDate,
              total_requests: 0,
            };
          }

          obj[formattedDate].total_requests++;
          return obj;
        },
        {},
      );
      this.setState({
        requestDataImportDate: Object.values(requestsBarChartImportDate),
      });

      let requestsBarChartImportHours = importRequest.reduce(
        (obj, requestData, index) => {
          let formattedDate = moment(requestData.import_date).format('HH:00');
          if (!obj[formattedDate]) {
            obj[formattedDate] = {
              import_date_hour: formattedDate,
              total_requests: 0,
            };
          }

          obj[formattedDate].total_requests++;
          return obj;
        },
        {},
      );

      let dataForImportHours = Object.values(requestsBarChartImportHours).sort(
        (a, b) => (a.import_date_hour > b.import_date_hour ? 1 : -1),
      );

      this.setState({
        requestDataImportHours: dataForImportHours,
      });

      let importRequestPostalCodeError = importRequest.filter(
        request => request.import_postal_code_error === true,
      );
      let requestsBarChartPostalCodeError = importRequestPostalCodeError.reduce(
        (obj, requestData, index) => {
          let formattedDate = moment(requestData.import_date).format(
            'YYYY-MM-DD',
          );
          if (!obj[formattedDate]) {
            obj[formattedDate] = {
              import_date: formattedDate,
              total_requests: 0,
            };
          }

          obj[formattedDate].total_requests++;
          return obj;
        },
        {},
      );
      this.setState({
        requestDataPostalCodeError: Object.values(
          requestsBarChartPostalCodeError,
        ),
      });

      const allBoxes = await apiHelper.getProducts({
        where: { category: 'box' },
      });
      let allBoxesData = allBoxes.data;
      let requestsBarChartOfBoxesUsed = importRequest.reduce(
        (obj, requestData, index) => {
          let boxInfo = allBoxesData.find(item => item.id === requestData.size);
          if (requestData.size === null || requestData.size === 0 || !boxInfo)
            return obj;
          if (!obj[boxInfo.id]) {
            obj[boxInfo.id] = {
              size: `${boxInfo.description} (${boxInfo.weight * 1000}gr)`,
              qnty: 0,
            };
          }
          obj[boxInfo.id].qnty++;
          return obj;
        },
        {},
      );
      this.setState({
        requestDataBoxesUsed: Object.values(requestsBarChartOfBoxesUsed),
      });

      let requestsBarChartSentToProvider = sentToProviderRequest.reduce(
        (obj, requestData, index) => {
          let formattedDate = moment(requestData.sent_to_provider_date).format(
            'YYYY-MM-DD',
          );
          if (!obj[formattedDate]) {
            obj[formattedDate] = {
              sent_to_provider_date: formattedDate,
              total_requests: 0,
              total_products: 0,
            };
          }

          let totalProducts = requestData.productRequestRows.map(
            prod => prod.quantity,
          );
          totalProducts = totalProducts.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0,
          );

          obj[formattedDate].total_requests++;
          obj[formattedDate].total_products =
            obj[formattedDate].total_products + totalProducts;
          return obj;
        },
        {},
      );

      let chartData = Object.values(requestsBarChartSentToProvider);
      let orderedChartData = chartData.sort((a, b) =>
        a.sent_to_provider_date > b.sent_to_provider_date ? 1 : -1,
      );
      this.setState({
        requestDataProductsXRequests: orderedChartData,
      });

      let requestDataSentToProviderHours = sentToProviderRequest.reduce(
        (obj, requestData, index) => {
          let formattedHour = moment(requestData.sent_to_provider_date).format(
            'HH:00',
          );
          if (!obj[formattedHour]) {
            obj[formattedHour] = {
              sent_to_provider_hour: formattedHour,
              total_requests: 0,
              total_products: 0,
            };
          }

          let totalProducts = requestData.productRequestRows.map(
            prod => prod.quantity,
          );
          totalProducts = totalProducts.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0,
          );

          obj[formattedHour].total_requests++;
          obj[formattedHour].total_products =
            obj[formattedHour].total_products + totalProducts;
          return obj;
        },
        {},
      );

      let dataForSentProviderHours = Object.values(
        requestDataSentToProviderHours,
      ).sort((a, b) =>
        a.sent_to_provider_hour > b.sent_to_provider_hour ? 1 : -1,
      );

      this.setState({
        requestDataSentToProviderHours: dataForSentProviderHours,
      });
      this.setState({ loadingExportReports: false });
    } catch (error) {
      const statusCode = error.response.status;
      if (statusCode === 400 || statusCode === 422 || statusCode === 204) {
        this.props.alert.error('Error al cargar Reportes de Ausencias');
      } else if (error.request) {
        this.props.alert.error('Sin respuesta del Servidor');
      } else {
        this.props.alert.error(error.message);
      }
    }
  }

  validateEmptyFilters(filters) {
    let filter;
    if (
      filters === null ||
      qs.stringify(filters, { skipNulls: true }).length == 0
    ) {
      filter = {
        where: {
          min_import_date: moment()
            .subtract(1, 'day')
            .endOf('day')
            .toDate()
            .toISOString(),
          max_import_date: moment().endOf('day').toDate().toISOString(),
        },
      };
    } else {
      filter = filters;
    }
    return filter;
  }

  render() {
    let loading;
    let requestDataWeightDate;
    let requestDataWeightHours;
    let requestDataWeightUser;
    let requestDataPickedDate;
    let requestDataPickedHours;
    let requestDataImportDate;
    let requestDataImportHours;
    let requestDataPostalCodeError;
    let requestDataBoxesUsed;
    let requestDataProductsXRequests;
    let requestDataProductsSentToProvider;
    let requestDataSentToProviderHours;

    let requestDataWeightDateTitle;
    let requestDataWeightHoursTitle;
    let requestDataWeightUserTitle;
    let requestDataPickedDateTitle;
    let requestDataPickedHoursTitle;
    let requestDataImportDateTitle;
    let requestDataImportHoursTitle;
    let requestDataPostalCodeErrorTitle;
    let requestDataBoxesUsedTitle;
    let requestDataProductsXRequestsTitle;
    let requestDataProductsSentToProviderTitle;
    let requestDataSentToProviderHoursTitle;

    if (this.state.loading) {
      loading = <Loading />;
    } else {
      requestDataWeightHours = (
        <RequestsBarChartOfWeightHours
          data={this.state.requestDataWeightHours}
        />
      );
      requestDataImportDate = (
        <RequestsBarChartOfImportDate data={this.state.requestDataImportDate} />
      );
      requestDataImportHours = (
        <RequestsBarChartOfImportHours
          data={this.state.requestDataImportHours}
        />
      );
      requestDataWeightDate = (
        <RequestsBarChartOfWeightDate data={this.state.requestDataWeightDate} />
      );
      requestDataPickedDate = (
        <RequestsBarChartOfPickedDate data={this.state.requestDataPickedDate} />
      );
      requestDataWeightUser = (
        <RequestsBarChartOfWeightByUser
          data={this.state.requestDataWeightUser}
        />
      );
      requestDataPickedHours = (
        <RequestBarChartOfLastPickedHours
          data={this.state.requestDataPickedHours}
        />
      );
      requestDataProductsXRequests = (
        <RequestBarChartOfProductsXRquests
          data={this.state.requestDataProductsXRequests}
        />
      );
      requestDataProductsSentToProvider = (
        <RequestBarChartOfProductsSentToProvider
          data={this.state.requestDataProductsXRequests}
        />
      );
      requestDataSentToProviderHours = (
        <RequestBarChartOfSentToProviderHours
          data={this.state.requestDataSentToProviderHours}
        />
      );

      requestDataPostalCodeError = (
        <RequestsBarChartOfPostalCodeError
          data={this.state.requestDataPostalCodeError}
        />
      );
      requestDataBoxesUsed = (
        <RequestBarChartOfBoxesUsed data={this.state.requestDataBoxesUsed} />
      );

      requestDataWeightHoursTitle = (
        <div>
          <h3>
            <span>Gráfico por hora de pesaje</span>
          </h3>
        </div>
      );
      requestDataImportDateTitle = (
        <div>
          <h3>
            <span>Gráfico por dia de importación</span>
          </h3>
        </div>
      );
      requestDataImportHoursTitle = (
        <div>
          <h3>
            <span>Gráfico por hora de importación</span>
          </h3>
        </div>
      );
      requestDataWeightDateTitle = (
        <div>
          <h3>
            <span>Gráfico por día de pesaje</span>
          </h3>
        </div>
      );
      requestDataWeightUserTitle = (
        <div>
          <h3>
            <span>Gráfico por usuario de pesaje</span>
          </h3>
        </div>
      );
      requestDataPickedDateTitle = (
        <div>
          <h3>
            <span>Gráfico por día de expedición</span>
          </h3>
        </div>
      );
      requestDataPickedHoursTitle = (
        <div>
          <h3>
            <span>Gráfico por hora de expedición</span>
          </h3>
        </div>
      );
      requestDataSentToProviderHoursTitle = (
        <div>
          <h3>
            <span>Gráfico por hora de envío a operador logístico</span>
          </h3>
        </div>
      );
      requestDataProductsXRequestsTitle = (
        <div>
          <h3>
            <span>Gráfico por día de envío a operador logístico</span>
          </h3>
        </div>
      );
      requestDataPostalCodeErrorTitle = (
        <div>
          <h3>
            <span>Gráfico por día de código postal con error</span>
          </h3>
        </div>
      );
      requestDataBoxesUsedTitle = (
        <div>
          <h3>
            <span>Gráfico por tamaño de caja</span>
          </h3>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className=" container">
          <h1 className="row text-nuskin">
            <div className="col-lg-6 title col-sm-12">
              <i className="fas fa-chart-bar"></i>
              <span>Reporte de Pedidos</span>
            </div>
          </h1>

          {/* 1st BTN ROW */}
          <div className="row justify-content-center pb-3 col-sm-12">
            <CSVLink
              ref={this.download}
              data={this.state.CSVdata}
              filename={this.state.CSVtitle}
              className="hidden"></CSVLink>
            {!this.state.loadingExports.export ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.exportRequests.bind(this)}>
                Exportar
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}
            {!this.state.loadingExports.exportSAP ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.exportSapRequests.bind(this)}>
                Exportar SAP
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte SAP...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}
            {!this.state.loadingExports.exportBoxes ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.exportBoxesBySize.bind(this)}>
                Exportar reporte de cajas
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte de cajas...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}
          </div>

          {/* 2nd BTN ROW */}
          <div className="row justify-content-center pb-3 col-sm-12">
            {!this.state.loadingExports.export ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.exportProductsOutOfStock.bind(this)}>
                Exportar Prod. s/Stock
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}
            {!this.state.loadingExports.exportProducts ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.exportTotalProducts.bind(this)}>
                Exportar productos
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte de productos...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}
            {!this.state.loadingExports.exportProducts ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.exportTotalReturnedProducts.bind(this)}>
                Exportar productos devueltos
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte de productos devueltos...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}
          </div>

          {/* 3rd BTN ROW */}
          <div className="row justify-content-center pb-3 col-sm-12">
            {!this.state.loadingExports.export ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.exportYearRequestReport.bind(this)}>
                Exportar reporte anual
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}

            {!this.state.loadingExports.export ? (
              <button
                type="button"
                className="btn btn-success export-button col-3"
                disabled={this.state.loadingExportReports}
                onClick={this.export12MonthsSentToProviderCount.bind(this)}>
                Exportar total despachados
                <i className="fas fa-file-export pl-1"></i>
              </button>
            ) : (
              <button
                className="btn btn-success export-button col-3"
                type="button"
                disabled>
                Exportando reporte...
                <span
                  className="spinner-border spinner-border-sm m-1"
                  role="status"
                  aria-hidden="true"></span>
              </button>
            )}
          </div>

          <RequestsFilters
            loadData={this.handleChangeFilters.bind(this)}
            isLoadingData={this.state.loadingExportReports}
          />
          {loading}
          {requestDataImportDateTitle}
          {requestDataImportDate}
          {requestDataImportHoursTitle}
          {requestDataImportHours}
          {requestDataWeightDateTitle}
          {requestDataWeightDate}
          {requestDataWeightHoursTitle}
          {requestDataWeightHours}
          {requestDataWeightUserTitle}
          {requestDataWeightUser}
          {requestDataPickedDateTitle}
          {requestDataPickedDate}
          {requestDataPickedHoursTitle}
          {requestDataPickedHours}
          {requestDataProductsXRequestsTitle}
          {requestDataProductsSentToProvider}
          {requestDataProductsXRequests}
          {requestDataSentToProviderHoursTitle}
          {requestDataSentToProviderHours}
          {!config.countryVersion?.noPostalCode &&
            requestDataPostalCodeErrorTitle}
          {!config.countryVersion?.noPostalCode && requestDataPostalCodeError}
          {requestDataBoxesUsedTitle}
          {requestDataBoxesUsed}
        </div>
        <Footer />
      </div>
    );
  }
}

RequestsReports.contextType = FiltersCacheContext;

export default withAlert()(RequestsReports);
