import React, { Component } from 'react';
import Modal from 'react-modal';
import apiHelpers from '../../../utils/apiHelpers';
import AlertMessage from '../../common/AlertMessage';

export class UnlinkReturnLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: this.props.requestId,
      isOpen: this.props.isOpen,
      loading: false,
      errorLinkingLabels: null,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  handleUnlinkLabel = async () => {
    this.setState({ loading: true });
    try {
      //save request
      const { data: unlinkResponse } =
        await apiHelpers.unlinkReturnIdLabelsOfAPI(this.state.requestId);

      this.props.closeModal();
      this.setState({
        loading: false,
      });
      window.location.reload();
    } catch (e) {
      console.log('Error update request: ' + e.message);
      this.setState({
        loading: false,
        errorLinkingLabels: 'Error desasociando etiqueta',
      });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={!this.state.loading && this.props.closeModal}
        className="fadeInDown modal-dialog modal-lg"
        contentLabel="Nota de entrega">
        <div className="modal-content text-center">
          <div className="col-md-12 modal-header">
            <div className="col-md-11">
              <span>
                <h2 className="fas fa-print fa-fw ml-5"></h2>
                <h3 className="ml-5">Desasociar etiquetas</h3>
              </span>
            </div>
            <div className="col-md-1">
              {!this.state.loading && (
                <button
                  type="button"
                  className="close"
                  onClick={this.props.closeModal}>
                  &times;
                </button>
              )}
            </div>
          </div>
          <div className="modal-body d-flex flex-column py-4">
            <h5 className="text-muted">
              Confirma eliminar la etiqueta asociada del pedido{' '}
              {this.props.currentRequest?.order_number} ?
            </h5>
            <div>
              <button
                type="button"
                className="btn btn-danger m-3 w-25"
                disabled={this.state.loading}
                onClick={this.handleUnlinkLabel}>
                Desasociar <i class="fas fa-unlink m-2"></i>
              </button>
              <button
                type="button"
                className="btn btn-secondary m-3 w-25"
                disabled={this.state.loading}
                onClick={this.props.closeModal}>
                Cancelar <i class="fas fa-backspace m-2"></i>
              </button>
            </div>
            {this.state.errorLinkingLabels && (
              <AlertMessage
                type="error"
                title={this.state.errorLinkingLabels}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default UnlinkReturnLabels;
