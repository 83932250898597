import apiHelper from '../../utils/apiHelpers';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import './OrderWeightDetail.css';
import SelectBoxSizeAsync from '../common/selects/SelectBoxSizeAsync';
import Tooltip from '../common/Tooltip';
import ProductRequestValidation from '../requests/productRequestValidation';
import SelectProductDescription from '../common/selects/SelectProductDescription';
import Modal from 'react-modal';
import moment from 'moment';
import RequestPackages from '../requests/RequestPackages';
import {
  downloadPDF,
  generateAsociatedLabel,
  generatePDF,
} from '../common/InvoicePDF';
import AlertMessage from '../common/AlertMessage';
import LinkLabels from './components/LinkLabels';
import config from '../../config/config';
import apiHelpers from '../../utils/apiHelpers';
import UnlinkLabels from './components/UnlinkLabels';

const initialStateOrderWeight = {
  id: '',
  deposit: '',
  customer_name: '',
  order_number: '',
  sale_date: '',
  ship_to_name: '',
  ship_to_street: '',
  ship_to_street_2: '',
  ship_to_city: '',
  home_phone: '',
  value: '',
  extra_value: '',
  tax: '',
  extra_tax: '',
  ship_to_postal_code: {
    deletedAt: null,
    id: 0,
    postalCode: '',
    provider: {
      apiKey: '',
      cuit: 0,
      deletedAt: null,
      denomination: '',
      email: '',
      id: 0,
      name: '',
      printLabels: '',
      shippingRequests: '',
    },
  },
  ship_to_region: '',
  ship_to_email: '',
  sold_to_street: '',
  sold_to_id: '',
  sold_to_street_2: '',
  size: '',
  sequence_number: '',
  cancelled: '',
  bill_number: '',
  weight: '',
  order_total: '',
  formatted_address: '',
  weight_hours: '',
  productRequestRows: [],
  user_weight_id: '',
  state_weight: '',
  observations: '',
  state_postal_code: '',
  packages: [],
  urlLabels: '',
  provider: null,
  mobile_phone: '',
  sent_to_provider: '',
  ship_to_district: '',
  invoice: {
    number_invoice: '',
    prints: [],
  },
  label_data: '',
};

export default class OrderWeightDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: false,
      isNew: props.match.params.id === 'new',
      order_weightId: props.match.params.id,
      order_weight: initialStateOrderWeight,
      user: null,
      order_number: '',
      modalIsOpen: false,
      notWeightDifference: true,
      modalOfWeightStateIsOpen: false,
      modalOfBoxIsOpen: false,
      saveRequest: false,
      printInvoice: false,
      modalInvoiceIsOpen: false,
      saveRequestWithoutBox: false,
      disabledButtonPrintInvoice: false,
      disabledButtonForInitialState: true,
      notShipToStreet: false,
      modalStreetIsOpen: false,
      modalWeightlessPackageIsOpen: false,
      boxOptions: [],
      message: null,
      loadingPrint: false,
      modalGiftIsOpen: false,
      linkLabelsModal: false,
      loadingPrintAsociated: false,
      unlinkLabelsModal: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.loadData();
  }

  async loadData() {
    let requestResponse;
    if (!this.state?.isNew) {
      requestResponse = await apiHelper.getRequest(this.state.order_weightId);
    }

    const userResponse = await apiHelper.getCurrentUser();

    const boxResponse = await apiHelper.getProducts({
      where: { category: 'box' },
    });

    if (!this.state.isNew) {
      if (
        (requestResponse.data.ship_to_street === '' ||
          !requestResponse.data.ship_to_street) &&
        (requestResponse.data.ship_to_street_2 === '' ||
          !requestResponse.data.ship_to_street_2)
      ) {
        this.setState({ notShipToStreet: true, modalStreetIsOpen: true });
      }
      this.setState(prevState => ({
        ...prevState,
        order_weight: requestResponse.data,
        disabledButtonForInitialState: false,
        user: userResponse.data,
        boxOptions: boxResponse.data,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        user: userResponse.data,
        boxOptions: boxResponse.data,
      }));
    }

    if (this.weight) {
      if (this.state.order_weight.weight === '0.000') {
        let orderWeight = this.state.order_weight;
        orderWeight.weight = '';
        this.setState({ order_weight: orderWeight });
      }
      this.weight.focus();
    } else {
      this.order_number.focus();
    }
  }

  handleChange(event) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const order_weight = this.state.order_weight;
    order_weight[name] = value;

    //Validate weight
    if (name === 'weight' || name === 'size' || name === 'packages') {
      const weight = name === 'weight' ? value : this.state.order_weight.weight;
      const size = name === 'size' ? value : this.state.order_weight.size;
      const order = JSON.parse(JSON.stringify(this.state.order_weight));
      order.weight = weight;
      order.size = size;
      let weightPackages = 0;
      if (order_weight.packages) {
        weightPackages = order_weight.packages.reduce(
          (totalWeightPackage, packageRequest) => {
            if (packageRequest.weight) {
              return totalWeightPackage + Number(packageRequest.weight);
            }
            return totalWeightPackage;
          },
          0,
        );
      }

      let weightRequest = Math.round(Number(order_weight.weight) * 1000) / 1000; // input
      const estimatedWeight =
        Math.round(
          formatter.getEstimatedWeight(order, this.state.boxOptions) * 1000,
        ) / 1000;

      let differenceOfWeight = Math.abs(
        estimatedWeight - parseFloat(weightRequest),
      );
      differenceOfWeight = Math.floor(differenceOfWeight * 1000) / 1000;

      let tolerance = Number(config.weight.toleranceValue);

      let percentTolerance =
        Number(estimatedWeight * config.weight.numberOfPercent) / 100;
      percentTolerance =
        Math.ceil((percentTolerance + Number.EPSILON) * 1000) / 1000; //this will math ceil the unit gram digit

      if (parseFloat(weightRequest) !== 0) {
        if (tolerance <= percentTolerance) {
          if (
            differenceOfWeight <= percentTolerance &&
            tolerance <= percentTolerance
          ) {
            order_weight.state_weight = 'PV';
            this.setState({ errorMessage: null });
          } else {
            order_weight.state_weight = 'PD';
          }
        } else {
          if (differenceOfWeight <= tolerance && percentTolerance < tolerance) {
            order_weight.state_weight = 'PV';
            this.setState({ errorMessage: null });
          } else {
            order_weight.state_weight = 'PD';
          }
        }
      } else {
        order_weight.state_weight = 'SP';
      }
    }

    this.setState({
      order_weight: order_weight,
    });
  }

  async deleteOrderWeight() {
    let order_weightId = this.state.isNew
      ? this.state.order_weight.id
      : this.state.order_weightId;
    apiHelper.deleteRequest(order_weightId);
    this.props.history.push('/orderWeight');
  }

  async onDownloadOneInvoiceSubmit(id) {
    this.setState({ loadingPrint: true });
    try {
      const response = await apiHelper.getRequestInvoice(id);

      if (
        this.state.order_weight.provider.printLabels === 'PDF' &&
        (config.countryVersion.noPostalCode ||
          (this.state.order_weight.ship_to_postal_code &&
            this.state.order_weight.ship_to_postal_code.postalCode))
      ) {
        // If the request's label should be generated locally as a PDF, generate it
        const request = response.data;
        await generatePDF(
          'Nota de entrega.pdf',
          [request],
          request.provider.name,
        );
        this.setState({ loadingPrint: false });
      } else {
        if (this.state.order_weight.provider.printLabels !== 'PDF') {
          // If the PDF should be generated by a third party service
          // Validate if it is contained in the previous response
          if (this.state.label_data) {
            let error = await downloadPDF(
              this.state.order_weight,
              0,
              this.state.label_data,
            );
            if (error) {
              this.setState({
                errorMessage: 'No se pudo obtener la etiqueta del proveedor',
              });
            }
            this.setState({ loadingPrint: false });
            return;
          } else if (!this.state.order_weight.urlLabels) {
            // If the request has an associated URL for label generation, use it
            let requestWithURL;
            let response = await apiHelper.getRequest(
              this.state.order_weightId,
            );
            requestWithURL = response.data;
            let error = await downloadPDF(requestWithURL);
            if (error) {
              this.setState({
                errorMessage: 'No se pudo obtener la etiqueta del proveedor',
              });
            }
            this.setState({ loadingPrint: false });
            return;
          } else {
            // If the label was already generated, just download it
            let error = await downloadPDF(this.state.order_weight);
            if (error) {
              this.setState({
                errorMessage: 'No se pudo obtener la etiqueta del proveedor',
              });
            }
            this.setState({ loadingPrint: false });
            return;
          }
        }
      }
    } catch (e) {
      console.log('Error printing invoice: ' + e);
      this.setState({
        errorMessage: 'No se pudo obtener la etiqueta del proveedor',
      });
      this.setState({ loadingPrint: false });
    }
  }

  async onDownloadAsociatedLabels(idLabel) {
    this.setState({ loadingPrintAsociated: true });
    try {
      const { data: asociatedRequests } = await apiHelpers.getRequests({
        where: { idLabelsOfAPI: idLabel },
      });
      await generateAsociatedLabel('Pedidos asociados.pdf', asociatedRequests);
      this.setState({ loadingPrint: false }, () => this.loadData());
      this.setState({
        loadingPrintAsociated: false,
      });
    } catch (error) {
      console.log(`Error imprimiendo etiqueta con pedidos asociados: ${error}`);
      this.setState({ loadingPrintAsociated: false });
    }
  }

  closeModalShipToStreet() {
    this.setState({ modalStreetIsOpen: false });
  }

  openModalWeightlessPackage() {
    this.setState({ modalWeightlessPackageIsOpen: true });
  }

  closeModalWeightlessPackage() {
    this.setState({ modalWeightlessPackageIsOpen: false });
  }

  openModalWeight() {
    this.setState({
      modalOfWeightStateIsOpen: true,
      notWeightDifference: false,
    });
  }

  closeModalWeight() {
    this.setState({ modalOfWeightStateIsOpen: false });
  }

  openModalBox() {
    this.setState({ modalOfBoxIsOpen: true });
  }

  closeModalBox() {
    this.setState(
      { modalOfBoxIsOpen: false, saveRequestWithoutBox: false },
      () => this.closeModalWeight(),
    );
  }

  async saveRequestWithDifferenceWeight() {
    await this.setState(
      {
        notWeightDifference: true,
        modalOfWeightStateIsOpen: false,
        saveRequest: true,
        modalOfBoxIsOpen: false,
        saveRequestWithoutBox: true,
      },
      () => this.submitOrderWeight(),
    );
  }

  async submitOrderWeight(event) {
    await this.setState({ label_data: '' });
    let order_weightId = this.state.isNewsaveRequest
      ? this.state.order_weight.id
      : this.state.order_weightId;

    try {
      //If order_weights already exists, update it. If not, create it
      const order_weight = this.state.order_weight;
      order_weight.user_weight_id = this.state.user.id;
      order_weight.weight_hours = new Date();
      if (order_weight.weight) {
        order_weight.weight = order_weight.weight.replace(',', '.');
      }

      // Checking if there are weightless packages
      if (order_weight.packages.some(pack => pack.weight <= 0)) {
        this.openModalWeightlessPackage();
        return;
      }

      if (
        order_weight.state_weight === 'PD' ||
        order_weight.state_weight === 'SP'
      ) {
        await this.setState({ notWeightDifference: false });
      } else {
        await this.setState({ saveRequest: true });
      }
      if (
        this.state.order_weight.size === null &&
        !this.state.saveRequestWithoutBox
      ) {
        await this.setState(
          { modalOfBoxIsOpen: true, saveRequest: false },
          () => this.openModalBox(),
        );
      }

      if (this.state.saveRequest) {
        this.setState({ disabledButtonForInitialState: true });

        let response = await apiHelper.patchRequest(
          order_weightId,
          order_weight,
        );

        if (response.status === 201) {
          let newState = this.state;
          let responseAsText = await response.data.text();
          let responseAsJSON = JSON.parse(responseAsText);
          newState.message = responseAsJSON.message
            ? responseAsJSON.message
            : order_weight.provider.shippingRequests === 'CSV'
            ? 'Pedido validado'
            : 'Pedido enviado a proveedor por API';
          this.setState(newState);
        } else if (response.status === 200) {
          let newState = this.state;
          if (order_weight.provider)
            newState.message =
              order_weight.provider.shippingRequests === 'CSV'
                ? 'Pedido validado'
                : 'Pedido enviado a proveedor por API';
          if (response.data) {
            newState.label_data = response.data;
          }
          this.setState(newState);
        }
      } else {
        await this.openModalWeight();
        this.setState({ disabledButtonForInitialState: false });
      }

      if (
        this.state.printInvoice &&
        this.state.order_weight.state_weight === 'PV' &&
        this.state.order_weight.provider
      ) {
        await this.onDownloadOneInvoiceSubmit(order_weightId);
      }

      if (this.state.saveRequest && this.state.isNew) {
        await this.setState({
          order_weight: initialStateOrderWeight,
          disabledButtonForInitialState: true,
        });
      }
      if (this.state.saveRequest && !this.state.isNew) {
        await this.setState({ disabledButtonForInitialState: true });
        if (
          !(
            this.state.printInvoice &&
            this.state.order_weight.state_weight === 'PV'
          )
        ) {
          this.props.history.push({
            pathname: '/orderWeight',
            state: this.state.message,
          });
        }
      }
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = await error.response.data.text();
      }

      this.setState({
        disabledButtonForInitialState: false,
        errorMessage: errorMessage,
      });
    }
    if (this.state.isNew) {
      this.order_number.focus();
    }
  }

  getRequestWithOrderNumber = order_number => {
    let filters = {
      where: {
        order_number: order_number,
      },
    };

    apiHelper
      .getRequests(filters)
      .then(requests => {
        if (requests.data.length > 0) {
          const matchRequest = requests.data.find(
            request => request.order_number === order_number,
          );
          if (matchRequest) {
            if (matchRequest.size === null && config.defaultBox) {
              matchRequest.size = config.defaultBox.size;
            }
            this.setState(
              oldState => ({
                ...oldState,
                order_weight: matchRequest,
                order_number: '',
                disabledButtonForInitialState: matchRequest.cancelled,
                order_weightId: matchRequest.id,
                errorMessage: false,
                message: null,
              }),
              () => {
                if (this.weight) {
                  if (this.state.order_weight.weight === '0.000') {
                    let orderWeight = this.state.order_weight;
                    orderWeight.weight = '';
                    this.setState({
                      order_weight: orderWeight,
                      disabledButtonForInitialState: false,
                      errorMessage: false,
                    });
                    this.weight.focus();
                  } else {
                    this.openModal();
                  }
                } else {
                  this.weight.focus();
                }
              },
            );

            if (
              (matchRequest.ship_to_street === '' ||
                !matchRequest.ship_to_street) &&
              (matchRequest.ship_to_street_2 === '' ||
                !matchRequest.ship_to_street_2)
            ) {
              this.setState({ notShipToStreet: true, modalStreetIsOpen: true });
            }

            if (matchRequest.with_gift) {
              this.setState({ modalGiftIsOpen: true });
            }
          }
        }
      })
      .catch(error => {
        const statusCode = error.response.status;
        if (statusCode === 400 || statusCode === 422 || statusCode === 204) {
          alert('Error al cargar datos de los pedidos');
        } else if (error.request) {
          alert('Sin respuesta del Servidor');
        } else {
          alert(error.message);
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.order_number !== prevState.order_number) {
      if (this.state.order_number.length >= 6) {
        const _this = this;
        let currOrderNumber = this.state.order_number;
        setTimeout(function () {
          if (currOrderNumber == _this.state.order_number)
            _this.getRequestWithOrderNumber(_this.state.order_number);
        }, 500);
      }
    }
  }

  handleChangePick = e => {
    this.setState({
      ...this.state,
      order_number: e.target.value.toString().trim(),
    });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeAlert() {
    this.setState({ errorMessage: false });
  }

  openModalInvoice() {
    this.setState({ modalInvoiceIsOpen: true });
    let today = moment().format('DD/MM/YYYY');

    if (
      formatter.formatDate(
        this.state.order_weight.invoice.prints[0].print_date,
      ) !== today &&
      !(apiHelper.isAdmin() || apiHelper.isSupervisor()) &&
      this.state.order_weight.ship_to_country !== 'CL'
    ) {
      this.setState({ disabledButtonPrintInvoice: true });
    }
  }

  closeModalInvoice() {
    this.setState({ modalInvoiceIsOpen: false });
  }

  printInvoice() {
    if (this.state.printInvoice) {
      this.setState({ printInvoice: false });
    } else {
      this.setState({ printInvoice: true });
    }
  }

  closeGiftModal() {
    this.setState({ modalGiftIsOpen: false });
  }

  openLinkLabelsModal = () => {
    //Validate weight
    if (
      this.state.order_weight.state_weight === 'PD' ||
      this.state.order_weight.state_weight === 'SP'
    ) {
      this.setState({ errorMessage: 'Error validando el peso' });
    } else {
      this.setState({ linkLabelsModal: true });
    }
  };

  closeLinkLabelsModal = () => {
    this.setState({ linkLabelsModal: false });
  };

  render() {
    let title = 'Pesaje de pedido ';
    let alert = '';
    let estimated_weight = '';
    let alertPostalCode = '';
    let modalInvoice;
    let number_invoice;
    let colorMark;

    if (
      this.state.order_weight.invoice !== null &&
      this.state.order_weight.invoice !== undefined
    ) {
      number_invoice = '' + this.state.order_weight.invoice.number_invoice;
      while (number_invoice.length < 8) {
        number_invoice = '0' + number_invoice;
      }
    }
    const isBalance =
      this.state.user && this.state.user.role.userRole === 'BALANCE'
        ? true
        : false;

    if (isBalance && this.state.order_weight.provider) {
      colorMark = (
        <div className="form-group col-md-6 d-flex justify-content-center align-items-center m-0 pt-4">
          <h4 className="w-100">
            <span
              className="badge p-2 w-100 d-flex justify-content-center align-items-center"
              style={{
                color: '#fff',
                backgroundColor:
                  this.state.order_weight.provider.colorMark ?? '#000000',
                height: '38px',
              }}>
              {this.state.order_weight.provider.name}
            </span>
          </h4>
        </div>
      );
    }

    const isAdmin =
      this.state.user && this.state.user.role.userRole === 'ADMIN'
        ? true
        : false;

    const isSupervisor =
      this.state.user && this.state.user.role.userRole === 'SUPERVISOR'
        ? true
        : false;

    const isManager =
      this.state.user && this.state.user.role.userRole === 'MANAGER'
        ? true
        : false;

    if (isAdmin || isSupervisor || isManager) {
      estimated_weight = (
        <div className="form-group col-md-6">
          <label htmlFor="estimated_weight"> Peso estimado</label>
          <input
            onChange={this.handleChange.bind(this)}
            name="estimated_weight"
            className="form-control"
            placeholder=" Peso estimado "
            value={formatter.getEstimatedWeight(
              this.state.order_weight,
              this.state.boxOptions,
            )}
            disabled
          />
        </div>
      );
    }

    if (
      this.state.order_weight.id &&
      this.state.order_weight.provider &&
      !(
        config.countryVersion.noPostalCode ||
        this.state.order_weight.ship_to_postal_code
      ) &&
      this.state.order_weight.provider.printLabels === 'PDF'
    ) {
      alertPostalCode = (
        <AlertMessage
          type="error"
          title="Este pedido no tiene código postal, su nota de entrega NO será"
        />
      );
    } else {
      if (this.state.order_weight.id && !this.state.order_weight.provider) {
        alertPostalCode = (
          <AlertMessage
            type="error"
            title="Este pedido no tiene proveedor asignado, su nota de entrega NO será impresa"
          />
        );
      }
    }

    if (
      this.state.modalInvoiceIsOpen &&
      this.state.order_weight.invoice.prints.length > 0
    ) {
      modalInvoice = (
        <Modal
          isOpen={this.state.modalInvoiceIsOpen}
          onRequestClose={this.closeModalInvoice.bind(this)}
          className="modal-dialog-email fadeInDown modal-dialog"
          contentLabel="Nota de entrega">
          <div className="modal-content text-center">
            <div className="col-md-12 modal-header">
              <div className="col-md-11">
                <span>
                  <h3 className="ml-5">
                    Nota de entrega
                    <i className="fas fa-print fa-sm fa-fw mr-2 text-gray-400"></i>
                  </h3>
                </span>
              </div>
              <div className="col-md-1">
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModalInvoice.bind(this)}>
                  &times;
                </button>
              </div>
            </div>
            <div className="modal-body">
              {this.state.order_weight?.isHeadLabelOfAPI && (
                <div className="row p-2">
                  <div className="col-sm-7">
                    <h5 className="font-italic font-weight-bold text-info">
                      Esta etiqueta es cabecera de más pedidos
                    </h5>
                  </div>
                  <div className="col-sm-5">
                    <button
                      className="btn btn-info btn-block"
                      onClick={() => {
                        this.onDownloadAsociatedLabels(
                          this.state.order_weight.idLabelsOfAPI,
                        );
                      }}
                      disabled={this.state.loadingPrintAsociated}>
                      {this.state.loadingPrintAsociated
                        ? 'Imprimiendo etiquetas...'
                        : 'Imprimir etiquetas asociadas'}{' '}
                      <i className="fas fa-file-pdf"></i>
                    </button>
                  </div>
                </div>
              )}
              {this.state.order_weight?.headLabelRequestId > 0 && (
                <div className="row py-3 ">
                  <div className="col-4">
                    <h5 className="text-info">Etiqueta asociada</h5>
                  </div>
                  <div className="btn-group col-8" role="group">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() =>
                        this.setState({
                          modalInvoiceIsOpen: false,
                          unlinkLabelsModal: true,
                        })
                      }>
                      Desasignar <i className="fas fa-window-close"></i>
                    </button>
                    <Tooltip
                      id={this.state.order_weight.id + 'edit'}
                      tooltipText="Reasignación de etiquetas"
                    />
                    <button
                      type="button"
                      className="btn btn-outline-warning"
                      onClick={() =>
                        this.setState({
                          modalInvoiceIsOpen: false,
                          linkLabelsModal: true,
                        })
                      }>
                      Reasignar <i className="fas fa-sitemap"></i>
                    </button>
                    <Tooltip
                      id={this.state.order_weight.id + 'edit'}
                      tooltipText="Reasignación de etiquetas"
                    />
                    <button
                      className="btn btn-outline-info"
                      onClick={() => {
                        this.props.history.push(
                          `/orderWeight/${this.state.order_weight.headLabelRequestId}`,
                        );
                        this.closeModalInvoice();
                        window.location.reload();
                      }}>
                      Ver cabecera <i className="fas fa-external-link-alt"></i>
                    </button>
                  </div>
                </div>
              )}

              <form method="post" action="" encType="multipart/form-data">
                <div className="dropdown-divider p-2"></div>

                <div className="row">
                  <div className="col-4">
                    <span className="align-content-center">
                      {' '}
                      Nota de entrega Nº: <b>{number_invoice}</b>
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="ml-4 align-content-center">
                      {' '}
                      Impresiones:{' '}
                      <b>{this.state.order_weight.invoice.prints.length}</b>
                    </span>
                  </div>
                  <div className="col-5">
                    {this.state.loadingPrint ? (
                      <button
                        type="button"
                        className="mr-2 pull-right btn btn-warning button-submit"
                        disabled>
                        Loading...{' '}
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="mr-2 pull-right btn btn-warning button-submit"
                        onClick={() => {
                          this.onDownloadOneInvoiceSubmit(
                            this.state.order_weight.id,
                          );
                        }}
                        disabled={
                          this.state.disabledButtonPrintInvoice &&
                          this.state.notShipToStreet
                        }>
                        Reimprimir nota de entrega{' '}
                        <i className="fas fa-file-pdf"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className="table-responsive overflow-auto my-2"
                  style={{ maxHeight: '300px' }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Usuario impresor</th>
                        <th>Fecha y hora de impresión</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.order_weight.invoice.prints.map(print => {
                        return (
                          <tr key={`${print.print_date}`}>
                            <td>{print.user}</td>
                            <td>
                              {formatter.formatDateTime(print.print_date)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <div key={this.state.order_weightId} className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3 mb-3">
          <h1 className="text-position-title">
            <i className="fas fa-weight"></i>
            <span> {title} </span>

            {function () {
              let buttonSubmit;
              {
                this.state.loadingPrint
                  ? (buttonSubmit = (
                      <button
                        className="pull-right btn btn-primary"
                        type="button"
                        disabled>
                        Loading...
                        <span
                          className="spinner-border spinner-border-sm m-1"
                          role="status"
                          aria-hidden="true"></span>
                      </button>
                    ))
                  : !this.state.disabledButtonForInitialState &&
                    !this.state.notShipToStreet
                  ? (buttonSubmit = (
                      <button
                        className="button-submit pull-right btn btn-primary "
                        tabIndex="4"
                        onClick={this.submitOrderWeight.bind(this)}
                        type="submit">
                        Validar <i className="fas fa-save"></i>
                      </button>
                    ))
                  : (buttonSubmit = (
                      <button
                        className="button-submit pull-right btn btn-primary "
                        tabIndex="4"
                        onClick={this.submitOrderWeight.bind(this)}
                        type="submit"
                        disabled>
                        Validar <i className="fas fa-save"></i>
                      </button>
                    ));
              }

              return buttonSubmit;
            }.bind(this)()}

            <div className="col-lg-3 alert alert-info print pull-right p-2 mr-2">
              <input
                className="ml-2 form-check-input"
                type="checkbox"
                id="printInvoice"
                name="printInvoice"
                checked={this.state.printInvoice}
                onChange={this.printInvoice.bind(this)}
              />
              <h5 className="ml-4 align-content-center">
                {' '}
                Imprimir nota de entrega
              </h5>
            </div>

            {!this.state.disabledButtonForInitialState &&
              (this.state.order_weight.invoice !== null &&
              this.state.order_weight.sent_to_provider ? (
                <button
                  type="button"
                  className="mr-2 pull-right btn btn-warning button-submit"
                  onClick={() => {
                    this.openModalInvoice();
                  }}>
                  Nota de entrega <i className="fas fa-file-pdf"></i>
                </button>
              ) : (
                <span
                  data-tip
                  data-for={this.state.order_weight.id + 'invoice'}>
                  {config.countryVersion.linkLabelsForPP ? (
                    <>
                      <button
                        type="button"
                        className="mr-2 pull-right btn btn-warning button-submit"
                        onClick={this.openLinkLabelsModal}
                        disabled={
                          this.state.errorMessage ||
                          alertPostalCode !== '' ||
                          this.state.order_weight.cancelled
                        }>
                        Asociar a etiqueta <i className="fas fa-file-pdf"></i>
                      </button>
                      <Tooltip
                        id={this.state.order_weight.id + 'invoice'}
                        tooltipText="Asociar a una etiqueta en curso"
                      />
                    </>
                  ) : (
                    <>
                      <button
                        data-tip
                        type="button"
                        className="mr-2 pull-right btn btn-warning button-submit"
                        data-for={this.state.order_weight.id + 'invoice'}
                        disabled>
                        Nota de entrega <i className="fas fa-file-pdf"></i>
                      </button>
                      <Tooltip
                        id={this.state.order_weight.id + 'invoice'}
                        tooltipText="No hay remito generado en la fecha"
                      />
                    </>
                  )}
                </span>
              ))}

            <div className="margin-top-picking td-flex flex-column h-100">
              <div className="form-group">
                <input
                  name="order_number"
                  type="text"
                  className="form-control"
                  tabIndex="1"
                  ref={input => {
                    this.order_number = input;
                  }}
                  placeholder="No definido"
                  value={this.state.order_number}
                  onChange={this.handleChangePick.bind(this)}
                />
              </div>
            </div>
          </h1>
          {this.state.order_weight.cancelled && (
            <AlertMessage
              type="error"
              title="Pedido cancelado, no podrá cargar peso ni tamaño de caja"
            />
          )}
          {this.state.message !== null && (
            <AlertMessage type="success" title={`${this.state.message}`} />
          )}
          {this.state.errorMessage && (
            <AlertMessage type="error" title={`${this.state.errorMessage}`} />
          )}
          {modalInvoice}
          {alertPostalCode}
          {this.state.order_number.length == 0 &&
            this.state.order_weight.id.length != 0 && (
              <div className="row">
                <div className="col-md-4 creation-info p-3">
                  <h5 className="h5 text-center ">Datos del pedido</h5>

                  <div className="form-group">
                    <label htmlFor="order_number"> Número de pedido </label>
                    <input
                      value={this.state.order_weight.order_number}
                      className="form-control"
                      placeholder="Número de pedido"
                      onChange={this.handleChange.bind(this)}
                      required
                      disabled
                    />
                  </div>

                  <div className=" form-group">
                    <label htmlFor="sale_date"> Fecha de venta </label>
                    <input
                      name="sale_date"
                      className="form-control"
                      value={formatter.formatDate(
                        this.state.order_weight.sale_date,
                      )}
                      onChange={this.handleChange.bind(this)}
                      disabled
                    />
                  </div>

                  <div className=" form-group">
                    <label htmlFor="print_date"> Fecha de impresión </label>
                    <input
                      name="print_date"
                      className="form-control"
                      value={formatter.formatDate(
                        this.state.order_weight.print_date,
                      )}
                      onChange={this.handleChange.bind(this)}
                      required
                      disabled
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="sold_to_id">
                      {' '}
                      Última fecha de expedición{' '}
                    </label>
                    <input
                      name="last_picked_date"
                      value={
                        this.state.order_weight.last_picked_date
                          ? formatter.formatDate(
                              this.state.order_weight.last_picked_date,
                            )
                          : 'Sin expedición'
                      }
                      className="form-control"
                      placeholder="Sin expedición"
                      required
                      disabled
                    />
                  </div>

                  {!this.state.order_weight.cancelled ? (
                    <div>
                      <div className=" form-group">
                        <label htmlFor="size"> Tamaño de caja </label>
                        <SelectBoxSizeAsync
                          name="size"
                          tabIndex="3"
                          value={this.state.order_weight.size}
                          placeholder=" Tamaño de caja "
                          onChange={this.handleChange.bind(this)}
                          required
                        />
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="weight"> Peso total </label>
                          <input
                            name="weight"
                            value={this.state.order_weight.weight}
                            tabIndex="2"
                            ref={input => {
                              this.weight = input;
                            }}
                            className="form-control"
                            placeholder="Peso total"
                            onChange={this.handleChange.bind(this)}
                            required
                          />
                        </div>
                        {colorMark}

                        {estimated_weight}
                      </div>
                      <div>
                        <div className="col-md-12  p-3">
                          <RequestPackages
                            name="packages"
                            value={this.state.order_weight.packages}
                            onChange={this.handleChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="weight"> Peso total </label>
                          <input
                            name="weight"
                            value={this.state.order_weight.weight}
                            tabIndex="2"
                            ref={input => {
                              this.weight = input;
                            }}
                            className="form-control"
                            placeholder="Peso total"
                            onChange={this.handleChange.bind(this)}
                            required
                            disabled
                          />
                        </div>
                        {colorMark}

                        {estimated_weight}
                      </div>

                      <div className=" form-group">
                        <label htmlFor="size"> Tamaño de caja </label>
                        <SelectBoxSizeAsync
                          name="size"
                          tabIndex="3"
                          value={this.state.order_weight.size}
                          placeholder=" Tamaño de caja "
                          onChange={this.handleChange.bind(this)}
                          required
                          isDisabled
                        />
                      </div>
                      <div>
                        <div className="col-md-12  p-3">
                          <RequestPackages
                            name="packages"
                            value={this.state.order_weight.packages}
                            onChange={this.handleChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-4 creation-info p-3">
                  <h5 className="h5 text-center ">Productos</h5>

                  {this.state.order_weight.productRequestRows.map(
                    productRequest => {
                      return (
                        <div key={productRequest.id} className="row mb-2">
                          <div className="form-group col-md-9">
                            <SelectProductDescription
                              name="weight"
                              value={productRequest.material}
                              onChange={this.handleChange.bind(this)}
                              isDisabled={true}
                            />
                          </div>

                          <div className="form-group col-md-3">
                            <input
                              name="weight"
                              value={productRequest.quantity}
                              className="form-control"
                              disabled
                            />
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>

                <div className="col-md-4 creation-info p-3">
                  <h5 className="h5 text-center">Validaciones</h5>
                  <ProductRequestValidation
                    render={(
                      addressValidation,
                      postalCodeValidation,
                      productsWeightValidation,
                      weightValidation,
                    ) => {
                      return (
                        <div>
                          <div className="form-group ">
                            {apiHelper.isAdmin() || apiHelper.isSupervisor() ? (
                              <span
                                className={
                                  'fa fa-home ml-3 ' +
                                  addressValidation(this.state.order_weight)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'address'
                                }>
                                <Tooltip
                                  id={this.state.order_weight.id + 'address'}
                                />
                                {
                                  addressValidation(this.state.order_weight)
                                    .text
                                }
                              </span>
                            ) : (
                              <h4
                                className={
                                  'fa fa-home ml-3 ' +
                                  addressValidation(this.state.order_weight)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'address'
                                }>
                                <Tooltip
                                  id={this.state.order_weight.id + 'address'}
                                />
                                {
                                  addressValidation(this.state.order_weight)
                                    .text
                                }
                              </h4>
                            )}
                          </div>
                          <div className="form-group ">
                            {apiHelper.isAdmin() || apiHelper.isSupervisor() ? (
                              <span
                                className={
                                  'fa fa-mail-bulk ml-3 ' +
                                  postalCodeValidation(this.state.order_weight)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'postalCode'
                                }>
                                <Tooltip
                                  id={this.state.order_weight.id + 'postalCode'}
                                />
                                {
                                  postalCodeValidation(this.state.order_weight)
                                    .text
                                }
                              </span>
                            ) : (
                              <h4
                                className={
                                  'fa fa-mail-bulk ml-3 ' +
                                  postalCodeValidation(this.state.order_weight)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'postalCode'
                                }>
                                <Tooltip
                                  id={this.state.order_weight.id + 'postalCode'}
                                />
                                {
                                  postalCodeValidation(this.state.order_weight)
                                    .text
                                }
                              </h4>
                            )}
                          </div>
                          <div className="form-group ">
                            {apiHelper.isAdmin() || apiHelper.isSupervisor() ? (
                              <span
                                className={
                                  'fa fa-box ml-3 ' +
                                  productsWeightValidation(
                                    this.state.order_weight,
                                  ).class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'productsWeight'
                                }>
                                <Tooltip
                                  id={
                                    this.state.order_weight.id +
                                    'productsWeight'
                                  }
                                />
                                {
                                  productsWeightValidation(
                                    this.state.order_weight,
                                  ).text
                                }
                              </span>
                            ) : (
                              <h4
                                className={
                                  'fa fa-box ml-3 ' +
                                  productsWeightValidation(
                                    this.state.order_weight,
                                  ).class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'productsWeight'
                                }>
                                <Tooltip
                                  id={
                                    this.state.order_weight.id +
                                    'productsWeight'
                                  }
                                />
                                {
                                  productsWeightValidation(
                                    this.state.order_weight,
                                  ).text
                                }
                              </h4>
                            )}
                          </div>
                          <div className="form-group">
                            {apiHelper.isAdmin() || apiHelper.isSupervisor() ? (
                              <span
                                className={
                                  'fa fa-weight ml-3 ' +
                                  weightValidation(
                                    this.state.order_weight,
                                    this.state.boxOptions,
                                  ).class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'weight'
                                }>
                                <Tooltip
                                  id={this.state.order_weight.id + 'weight'}
                                />
                                {
                                  weightValidation(
                                    this.state.order_weight,
                                    this.state.boxOptions,
                                  ).text
                                }
                              </span>
                            ) : (
                              <h4
                                className={
                                  'fa fa-weight ml-3 ' +
                                  weightValidation(
                                    this.state.order_weight,
                                    this.state.boxOptions,
                                  ).class
                                }
                                data-tip
                                data-for={
                                  this.state.order_weight.id + 'weight'
                                }>
                                <Tooltip
                                  id={this.state.order_weight.id + 'weight'}
                                />
                                {
                                  weightValidation(
                                    this.state.order_weight,
                                    this.state.boxOptions,
                                  ).text
                                }
                              </h4>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <div className="  form-group">
                    <label htmlFor="observations"> Observaciones </label>
                    <textarea
                      onChange={this.handleChange.bind(this)}
                      name="observations"
                      className="form-control width-observations"
                      placeholder=" "
                      value={this.state.order_weight.observations}
                    />
                  </div>
                </div>
              </div>
            )}
        </div>
        <iframe className="pdf-frame" id="pdf-frame"></iframe>
        <Footer />

        <LinkLabels
          isOpen={this.state.linkLabelsModal}
          closeModal={this.closeLinkLabelsModal}
          requestId={this.state.order_weightId}
          currentRequest={this.state.order_weight}
          weight={this.state.order_weight.weight}
          size={this.state.order_weight.size}
          getRequestWithOrderNumber={this.getRequestWithOrderNumber}
        />

        <UnlinkLabels
          isOpen={this.state.unlinkLabelsModal}
          closeModal={() => this.setState({ unlinkLabelsModal: false })}
          requestId={this.state.order_weightId}
          currentRequest={this.state.order_weight}
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          className="modal-dialog fadeInDown"
          contentLabel="Pedido ya pesado">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={this.closeModal.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h3>El pedido ya tiene su peso cargado</h3>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalOfWeightStateIsOpen}
          onRequestClose={this.closeModalWeight.bind(this)}
          className="modal-dialog fadeInDown"
          contentLabel="Peso sin validar">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={this.closeModalWeight.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h3>El pedido tiene diferencia de peso</h3>
              <h4 className="mt-2">¿Desea validar de igual manera?</h4>
              <button
                className="button-delete-modal btn btn-success some-top-air"
                type="button"
                onClick={this.saveRequestWithDifferenceWeight.bind(this)}>
                {' '}
                Si
              </button>
              <button
                className="button-delete-modal btn btn-danger some-top-air"
                type="button"
                onClick={this.closeModalWeight.bind(this)}>
                {' '}
                No
              </button>
              <AlertMessage
                type="error"
                title="La Nota de entrega no será impresa"
              />
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalOfBoxIsOpen}
          onRequestClose={this.closeModalBox.bind(this)}
          className="modal-dialog fadeInDown"
          contentLabel="Tamaño de caja">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={this.closeModalBox.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h3>El pedido no tiene tamaño de caja asignado</h3>
              <h4 className="mt-2">¿Desea validar de igual manera?</h4>
              <button
                className="button-delete-modal btn btn-success some-top-air"
                type="button"
                onClick={this.saveRequestWithDifferenceWeight.bind(this)}>
                {' '}
                Si
              </button>
              <button
                className="button-delete-modal btn btn-danger some-top-air"
                type="button"
                onClick={this.closeModalBox.bind(this)}>
                {' '}
                No
              </button>
              <AlertMessage
                type="error"
                title="La Nota de entrega no será impresa"
              />
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalStreetIsOpen}
          onRequestClose={this.closeModalShipToStreet.bind(this)}
          className="modal-dialog fadeInDown"
          contentLabel="Pedido sin dirección">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={this.closeModalShipToStreet.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h3>El pedido no tiene dirección asignada</h3>
              <h4 className="mt-2">No podrá cargar el peso del pedido</h4>
              <button
                className="button-delete-modal btn btn-success some-top-air"
                type="button"
                onClick={this.closeModalShipToStreet.bind(this)}>
                {' '}
                Aceptar
              </button>
              <AlertMessage
                type="error"
                title="La Nota de entrega no será impresa"
              />
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalGiftIsOpen}
          onRequestClose={this.closeGiftModal.bind(this)}
          className="modal-dialog fadeInDown"
          contentLabel="Pedido ya pesado">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={this.closeGiftModal.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h3>Este pedido contiene regalo/os</h3>
              <button
                className="btn btn-primary some-top-air w-50"
                type="button"
                onClick={this.closeGiftModal.bind(this)}>
                {' '}
                Aceptar
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalWeightlessPackageIsOpen}
          onRequestClose={this.closeModalWeightlessPackage.bind(this)}
          className="modal-dialog fadeInDown"
          contentLabel="Pedido sin dirección">
          <div className="modal-content text-center">
            <div className="modal-header">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={this.closeModalWeightlessPackage.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h3>El pedido tiene bultos sin peso</h3>
              <h4 className="mt-2">Verifique el peso de los bultos</h4>
              <button
                className="button-delete-modal btn btn-success some-top-air"
                type="button"
                onClick={this.closeModalWeightlessPackage.bind(this)}>
                {' '}
                Aceptar
              </button>
              <AlertMessage type="error" title="Bultos sin peso cargado" />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
