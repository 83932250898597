import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import Pagination from '../common/Pagination';
import './PostalCode.css';
import Modal from 'react-modal';
import PostalCodeFilters from './PostalCodeFilters';
import { CSVLink } from 'react-csv';
import AlertMessage from '../common/AlertMessage';

export default class PostalCodes extends React.Component {
  download = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      postalCodes: [],
      modalIsOpen: false,
      count: 0,
      newPostalCodes: null,
      pageSize: 25,
      currentPage: 1,
      loading: false,
      importErrors: null,
      importSuccess: false,
      CSVdata: '',
      filters: {},
      haveDocumentImport: false,
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  closeWarning() {
    this.setState({ importErrors: null });
  }

  closeSuccess() {
    this.setState({ importSuccess: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getPostalCodes();
    });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getPostalCodes();
    });
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  async onExportSubmit() {
    const { filters } = this.state;
    try {
      const response = await apiHelper.getExportPostalCodes({
        filter: filters,
      });
      this.setState({ CSVdata: response.data }, () => {
        this.download.current.link.click();
      });
    } catch (e) {
      console.log('Error loading requests');
    }
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({
      newPostalCodes: event.target.files[0],
      haveDocumentImport: true,
    });
  }

  async importPostalCodes() {
    this.setState({ loading: true });
    const response = await apiHelper.importPostalCodes(
      this.state.newPostalCodes,
    );
    if (response.data.success) {
      this.closeModal();
      this.setState({ importErrors: null, loading: false });
      this.setState({ importSuccess: true });
    } else {
      this.closeModal();
      const errors = response.data.errors;
      this.setState({ importErrors: errors, loading: false });
    }
    this.getPostalCodes({ query: { limit: 10, skip: 0 } });
  }

  async getPostalCodes() {
    const { filters, currentPage, pageSize } = this.state;

    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [response, postalCodesCount] = await Promise.all([
        await apiHelper.getPostalCodes(filtersWithPaging),
        await apiHelper.getPostalCodesCount(filters),
      ]);

      this.setState({
        postalCodes: response.data,
        count: postalCodesCount.data.count,
      });
    } catch (e) {
      console.log('Error loading postalCodes');
    }
  }

  componentDidMount() {
    //  this.getPostalCodes();
  }

  render() {
    let modalImportSuccess = '';

    if (this.state.importErrors !== null) {
      modalImportSuccess = (
        <AlertMessage
          type="warning"
          title="Error de importación"
          onClose={this.closeWarning.bind(this)}>
          {this.state.importErrors.map(importErrors => {
            return (
              <tr key={importErrors.line}>
                <td>
                  <p>
                    {'Error en la linea '}
                    {importErrors.line}:<b> {importErrors.message}</b>
                  </p>
                </td>
              </tr>
            );
          })}
        </AlertMessage>
      );
    } else {
      if (
        this.state.importErrors === null &&
        this.state.importSuccess === true
      ) {
        modalImportSuccess = (
          <AlertMessage
            type="success"
            title="Importación"
            onClose={this.closeSuccess.bind(this)}>
            <strong>
              Todos los códigos postales se importaron correctamente
            </strong>
          </AlertMessage>
        );
      }
    }
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />

        <div className="container">
          <h1 className=" row text-nuskin">
            <div className="col-lg-7 title col-sm-12">
              <i className="fas fa-map-marked-alt mt-3"></i>{' '}
              <span>Listado de códigos postales</span>
            </div>
            <div className="row margin-import col-lg-5 col-sm-12 ">
              <div className=" col-sm-12 col-lg-4">
                <Link to={`${process.env.PUBLIC_URL}/postalCode/new`}>
                  <button
                    type="button"
                    className="btn w-100 btn-success pull-right some-air">
                    Agregar <i className="fas fa-plus-square"></i>
                  </button>
                </Link>
              </div>
              <div className="col-sm-12 col-lg-4">
                <button
                  type="button"
                  className="btn w-100  btn-success pull-right some-top-air"
                  onClick={this.openModal.bind(this)}>
                  Importar <i className="fas fa-file-import"></i>
                </button>
              </div>
              <div className="col-sm-12 col-lg-4">
                <button
                  type="button"
                  className="btn w-100 btn-success pull-right some-air"
                  onClick={this.onExportSubmit.bind(this)}>
                  Exportar
                  <i className="fas fa-file-export"></i>
                </button>
                <CSVLink
                  ref={this.download}
                  data={this.state.CSVdata}
                  filename="Códigos postales.csv"
                  className="hidden"></CSVLink>
              </div>
            </div>
          </h1>

          <Modal
            isOpen={this.state.modalIsOpen}
            onPostalCodeClose={this.closeModal.bind(this)}
            className="modal-dialog fadeInDown"
            contentLabel="Importar postalCodeos">
            <div className="modal-content text-center">
              <div className="modal-header">
                <span className="import-title">Importar códigos postales</span>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <a
                  href="/docs/modelo_para_codigos_postales.csv"
                  className=" creation-info alert-dismissible dropdown-item"
                  target="_blank">
                  <i className="fas fa-file-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
                  Archivo modelo
                </a>

                <form method="post" action="" encType="multipart/form-data">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className=" mt-3 mb-3 import-field"
                    onChange={this.onChangeFile.bind(this)}
                  />
                  <button
                    type="button"
                    className="btn btn-info"
                    id="upload"
                    onClick={this.importPostalCodes.bind(this)}
                    disabled={
                      this.state.loading || !this.state.haveDocumentImport
                    }>
                    Importar codigos postales{' '}
                    {this.state.loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </Modal>

          <PostalCodeFilters loadData={this.handleChangeFilters.bind(this)} />

          <div className="table-responsive">
            {modalImportSuccess}
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Código postal</th>
                  <th scope="col">Proveedor</th>
                  <th scope="col">Entrega estimada (días)</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.postalCodes.map(postalCode => {
                  const actionColumn = (
                    <td>
                      <div className="text-center">
                        <Link
                          to={`${process.env.PUBLIC_URL}/postalCode/${postalCode.id}`}>
                          <button
                            type="button"
                            className="btn btn-primary btn-circle">
                            <span className="fa fa-edit"></span>
                          </button>
                        </Link>
                      </div>
                    </td>
                  );
                  return (
                    <tr key={postalCode.id}>
                      <td>{postalCode.postalCode}</td>
                      <td>{postalCode.provider.name}</td>
                      <td>
                        {postalCode.estimatedDeliveryTime >= 0
                          ? postalCode.estimatedDeliveryTime
                          : 'N/A'}
                      </td>
                      {actionColumn}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-center">
          <Pagination
            onChangePage={this.handleChangePage.bind(this)}
            currentPage={this.state.currentPage}
            totalPages={this.getNumberOfPages()}
          />
        </div>
        <Footer />
      </div>
    );
  }
}
