module.exports.backend = {
    apiUrl: "https://pickingcl.venturing.com.ar/vPicking/api",
    wsUrl: "wss://pickingcl.venturing.com.ar",
    wsPath: "/vPicking/ws/"
};

module.exports.weight = {
    numberOfPercent : 1.5,
    toleranceValue: 0.020,
};

module.exports.sizeOfPdf = {
    width: 425 ,
    high: 566,
};

module.exports.countryVersion = {
    currentCountry: 'CL', // AR - CL - MX - CO - PE
    pickUpFeatures: true,
    noPostalCode: true,
    backLogDashboard: true,
    linkLabelsForPP: true,
    vpicking_integrations: [
    ],
}

module.exports.defaultBox = {
    size: 0
};

module.exports.giftsFeature = {
    active: true
};

module.exports.validatePicking = {
  active: true
};

module.exports.pickUp = {
    maximum_days_of_storage: 0,
};

module.exports.validateDelivery = {
  active: false
}
