import React from 'react';
import Select from 'react-select';

export default class SelectGIftConfigurationState extends React.Component {
  constructor(props) {
    super(props);

    this.options = [
      { value: true, label: 'Activa' },
      { value: false, label: 'Inactiva' },
    ];
  }

  onChangeWrapper(value) {
    let event = { target: {} };
    event.target.name = this.props.name ? this.props.name : 'select-nameless';
    event.target.value = value ? value.value : null;
    event.target.type = 'react-select';
    this.props.onChange(event);
  }

  render() {
    let value = this.props.value;

    if (value !== undefined) {
      value = this.options.find(option => option.value === value);
    }

    return (
      <Select
        className={this.props.selectClassName}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={value || ''}
        required={this.props.required}
        options={this.options}
        isClearable={
          this.props.isClearable !== undefined ? this.props.isClearable : true
        }
        placeholder={this.props.placeholder}
        isDisabled={this.props.isDisabled}
        menuContainerStyle={{ zIndex: 999 }}
      />
    );
  }
}
