import React, { Component } from 'react';
import Loading from '../../common/Loading';
import CustomSelect from '../../common/selects/CustomSelect';
import LatamBoxesBoard from './boards/LatamBoxesBoard';
import LatamRequestsBoard from './boards/LatamRequestsBoard';
import CountryFlag from '../../common/ContryFlag';
import { LatamBoardHelpers } from './boards/LatamBoardHelpers';
import moment from 'moment';
import { CSVLink } from 'react-csv';

class LatamCountriesBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: props.country,
      countryColor: {
        AR: 'rgba(117,170,219, 0.5)',
        CL: 'rgba(235, 80, 70, 0.5)',
        CO: 'rgba(255, 205, 0, 0.5)',
        PE: 'rgb(255, 255, 255)',
      },
      openBoard: false,
      requestReportsData: props.data,
      provinceFilter: null,
      dateRangeFilter: null,
      LATAMreportsToExport: null,
      loadingExport: false,
      CSVdata: '',
      CSVtitle: '',
    };
  }

  download = React.createRef();

  handleToggleBoard = e => {
    this.setState(prev => {
      return {
        ...prev,
        openBoard: !prev.openBoard,
      };
    });
  };

  handleProvinceFilter = e => {
    const value = e.target.value;
    this.setState(prev => {
      return {
        ...prev,
        provinceFilter: value ?? null,
      };
    });
  };

  handleDateRangeFilter = e => {
    const value = e.target.value;
    this.setState(prev => {
      return {
        ...prev,
        dateRangeFilter: value ?? null,
      };
    });
  };

  handleExportReport = () => {
    this.setState({
      loadingExportReturns: true,
    });
    let data = this.state.requestReportsData;

    if (this.state.provinceFilter) {
      data = data.filter(
        elem =>
          elem.province === this.state.provinceFilter ||
          elem.province === 'Todo el país',
      );
    }

    if (this.state.dateRangeFilter) {
      if (this.state.dateRangeFilter === 'all') {
        let consolidatedRequestsData = LatamBoardHelpers.consolidateDates(data);
        let consolidatedRequestsBoxesData =
          LatamBoardHelpers.consolidateDatesInBoxes(data);
        data = consolidatedRequestsData
          .map(elem => {
            return {
              ...elem,
              report_date: `${new Date(
                data.at(-1).report_date,
              ).toLocaleDateString()} - ${new Date(
                data.at(0).report_date,
              ).toLocaleDateString()}`,
              boxes_report: consolidatedRequestsBoxesData.find(
                currentElement => {
                  return currentElement.province === elem.province;
                },
              ).boxes_report,
            };
          })
          .sort((a, b) => (a.imported_count < b.imported_count ? 1 : -1));
      } else {
        data = data.filter(elem => {
          return (
            new Date(elem.report_date).toLocaleDateString() ===
            this.state.dateRangeFilter
          );
        });
      }
    }
    const csv = LatamBoardHelpers.generateCSVReportLATAM(data);
    this.setState(
      {
        loadingExportReturns: false,
        CSVdata: csv,
        CSVtitle: `Reporte LATAM - ${moment().format('DD-MM-YYYY')}.csv`,
      },
      () => {
        this.download.current.link.click();
      },
    );
  };

  render() {
    return (
      <>
        <button
          className={`btn btn-lg btn-block my-4 creation-info`}
          style={{
            backgroundColor: this.state.countryColor[this.state.country],
          }}
          onClick={this.handleToggleBoard}>
          <div className="d-flex justify-content-center w-100">
            <div className="d-flex justify-content-center w-100 mx-4 mt-3">
              <h5 className="font-weight-bold text-dark mr-3">{`${
                this.state.openBoard ? 'Ocultar' : 'Ver'
              } reportes ${this.state.country}`}</h5>

              {!this.state.openBoard ? (
                <i className="fas fa-eye"></i>
              ) : (
                <i className="fas fa-eye-slash"></i>
              )}
            </div>
            <div className="m-1">
              <CountryFlag
                country={`${this.state.country}`}
                size="md"
                className="p-0 m-0"
              />
            </div>
          </div>
        </button>

        <div className={`collapse ${this.state.openBoard && 'show'}`}>
          {this.state.country === null ? (
            <Loading />
          ) : (
            <>
              <div className="creation-info p-4 mx-4">
                <div className="p-2 w-100 d-flex justify-content-between">
                  <div
                    className="input-group"
                    style={{
                      width:
                        this.state.requestReportsData?.length > 25
                          ? '38%'
                          : '60%',
                    }}>
                    <div className="input-group-prepend w-50">
                      <span className="input-group-text w-100 bg-warning font-weight-bold">
                        Filtro por provincia
                      </span>
                    </div>
                    <CustomSelect
                      labelAll="Todo el país"
                      options={[
                        ...new Set(
                          this.state.requestReportsData
                            .map(req => req.province)
                            .filter(option => option !== 'Todo el país'),
                        ),
                      ]}
                      value={this.state.provinceFilter}
                      selectClassName="w-50"
                      onChange={this.handleProvinceFilter}
                    />
                  </div>
                  {this.state.requestReportsData?.length > 25 && (
                    <div className="input-group" style={{ width: '38%' }}>
                      <div className="input-group-prepend w-50">
                        <span className="input-group-text w-100 bg-warning font-weight-bold">
                          Filtro por fecha
                        </span>
                      </div>
                      <CustomSelect
                        labelAll={'Todo el período'}
                        options={[
                          ...new Set(
                            this.state.requestReportsData
                              .map(req => req.report_date)
                              .sort()
                              .map(elem => new Date(elem).toLocaleDateString()),
                          ),
                        ]}
                        value={this.state.dateRangeFilter}
                        selectClassName="w-50"
                        onChange={this.handleDateRangeFilter}
                      />
                    </div>
                  )}
                  <div className="mr-1">
                    <CSVLink
                      ref={this.download}
                      data={this.state.CSVdata}
                      filename={this.state.CSVtitle}
                      className="hidden"></CSVLink>
                    {this.state.loadingExport ? (
                      <button type="button" className="btn btn-light" disabled>
                        Exportando reporte{' '}
                        <span
                          className="spinner-border spinner-border-sm m-1"
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        style={{ background: '#885C95' }}
                        className="btn btn-dark"
                        onClick={this.handleExportReport}>
                        Exportar reporte {this.state.country}{' '}
                        <i className="fas fa-searchfas fa-file-export"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div className="dropdown-divider my-4"></div>
                <h3 className="title">Reporte de pedidos</h3>
                <div className="dropdown-divider my-4"></div>
                <LatamRequestsBoard
                  data={this.state.requestReportsData}
                  provinceFilter={this.state.provinceFilter}
                  dateRangeFilter={this.state.dateRangeFilter}
                  history={this.props.history}
                />
                <div className="dropdown-divider my-4"></div>
                <h3 className="title">Reporte de cajas</h3>
                <div className="dropdown-divider my-4"></div>

                <LatamBoxesBoard
                  data={this.state.requestReportsData}
                  provinceFilter={this.state.provinceFilter}
                  dateRangeFilter={this.state.dateRangeFilter}
                  history={this.props.history}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
export default LatamCountriesBoard;
