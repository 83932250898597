import React, { useState } from 'react';
import RecoverPassword from '../login/RecoverPassword';
import Login from '../login/Login';
import Request from '../requests/Requests';
import Product from '../products/Products';
import RequestDetail from '../requests/RequestsDetails';
import ProductDetail from '../products/ProductsDetails';
import Log from '../log/Log';
import OrderWeightDetail from '../orderWeight/OrderWeightDetail';
import OrderWeight from '../orderWeight/OrderWeight';
import PostalCode from '../postalCode/PostalCode';
import PostalCodeDetail from '../postalCode/PostalCodeDetails';
import Picking from '../picking/Picking';
import Provider from '../provider/Providers';
import ProviderDetail from '../provider/ProvidersDetails';
import ChangePassword from '../user/ChangePassword';
import RequestsReports from '../reports/RequestReport';
import ControlPanel from '../controlPanel/ControlPanel';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import PrivateRoute from '../../utils/PrivateRoute';
import { FiltersCacheContext } from '../common/FiltersCacheContext';
import { VTasksWidget } from 'vtasks-widget';
import GiftConfig from '../gifts/GiftConfig';
import GiftConfigs from '../gifts/GiftConfigs';
import ReturnRequest from '../returns/ReturnRequest';
import ReturnsControlPanel from '../returns/ReturnsControlPanel';
import LatamPanel from '../latamPanel/LatamPanel';

const history = createBrowserHistory();

function App() {
  const filters = useState({});

  return (
    <>
      <VTasksWidget
        project="vPicking"
        environmentLabel="vPicking DEV"
        environmentUrl="dev.vpicking.venturing.com.ar"
        apikey="TLJIQ5D9YADZH8JS7ITZ"
        vTasksUrl="http://vtasks.venturing.com.ar"
        vTasksAPIUrl="https://vtasks.venturing.com.ar/vTasks/api"
      />
      <Router history={history}>
        <Switch>
          <Route name="login" path={`/login`} component={Login} />
          <Route
            name="recover_password"
            path={`/recover_password`}
            component={RecoverPassword}
          />
          <FiltersCacheContext.Provider value={filters}>
            <PrivateRoute
              name="requestsReports"
              exact
              path={`/requests-reports`}
              component={RequestsReports}
            />
            <PrivateRoute
              name="newRequest"
              exact
              path={`/request/:id`}
              component={RequestDetail}
            />
            <PrivateRoute
              name="Request"
              exact
              path={`/request`}
              component={Request}
            />
            <PrivateRoute name="Log" exact path={`/log`} component={Log} />
            <PrivateRoute
              name="newOrderWeight"
              exact
              path={`/orderWeight/:id`}
              component={OrderWeightDetail}
            />
            <PrivateRoute
              name="OrderWeight"
              exact
              path={`/orderWeight`}
              component={OrderWeight}
            />
            <PrivateRoute
              name="newProduct"
              exact
              path={`/product/:id`}
              component={ProductDetail}
            />
            <PrivateRoute
              name="Product"
              exact
              path={`/product`}
              component={Product}
            />
            <PrivateRoute
              name="newPostalCode"
              exact
              path={`/postalCode/:id`}
              component={PostalCodeDetail}
            />
            <PrivateRoute
              name="PostalCode"
              exact
              path={`/postalCode`}
              component={PostalCode}
            />
            <PrivateRoute
              name="newProvider"
              exact
              path={`/provider/:id`}
              component={ProviderDetail}
            />
            <PrivateRoute
              name="Provider"
              exact
              path={`/provider`}
              component={Provider}
            />
            <PrivateRoute
              name="picking"
              exact
              path={`/picking`}
              component={Picking}
            />
            <PrivateRoute
              name="change-password"
              exact
              path={`/change-password`}
              component={ChangePassword}
            />
            <PrivateRoute
              name="returns"
              exact
              path={`/returns`}
              component={ReturnsControlPanel}
            />
            <PrivateRoute
              name="returns"
              exact
              path={`/new-return`}
              component={ReturnRequest}
            />
            <PrivateRoute
              name="gifts"
              exact
              path={`/gifts`}
              component={GiftConfigs}
            />
            <PrivateRoute
              name="gifts"
              exact
              path={`/gifts/:id`}
              component={GiftConfig}
            />
            <PrivateRoute
              name="default"
              exact
              path={`/`}
              component={ControlPanel}
            />
            <PrivateRoute
              name="default"
              exact
              path={`/latam`}
              component={LatamPanel}
            />
          </FiltersCacheContext.Provider>
        </Switch>
      </Router>
    </>
  );
}

export default App;
