import React from 'react';
import './RecorverPassword.css';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import { Link } from 'react-router-dom';
import AlertMessage from '../common/AlertMessage';

class RecoverPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errorMessage: null,
      loading: false,
      resetCompleted: false,
      successMessage: null,
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = formatter.formatFromTarget(target);
    const name = target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });

    try {
      let { data, status } = await apiHelper.resetPassword({
        email: this.state.email,
      });
      if (status === 200) {
        this.setState({
          loading: false,
          resetCompleted: true,
          successMessage: data.message,
        });
      }
    } catch (error) {
      const statusCode = error.response.status;

      let errorMessage;
      if (statusCode === 401) {
        errorMessage = `Error restableciendo contraseña de ${this.state.email}`;
      } else if (statusCode === 404) {
        errorMessage = 'Email no asociado a ningun usuario';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        loading: false,
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let alert;

    if (this.state.errorMessage) {
      alert = (
        <AlertMessage type="warning" title={`${this.state.errorMessage}`} />
      );
    }

    return (
      <div className="container">
        <div className="wrapper fadeInDown">
          <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
            <div className="fadeIn first">
              {this.state.resetCompleted ? (
                <div>
                  <h2 className="text-center mt-2">
                    {this.state.successMessage}
                  </h2>
                  <p>
                    Se enviaron instrucciones para continuar a la casilla:{' '}
                    <br />
                    {`${this.state.email}`}
                  </p>
                  <button
                    className="btn btn-primary btn-block"
                    onClick={() => this.props.history.push('/login')}>
                    Aceptar
                  </button>
                </div>
              ) : (
                <div>
                  <h2 className="text-center mt-2">
                    ¿Olvidaste tu contraseña?
                  </h2>
                  <p>Ingresa tu direccion de Email para recuperarla</p>
                  <div className="panel-body">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          placeholder="Dirección de Email"
                          className="form-control"
                          name="email"
                          type="email"
                          value={this.state.email}
                          onChange={this.handleChange.bind(this)}
                          required
                          autoFocus
                        />
                      </div>
                    </div>

                    {alert}

                    {this.state.loading ? (
                      <button className="btn btn-primary btn-block" disabled>
                        Recuperando contraseña
                        <span
                          className="spinner-border spinner-border-sm m-1"
                          role="status"
                          aria-hidden="true"></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block"
                        type="submit">
                        Recuperar contraseña
                      </button>
                    )}
                  </div>
                  <hr />
                  <Link to="/login">Volver al Login</Link>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default RecoverPassword;
