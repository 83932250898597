import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import apiHelpers from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import SelectReturnResolution from '../common/selects/SelectReturnResolution';
import AlertMessage from '../common/AlertMessage';

export class ReturnDetails extends Component {
  static propTypes = {
    returnRequest: {
      id: PropTypes.number,
      reason: PropTypes.string,
      details: PropTypes.string,
      solved: PropTypes.bool,
      resolution: PropTypes.string,
      resolutionDetails: PropTypes.string,
      caseNumber: PropTypes.number,
      documentNumber: PropTypes.number,
    },
    readOnly: PropTypes.bool,
  };
  constructor(props) {
    super(props);

    this.state = {
      returnRequest: this.props.returnRequest,
      errorMessage: null,
      editMode: {
        status: false,
        memo: null,
      },
      confirmModal: {
        state: false,
        data: null,
      },
    };
  }

  handleChangeResolution(event) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const returns = this.state.returnRequest;
    returns[name] = value;
    this.setState({
      returns: returns,
    });
  }

  handleResolveReturn = async resolveType => {
    let returnRequest = this.state.returnRequest;
    if (returnRequest.resolution && returnRequest.caseNumber) {
      returnRequest.solved = resolveType === 'solve';
      try {
        let patchReturnResponse = await apiHelpers.patchReturnRequest(
          returnRequest.id,
          returnRequest,
        );
        if (patchReturnResponse.status === 200) {
          this.setState(
            prev => {
              return {
                ...prev,
                errorMessage: null,
                confirmModal: {
                  state: false,
                  data: null,
                },
              };
            },
            () => window.location.reload(),
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState(prev => {
        return {
          ...prev,
          confirmModal: {
            state: false,
            data: null,
          },
          errorMessage:
            'Debe indicar Resolución y Número de caso para cerrar la devolución',
        };
      });
    }
  };

  async handleEditReturn() {
    let returnRequest = this.state.returnRequest;
    if (returnRequest.resolution && returnRequest.caseNumber) {
      try {
        let patchReturnResponse = await apiHelpers.patchReturnRequest(
          returnRequest.id,
          returnRequest,
        );
        if (patchReturnResponse.status === 200) {
          this.setState(prev => {
            return {
              ...prev,
              errorMessage: null,
              editMode: {
                status: false,
              },
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState(prev => {
        return {
          ...prev,
          errorMessage:
            'Debe indicar Resolución y Número de caso para cerrar la devolución',
        };
      });
    }
  }

  toggleEditMode() {
    this.setState(prev => {
      return {
        ...prev,
        editMode: {
          status: true,
          memo: { ...prev.returnRequest },
        },
      };
    });
  }

  arrowToggleEditMode = () => {
    this.setState(prev => {
      return {
        ...prev,
        returnRequest: prev.editMode.memo,
        editMode: {
          ...prev.editMode,
          status: false,
        },
      };
    });
  };

  validateResolveReturn = e => {
    let returnRequest = this.state.returnRequest;
    if (!returnRequest.resolution || !returnRequest.caseNumber) {
      this.setState(
        prev => {
          return {
            ...prev,
            confirmModal: {
              state: false,
              data: null,
            },
            errorMessage:
              'Debe indicar Resolución y Número de caso para cerrar la devolución',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
    } else {
      return this.toggleConfirmModal(e);
    }
  };

  toggleConfirmModal = e => {
    let confirmAction = e.target.id;
    this.setState(prev => {
      return {
        ...prev,
        confirmModal: {
          data: confirmAction ?? prev.confirmModal.data,
          state: !prev.confirmModal.state,
        },
      };
    });
  };

  render() {
    return (
      <div className={`creation-info my-4 col-sm-12`}>
        <div className="col-sm-12">
          {this.state.errorMessage && (
            <AlertMessage type="error" title={this.state.errorMessage} />
          )}
        </div>
        <div className=" container my-4 form-group col-sm-12 d-flex flex-column flex-md-row justify-content-center gap-4">
          <div className="col-sm-12 col-md-4 mt-3">
            <label htmlFor="returnReason"> Motivo de la devolución </label>
            <input
              disabled
              name="reason"
              className="form-control"
              placeholder="Elija el motivo de la devolución"
              value={this.state.returnRequest.reason}
            />

            <label className="mt-3" htmlFor="returnDetails">
              {' '}
              Detalles de la devolución{' '}
            </label>
            <textarea
              disabled
              name="details"
              className="form-control h-25"
              placeholder="Ingrese los detalles de la devolución"
              value={this.state.returnRequest.details}
            />
          </div>

          <div className="col-sm-12 col-md-5 mt-3">
            <label htmlFor="ReturnResolution"> Resolución </label>
            <SelectReturnResolution
              disabled={
                (this.state.returnRequest.solved &&
                  !this.state.editMode.status) ||
                this.props.readOnly
              }
              name="resolution"
              errorMessage={this.state.errorMessage}
              className="form-control"
              placeholder="Resolución"
              onChange={this.handleChangeResolution.bind(this)}
              value={this.state.returnRequest.resolution}
            />

            <label className="mt-3" htmlFor="ReturnResolution">
              {' '}
              Detalles de la resolución{' '}
            </label>
            <textarea
              disabled={
                (this.state.returnRequest.solved &&
                  !this.state.editMode.status) ||
                this.props.readOnly
              }
              id="resolutionDetails"
              name="resolutionDetails"
              className="form-control h-25"
              placeholder="Detalles de la resolución"
              onChange={this.handleChangeResolution.bind(this)}
              value={this.state.returnRequest.resolutionDetails ?? ''}
            />
          </div>

          <div className="col-sm-12 col-md-3">
            <label className="mt-3" htmlFor="caseNumber">
              Nro de caso
            </label>
            <input
              disabled={
                (this.state.returnRequest.solved &&
                  !this.state.editMode.status) ||
                this.props.readOnly
              }
              id="caseNumber"
              name="caseNumber"
              className="form-control"
              placeholder="Número de caso"
              onChange={this.handleChangeResolution.bind(this)}
              value={this.state.returnRequest.caseNumber}
            />

            <label className="mt-3" htmlFor="documentNumber">
              Nro de documento
            </label>
            <input
              disabled={
                (this.state.returnRequest.solved &&
                  !this.state.editMode.status) ||
                this.props.readOnly
              }
              id="documentNumber"
              name="documentNumber"
              className="form-control"
              placeholder="Número de documento"
              onChange={this.handleChangeResolution.bind(this)}
              value={this.state.returnRequest.documentNumber ?? ''}
            />
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <div className="container my-4">
          {this.state.returnRequest.returnRequestRows && (
            <div className="row">
              <div className="form-group col-sm-12 pr-0">
                <div className="row col-sm-12 px-0">
                  <label htmlFor="product_request_row" className="col-sm-9">
                    Productos
                  </label>
                  <label
                    htmlFor="product_request_row.quantity"
                    className="col-sm-1 text-right">
                    Cantidad
                  </label>
                  <label
                    htmlFor="product_request_row.quantity"
                    className="col-sm-2 text-right">
                    Lote
                  </label>
                </div>
                {this.state.returnRequest.returnRequestRows.map(
                  returnRequestRow => {
                    return (
                      <div
                        className="row mb-2 col-md-12 px-0"
                        key={returnRequestRow.product.id}>
                        <div className="col-md-9">
                          <input
                            disabled
                            className="form-control"
                            name="material"
                            placeholder="No Definido"
                            value={`${returnRequestRow.product?.description} (${returnRequestRow.product.code})`}
                          />
                        </div>
                        <div className="col-md-1">
                          <input
                            disabled
                            className="form-control input-number"
                            name="quantity"
                            value={returnRequestRow.quantity}
                            pattern="^[+]?\d+([.]\d+)?$"
                          />
                        </div>
                        <div className="col-md-2 px-0">
                          <input
                            disabled
                            className="form-control input-number"
                            value={returnRequestRow.lote}
                          />
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          )}
        </div>

        <div class="dropdown-divider"></div>

        <div className="my-4 form-group col-sm-6 d-flex gap-4">
          <div className="col-sm-12 col-md-6">
            <label htmlFor="returnReason"> Fecha de creación </label>
            <input
              disabled
              name="createdAt"
              className="form-control"
              value={new Date(
                this.state.returnRequest.createdAt,
              ).toLocaleDateString()}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <label htmlFor="returnReason"> Fecha de resolución </label>
            <input
              disabled
              name="createdAt"
              className="form-control"
              value={
                this.state.returnRequest.resolveDate
                  ? new Date(
                      this.state.returnRequest.resolveDate,
                    ).toLocaleDateString()
                  : 'Sin resolución'
              }
            />
          </div>
        </div>

        <div class="dropdown-divider"></div>

        <div className="container my-4">
          {!this.state.returnRequest.solved ? (
            <div
              class="alert alert-warning m-1 d-flex w-100 justify-content-between align-items-center"
              role="alert">
              <h4 className="font-weight-bold text-secondary m-1 w-25">
                Devolución pendiente
              </h4>
              {!this.props.readOnly && (
                <button
                  id="solve"
                  className="btn btn-warning w-50 text-dark"
                  onClick={e => this.validateResolveReturn(e)}>
                  Resolver devolución
                </button>
              )}
            </div>
          ) : (
            <div
              class="alert alert-info m-1 d-flex w-100 justify-content-between align-items-center"
              role="alert">
              {!this.state.editMode.status ? (
                <>
                  <h4 className="font-weight-bold text-secondary m-1">
                    Devolución resuelta
                  </h4>
                  {!this.props.readOnly && (
                    <div className="w-50 d-flex justify-content-end">
                      <button
                        id="unsolve"
                        className="btn btn-outline-danger mr-4"
                        onClick={e => this.toggleConfirmModal(e)}>
                        Revertir resolución
                      </button>
                      <button
                        className="btn btn-info w-25"
                        onClick={this.toggleEditMode.bind(this)}>
                        Editar
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex w-100 justify-content-end align-items-center">
                  <button
                    type="button"
                    class="btn btn-success mr-4"
                    onClick={this.handleEditReturn.bind(this)}>
                    Actualizar datos de devolución
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={this.arrowToggleEditMode}>
                    Cancelar
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        <ReactModal
          isOpen={this.state.confirmModal.state}
          className="modal-dialog fadeInDown">
          <div className="modal-content text-center">
            <div className="modal-header text-center">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={this.toggleConfirmModal}>
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <h3>¿Desea cambiar el estado de la devolución ?</h3>
              {this.state.confirmModal.data === 'solve' && (
                <button
                  className="btn btn-success m-2"
                  type="button"
                  onClick={() =>
                    this.handleResolveReturn(this.state.confirmModal.data)
                  }>
                  {' '}
                  Resolver devolución
                </button>
              )}
              {this.state.confirmModal.data === 'unsolve' && (
                <button
                  className="btn btn-success m-2"
                  type="button"
                  onClick={() =>
                    this.handleResolveReturn(this.state.confirmModal.data)
                  }>
                  {' '}
                  Deshacer resolución
                </button>
              )}
              <button
                className="btn btn-outline-danger m-2"
                type="button"
                onClick={this.toggleConfirmModal}>
                {' '}
                Cancelar
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default ReturnDetails;
