import React from 'react';
import PropTypes from 'prop-types';
import SelectProductDescription from '../common/selects/SelectProductDescription';

function RequestRow(props) {
  let buttonRemove;
  buttonRemove = (
    <button
      className="btn btn-danger"
      type="button"
      onClick={props.onRemoveProductRequestRow}>
      <i className="fas fa-minus"></i>
    </button>
  );

  return (
    <div className="row mb-2">
      <div className="col-md-8 col-8">
        <SelectProductDescription
          onChange={props.onUpdateProductRequestRow.bind(this)}
          name="material"
          placeholder="No Definido"
          value={props.productRequestRow.material}
          required
        />
      </div>
      <div className="col-md-2 col-2">
        <input
          type="number"
          className="form-control input-number"
          name="quantity"
          placeholder={0}
          onChange={props.onUpdateProductRequestRow.bind(this)}
          value={props.productRequestRow.quantity}
          min="1"
          pattern="^[+]?\d+([.]\d+)?$"
        />
      </div>
      <div className="col-md-2 col-2">{buttonRemove}</div>
    </div>
  );
}

export default class RequestRows extends React.Component {
  newProductRequestRow() {
    return { material: '', quantity: 0 };
  }

  handleAddProductRequestRow(event) {
    const productRequestRows = this.props.value.slice();
    productRequestRows.push(this.newProductRequestRow());
    this.updateOnChange(productRequestRows);
  }

  handleRemoveProductRequestRow(index, event) {
    const productRequestRows = this.props.value.slice();
    productRequestRows.splice(index, 1);
    this.updateOnChange(productRequestRows);
  }

  handleUpdateProductRequestRow(index, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let productRequestRow = this.props.value.slice();
    productRequestRow[index][name] = value;
    this.updateOnChange(productRequestRow);
  }

  updateOnChange(productRequestRow) {
    if (this.props.onChange) {
      let event = { target: {} };
      event.target.name = this.props.name || 'productrequestrow-nameless';
      event.target.value = productRequestRow.slice();
      event.target.type = 'venturing-productrequestrow';
      this.props.onChange(event);
    }
  }

  render() {
    const items = this.props.value;

    //  if (!items.length)
    //    items.push(this.newProductRequestRow());

    return (
      <>
        <div className="form-group">
          <label htmlFor="productRequestRows"> Productos </label>
        </div>
        <div className="row ml-0 mr-0 ">
          <div className="col-md-8 col-8 pl-0">
            <label htmlFor="description"> Producto </label>
          </div>
          <div className="col-md-2 col-2 text-left pl-3">
            <label htmlFor="cantidad"> Cantidad </label>
          </div>
        </div>
        {items.map((productRequestRow, index, productRequestRows) => {
          let isLast = index === productRequestRows.length - 1;
          let isFirst = index === 0;
          return (
            <RequestRow
              productRequestRow={productRequestRow}
              key={index}
              isLast={isLast}
              isFirst={isFirst}
              onAddProductRequestRow={this.handleAddProductRequestRow.bind(
                this,
                index,
              )}
              onRemoveProductRequestRow={this.handleRemoveProductRequestRow.bind(
                this,
                index,
              )}
              onUpdateProductRequestRow={this.handleUpdateProductRequestRow.bind(
                this,
                index,
              )}
            />
          );
        })}
        <button
          className="btn btn-success mt-45 w-100"
          type="button"
          onClick={this.handleAddProductRequestRow.bind(this)}>
          <i className="fas fa-plus"></i>
        </button>
      </>
    );
  }
}

RequestRows.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
};
