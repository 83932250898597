import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React from 'react';
import PropTypes from 'prop-types';

export default class RequestBarChartOfProductsXRquests extends React.Component {
  componentDidUpdate(data) {
    if (this.chart) {
      this.chart.dispose();
    }
    if (data !== this.props.data) {
      // Apply chart themes
      am4core.useTheme(am4themes_animated);

      // Create chart instance
      let chart = am4core.create('barchartProductsXRequest', am4charts.XYChart);

      // Add data
      chart.data = this.props.data;

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dataFields.date = 'sent_to_provider_date';
      dateAxis.dateFormats.setKey('day', 'dd MMM');
      dateAxis.title.text = 'Día de procesamiento';
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 80;
      dateAxis.skipEmptyPeriods = true;

      let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = 'Total procesados';

      // First series
      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = 'total_requests';
      series1.dataFields.dateX = 'sent_to_provider_date';
      series1.name = 'Total de pedidos';
      series1.tooltipText = '{name}: [bold]{valueY}[/]';

      // Second series
      let series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = 'total_products';
      series2.dataFields.dateX = 'sent_to_provider_date';
      series2.name = 'Total de productos';
      series2.tooltipText = '{name}: [bold]{valueY}[/]';
      series2.strokeWidth = 5;

      // Enable interactions on series segments
      let segment = series2.segments.template;
      segment.interactionsEnabled = true;
      // Create hover state
      let hoverState = segment.states.create('hover');
      hoverState.properties.strokeWidth = 10;

      let circleBullet = series2.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.stroke = am4core.color('#fff');
      circleBullet.circle.strokeWidth = 2;

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'top';

      // Add cursor
      chart.cursor = new am4charts.XYCursor();

      // Add Scroll
      chart.scrollbarX = new am4core.Scrollbar();
      // let scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(series1);
      // scrollbarX.series.push(series2);
      // chart.scrollbarX = scrollbarX;

      this.chart = chart;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div
        id="barchartProductsXRequest"
        style={{ width: '100%', height: '500px' }}></div>
    );
  }
}

RequestBarChartOfProductsXRquests.propTypes = {
  data: PropTypes.array,
};
