import React from 'react';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import apiHelper from '../../utils/apiHelpers';
import { FiltersCacheContext } from '../common/FiltersCacheContext';
import LatamFilters from './components/LatamFilters';
import LatamCountriesBoard from './components/LatamCountriesBoard';
import Loading from '../common/Loading';
import { RegenInfoModal } from './components/modals/RegenInfoModal';
import CountryFlag from '../common/ContryFlag';

class LatamPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      openCountries: [],
      loading: false,
      loadingGenerateNewReports: false,
      requestsReports: null,
      otherReports: [],
      filters: null,
      openRegenInfoModal: false,
      errorMessage: null,
      missingDates: null,
    };
  }

  async getRequestsProcessed() {
    try {
      this.setState({ loading: true });

      let latamReportsResponse;
      if (this.state.filters) {
        const { data } = await apiHelper.getLatamReports(this.state.filters);
        latamReportsResponse = data;
      } else {
        const { data } = await apiHelper.getLatamReports();
        latamReportsResponse = data;
      }

      for (const reportOneCountry of latamReportsResponse) {
        //Check missing reports
        const report_dates = [
          ...new Set(reportOneCountry.data.map(elem => elem.report_date)),
        ];
        if (report_dates.length > 1) {
          try {
            let start = this.state.filters.where.min_report_date;
            let end = this.state.filters.where.max_report_date;
            let missingDates = this.findMissingDates(report_dates, start, end);
            this.setState(prev => {
              return {
                ...prev,
                missingDates: [
                  {
                    countryName: reportOneCountry.countryName,
                    missingDates: missingDates,
                  },
                ],
              };
            });
          } catch (error) {
            console.log(error);
          }
        }
      }

      this.setState({
        requestsReports: latamReportsResponse ?? null,
        loading: false,
      });
    } catch (e) {
      console.log('Error loading requests', e);
    }
  }

  async generateNewReport() {
    try {
      this.setState({ loading: true, loadingGenerateNewReports: true });
      let newReport;

      if (this.state.filters) {
        newReport = await apiHelper.generateLatamReports(this.state.filters);
      } else {
        newReport = await apiHelper.generateLatamReports();
      }

      this.setState(
        {
          loading: false,
          loadingGenerateNewReports: false,
        },
        () => this.getRequestsProcessed(),
      );

      return newReport;
    } catch (e) {
      console.log('Error loading requests', e);
      this.setState({
        errorMessage: `No se pudo generar el reporte`,
        loading: false,
        loadingGenerateNewReports: false,
      });
    }
  }

  componentDidMount() {
    apiHelper
      .getCurrentUser()
      .then(user => {
        if (user.data.role.id !== 3) {
          this.props.history.push('/');
        }
        this.setState({
          user: user.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters }, () => {
      this.getRequestsProcessed();
    });
  }

  toggleReportModal() {
    this.setState(prev => {
      return {
        ...prev,
        openRegenInfoModal: !prev.openRegenInfoModal,
      };
    });
  }

  findMissingDates(report_dates, startDate, endDate) {
    let dates = report_dates.map(elem => new Date(elem).getTime());
    let start = new Date(startDate);
    let end = new Date(endDate);
    dates.sort((a, b) => a - b);
    let missingDates = [];
    for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
      let timestamp = d.getTime();
      if (d.getDay() > 0 && d.getDay() < 6) {
        if (!dates.includes(timestamp)) {
          missingDates.push(timestamp);
        }
      }
    }
    missingDates = missingDates.map(elem =>
      new Date(elem).toLocaleDateString(),
    );
    return missingDates.length > 0 ? missingDates : null;
  }

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container bottom-air mb-4">
          <h1 className="text-nuskin text-center">
            <i className="fas fa-table mt-3"></i>{' '}
            <span> Tablero de Control LATAM</span>
          </h1>

          <LatamFilters
            loadData={this.handleChangeFilters.bind(this)}
            reportGenerator={this.generateNewReport.bind(this)}
            toggleReportModal={this.toggleReportModal.bind(this)}
            hiddenRegenInfo={!this.state.requestsReports?.length}
            disabled={this.state.loading}
          />

          {this.state.loading ? (
            <>
              {this.state.loadingGenerateNewReports ? (
                <>
                  <section className="jumbotron text-center">
                    <div className="container">
                      <h1 className="jumbotron-heading">
                        Se estan generando los reportes
                      </h1>
                      <p className="lead text-muted">
                        Este proceso puede tomar unos minutos...
                      </p>
                      <Loading />
                    </div>
                  </section>
                </>
              ) : (
                <Loading />
              )}
            </>
          ) : (
            <div>
              <>
                {this.state.requestsReports?.map(report => {
                  // If there is no report for selected date
                  if (report.data?.length < 1) {
                    return (
                      <section
                        className="jumbotron text-center"
                        key={report.countryName}>
                        <div className="container">
                          <CountryFlag country={report.countryName} />
                          <h1 className="jumbotron-heading">
                            No existe reporte para el pais en esta fecha
                          </h1>
                          <p className="lead text-muted">
                            Desea generar reportes para las fechas indicadas?
                          </p>
                          <button
                            className="btn btn-info my-2"
                            onClick={this.toggleReportModal.bind(this)}>
                            Generar reporte
                            <i className="fas fa-undo fa-sm fa-fw ml-2" />
                          </button>
                        </div>
                      </section>
                    );
                  }
                  // If there is no report for some of the selected dates
                  const missingDatesReport =
                    this.state.missingDates &&
                    this.state.missingDates.find(
                      missing => missing.countryName === report.countryName,
                    );
                  if (missingDatesReport?.missingDates) {
                    return (
                      <section
                        className="jumbotron text-center"
                        key={report.countryName}>
                        <div className="container">
                          <h1 className="jumbotron-heading">
                            No existe reporte para{' '}
                            {missingDatesReport?.countryName} de las sieguientes
                            fechas:
                          </h1>
                          <div className="alert alert-danger" role="alert">
                            <p className="lead text-danger">
                              {missingDatesReport.missingDates.join(' - ')}
                            </p>
                          </div>

                          <h4 className="lead text-muted">
                            Desea generar reportes para las fechas indicadas?
                          </h4>
                          <p>
                            <button
                              className="btn btn-info"
                              onClick={this.toggleReportModal.bind(this)}>
                              Generar reporte
                            </button>
                          </p>
                        </div>
                      </section>
                    );
                  }
                  // If there is report
                  return (
                    <div key={report.countryName}>
                      <LatamCountriesBoard
                        data={report.data}
                        country={report.countryName}
                      />
                    </div>
                  );
                })}
              </>
            </div>
          )}
        </div>
        <Footer />

        {/* Modal */}
        <RegenInfoModal
          isOpen={this.state.openRegenInfoModal}
          loadData={this.getRequestsProcessed.bind(this)}
          generateNewReport={this.generateNewReport.bind(this)}
          loading={this.state.loadingGenerateNewReports || this.state.loading}
          onClose={this.toggleReportModal.bind(this)}
        />
      </div>
    );
  }
}

LatamPanel.contextType = FiltersCacheContext;

export default LatamPanel;
