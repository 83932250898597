import React, { Component } from 'react';
import Modal from 'react-modal';
import apiHelpers from '../../../utils/apiHelpers';
import { generateAsociatedLabel } from '../../common/InvoicePDF';

export class AssociatedLabelsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headLabels: null,
      linkedLabels: null,
      loadingPrint: null,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.setState({ headLabels: this.props.headLabels });
  }

  onDownloadAsociatedLabels = async (idLabel, order_number) => {
    this.setState({ loadingPrint: order_number });
    try {
      const { data: asociatedRequests } = await apiHelpers.getRequests({
        where: { idLabelsOfAPI: idLabel },
      });
      await generateAsociatedLabel(
        `Pedidos asociados a ${order_number}.pdf`,
        asociatedRequests,
      );
      setTimeout(() => {
        this.setState({ loadingPrint: null }, () => this.props.onCloseModal());
      }, 2000);
    } catch (error) {
      console.log(`Error imprimiendo etiqueta con pedidos asociados: ${error}`);
      this.setState({ loadingPrint: null });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onCloseModal}
        className="modal-dialog-email fadeInDown modal-dialog"
        contentLabel="Código postal erroneo">
        <div className="modal-content text-center">
          <div className="modal-header">
            <div className="col-md-8">
              <span>
                <h3 className="pull-right">Pedidos cabecera</h3>
              </span>
            </div>
            <div className="col-md-2">
              <button
                type="button"
                className="close"
                onClick={this.props.onCloseModal}>
                &times;
              </button>
            </div>
          </div>
          <div className="modal-body d-flex justify-content-center">
            <ul className="list-group w-75">
              {this.props.headLabels?.map(headLabel => {
                return (
                  <li
                    key={headLabel.id}
                    className="list-group-item list-group-item-action ">
                    <div className="d-flex align-item-center justify-content-between">
                      <p className="m-3 h4">{headLabel.order_number}</p>
                      <div className="btn-group m-2" role="group">
                        <button
                          key={headLabel.id}
                          className="btn btn-info"
                          onClick={() =>
                            this.onDownloadAsociatedLabels(
                              headLabel.idLabelsOfAPI,
                              headLabel.order_number,
                            )
                          }
                          disabled={this.state.loadingPrint}>
                          <i className="fas fa-print p-2"></i>
                          {this.state.loadingPrint &&
                          this.state.loadingPrint === headLabel.order_number
                            ? `Imprimiendo ...`
                            : `Imprimir `}
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AssociatedLabelsModal;
