import apiHelper from '../../utils/apiHelpers';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import './PostalCodeDetails.css';
import SelectProvider from '../common/selects/SelectProvider';
import Modal from 'react-modal';

export default class PostalCodeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      postalCodeDocument: null,
      isNew: props.match.params.id === 'new',
      postalCodeId: props.match.params.id,
      modalIsOpen: false,
      alert: false,
      postalCode: {
        postalCode: '',
        providerId: '',
        estimatedDeliveryTime: '',
      },
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    apiHelper
      .getPostalCode(this.state.postalCodeId)
      .then(response => {
        this.setState({
          postalCode: {
            postalCode: response.data.postalCode,
            providerId: response.data.provider.id,
            estimatedDeliveryTime: response.data.estimatedDeliveryTime,
          },
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.deletePostalCode.bind(this);
    this.submitPostalCode.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChange(event) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const postalCode = this.state.postalCode;
    postalCode[name] = value;
    this.setState({
      postalCode: postalCode,
    });
  }

  async deletePostalCode() {
    apiHelper.deletePostalCode(this.state.postalCodeId);
    this.props.history.push('/postalCode');
  }

  async submitPostalCode(event) {
    event.preventDefault();
    let postalCodeId = this.state.postalCodeId;

    try {
      //If postalCodes already exists, update it. If not, create it
      const postalCode = this.state.postalCode;

      if (this.state.isNew) {
        let response = await apiHelper.postPostalCode(postalCode);
        if (response.status === 203) {
          this.setState({ errorMessage: response.data, alert: true });
        } else {
          this.setState({ alert: false });
        }
      } else {
        await apiHelper.patchPostalCode(postalCodeId, postalCode);
      }

      if (!this.state.alert) {
        this.props.history.push('/postalCode');
      }
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let title = 'Crear Código Postal';
    let deleteButton = false;

    if (!this.state.isNew) {
      title = 'Código postal';
    }
    if (!this.state.isNew) {
      deleteButton = (
        <button
          className="btn btn-danger pull-right some-top-air"
          type="button"
          onClick={this.openModal.bind(this)}>
          Eliminar <span className="fa fa-trash-alt"></span>
        </button>
      );
    }

    return (
      <div key={this.state.postalCodeId} className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3">
          <h1 className="text-position-title">
            <i className="fas fa-map-marked-alt"></i>
            <span> {title} </span>
            {deleteButton}
          </h1>

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog fadeInDown">
            <div className="modal-content text-center">
              <div className="modal-header">
                <span>
                  <h3 className="text-center">
                    ¿Está seguro de querer eliminar este código postal?
                  </h3>
                </span>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <button
                  className="button-delete-modal btn btn-success some-top-air"
                  type="button"
                  onClick={this.deletePostalCode.bind(this)}>
                  {' '}
                  Si
                </button>
                <button
                  className="button-delete-modal btn btn-danger some-top-air"
                  type="button"
                  onClick={this.closeModal.bind(this)}>
                  {' '}
                  No
                </button>
              </div>
            </div>
          </Modal>

          <div className="row ">
            <div className="col-md-4 creation-info position p-3">
              <div className="form-group">
                <label htmlFor="description"> Código postal</label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="postalCode"
                  className="form-control"
                  placeholder=" Codigo Postal "
                  value={this.state.postalCode.postalCode}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="provider"> Proveedor</label>
                <SelectProvider
                  onChange={this.handleChange.bind(this)}
                  name="providerId"
                  placeholder=" Proveedor "
                  value={this.state.postalCode.providerId}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">
                  {' '}
                  Tiempo estimado de entrega (días)
                </label>
                <input
                  type="number"
                  min={0}
                  onChange={this.handleChange.bind(this)}
                  name="estimatedDeliveryTime"
                  className="form-control"
                  placeholder="Tiempo estimado de entrega (días)"
                  value={this.state.postalCode.estimatedDeliveryTime}
                />
              </div>
            </div>
          </div>
          {this.state.alert && (
            <div className=" mt-2 alert alert-warning ml-5 mr-5 text-center">
              {this.state.errorMessage}
            </div>
          )}
          <div className="text-center">
            {function () {
              return (
                <button
                  className="button-submit btn btn-primary my-5"
                  onClick={this.submitPostalCode.bind(this)}
                  type="submit">
                  Guardar <i className="fas fa-save"></i>
                </button>
              );
            }.bind(this)()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
