import React, { Component } from 'react';
import moment from 'moment';
import apiHelper from '../../utils/apiHelpers';
import config from '../../config/config';
import { FiltersCacheContext } from '../common/FiltersCacheContext';

class MainBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestsProcessed: props.data,
    };
  }

  onClickRequestsProcessed(value) {
    if (apiHelper.isAdmin()) {
      value.cancelled = false;
      const [cacheFilters, setCacheFilters] = this.context;
      setCacheFilters({ ...cacheFilters, ['RequestsFilters']: value });
      this.props.history.push('/request');
    }
  }

  render() {
    return (
      <>
        <div className="row panel-data">
          <div
            className={`col-sm-12 col-lg-${
              config.countryVersion?.backLogDashboard ? 3 : 4
            } margin-left category-hidden category`}>
            <h5 className="h5 text-center mt-2">Categoría</h5>
            <div className=" creation-info p-3">
              <div className={apiHelper.isAdmin() ? 'clickable' : ''}>
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      min_import_date: moment().startOf('day').toDate(),
                      max_import_date: moment().endOf('day').toDate(),
                    })
                  }>
                  <i className="fas fa-file-import text-success ml-3"></i>
                  <span>
                    <b> Pedidos importados </b>
                  </span>
                </div>
              </div>
              <div>
                <div
                  className={apiHelper.isAdmin() ? 'clickable' : ''}
                  onClick={() =>
                    this.onClickRequestsProcessed({ state_weight: 'SP' })
                  }>
                  <i className="fas fa-weight text-danger ml-3 "></i>
                  <span>
                    <b> Pedidos sin pesar </b>
                  </span>
                </div>
              </div>
              {config.validatePicking.active && (
                <div>
                  <div
                    className={apiHelper.isAdmin() ? 'clickable' : ''}
                    onClick={() =>
                      this.onClickRequestsProcessed({ state_picking: 'SP' })
                    }>
                    <i className="fas fa-cubes text-danger ml-3 "></i>
                    <span>
                      <b> Pedidos sin expedición </b>
                    </span>
                  </div>
                </div>
              )}
              <div>
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      sent_to_provider: '0',
                    })
                  }
                  className={apiHelper.isAdmin() ? 'clickable' : ''}>
                  <i className="fa fa-envelope text-danger ml-3"></i>
                  <span>
                    <b> Pedidos no enviados</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              config.countryVersion?.backLogDashboard
                ? 'col-sm-12 col-lg-1'
                : 'col-sm-12 col-lg-2'
            }>
            <h5 className="h5 text-center mt-2">Hoy</h5>
            <div className=" creation-info p-3">
              <div
                className={
                  apiHelper.isAdmin() ? 'clickable text-center' : 'text-center'
                }
                onClick={() =>
                  this.onClickRequestsProcessed({
                    min_import_date: moment().startOf('day').toDate(),
                    max_import_date: moment().endOf('day').toDate(),
                  })
                }>
                <b>
                  <span className="mr-3  category-hidden-lg ">
                    <i className="fas fa-file-import text-success  category-hidden-lg "></i>
                    <span className=" category-hidden-lg mr-2">
                      {' '}
                      Pedidos importados{' '}
                    </span>
                  </span>
                  {this.state.requestsProcessed.totalImportedRequests}
                </b>
              </div>
              <div
                onClick={() =>
                  this.onClickRequestsProcessed({
                    min_import_date: moment().startOf('day').toDate(),
                    max_import_date: moment().endOf('day').toDate(),
                    state_weight: 'SP',
                  })
                }
                className={
                  apiHelper.isAdmin() ? 'clickable text-center' : 'text-center'
                }>
                <b>
                  <span className="mr-4  category-hidden-lg ">
                    <i className="fas fa-weight text-danger category-hidden-lg "></i>
                    <span className=" category-hidden-lg mr-3">
                      {' '}
                      Pedidos sin pesar{' '}
                    </span>
                  </span>
                  {this.state.requestsProcessed.totalRequestWeightlessToday}
                </b>
              </div>
              {config.validatePicking.active && (
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      min_import_date: moment().startOf('day').toDate(),
                      max_import_date: moment().endOf('day').toDate(),
                      state_picking: 'SP',
                    })
                  }
                  className={
                    apiHelper.isAdmin()
                      ? 'clickable text-center'
                      : 'text-center'
                  }>
                  <b>
                    <span className=" category-hidden-lg ">
                      <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                      <span className=" category-hidden-lg mr-1">
                        {' '}
                        Pedidos sin expedición:
                      </span>
                    </span>
                    {this.state.requestsProcessed.totalRequestNotPickedToday}
                  </b>
                </div>
              )}
              <div
                onClick={() =>
                  this.onClickRequestsProcessed({
                    min_import_date: moment().startOf('day').toDate(),
                    max_import_date: moment().endOf('day').toDate(),
                    sent_to_provider: false,
                  })
                }
                className={
                  apiHelper.isAdmin() ? 'clickable text-center' : 'text-center'
                }>
                <b>
                  <span className="mr-3 category-hidden-lg ">
                    <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                    <span> Pedidos no enviados:</span>
                  </span>
                  {this.state.requestsProcessed.totalRequestNotSentToday}
                </b>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-2">
            <h5 className="h5 text-center mt-2">Pendiente</h5>
            <div className=" creation-info p-3">
              <div className="text-center">
                <b>----</b>
              </div>
              <div
                onClick={
                  config.countryVersion?.backLogDashboard
                    ? () =>
                        this.onClickRequestsProcessed({
                          max_import_date: moment()
                            .subtract(1, 'days')
                            .endOf('day')
                            .toDate(),
                          min_import_date: moment()
                            .subtract(1, 'days')
                            .startOf('day')
                            .toDate(),
                          state_weight: 'SP',
                        })
                    : () =>
                        this.onClickRequestsProcessed({
                          max_import_date: moment()
                            .subtract(1, 'days')
                            .endOf('day')
                            .toDate(),
                          state_weight: 'SP',
                        })
                }
                className={
                  apiHelper.isAdmin() ? 'clickable text-center' : 'text-center'
                }>
                <b>
                  <span className="mr-4  category-hidden-lg ">
                    <i className="fas fa-weight text-danger category-hidden-lg "></i>
                    <span className=" category-hidden-lg mr-3">
                      {' '}
                      Pedidos sin pesar:{' '}
                    </span>
                  </span>
                  {config.countryVersion?.backLogDashboard
                    ? this.state.requestsProcessed.totalRequestWeightlessOnTime
                    : this.state.requestsProcessed
                        .totalRequestWeightlessPending}
                </b>
              </div>
              {config.validatePicking.active && (
                <div
                  onClick={
                    config.countryVersion?.backLogDashboard
                      ? () =>
                          this.onClickRequestsProcessed({
                            max_import_date: moment()
                              .subtract(1, 'days')
                              .endOf('day')
                              .toDate(),
                            min_import_date: moment()
                              .subtract(1, 'days')
                              .startOf('day')
                              .toDate(),
                            state_picking: 'SP',
                          })
                      : () =>
                          this.onClickRequestsProcessed({
                            max_import_date: moment()
                              .subtract(1, 'days')
                              .endOf('day')
                              .toDate(),
                            state_picking: 'SP',
                          })
                  }
                  className={
                    apiHelper.isAdmin()
                      ? 'clickable text-center'
                      : 'text-center'
                  }>
                  <b>
                    <span className="category-hidden-lg ">
                      <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                      <span className=" category-hidden-lg mr-1">
                        {' '}
                        Pedidos sin expedición:{' '}
                      </span>
                    </span>
                    {config.countryVersion?.backLogDashboard
                      ? this.state.requestsProcessed
                          .totalRequestNotPickedPendingOnTime
                      : this.state.requestsProcessed
                          .totalRequestNotPickedPending}
                  </b>
                </div>
              )}

              <div
                onClick={
                  config.countryVersion?.backLogDashboard
                    ? () =>
                        this.onClickRequestsProcessed({
                          sent_to_provider: false,
                          max_import_date: moment()
                            .subtract(1, 'days')
                            .endOf('day')
                            .toDate(),
                          min_import_date: moment()
                            .subtract(1, 'days')
                            .startOf('day')
                            .toDate(),
                        })
                    : () =>
                        this.onClickRequestsProcessed({
                          sent_to_provider: false,
                          max_import_date: moment()
                            .subtract(1, 'days')
                            .endOf('day')
                            .toDate(),
                        })
                }
                className={
                  apiHelper.isAdmin() ? 'clickable text-center' : 'text-center'
                }>
                <b>
                  <span className="mr-3 category-hidden-lg ">
                    <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                    <span> Pedidos no enviados:</span>
                  </span>
                  {config.countryVersion?.backLogDashboard
                    ? this.state.requestsProcessed
                        .totalRequestNotSentPendingOnTime
                    : this.state.requestsProcessed.totalRequestNotSentPending}
                </b>
              </div>
            </div>
          </div>

          {config.countryVersion?.backLogDashboard && (
            <div className="col-sm-12 col-lg-2">
              <h5 className="h5 text-center mt-2">Demorados</h5>
              <div className=" creation-info p-3">
                <div className="text-center">
                  <b>----</b>
                </div>
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      max_import_date: moment()
                        .subtract(2, 'days')
                        .endOf('day')
                        .toDate(),
                      state_weight: 'SP',
                    })
                  }
                  className={
                    apiHelper.isAdmin()
                      ? 'clickable text-center'
                      : 'text-center'
                  }>
                  <b>
                    <span className="mr-4  category-hidden-lg ">
                      <i className="fas fa-weight text-danger category-hidden-lg "></i>
                      <span className=" category-hidden-lg mr-3">
                        {' '}
                        Pedidos sin pesar:{' '}
                      </span>
                    </span>
                    {this.state.requestsProcessed.totalRequestWeightlessDelayed}
                  </b>
                </div>
                {config.validatePicking.active && (
                  <div
                    onClick={() =>
                      this.onClickRequestsProcessed({
                        max_import_date: moment()
                          .subtract(2, 'days')
                          .endOf('day')
                          .toDate(),
                        state_picking: 'SP',
                      })
                    }
                    className={
                      apiHelper.isAdmin()
                        ? 'clickable text-center'
                        : 'text-center'
                    }>
                    <b>
                      <span className="category-hidden-lg ">
                        <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                        <span className=" category-hidden-lg mr-1">
                          {' '}
                          Pedidos sin expedición:{' '}
                        </span>
                      </span>
                      {
                        this.state.requestsProcessed
                          .totalRequestNotPickedPendingDelayed
                      }
                    </b>
                  </div>
                )}

                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      sent_to_provider: false,
                      max_import_date: moment()
                        .subtract(2, 'days')
                        .endOf('day')
                        .toDate(),
                    })
                  }
                  className={
                    apiHelper.isAdmin()
                      ? 'clickable text-center'
                      : 'text-center'
                  }>
                  <b>
                    <span className="mr-3 category-hidden-lg ">
                      <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                      <span> Pedidos no enviados:</span>
                    </span>
                    {
                      this.state.requestsProcessed
                        .totalRequestNotSentPendingDelayed
                    }
                  </b>
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-12 col-lg-2">
            <h5 className="h5 text-center mt-2">Hechos</h5>
            <div className=" creation-info p-3">
              <div className="text-center">
                <b>----</b>
              </div>
              <div
                onClick={() =>
                  this.onClickRequestsProcessed({
                    state_weight: 'PV',
                    weight_hours: moment().toDate(),
                  })
                }
                className={
                  apiHelper.isAdmin() ? 'clickable text-center' : 'text-center'
                }>
                <b>
                  <span className="mr-4  category-hidden-lg ">
                    <i className="fas fa-weight text-danger category-hidden-lg "></i>
                    <span className=" category-hidden-lg mr-3">
                      {' '}
                      Pedidos sin pesar{' '}
                    </span>
                  </span>
                  {this.state.requestsProcessed.requestWeightless}
                </b>
              </div>
              {config.validatePicking.active && (
                <div
                  onClick={() =>
                    this.onClickRequestsProcessed({
                      state_picking: 'PV',
                      last_picked_date: moment().toDate(),
                    })
                  }
                  className={
                    apiHelper.isAdmin()
                      ? 'clickable text-center'
                      : 'text-center'
                  }>
                  <b>
                    <span className=" category-hidden-lg ">
                      <i className="fas fa-cubes text-danger  category-hidden-lg"></i>
                      <span className=" category-hidden-lg mr-1">
                        {' '}
                        Pedidos sin expedición:
                      </span>
                    </span>
                    {this.state.requestsProcessed.totalNotPicked}
                  </b>
                </div>
              )}

              <div
                onClick={() =>
                  this.onClickRequestsProcessed({
                    sent_to_provider: true,
                    sent_to_provider_date: moment().toDate(),
                  })
                }
                className={
                  apiHelper.isAdmin() ? 'clickable text-center' : 'text-center'
                }>
                <b>
                  <span className=" mr-3 category-hidden-lg ">
                    <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                    <span> Pedidos no enviados: </span>
                  </span>
                  {this.state.requestsProcessed.totalRequestNotSent}
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="justify-content-center row provider-name provider col-lg-10 margin-left">
            {!config.countryVersion?.noPostalCode && (
              <div
                className={`${apiHelper.isAdmin() && 'clickable '}  
                      col postal-code provider creation-info p-3 margin-top`}
                onClick={() =>
                  this.onClickRequestsProcessed({
                    state_postal_code: 'CD',
                  })
                }>
                <span>
                  <i className="fa fa-mail-bulk ml-3 text-danger ml-3"></i>
                  <b> Pedidos con error de código postal</b>
                </span>
                <div className="pull-right provider-name">
                  <b>
                    {
                      this.state.requestsProcessed
                        .totalRequestWithWrongPostalCode
                    }
                  </b>
                </div>
              </div>
            )}

            <div
              className={`${apiHelper.isAdmin() && 'clickable '} ${
                config.countryVersion?.noPostalCode ? 'col-sm-12' : 'col margin'
              } postal-code category creation-info p-3 margin-top`}
              onClick={() =>
                this.onClickRequestsProcessed({
                  state_weight: 'PD',
                })
              }>
              <span>
                <i className="fa fa-weight text-warning ml-3"></i>
                <b> Pedidos con diferencia de peso</b>
              </span>
              <div className="pull-right">
                <b>
                  {this.state.requestsProcessed.totalRequestWithWrongWeight}
                </b>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

MainBoard.contextType = FiltersCacheContext;

export default MainBoard;
