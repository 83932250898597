import React from 'react'
import ReactLoading from 'react-loading';


export default function Loading() {
    return (
        <div className='d-flex justify-content-center p-5'>
            <ReactLoading
                type={'spin'}
                color={'#55CDE4'}
                height={100}
                width={100}
            />
        </div>
    )
}
