import React from 'react';
import './OrderWeightsFilters.css';
import SelectRequestOrderNumber from '../common/selects/SelectRequestOrderNumber';
import SelectWeight from '../common/selects/SelectWeight';
import SelectProvider from '../common/selects/SelectProvider';
import SelectDates from '../common/selects/SelectDates';
import { FiltersCacheContext } from '../common/FiltersCacheContext';

export default class OrderWeightFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getFiltersObject() {
    return {
      where: {
        order_number: this.state.order_number,
        'provider.id': this.state['provider.id'],
        import_date: this.state.import_date,
        state_weight: this.state.state_weight,
        cancelled: this.state.cancelled,
      },
    };
  }

  componentWillUnmount() {
    const [cacheFilters, setCacheFilters] = this.context;
    setCacheFilters({ ...cacheFilters, ['RequestsFilters']: this.state });
  }

  componentDidMount() {
    const cachedState = this.context[0]['RequestsFilters'];
    if (cachedState) {
      this.setState({ ...cachedState }, () => {
        this.onFiltersSubmit();
      });
    }
  }

  getInitialState() {
    return {
      order_number: null,
      state_weight: null,
      'provider.id': null,
      import_date: null,
      cancelled: false,
    };
  }

  cleardate() {
    if (this.state.import_date) {
      this.setState({ import_date: null });
    }
  }

  onFiltersChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  onFiltersSubmit() {
    this.props.loadData(this.getFiltersObject());
  }

  onFiltersRefresh() {
    this.setState(this.getInitialState(), () => {
      this.props.loadData(this.getFiltersObject());
    });
  }

  render() {
    return (
      <div className=" row d-flex justify-content-center orderWeight-filters  ">
        <div className="row  align-content-center orderWeight-filters col-sm-12 col-lg-9">
          <div className="col-lg-3 ">
            <SelectRequestOrderNumber
              name="order_number"
              placeholder="Número de orden"
              onChange={this.onFiltersChange.bind(this)}
              value={this.state.order_number}
            />
          </div>
          <div className="col-lg-3 ">
            <SelectWeight
              name="state_weight"
              placeholder="Estado del peso"
              onChange={this.onFiltersChange.bind(this)}
              value={this.state.state_weight}
            />
          </div>
          <div className="col-lg-3 ">
            <SelectProvider
              name="provider.id"
              placeholder="Proveedor"
              onChange={this.onFiltersChange.bind(this)}
              value={this.state['provider.id']}
            />
          </div>
          <div className="col-lg-3">
            <SelectDates
              onChange={this.onFiltersChange.bind(this)}
              name="import_date"
              placeholder="Fecha de importación"
              value={this.state.import_date}
            />{' '}
          </div>
        </div>
        <div className="ml-3  row margin-filter orderWeight-filters col-sm-12 col-lg-3">
          <div>
            <button
              type="button"
              className="btn  ml-2  btn-primary "
              onClick={this.onFiltersSubmit.bind(this)}>
              Filtrar<i className="ml-1 fas fa-search"></i>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn  ml-2  btn-warning pull-right"
              onClick={this.onFiltersRefresh.bind(this)}>
              Reiniciar filtros<i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

OrderWeightFilters.contextType = FiltersCacheContext;
