import React, { Component } from 'react';
import apiHelpers from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import Footer from '../common/Footer';
import NavBar from '../common/NavBar';
import SelectRequestOrderNumber from '../common/selects/SelectRequestOrderNumber';
import SelectReturnReason from '../common/selects/SelectReturnReason';
import Modal from 'react-modal';
import AlertMessage from '../common/AlertMessage';

const initialRequestData = {
  id: null,
  orderNumber: '',
  clientName: '',
  products: null,
};

const initialReturnData = {
  details: '',
  reason: '',
  resolution: '',
  resolutionDetails: '',
  requests: null,
  solved: false,
};

export class ReturnRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnReason: '',
      returnDetails: '',
      requests: [initialRequestData],
      openSuccessModal: false,
      createdNewReturn: initialReturnData,
      openErrorAlert: false,
      errorMessage: null,
      openSubmitErrorAlert: false,
      errorSubmitMessage: null,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  handleChangeReturnDetails(event) {
    const target = event.target;
    const name = target.name;
    let value = formatter.formatFromTarget(target);
    this.setState(prev => {
      return {
        ...prev,
        [name]: value ?? '',
      };
    });
  }

  handleChangeRequest(event) {
    if (event.target.value === null) {
      let newRequests = this.state.requests;
      newRequests[event.target.name] = initialRequestData;
      this.setState(prev => {
        return {
          ...prev,
          requests: newRequests,
        };
      });
    }
    event.target.value !== null &&
      this.getRequestWithOrderNumber(event.target.value, event.target.name);
  }

  handleChangeLoteProduct(event) {
    let currentRequests = this.state.requests;

    let newRequests = currentRequests.map(request => {
      let updatedRequest = request;
      if (updatedRequest.id === Number(event.target.id)) {
        updatedRequest = {
          ...request,
          products: request.products.map(product => {
            let updatedProd = product;
            if (updatedProd.id === Number(event.target.name)) {
              updatedProd = {
                ...product,
                lote: event.target.value,
              };
            }
            return updatedProd;
          }),
        };
      }
      return updatedRequest;
    });

    this.setState(prev => {
      return {
        ...prev,
        requests: newRequests,
      };
    });
  }

  async getRequestWithOrderNumber(order_number, requestIdx) {
    const { data, error } = await apiHelpers.getRequests({
      where: { order_number: order_number },
    });
    if (error || order_number !== data[0].order_number) {
      this.setState(prev => {
        return {
          ...prev,
          openErrorAlert: true,
          errorMessage: 'Error obteniendo datos del pedido',
        };
      });
      return;
    } else {
      this.setState(prev => {
        return {
          ...prev,
          openErrorAlert: false,
        };
      });
    }

    let newData = {
      id: data[0].id,
      orderNumber: data[0].order_number,
      clientName: data[0].ship_to_name,
      products: data[0].productRequestRows,
    };

    let newRequests = this.state.requests;
    newRequests[requestIdx] = newData;
    this.setState(prev => {
      return {
        ...prev,
        requests: newRequests,
      };
    });
  }

  handleAddRequest() {
    this.setState(prev => {
      return {
        ...prev,
        requests: prev.requests.concat(initialRequestData),
      };
    });
  }

  handleRemoveRequest(idx) {
    let newRequests = [...this.state.requests];
    newRequests.splice(idx, 1);
    this.setState(prev => {
      return {
        ...prev,
        requests: newRequests,
      };
    });
  }

  handleCreateReturn() {
    if (
      this.state.returnReason === '' ||
      this.state.requests[0]?.orderNumber === ''
    ) {
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage:
              'No es posible generar una devolución sin seleccionar el motivo o sin asociar pedidos',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
      return;
    }

    let noLote = !this.state.requests.every(req =>
      req.products.every(prod => prod.lote && prod.lote.length > 0),
    );
    if (noLote) {
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage:
              'Es necesario incluir el número de lote de cada producto',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
      return;
    }
    let returnData = {
      reason: this.state.returnReason,
      details: this.state.returnDetails,
      requests: this.state.requests,
    };
    this.submitReturn(returnData);
  }

  async submitReturn(payload) {
    try {
      let { data } = await apiHelpers.postReturn(payload);
      let newReturn = data.returnData;
      this.setState(prev => {
        return {
          ...prev,
          openSuccessModal: true,
          createdNewReturn: newReturn,
        };
      });
    } catch (error) {
      console.log('Error creating return record', error);
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage: 'Error creating return record',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
    }
  }

  closeSuccessModal() {
    this.setState(prev => {
      return {
        ...prev,
        returnReason: '',
        returnDetails: '',
        requests: [initialRequestData],
        openSuccessModal: false,
        openErrorAlert: false,
        errorMessage: null,
        openSubmitErrorAlert: false,
        errorSubmitMessage: null,
      };
    });
  }

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3">
          <h1 className=" col-sm-12 row text-position-title">
            <div className="col-lg-7">
              <i className="fas fa-box-open"></i>
              <span>Devoluciones</span>
            </div>
          </h1>
          <div className="d-flex justify-content-center">
            {this.state.openSubmitErrorAlert && (
              <AlertMessage
                type="error"
                title={this.state.errorSubmitMessage}
              />
            )}
          </div>
          <div className="creation-info my-4">
            <div className="p-4 row">
              <div className="form-group col-sm-6">
                <label htmlFor="observations"> Motivo de la devolución </label>
                <SelectReturnReason
                  selectClassName={
                    this.state.errorSubmitMessage &&
                    this.state.returnReason === '' &&
                    'border border-danger rounded'
                  }
                  onChange={this.handleChangeReturnDetails.bind(this)}
                  name="returnReason"
                  className="form-control"
                  placeholder="Elija el motivo de la devolución"
                  value={this.state.returnReason}
                />
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="observations">
                  {' '}
                  Detalles de la devolución{' '}
                </label>
                <textarea
                  onChange={this.handleChangeReturnDetails.bind(this)}
                  name="returnDetails"
                  className="form-control"
                  placeholder="Ingrese los detalles de la devolución"
                  value={this.state.returnDetails}
                />
              </div>
            </div>
          </div>
          <h3>Pedidos asociados a la devolución</h3>
          {this.state.requests?.map((request, idx) => {
            return (
              <div className="creation-info my-4">
                <div className="px-4 pt-3 row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label htmlFor="sold_to_id"> Número de pedido </label>
                    <SelectRequestOrderNumber
                      className={
                        this.state.errorSubmitMessage &&
                        this.state.requests[0].orderNumber === '' &&
                        'border border-danger rounded'
                      }
                      name={`${idx}`}
                      placeholder="Buscar pedido"
                      onChange={this.handleChangeRequest.bind(this)}
                      value={request.orderNumber}
                      selectedOrders={this.state.requests?.map(
                        elem => elem.orderNumber,
                      )}
                    />
                  </div>
                  {this.state.openErrorAlert && (
                    <div className="form-group col-sm-12 col-md-12">
                      <AlertMessage
                        type="error"
                        title={this.state.errorMessage}
                      />
                    </div>
                  )}
                  {request?.clientName && (
                    <div
                      className={`form-group col-sm-12 col-md-${idx ? 5 : 6}`}>
                      <label htmlFor="ship_to_name">Nombre del cliente</label>
                      <input
                        name="ship_to_name"
                        className="form-control"
                        placeholder="A quien se envia"
                        value={request.clientName}
                        disabled
                      />
                    </div>
                  )}
                  {this.state.requests.length > 1 && idx > 0 && (
                    <div className="form-group col-sm-12 col-md-1">
                      <label htmlFor="remove_request">Quitar</label>
                      <button
                        className="btn btn-danger mr-0"
                        type="button"
                        onClick={() => this.handleRemoveRequest(idx)}>
                        x
                      </button>
                    </div>
                  )}
                </div>
                {request?.products && (
                  <div className="px-4 pb-3 row">
                    <div className="form-group col-sm-12 pr-0">
                      <div className="row col-sm-12 px-0">
                        <label
                          htmlFor="product_request_row"
                          className="col-sm-8">
                          Productos
                        </label>
                        <label
                          htmlFor="product_request_row.quantity"
                          className="col-sm-2 text-right">
                          Cantidad
                        </label>
                        <label
                          htmlFor="product_request_row.quantity"
                          className="col-sm-2 text-right">
                          Lote
                        </label>
                      </div>
                      {request?.products?.map(product => {
                        return (
                          <div
                            className="row mb-2 col-md-12 px-0"
                            key={product.id}>
                            <div className="col-md-8">
                              <input
                                disabled
                                className="form-control"
                                name="material"
                                placeholder="No Definido"
                                value={`${product?.product?.description} (${product.material})`}
                              />
                            </div>
                            <div className="col-md-2">
                              <input
                                disabled
                                className="form-control input-number"
                                name="quantity"
                                value={product.quantity}
                                pattern="^[+]?\d+([.]\d+)?$"
                              />
                            </div>
                            <div className="col-md-2 px-0">
                              <input
                                id={request.id}
                                name={product.id}
                                onChange={this.handleChangeLoteProduct.bind(
                                  this,
                                )}
                                className="form-control input-number"
                                value={product.product.lote}
                                pattern="^[+]?\d+([.]\d+)?$"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          }, this)}
          {this.state.requests[0].orderNumber !== '' && (
            <div className="form-group col-sm-12">
              <button
                className="btn btn-primary"
                onClick={this.handleAddRequest.bind(this)}>
                Agregar pedido
              </button>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center m-3">
          <button
            className="btn btn-success btn-lg mb-5"
            onClick={this.handleCreateReturn.bind(this)}>
            Generar devolución
          </button>
        </div>

        <Modal
          isOpen={this.state.openSuccessModal}
          className="modal-dialog-email fadeInDown modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h3 className="display-4">Devolución creada</h3>
            </div>
            {this.state.createdNewReturn?.reason &&
              this.state.createdNewReturn?.requests && (
                <div className="modal-body">
                  <div className="d-flex flex-column align-items-center w-100">
                    <h4>La solicitud de devolución se generó con éxito:</h4>
                    <div className="d-flex flex-column align-items-center w-100 m-2">
                      <h5>Nombre cliente:</h5>
                      <input
                        className="form-control w-50"
                        disabled
                        value={
                          this.state.createdNewReturn.requests[0]?.clientName
                        }
                      />
                    </div>
                    <div className="d-flex flex-column align-items-center w-100 m-2">
                      <h5>Motivo de la devolución:</h5>
                      <input
                        className="form-control w-50"
                        disabled
                        value={this.state.createdNewReturn.reason}
                      />
                    </div>
                    <div className="d-flex flex-column align-items-center w-100 m-2">
                      <h5>Detalles de la devolución:</h5>
                      <textarea
                        className="form-control w-50"
                        disabled
                        value={this.state.createdNewReturn.details}
                      />
                    </div>
                    <div className="d-flex flex-column align-items-center w-100 m-2">
                      <h5>Pedidos asociados:</h5>
                      {this.state.createdNewReturn.requests.map(request => {
                        return (
                          <input
                            className="form-control w-50 mb-2"
                            disabled
                            value={request.orderNumber}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            <div className="modal-footer">
              <div className="d-flex w-100 justify-content-center">
                <button
                  className="btn btn-primary btn-lg w-50"
                  onClick={this.closeSuccessModal.bind(this)}>
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Footer />
      </div>
    );
  }
}

export default ReturnRequest;
