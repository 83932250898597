import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const options = props.options.map(option => {
            return { value: option, label: option }
        })
        options.unshift({ value: 'all', label: props.labelAll })
        options.unshift({value: null, label: 'Sin filtro'})
        setOptions(options)
    }, [])
    

    const onChangeWrapper = (value) => {
        let event = { target: {} };
        event.target.name = props.name ? props.name : 'select-nameless';
        event.target.value = value.value;
        event.target.type = 'react-select';
        props.onChange(event);
    }

    let value = props.value;

    if (value) {
        value = options.find(option => option.value === value);
    }

    return (
        <Select
            className={props.selectClassName}
            name={props.name}
            onChange={onChangeWrapper}
            value={value || ''}
            required={props.required}
            options={options}
            clearable={false}
            placeholder={props.placeholder}
            menuContainerStyle={{ zIndex: 999 }}
            isDisabled={props.isDisabled}
            tabIndex={props.tabIndex}
        />
    );
};

export default CustomSelect;