import apiHelper from '../../utils/apiHelpers';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import SelectUserRole from '../common/selects/SelectUserRole';
import AlertMessage from '../common/AlertMessage';
import SelectProviderRequest from '../common/selects/SelectProviderRequest';

export default class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      isNew: props.match.params.id === 'new',
      user: {
        first_name: '',
        last_name: '',
        username: '',
        role: {
          userRole: '',
        },
        password: '',
        roleId: '',
        email: '',
        pickUpProvider: null,
      },
      alertError: false,
      alertSuccess: false,
    };
  }

  componentDidMount() {
    apiHelper
      .getCurrentUser()
      .then(response => {
        this.setState({
          user: response.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChange(event) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const user = this.state.user;
    user[name] = value;
    this.setState({
      user: user,
    });
  }

  async submitUser(event) {
    event.preventDefault();
    let userId = this.state.user.id;

    try {
      const user = this.state.user;

      await apiHelper.patchUser(userId, user);
      this.setState({ alertSuccess: true }, () =>
        this.props.history.push('/request'),
      );
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        errorMessage: errorMessage,
        alertError: true,
      });
    }
  }

  render() {
    let title = this.state.user.first_name;
    let alert;
    let userRole = '';

    if (this.state.alertError) {
      alert = (
        <div className="alert alert-warning ml-5 mr-5 text-center">
          {this.state.errorMessage}
        </div>
      );
    }

    if (this.state.alertSuccess) {
      alert = (
        <AlertMessage type="success" title="Datos guardados exitosamente" />
      );
    }
    if (
      this.state.user.role.userRole === 'ADMIN' ||
      this.state.user.role.userRole === 'SUPERVISOR' ||
      this.state.user.role.userRole === 'MANAGER'
    ) {
      userRole = (
        <div className="form-group">
          <label htmlFor="role"> Rol del usuario </label>
          <SelectUserRole
            onChange={this.handleChange.bind(this)}
            name="roleId"
            className="form-control"
            placeholder=" Rol "
            value={this.state.user.roleId}
          />
        </div>
      );
    } else if (this.state.user.role.userRole === 'PICKUP') {
      userRole = (
        <>
          <div className="form-group">
            <label htmlFor="role"> Rol del usuario </label>
            <input
              onChange={this.handleChange.bind(this)}
              name="roleId"
              className="form-control"
              placeholder=" Rol "
              value={this.state.user.role.userRole}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="provider "> Proveedor Asociado </label>
            <SelectProviderRequest
              onChange={this.handleChange.bind(this)}
              name="pickUpProvider"
              placeholder=" Proveedor "
              value={
                this.state.user.pickUpProvider
                  ? this.state.user.pickUpProvider.id
                  : null
              }
            />
          </div>
        </>
      );
    } else {
      userRole = (
        <div className="form-group">
          <label htmlFor="role"> Rol del usuario </label>
          <input
            onChange={this.handleChange.bind(this)}
            name="roleId"
            className="form-control"
            placeholder=" Rol "
            value={this.state.user.role.userRole}
            disabled
          />
        </div>
      );
    }

    return (
      <div key={this.state.user.id} className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3">
          <h1 className=" text-position-title">
            <i className="fas fa-user"></i>
            <span> {title} </span>
          </h1>
          {alert}
          <div className="row">
            <div className="col-md-6 creation-info p-3">
              <h5 className="h5 text-center">Datos del usuario</h5>

              <div className="form-group">
                <label htmlFor="first_name"> Nombre</label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="first_name"
                  className="form-control"
                  placeholder=" Nombre"
                  value={this.state.user.first_name}
                />
              </div>

              <div className="form-group">
                <label htmlFor="last_name"> Apellido </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="last_name"
                  className="form-control"
                  placeholder=" Apellido"
                  value={this.state.user.last_name}
                />
              </div>

              {userRole}
            </div>

            <div className="col-md-6 creation-info p-3">
              <h5 className="h5 text-center">Datos de login</h5>

              <div className="form-group">
                <label htmlFor="username"> Usuario</label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="username"
                  className="form-control"
                  placeholder=" Nombre de usuario"
                  value={this.state.user.username}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email"> Email</label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="email"
                  className="form-control"
                  placeholder=" Correo electrónico"
                  value={this.state.user.email}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Contraseña</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  autoComplete="new-password"
                  placeholder="No definido"
                  value={this.state.user.password}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            {function () {
              return (
                <button
                  className="button-submit btn btn-primary my-5"
                  onClick={this.submitUser.bind(this)}
                  type="submit">
                  Guardar <i className="fas fa-save"></i>
                </button>
              );
            }.bind(this)()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
