import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';
import apiHelper from '../../utils/apiHelpers';
import ReCAPTCHA from 'react-google-recaptcha';
import AlertMessage from '../common/AlertMessage';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      key: '',
      errorMessage: null,
      isVerified: false,
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      await apiHelper.login({
        username: this.state.username,
        password: this.state.password,
        key: this.state.key,
      });
      this.props.history.push('/');
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 401) {
        errorMessage = 'Email o contraseña invalidas';
      } else if (statusCode === 402) {
        errorMessage = 'Captcha invalido';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  onChange(value) {
    this.setState({
      key: value,
    });
  }

  render() {
    let alert;

    if (this.state.errorMessage) {
      alert = (
        <AlertMessage type="warning" title={`${this.state.errorMessage}`} />
      );
    }

    return (
      <div className="container">
        <div className="wrapper fadeInDown">
          <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
            <div className="fadeIn first">
              <img
                className="logo logo-login"
                src={process.env.PUBLIC_URL + '/img/logo.png'}
                alt="logo"
              />

              <h4 className="h4 mb-3 font-weight-normal">Ingrese sus datos</h4>
              <div className="form-group mb-0">
                <label htmlFor="inputEmail" className="sr-only">
                  Usuario
                </label>
                <input
                  type="text"
                  name="username"
                  className="form-control fadeIn second"
                  placeholder="Usuario"
                  value={this.state.username}
                  onChange={this.handleChange.bind(this)}
                  required
                  autoFocus
                />
              </div>

              <div className="form-group">
                <label htmlFor="inputPassword" className="sr-only">
                  Contraseña
                </label>
                <input
                  type="password"
                  name="password"
                  className="form-control fadeIn third"
                  placeholder="Contraseña"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                  required
                />
              </div>
              <div className="margin-captcha">
                <ReCAPTCHA
                  sitekey="6Ld-IHMaAAAAADqQyJv-lcsHs2jc2HCn6eJpUZpZ"
                  onChange={this.onChange.bind(this)}
                />
              </div>
              {alert}
              <button
                className=" margin-button-login btn btn-lg-4 btn-primary btn-block fadeIn fourth"
                formAction="index.html"
                type="submit">
                Iniciar sesión <i className="fas fa-sign-in-alt"></i>
              </button>
              <hr />
              <Link to="/recover_password">Olvide mi Contraseña</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
