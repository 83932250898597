import React from 'react';
import SelectProductDescription from '../common/selects/SelectProductDescription';

const Gift = ({ gift, giftIdx, handleChangeGift, handleRemoveGift }) => {
  return (
    <div className="row mb-4 col-sm-12 px-0">
      <div className="col-sm-9">
        <SelectProductDescription
          id={giftIdx}
          onChange={handleChangeGift}
          name="material"
          placeholder="No Definido"
          value={gift.material}
          required
        />
      </div>
      <div className="col-sm-2">
        <input
          id={giftIdx}
          type="number"
          className="form-control input-number"
          style={{ maxWidth: '60px' }}
          name="quantity"
          onChange={handleChangeGift}
          value={gift.quantity || 1}
          min={1}
          pattern="^[+]?\d+([.]\d+)?$"
        />
      </div>
      <div className="col-sm-1 pr-0">
        <button
          id={giftIdx}
          className="btn btn-danger"
          type="button"
          name="remove"
          onClick={e => handleRemoveGift(e)}>
          x
        </button>
      </div>
    </div>
  );
};

export default Gift;
