import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import Pagination from '../common/Pagination';
import './Log.css';
import Modal from 'react-modal';
import LogFilters from './LogFilters';
import formtatter from '../../utils/formatter';
const config = require('../../config/config');

export default class Log extends React.Component {
  download = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      modalIsOpen: false,
      count: 0,
      pageSize: 25,
      currentPage: 1,
      loading: false,
      filters: {},
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getLogs();
    });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getLogs();
    });
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  async getLogs() {
    const { filters, currentPage, pageSize } = this.state;

    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [response, logsCount] = await Promise.all([
        await apiHelper.getLogs(filtersWithPaging),
        await apiHelper.getLogsCount(filters),
      ]);

      this.setState({
        logs: config.countryVersion?.noPostalCode
          ? response.data.filter(err => err.type !== 'postalCode')
          : response.data,
        count: logsCount.data.count,
      });
    } catch (e) {
      console.log('Error loading logs');
    }
  }

  componentDidMount() {
    this.getLogs();
  }

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />

        <div className="container">
          <h1 className="text-nuskin">
            <div className=" col-lg-7 title col-sm-12">
              <i className=" fas fa-exclamation-triangle mt-3"></i>{' '}
              <span>Historial de errores</span>
            </div>
          </h1>

          <LogFilters loadData={this.handleChangeFilters.bind(this)} />

          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">Pedido</th>
                  <th scope="col">Tipo de error</th>
                  <th scope="col">Mensaje</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.logs.map(log => {
                  return (
                    <tr key={log.id}>
                      <td>{formtatter.formatDate(log.createdAt)}</td>
                      <td>
                        {log.productRequest
                          ? log.productRequest.order_number +
                            ' (' +
                            log.productRequest.delivery_number +
                            ')'
                          : ''}
                      </td>
                      <td>{formtatter.formatErrorType(log.type)}</td>
                      <td>{log.message}</td>
                      <td>{log.user.username}</td>
                      {log.productRequest && (
                        <td>
                          <div className="text-center">
                            <Link
                              to={`${process.env.PUBLIC_URL}/request/${log.productRequest.id}`}>
                              <button
                                type="button"
                                className="btn btn-primary btn-circle">
                                <span className="fa fa-edit"></span>
                              </button>
                            </Link>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-center">
          <Pagination
            onChangePage={this.handleChangePage.bind(this)}
            currentPage={this.state.currentPage}
            totalPages={this.getNumberOfPages()}
          />
        </div>
        <Footer />
      </div>
    );
  }
}
