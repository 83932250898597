import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React from 'react';
import PropTypes from 'prop-types';

export default class RequestsBarChartOfWeightDate extends React.Component {
  componentDidUpdate(data) {
    if (this.chart) {
      this.chart.dispose();
    }
    if (data !== this.props.data) {
      let chart = am4core.create('barchartWeightDate', am4charts.XYChart);
      chart.cursor = new am4charts.XYCursor();
      chart.responsive.enabled = true;
      chart.data = this.props.data;
      chart.scrollbarX = new am4core.Scrollbar();
      am4core.useTheme(am4themes_animated);

      let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
      categoryAxis.dataFields.valueAxis = 'weight_hours';
      categoryAxis.dateFormats.setKey('day', 'dd MMM yyyy');
      categoryAxis.title.text = 'Día de pesaje';
      categoryAxis.skipEmptyPeriods = true;

      categoryAxis.baseInterval = {
        timeUnit: 'day',
        count: 1,
      };

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.dataFields.category = 'total_requests';
      valueAxis.title.text = 'Total de pedidos';

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = 'Total de pedido';
      series.dataFields.dateX = 'weight_hours';
      series.dataFields.valueY = 'total_requests';
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.columns.template.focusable = true;
      series.columns.template.hoverOnFocus = true;
      series.stacked = true;

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = '{valueY}';
      labelBullet.label.fill = am4core.color('#000000');
      labelBullet.isHover = true;
      labelBullet.label.dy = -20;

      this.chart = chart;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div
        id="barchartWeightDate"
        style={{ width: '100%', height: '500px' }}></div>
    );
  }
}

RequestsBarChartOfWeightDate.propTypes = {
  data: PropTypes.array,
};
