import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import Pagination from '../common/Pagination';
import './OrderWeight.css';
import Modal from 'react-modal';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '../common/Tooltip';
import ProductRequestValidation from '../requests/productRequestValidation';
import OrderWeightFilters from './OrderWeightFilters';
import { CSVLink } from 'react-csv';
import formatter from '../../utils/formatter';
import AlertMessage from '../common/AlertMessage';
import ReactLoading from 'react-loading';
import AssociatedLabelsModal from './components/AssociatedLabelsModal';

export default class OrderWeights extends React.Component {
  download = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      orderWeights: [],
      modalIsOpen: false,
      count: 0,
      newOrderWeights: null,
      pageSize: 25,
      currentPage: 1,
      responseWithWrongPostalCode: [],
      loading: false,
      importErrors: null,
      importSuccess: false,
      dropDown: false,
      query: '',
      CSVdata: '',
      boxOptions: [],
      currentUser: null,
      message: false,
      loadingRequests: false,
      headLabels: null,
      headLabelsModal: false,
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      this.setState({ message: true });
    }
    this.getBoxData();
    this.getCurrentUser();
    this.getOrderWeights();
    this.getHeadLabels();
  }

  closeWarning() {
    this.setState({ importErrors: null, dropDown: false });
  }

  closeSuccess() {
    this.setState({ importSuccess: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openDropDown() {
    this.setState({ dropDown: true });
  }

  closeMessageSentProvider() {
    this.setState({ message: false });
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getOrderWeights();
    });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getOrderWeights();
    });
  }

  async getBoxData() {
    const boxResponse = await apiHelper.getProducts({
      where: { category: 'box' },
    });
    this.setState({ boxOptions: boxResponse.data });
  }

  async getCurrentUser() {
    await apiHelper
      .getCurrentUser()
      .then(response => {
        this.setState({
          currentUser: response.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async getOrderWeights() {
    this.setState({ loadingRequests: true });
    const { filters, currentPage, pageSize, count } = this.state;

    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [response, requestsCount] = await Promise.all([
        await apiHelper.getRequests(filtersWithPaging),
        await apiHelper.getRequestsCount(filters),
      ]);

      const orderWeight = response.data.filter(
        request => request.cancelled === false,
      );
      const orderWeightCount = requestsCount.data.count;

      this.setState(
        {
          orderWeights: orderWeight,
          count: orderWeightCount,
          loadingRequests: false,
        },
        () => console.log(this.state.orderWeights),
      );
    } catch (e) {
      console.log('Error loading orderWeights');
    }
  }

  async getHeadLabels() {
    const { data: headLabels } = await apiHelper.getRequests({
      where: {
        isHeadLabelOfAPI: 1,
        state_picking: 'SP',
      },
    });
    this.setState({ headLabels });
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  async exportRequests() {
    const { filters, currentPage, pageSize, count } = this.state;
    try {
      const filtersWithPaging = {
        ...filters,
      };

      const response = await apiHelper.getExportRequests(filtersWithPaging);
      const requestsForExport = await apiHelper.getRequests(filtersWithPaging);
      let responseWithWrongPostalCode = requestsForExport.data.filter(
        request => request.state_postal_code == 'CD',
      );
      this.setState(
        {
          CSVdata: response.data,
          modalIsOpen: responseWithWrongPostalCode.length,
          responseWithWrongPostalCode: responseWithWrongPostalCode,
        },
        () => {
          if (this.state.responseWithWrongPostalCode.length == 0) {
            this.download.current.link.click();
          }
        },
      );
    } catch (e) {
      console.log('Error loading requests');
    }
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({ newOrderWeights: event.target.files[0] });
  }

  render() {
    let estimatedWeigthTitle;
    let buttonExport;

    if (this.state.currentUser) {
      if (
        this.state.currentUser.role.userRole === 'ADMIN' ||
        this.state.currentUser.role.userRole === 'MANAGER'
      ) {
        estimatedWeigthTitle = <th scope="col">Peso estimado</th>;
      }
    }

    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />

        <div className="container">
          <h1 className="row text-nuskin ">
            <div className="col-lg-4 title col-sm-12">
              <i className="fas fa-weight mt-3"></i>
              {''} <span>Balanza</span>
            </div>

            <div className="row ml-2 margin-import col-lg-8 col-sm-12 ">
              {this.state.headLabels?.length > 0 ? (
                <div className=" col-sm-12 col-lg-4">
                  <button
                    className=" w-100 btn btn-info pull-right some-air"
                    onClick={() => this.setState({ headLabelsModal: true })}>
                    Pedidos cabecera <i class="fas fa-sitemap"></i>
                  </button>
                </div>
              ) : (
                <div className=" col-sm-12 col-lg-4"></div>
              )}
              <div className=" col-sm-12 col-lg-4">
                <button
                  type="button"
                  className=" w-100 btn btn-success pull-right some-air"
                  onClick={this.exportRequests.bind(this)}>
                  Exportar <i className="fas fa-file-export"></i>
                </button>
                <CSVLink
                  ref={this.download}
                  data={this.state.CSVdata}
                  filename="Reporte de pedidos.csv"
                  className="hidden"></CSVLink>
              </div>
              <div className="col-sm-12 col-lg-4">
                <Link to={`${process.env.PUBLIC_URL}/orderWeight/new`}>
                  <button
                    type="button"
                    className=" w-100 btn btn-success pull-right some-air">
                    Iniciar Pesaje <i className="fas fa-plus-square"></i>
                  </button>
                </Link>
              </div>
            </div>
          </h1>
          <OrderWeightFilters loadData={this.handleChangeFilters.bind(this)} />
          {this.props.location.state !== null && this.state.message && (
            <AlertMessage
              type="success"
              title={`${this.props.location.state}`}
              onClose={this.closeMessageSentProvider.bind(this)}></AlertMessage>
          )}
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog-email fadeInDown modal-dialog"
            contentLabel="Código postal erroneo">
            <div className="modal-content text-center">
              <div className="modal-header">
                <div className="  col-md-10">
                  <span>
                    <h3 className="pull-right mr-4">
                      Pedidos con código postal erróneo
                    </h3>
                  </span>
                </div>
                <div className="col-md-2">
                  <button
                    type="button"
                    className="close"
                    onClick={this.closeModal.bind(this)}>
                    &times;
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <form method="post" action="" encType="multipart/form-data">
                  <div
                    className="table-responsive overflow-auto my-2"
                    style={{ maxHeight: '300px' }}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Número de pedido</th>
                          <th>Numero de entrega</th>
                          <th>Fecha de importación</th>
                          <th>Observaciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.responseWithWrongPostalCode.map(
                          orderWeight => {
                            return (
                              <tr key={orderWeight.id}>
                                <td>{orderWeight.order_number}</td>
                                <td>{orderWeight.delivery_number}</td>
                                <td>
                                  {formatter.formatDate(
                                    orderWeight.import_date,
                                  )}
                                </td>
                                <td>
                                  {
                                    <ProductRequestValidation
                                      request={orderWeight}
                                      render={(
                                        addressValidation,
                                        postalCodeValidation,
                                        productsWeightValidation,
                                        weightValidation,
                                      ) => {
                                        return (
                                          <div>
                                            <span
                                              className={
                                                'fa fa-home ' +
                                                addressValidation(orderWeight)
                                                  .class
                                              }
                                              data-tip
                                              data-for={
                                                orderWeight.id + 'address'
                                              }>
                                              <Tooltip
                                                id={orderWeight.id + 'address'}
                                                tooltipText={
                                                  addressValidation(orderWeight)
                                                    .text
                                                }
                                              />
                                            </span>

                                            <span
                                              className={
                                                'fa fa-mail-bulk ml-3 ' +
                                                postalCodeValidation(
                                                  orderWeight,
                                                ).class
                                              }
                                              data-tip
                                              data-for={
                                                orderWeight.id + 'postalCode'
                                              }>
                                              <Tooltip
                                                id={
                                                  orderWeight.id + 'postalCode'
                                                }
                                                tooltipText={
                                                  postalCodeValidation(
                                                    orderWeight,
                                                  ).textTooltip
                                                }
                                              />
                                            </span>
                                            <span
                                              className={
                                                'fa fa-box ml-3 ' +
                                                productsWeightValidation(
                                                  orderWeight,
                                                ).class
                                              }
                                              data-tip
                                              data-for={
                                                orderWeight.id +
                                                'productsWeight'
                                              }>
                                              <Tooltip
                                                id={
                                                  orderWeight.id +
                                                  'productsWeight'
                                                }
                                                tooltipText={
                                                  productsWeightValidation(
                                                    orderWeight,
                                                  ).textTooltip
                                                }
                                              />
                                            </span>

                                            <span
                                              className={
                                                'fa fa-weight ml-3 ' +
                                                weightValidation(
                                                  orderWeight,
                                                  this.state.boxOptions,
                                                ).class
                                              }
                                              data-tip
                                              data-for={
                                                orderWeight.id + 'weight'
                                              }>
                                              <Tooltip
                                                id={orderWeight.id + 'weight'}
                                                tooltipText={
                                                  weightValidation(
                                                    orderWeight,
                                                    this.state.boxOptions,
                                                  ).text
                                                }
                                              />
                                            </span>
                                          </div>
                                        );
                                      }}
                                    />
                                  }
                                </td>
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                    </table>
                    <h5>¿Desea exportar igualmente?</h5>
                    <div className=" mr-2 align-content-center">
                      <button
                        type="button"
                        className="btn btn-success button-margin some-air"
                        onClick={e => {
                          this.download.current.link.click();
                          this.closeModal();
                        }}>
                        Si
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger  button-margin some-air"
                        onClick={this.closeModal.bind(this)}>
                        No
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          {this.state.headLabelsModal && (
            <AssociatedLabelsModal
              history={this.props.history}
              isOpen={this.state.headLabelsModal}
              onCloseModal={() => this.setState({ headLabelsModal: false })}
              headLabels={this.state.headLabels}
            />
          )}
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">Numero de pedido</th>
                  {estimatedWeigthTitle}
                  <th scope="col">Peso Total</th>
                  <th scope="col">Observaciones</th>
                  <th scope="col">Editar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loadingRequests ? (
                  <ReactLoading
                    className="body-login"
                    type={'spin'}
                    color={'#55CDE4'}
                    height={100}
                    width={100}
                  />
                ) : (
                  this.state.orderWeights.map(orderWeight => {
                    let estimatedWeight = '';
                    if (
                      this.state.currentUser &&
                      this.state.currentUser.role.userRole === 'ADMIN'
                    ) {
                      estimatedWeight = (
                        <td>
                          {' '}
                          {formatter.getEstimatedWeight(
                            orderWeight,
                            this.state.boxOptions,
                          )}
                        </td>
                      );
                    }
                    const actionColumn = (
                      <td>
                        <div className="text-center">
                          <Link
                            to={`${process.env.PUBLIC_URL}/orderWeight/${orderWeight.id}`}>
                            <button
                              type="button"
                              className="btn btn-primary btn-circle">
                              <span className="fa fa-edit"></span>
                            </button>
                          </Link>
                        </div>
                      </td>
                    );

                    return (
                      <tr key={orderWeight.id}>
                        <td>{orderWeight.order_number}</td>
                        {estimatedWeight}
                        <td>{orderWeight.weight}</td>
                        <td>
                          {
                            <ProductRequestValidation
                              request={orderWeight}
                              render={(
                                addressValidation,
                                postalCodeValidation,
                                productsWeightValidation,
                                weightValidation,
                              ) => {
                                return (
                                  <div>
                                    <span
                                      className={
                                        'fa fa-home ' +
                                        addressValidation(orderWeight).class
                                      }
                                      data-tip
                                      data-for={orderWeight.id + 'address'}>
                                      <Tooltip
                                        id={orderWeight.id + 'address'}
                                        tooltipText={
                                          addressValidation(orderWeight).text
                                        }
                                      />
                                    </span>

                                    <span
                                      className={
                                        'fa fa-mail-bulk ml-3 ' +
                                        postalCodeValidation(orderWeight).class
                                      }
                                      data-tip
                                      data-for={orderWeight.id + 'postalCode'}>
                                      <Tooltip
                                        id={orderWeight.id + 'postalCode'}
                                        tooltipText={
                                          postalCodeValidation(orderWeight).text
                                        }
                                      />
                                    </span>

                                    <span
                                      className={
                                        'fa fa-box ml-3 ' +
                                        productsWeightValidation(orderWeight)
                                          .class
                                      }
                                      data-tip
                                      data-for={
                                        orderWeight.id + 'productsWeight'
                                      }>
                                      <Tooltip
                                        id={orderWeight.id + 'productsWeight'}
                                        tooltipText={
                                          productsWeightValidation(orderWeight)
                                            .text
                                        }
                                      />
                                    </span>

                                    <span
                                      className={
                                        'fa fa-weight ml-3 ' +
                                        weightValidation(
                                          orderWeight,
                                          this.state.boxOptions,
                                        ).class
                                      }
                                      data-tip
                                      data-for={orderWeight.id + 'weight'}>
                                      <Tooltip
                                        id={orderWeight.id + 'weight'}
                                        tooltipText={
                                          weightValidation(
                                            orderWeight,
                                            this.state.boxOptions,
                                          ).text
                                        }
                                      />
                                    </span>
                                  </div>
                                );
                              }}
                            />
                          }
                        </td>
                        {actionColumn}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        {!this.state.loadingRequests && (
          <div className="text-center">
            <Pagination
              onChangePage={this.handleChangePage.bind(this)}
              currentPage={this.state.currentPage}
              totalPages={this.getNumberOfPages()}
            />
          </div>
        )}
        <iframe className="pdf-frame" id="pdf-frame"></iframe>
        <Footer />
      </div>
    );
  }
}
