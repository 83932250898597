import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default class Tooltip extends React.Component {
  render() {
    let tooltip = '';

    if (this.props.tooltipText) {
      tooltip = (
        <ReactTooltip
          id={this.props.id}
          place={this.props.place ?? "left"}
          type={this.props.type ?? "info"}
          effect="float"
          delayShow={this.props.delayShow ?? 250}
          html={true}
          multiline={true}>
          {this.props.tooltipText}
        </ReactTooltip>
      );
    }

    return tooltip;
  }
}

Tooltip.propTypes = {
  id: PropTypes.string,
  tooltipText: PropTypes.string,
};
