import React from 'react';
import apiHelper from '../../utils/apiHelpers';
import config from '../../config/config';
import moment from 'moment';
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
const JsBarcode = require('jsbarcode');

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    border: 0.6,
    borderColor: '#FFFFFF',
    padding: 6,
  },
  paragraph: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    borderColor: '#FFFFFF',
    border: 0.3,
    padding: 1,
    marginTop: 6,
    width: '95%',
    marginLeft: 10,
    marginRight: 10,
  },
  paragraphR: {
    flexDirection: 'row',
    marginTop: 0.5,
  },
  paragraphBarcode: {
    flexDirection: 'row',
    marginTop: 20,
  },
  whiteRow: {
    flexDirection: 'row',
    padding: 28,
    flexGrow: 3,
  },
  section: {
    padding: 3,
    flexGrow: 3,
    fontSize: 11,
    color: '#4E4D4D',
  },
  sectionB: {
    padding: 3,
    flexGrow: 3,
    marginLeft: 14,
    fontSize: 11,
    color: '#4E4D4D',
  },
  sectionTitle: {
    color: '#000000',
    fontWeight: 'bold',
  },
  sectionTitleSignature: {
    padding: 3,
    width: '33%',
    fontSize: 11,
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 40,
    textAlign: 'center',
  },
  invoice: {
    padding: 3,
    fontSize: 19,
    borderColor: '#FFFFFF',
    border: 0.85,
    alignSelf: 'center',
    marginLeft: 99,
  },
  invoiceText: {
    padding: 3,
    fontSize: 9,
    borderColor: '#FFFFFF',
    alignSelf: 'center',
  },
  VtoDate: {
    padding: 3,
    fontSize: 11,
    borderColor: '#FFFFFF',
    alignSelf: 'flex-end',
    marginLeft: 68,
  },
  invoiceNumber: {
    padding: 6,
    marginVertical: 14,
    fontSize: 11,
    borderColor: '#FFFFFF',
    alignSelf: 'flex-end',
    marginLeft: 68,
    color: '#000000',
  },
  logo: {
    width: '20%',
    marginTop: 3,
    marginLeft: 5,
    padding: 1,
  },
  logoFooter: {
    width: '40%',
    margin: '0px 0 0 0 0',
    paddingLeft: 30,
  },
  barcode: {
    width: '40%',
    height: '70%',
    marginTop: 10,
    marginLeft: 30,
    paddingRight: 30,
  },
});

const styles_weCarry = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    paddingTop: 4,
    height: '20%',
  },
  barcode: {
    height: '80%',
    paddingBottom: 2,
  },
});

const styles_asociated = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    padding: 3,
    flexGrow: 3,
    fontSize: 14,
    color: '#4E4D4D',
    margin: 4,
  },
  paragraph: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    border: 0.3,
    padding: 3,
    width: '95%',
  },
  paragraphHead: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    border: 0.6,
    padding: 3,
    width: '95%',
    backgroundColor: '#dbdbdb',
  },
  title: {
    paddingTop: 4,
    height: '15%',
  },
  isHead: {
    color: '#0275d8',
    fontWeight: 'bold',
  },
  isAsociated: {
    color: '#5bc0de',
    fontWeight: 'bold',
  },
  pagination: {
    padding: 3,
    margin: 32,
    flexGrow: 3,
    fontSize: 12,
    color: '#4E4D4D',
  },
});

export const generatePDF = async (fileName, requests, provider) => {
  let blob;
  if (provider.toLowerCase() === 'wecarry') {
    blob = await pdf(<WeCarryPDF requests={requests} />).toBlob();
  } else {
    blob = await pdf(<RequestListPDF requests={requests} />).toBlob();
  }
  // Send to printer
  // Requires iframe tag with ID pdf-iframe in parent component
  var objectURL = URL.createObjectURL(blob);
  try {
    document.querySelector('#pdf-frame').src = '';
    document.querySelector('#pdf-frame').src = objectURL;
    objectURL = URL.revokeObjectURL(blob);
    window.setTimeout(function () {
      document.querySelector('#pdf-frame').contentWindow.print();
    }, 2000);
  } catch (e) {
    console.log(e);
  }
  // Download as PDF
  //saveAs(blob, fileName);
};

export const generateAsociatedLabel = async (fileName, requests) => {
  let blob = await pdf(<AsociatedLabelsPDF requests={requests} />).toBlob();
  // Send to printer
  // Requires iframe tag with ID pdf-iframe in parent component
  var objectURL = URL.createObjectURL(blob);
  try {
    document.querySelector('#pdf-frame').src = '';
    document.querySelector('#pdf-frame').src = objectURL;
    objectURL = URL.revokeObjectURL(blob);
    window.setTimeout(function () {
      document.querySelector('#pdf-frame').contentWindow.print();
    }, 2000);
  } catch (e) {
    console.log(e);
  }
  // Download as PDF
  //saveAs(blob, fileName);
};

export const downloadPDF = async (request, retry = 0, label_data) => {
  // if (label_data) {
  //   // If the label data has already been obtained, print it
  //   let objectURL = URL.createObjectURL(label_data);
  //   document.querySelector('#pdf-frame').src = '';
  //   document.querySelector('#pdf-frame').src = objectURL;
  //   window.setTimeout(function () {
  //     document.querySelector('#pdf-frame').contentWindow.print();
  //   }, 1000);
  // } else {
  // If the label data has not been obtained yet, try to obtain it, up to 5 retrys
  let printed = false;
  while (retry < 5 && !printed) {
    try {
      let response = await apiHelper.getRequestsLabels(request.id);
      let objectURL = URL.createObjectURL(response.data);
      document.querySelector('#pdf-frame').src = '';
      document.querySelector('#pdf-frame').src = objectURL;
      window.setTimeout(function () {
        document.querySelector('#pdf-frame').contentWindow.print();
      }, 1000);
    } catch (e) {
      retry++;
      console.log(e);
    }
    printed = !printed;
  }
  if (!printed) return true;
  // }
};

export const downloadReturnPDF = async (request, retry = 0, label_data) => {
  let printed = false;
  while (retry < 5 && !printed) {
    try {
      let response = await apiHelper.getReturnRequestsLabels(request.id);
      let objectURL = URL.createObjectURL(response.data);
      document.querySelector('#pdf-frame').src = '';
      document.querySelector('#pdf-frame').src = objectURL;
      window.setTimeout(function () {
        document.querySelector('#pdf-frame').contentWindow.print();
      }, 1000);
    } catch (e) {
      retry++;
      console.log(e);
    }
    printed = !printed;
  }
  if (!printed) return true;
};

function RequestListPDF(props) {
  const size = [config.sizeOfPdf.width, config.sizeOfPdf.high];
  let canvas = document.createElement('canvas');

  return (
    <Document>
      {props.requests.map((request, index) => {
        JsBarcode(canvas, request.order_number);
        const barcode = canvas.toDataURL();

        let invoice_number = '' + request.invoice.number_invoice;
        while (invoice_number.length < 8) {
          invoice_number = '0' + invoice_number;
        }

        let pageSize;
        if (
          request.provider.labelsWidth > 0 &&
          request.provider.labelsHeight > 0
        ) {
          // from mm to px
          let widthPixels = request.provider.labelsWidth * 3.779527559;
          let heightPixels = request.provider.labelsHeight * 3.779527559;
          pageSize = [widthPixels, heightPixels];
        } else {
          pageSize = size;
        }
        return (
          <Page key={request.id} size={pageSize} style={styles.page}>
            {request.provider.isWarehouse ? (
              // IS WAREHOUSE CASE: SHORT PDF
              <>
                <View style={styles.paragraphR}>
                  <Image
                    style={styles.logo}
                    source={process.env.PUBLIC_URL + '/img/logoInvoice.png'}
                  />
                </View>

                <View style={styles.paragraph}>
                  <View style={styles.section}>
                    <Text>
                      <Text style={styles.sectionTitle}>Número de Orden:</Text>{' '}
                      {request.order_number}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>
                        Fecha de Validación:
                      </Text>{' '}
                      {moment(request.weight_hours).format('DD/MM/YYYY')}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>
                        Fecha de Impresión:
                      </Text>{' '}
                      {moment().format('DD/MM/YYYY')}
                    </Text>
                  </View>
                </View>

                <View style={styles.paragraph}>
                  <View style={styles.section}>
                    <Text>
                      <Text style={styles.sectionTitle}>Cliente:</Text>{' '}
                      {request.ship_to_name}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>Teléfono:</Text>{' '}
                      {`${request.mobile_phone || ''} / ${
                        request.home_phone || ''
                      }`}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>Email:</Text>{' '}
                      {request.ship_to_email}
                    </Text>
                  </View>
                </View>
              </>
            ) : (
              // IS NOT WAREHOUSE: FULL PDF
              <>
                <View style={styles.paragraphR}>
                  <Image
                    style={styles.logo}
                    source={process.env.PUBLIC_URL + '/img/logoInvoice.png'}
                  />

                  <View style={styles.invoice}>
                    <Text>R</Text>
                  </View>
                  <View style={styles.invoiceNumber}>
                    <Text>REMITO</Text>
                    <Text>N° 00001 - {invoice_number}</Text>
                  </View>
                </View>

                <View style={styles.invoiceText}>
                  <Text>DOCUMENTO NO VÁLIDO COMO FACTURA</Text>
                </View>

                <View style={styles.paragraph}>
                  <View style={styles.section}>
                    <Text>Nu Skin Argentina, Inc. </Text>
                    <Text style={styles.sectionTitle}>Domicilio Fiscal: </Text>
                    <Text> Rosaria Vera Peñaloza 415/465 </Text>
                    <Text> Complejo Zencity Unidades</Text>
                    <Text> Comerciales Nº 25 y 26.</Text>
                    <Text> Puerto Madero Este CABA.</Text>
                    <Text> IVA Responsable Inscripto</Text>
                  </View>

                  <View style={styles.sectionB}>
                    <Text>
                      <Text style={styles.sectionTitle}>
                        {' '}
                        CAI N° 49140200189470
                      </Text>
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>CUIT: </Text>{' '}
                      30-69083303-0
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>Ingresos Brutos: </Text>
                      30690833030-901
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>
                        Inicio de Actividades:
                      </Text>
                      31/12/1996
                    </Text>
                  </View>
                </View>
                <View style={styles.paragraph}>
                  <View style={styles.section}>
                    <Text>
                      <Text style={styles.sectionTitle}>Número de Orden:</Text>{' '}
                      {request.order_number}
                    </Text>
                    <>
                      <Text>
                        <Text style={styles.sectionTitle}>
                          Número de Delivery:
                        </Text>{' '}
                        {request.delivery_number}
                      </Text>
                      <Text>
                        <Text style={styles.sectionTitle}>Descripción:</Text>{' '}
                        Cosmética
                      </Text>
                    </>
                  </View>
                </View>

                <View style={styles.paragraph}>
                  <View style={styles.section}>
                    <Text>
                      <Text style={styles.sectionTitle}>Cliente:</Text>{' '}
                      {request.ship_to_name}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>Teléfono:</Text>{' '}
                      {`${request.mobile_phone || ''} / ${
                        request.home_phone || ''
                      }`}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>Email:</Text>{' '}
                      {request.ship_to_email}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>
                        Dirección de Entrega:{' '}
                      </Text>{' '}
                      {request.ship_to_street + ', '}{' '}
                      {request.ship_to_city + ', '}
                      {request.ship_to_region + ', '}{' '}
                      {request.ship_to_country + ', '}{' '}
                      {config.countryVersion.noPostalCode
                        ? ''
                        : request.ship_to_postal_code.postalCode}
                    </Text>

                    {request.order_total > 20932 ? (
                      <Text>
                        <Text style={styles.sectionTitle}>DNI/CUIT: </Text>{' '}
                      </Text>
                    ) : (
                      <Text> </Text>
                    )}
                  </View>
                </View>

                <View style={styles.paragraph}>
                  <View style={styles.section}>
                    <Text>
                      <Text style={styles.sectionTitle}>Transportista: </Text>{' '}
                      {config.countryVersion.noPostalCode
                        ? ''
                        : request.ship_to_postal_code.provider.name}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>Denominación: </Text>
                      {config.countryVersion.noPostalCode
                        ? ''
                        : request.ship_to_postal_code.provider.denomination}
                    </Text>
                    <Text>
                      <Text style={styles.sectionTitle}>CUIT:</Text>{' '}
                      {config.countryVersion.noPostalCode
                        ? ''
                        : request.ship_to_postal_code.provider.cuit}
                    </Text>
                  </View>
                </View>

                <View style={styles.paragraph}>
                  <Text style={styles.sectionTitleSignature}>Firma</Text>
                  <Text style={styles.sectionTitleSignature}>Aclaración</Text>
                  <Text style={styles.sectionTitleSignature}>DNI</Text>
                </View>
              </>
            )}

            <View style={styles.paragraphBarcode}>
              <Image style={styles.barcode} src={barcode} />
            </View>
          </Page>
        );
      })}
    </Document>
  );
}

function WeCarryPDF(props) {
  const size = [config.sizeOfPdf.width, config.sizeOfPdf.high];
  let canvas = document.createElement('canvas');

  return (
    <Document>
      {props.requests.map((request, index) => {
        JsBarcode(canvas, request.order_number);
        const barcode = canvas.toDataURL();

        let pageSize;
        if (
          request.provider.labelsWidth > 0 &&
          request.provider.labelsHeight > 0
        ) {
          // from mm to px
          let widthPixels = request.provider.labelsWidth * 3.779527559;
          let heightPixels = request.provider.labelsHeight * 3.779527559;
          pageSize = [widthPixels, heightPixels];
        } else {
          pageSize = size;
        }
        return (
          <Page
            key={request.id}
            size={pageSize}
            style={styles_weCarry.page}
            orientation={'landscape'}>
            <View style={styles_weCarry.container}>
              <Image
                style={styles_weCarry.title}
                src={process.env.PUBLIC_URL + '/img/ImagenWeCarry.jpeg'}
              />
              <Image style={styles_weCarry.barcode} src={barcode} />
            </View>
          </Page>
        );
      })}
    </Document>
  );
}

function AsociatedLabelsPDF(props) {
  const size = [config.sizeOfPdf.width, config.sizeOfPdf.high];
  const orderedData = props.requests.sort(a =>
    a.isHeadLabelOfAPI || a.isHeadReturnLabelOfAPI ? -1 : 1,
  );
  let dataByPages = [];
  for (let i = 0; i < orderedData.length; i += 7) {
    dataByPages.push(orderedData.slice(i, i + 7));
  }

  return (
    <Document>
      {dataByPages.map((page, idx) => {
        return (
          <Page size={size} style={styles_asociated.page} key={idx}>
            <View style={styles_asociated.container}>
              <View style={styles_asociated.title}>
                <Text>Pedidos agrupados</Text>
              </View>
            </View>
            <View style={styles_asociated.container}>
              {page.map(request => {
                return (
                  <View
                    key={request.id}
                    style={
                      request.isHeadLabelOfAPI || request.isHeadReturnLabelOfAPI
                        ? styles_asociated.paragraphHead
                        : styles_asociated.paragraph
                    }>
                    <View style={styles_asociated.section}>
                      <Text>
                        {request.isHeadLabelOfAPI ||
                        request.isHeadReturnLabelOfAPI ? (
                          <Text style={styles_asociated.isHead}>Cabecera</Text>
                        ) : (
                          <Text style={styles_asociated.isAsociated}>
                            Asociado
                          </Text>
                        )}
                      </Text>

                      <Text>
                        <Text style={styles.sectionTitle}># Pedido: </Text>
                        {request.order_number}
                      </Text>

                      {(request.isHeadLabelOfAPI ||
                        request.isHeadReturnLabelOfAPI) && (
                        <Text>
                          <Text style={styles.sectionTitle}>ID etiqueta:</Text>{' '}
                          {request.idLabelsOfAPI ||
                            request.isHeadReturnLabelOfAPI}
                        </Text>
                      )}
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles_asociated.container}>
              <Text style={styles_asociated.pagination}>Pagina: {idx + 1}</Text>
            </View>
          </Page>
        );
      })}
    </Document>
  );
}
