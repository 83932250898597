import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import './RequestsDetails.css';
import SelectDates from '../common/selects/SelectDates';
import RequestRows from './RequestRows';
import RequestPackages from './RequestPackages';
import Tooltip from '../common/Tooltip';
import ProductRequestValidation from '../requests/productRequestValidation';
import SelectPostalCodeRequest from '../common/selects/SelectPostalCodeRequest';
import SelectSentToProvider from '../common/selects/SelectSentToProvider';
import Modal from 'react-modal';
import SelectProductDescription from '../common/selects/SelectProductDescription';
import moment from 'moment';
import {
  downloadPDF,
  downloadReturnPDF,
  generateAsociatedLabel,
  generatePDF,
} from '../common/InvoicePDF';
import SelectProviderRequest from '../common/selects/SelectProviderRequest';
import SelectBoxSizeAsync from '../common/selects/SelectBoxSizeAsync';
import AlertMessage from '../common/AlertMessage';
import apiHelpers from '../../utils/apiHelpers';
import UploadPickupNote from './UploadPickupNote';
import ReturnDetails from './ReturnDetails';
import SelectPickUpState from '../common/selects/SelectPickUpState';
import ReactLoading from 'react-loading';
import LinkReturnLabels from './components/LinkReturnLabels';
import UnlinkReturnLabels from './components/UnlinkReturnLabels';
import UnlinkLabels from '../orderWeight/components/UnlinkLabels';
import LinkLabels from '../orderWeight/components/LinkLabels';

const config = require('../../config/config');

const initialStateRequest = {
  customer_name: '',
  deposit: '',
  order_number: '',
  sale_date: null,
  ship_to_name: '',
  ship_to_street: '',
  ship_to_street_2: '',
  ship_to_city: '',
  home_phone: '',
  mobile_phone: '',
  value: 0,
  extra_value: 0,
  tax: 0,
  extra_tax: 0,
  cancelled: '',
  ship_to_postal_code: {
    deletedAt: null,
    id: 0,
    postalCode: '',
    provider: {
      apiKey: '',
      cuit: 0,
      deletedAt: null,
      denomination: '',
      email: '',
      id: 0,
      name: '',
      printLabels: '',
      shippingRequests: '',
    },
  },
  ship_to_region: '',
  ship_to_email: '',
  sold_to_street: '',
  shipping_instructions: '',
  sold_to_id: '',
  sold_to_street_2: '',
  sequence_number: '',
  bill_number: '',
  order_total: 0,
  size: null,
  formatted_address: '',
  formatted_postal_code: '',
  weight: 0,
  productRequestRows: [],
  packages: [],
  delivery_number: '',
  last_picked_date: '',
  user_weight_id: 0,
  state_weight: '',
  observations: '',
  state_postal_code: '',
  sent_to_provider: 0,
  ship_to_district: '',
  ship_charge: 0,
  provider: {
    id: '',
    name: '',
    printLabels: '',
  },
  invoice: {
    number_invoice: '',
    prints: [],
  },
  returnInvoice: {
    number_invoice: '',
    prints: [],
  },
  logs: [],
  urlLabels: '',
  withdraw_pickup_note: '',
  pick_up_state: '',
};

export default class RequestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: false,
      requestDocument: null,
      isNew: props.match.params.id === 'new',
      requestId: props.match.params.id,
      modalIsOpen: false,
      cancelButton: false,
      user: null,
      request: initialStateRequest,
      boxOptions: [],
      message: null,
      loadingPrint: false,
      loadingPrintAsociated: false,
      validations: {
        weightError: false,
        postalCodeError: false,
        boxSizeError: false,
        productsRowsError: false,
        formattedAddressError: false,
        formattedPostalCodeError: false,
        pickupNoteError: false,
      },
      openValidationsModal: false,
      returnButton: false,
      returnEditedModal: false,
      returns: null,
      showReturns: false,
      loadingDetails: false,
      prevPickUpState: null,
      openModalPrevPickUpState: false,
      backwardDeliveryButton: false,
      modalInvoiceIsOpen: false,
      modalReturnInvoiceIsOpen: false,
      loadingLinkDelivery: false,
      linkLabelsModal: false,
      unlinkLabelsModal: false,
      linkReturnLabelsModal: false,
      unlinkReturnLabelsModal: false,
      linkedLabels: null,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.loadData();
  }

  async loadData() {
    this.setState({ loadingDetails: true });
    let requestResponse;
    let returnsResponse;
    let linkedLabels;

    let expiredStorageDate = false;
    if (!this.state.isNew) {
      requestResponse = await apiHelpers.getRequest(this.state.requestId);
      returnsResponse = await apiHelpers.getReturnsByRequestId(
        this.state.requestId,
      );

      if (requestResponse?.data?.idLabelsOfAPI) {
        let response = await apiHelpers.getRequests({
          where: { idLabelsOfAPI: requestResponse?.data?.idLabelsOfAPI },
        });
        linkedLabels = response.data?.filter(
          elem => elem.id != this.state.requestId,
        );
      }

      let currentRequest = requestResponse?.data;

      if (currentRequest.provider && currentRequest.pickup_received_date) {
        let pickupDueDate = moment(currentRequest.pickup_received_date)
          .startOf('day')
          .add(currentRequest.provider.maximum_days_of_storage, 'days');
        if (moment().startOf('day') >= pickupDueDate) {
          expiredStorageDate = true;
        }
      }
    }
    this.setState({ errorMessage: false });

    const userResponse = await apiHelpers.getCurrentUser();

    const boxResponse = await apiHelpers.getProducts({
      where: { category: 'box' },
    });

    if (!this.state.isNew) {
      this.setState(prevState => ({
        ...prevState,
        request: requestResponse.data,
        returns: returnsResponse.data,
        returnButton: returnsResponse.data.length > 0,
        cancelButton: requestResponse.data.cancelled,
        backwardDeliveryButton:
          (expiredStorageDate &&
            (requestResponse.data.pick_up_state === 'Entregado a PP' ||
              requestResponse.data.pick_up_state ===
                'Pendiente de retiro en PP')) ||
          requestResponse.data.returnInvoice,
        user: userResponse.data,
        boxOptions: boxResponse.data,
        loadingDetails: false,
        prevPickUpState: requestResponse.data.pick_up_state,
        linkedLabels: linkedLabels,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        user: userResponse.data,
        boxOptions: boxResponse.data,
        loadingDetails: false,
      }));
    }
  }

  componentWillUnmount() {
    this.deleteRequest.bind(this);
    this.submitRequest.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async handleOpenModalPrevPickUpState() {
    await this.validateRequest(this.state.request);

    if (!this.state.validations.pickupNoteError) {
      this.setState({ openModalPrevPickUpState: true });
    }
  }

  closeModalPrevPickUpState() {
    this.setState({ openModalPrevPickUpState: false });
  }

  openValidationsModal() {
    this.setState({
      openValidationsModal: true,
    });
  }

  closeValidationsModal() {
    this.setState({
      openValidationsModal: false,
    });
  }

  async cancelRequest() {
    await this.setState({ cancelButton: true });
  }

  async restoreRequest() {
    await this.setState({ cancelButton: false });
  }

  handleChange(event) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const request = this.state.request;
    request[name] = value;

    //Validate
    if (name === 'weight' || name === 'size') {
      const weight = request.weight;
      const estimatedWeight = formatter.getEstimatedWeight(
        this.state.request,
        this.state.boxOptions,
      );
      let differenceOfWeight = Math.abs(estimatedWeight - parseFloat(weight));
      let tolerance = config.weight.toleranceValue;
      let percentTolerance =
        (estimatedWeight * config.weight.numberOfPercent) / 100;

      if (parseFloat(weight) !== 0) {
        if (tolerance <= percentTolerance) {
          if (
            differenceOfWeight <= percentTolerance &&
            tolerance <= percentTolerance
          ) {
            request.state_weight = 'PV';
          } else {
            request.state_weight = 'PD';
          }
        } else {
          if (differenceOfWeight <= tolerance && percentTolerance < tolerance) {
            request.state_weight = 'PV';
          } else {
            request.state_weight = 'PD';
          }
        }
      } else {
        request.state_weight = 'SP';
      }
    }

    if (name === 'ship_to_postal_code' && value) {
      request.ship_to_postal_code.postalCode = value.postalCode;
      request.provider = value.provider;
    } else {
      if (name === 'ship_to_postal_code' && !value) {
        request.provider = null;
      }
    }

    if (
      (apiHelpers.isAdmin() ||
        apiHelpers.isSupervisor() ||
        apiHelpers.isManager()) &&
      request.provider &&
      request.provider.printLabels === 'PDF'
    ) {
      this.validateRequest(this.state.request);
    }
    this.setState({
      request: request,
    });
  }

  async validateRequest(request) {
    let validations = {
      weightError: false,
      postalCodeError: false,
      boxSizeError: false,
      productsRowsError: false,
      formattedAddressError: false,
      formattedPostalCodeError: false,
      pickupNoteError: false,
    };

    request.state_weight === 'PV'
      ? (validations.weightError = false)
      : (validations.weightError = true);

    request.size !== null
      ? (validations.boxSizeError = false)
      : (validations.boxSizeError = true);

    request.productRequestRows.length > 0
      ? (validations.productsRowsError = false)
      : (validations.productsRowsError = true);

    request.formatted_address
      ? (validations.formattedAddressError = false)
      : (validations.formattedAddressError = true);

    request.formatted_postal_code
      ? (validations.formattedPostalCodeError = false)
      : (validations.formattedPostalCodeError = true);

    request.pick_up_state === 'Entregado a cliente' &&
    this.state.requestDocument === null
      ? (validations.pickupNoteError = true)
      : (validations.pickupNoteError = false);

    this.setState({
      validations,
    });
  }

  async onDownloadOneInvoiceSubmit(id) {
    this.setState({ loadingPrint: true });
    try {
      const response = await apiHelpers.getRequestInvoice(id);

      if (this.state.request.provider.printLabels === 'PDF') {
        if (
          this.state.request.ship_to_postal_code &&
          this.state.request.ship_to_postal_code.postalCode
        ) {
          const request = response.data;
          await generatePDF(
            'Nota de entrega.pdf',
            [request],
            request.provider.name,
          );
          this.setState({ loadingPrint: false }, () => this.loadData());
        }
      } else {
        if (!this.state.request.urlLabels) {
          let requestWithURL;
          let response = await apiHelpers.getRequest(this.state.requestId);
          requestWithURL = response.data;
          let error = await downloadPDF(requestWithURL);
          if (error) {
            this.setState({
              errorMessage: 'No se pudo obtener la etiqueta del proveedor',
            });
          }
          this.setState({ loadingPrint: false }, () => this.loadData());
          return;
        } else {
          let error = await downloadPDF(this.state.request);
          if (error) {
            this.setState({
              errorMessage: 'No se pudo obtener la etiqueta del proveedor',
            });
          }
          this.setState({ loadingPrint: false }, () => this.loadData());
          return;
        }
      }
    } catch (e) {
      console.log('Error loading requests');
      this.setState({ loadingPrint: false }, () => this.loadData());
    }
  }

  async onDownloadOneReturnInvoiceSubmit(id) {
    this.setState({ loadingPrint: true });
    try {
      const response = await apiHelpers.getReturnRequestInvoice(id);

      if (this.state.request.provider.printLabels === 'PDF') {
        if (
          this.state.request.ship_to_postal_code &&
          this.state.request.ship_to_postal_code.postalCode
        ) {
          const request = response.data;
          await generatePDF(
            'Nota de entrega (Logística inversa).pdf',
            [request],
            request.provider.name,
          );
          this.setState({ loadingPrint: false }, () => this.loadData());
        }
      } else {
        if (!this.state.request.returnUrlLabels) {
          let requestWithURL;
          let response = await apiHelpers.getRequest(this.state.requestId);
          requestWithURL = response.data;
          let error = await downloadReturnPDF(requestWithURL);
          if (error) {
            this.setState({
              errorMessage: 'No se pudo obtener la etiqueta del proveedor',
            });
          }
          this.setState({ loadingPrint: false }, () => this.loadData());
          return;
        } else {
          let error = await downloadReturnPDF(this.state.request);
          if (error) {
            this.setState({
              errorMessage: 'No se pudo obtener la etiqueta del proveedor',
            });
          }
          this.setState({ loadingPrint: false }, () => this.loadData());
          return;
        }
      }
    } catch (e) {
      console.log('Error loading requests');
      this.setState({ loadingPrint: false });
    }
  }

  async onDownloadAsociatedLabels(idLabel) {
    this.setState({ loadingPrintAsociated: true });
    try {
      const { data: asociatedRequests } = await apiHelpers.getRequests({
        where: { idLabelsOfAPI: idLabel },
      });
      await generateAsociatedLabel('Pedidos asociados.pdf', asociatedRequests);
      this.setState({ loadingPrint: false }, () => this.loadData());
      this.setState({
        loadingPrintAsociated: false,
      });
    } catch (error) {
      console.log(`Error imprimiendo etiqueta con pedidos asociados: ${error}`);
      this.setState({ loadingPrintAsociated: false });
    }
  }

  async onDownloadAsociatedReturnLabels() {
    this.setState({ loadingPrintAsociated: true });
    try {
      const { data: asociatedRequests } = await apiHelpers.getRequests({
        where: { returnIdLabelsOfAPI: this.state.request.returnIdLabelsOfAPI },
      });
      await generateAsociatedLabel('Pedidos asociados.pdf', asociatedRequests);
      this.setState({ loadingPrint: false }, () => this.loadData());
      this.setState({
        loadingPrintAsociated: false,
      });
    } catch (error) {
      console.log(`Error imprimiendo etiqueta con pedidos asociados: ${error}`);
      this.setState({ loadingPrintAsociated: false });
    }
  }

  async deleteRequest() {
    await apiHelpers.deleteRequest(this.state.requestId);
    this.props.history.push('/request');
  }

  async submitRequest(event, redirect = true) {
    this.setState({ loadingPrint: true });
    event.preventDefault();
    let requestId = this.state.requestId;

    await this.validateRequest(this.state.request);

    if (this.state.validations.boxSizeError && this.state.size > 0) {
      let errorMessage =
        'Para ingresar el peso del pedido es necesario seleccionar una opcion de caja';
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.setState({
        errorMessage: errorMessage,
        loadingPrint: false,
      });
    } else {
      if (!this.state.validations.pickupNoteError) {
        try {
          //If requests already exists, update it. If not, create it
          const request = this.state.request;
          if (!this.state.cancelButton) {
            request.cancelled = false;
          } else {
            request.cancelled = true;
          }

          if (request.order_total) {
            request.order_total = request.order_total.replace(',', '.');
          }
          if (request.weight) {
            request.weight = request.weight.replace(',', '.');
          }
          if (request.ship_charge) {
            request.ship_charge = request.ship_charge.replace(',', '.');
          }
          let responseAfterSave;
          if (this.state.isNew) {
            await apiHelpers.postRequest(request);
          } else {
            let response = await apiHelpers.patchRequest(requestId, request);
            if (this.state.requestDocument) {
              try {
                await apiHelpers.postFile(
                  requestId,
                  this.state.requestDocument,
                );
              } catch (error) {
                console.log(error);
              }
            }
            if (response.status === 200) {
              let newState = this.state;
              newState.message = response.data.message
                ? response.data.message
                : 'Pedido enviado a proveedor por API';
              if (response.data && !response.data.message) {
                newState.label_data = response.data;
              }
              this.setState(newState);
            }
            if (response.status === 201) {
              responseAfterSave = response;
            }
          }

          if (redirect) {
            this.props.history.push({
              pathname: '/request',
              state: this.state.message,
            });
            return;
          }
          this.setState({ loadingPrint: false });
          if (responseAfterSave) {
            return responseAfterSave.data;
          }
        } catch (error) {
          console.log(error);
          const statusCode = error.response.status;
          let errorMessage;

          if (statusCode === 422) {
            errorMessage = 'Datos invalidos o incompletos';
          } else {
            errorMessage = await error.response.data.text();
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.setState({
            errorMessage: errorMessage,
            loadingPrint: false,
          });
        }
      }
    }
  }

  async createInvoice(event) {
    this.setState({ loadingPrint: true });
    await this.validateRequest(this.state.request);

    if (
      !this.state.validations.weightError &&
      !this.state.validations.postalCodeError &&
      !this.state.validations.boxSizeError &&
      !this.state.validations.productsRowsError &&
      !this.state.validations.formattedAddressError &&
      !this.state.validations.formattedPostalCodeError
    ) {
      try {
        //save request
        let submitedData = await this.submitRequest(event, false);
        //generate and print invoice
        const { data } = await apiHelpers.getRequestInvoice(submitedData.id);
        let newState = this.state;
        newState.request.invoice = data.invoice;
        await generatePDF(
          `Nota de entrega ${submitedData.order_number}.pdf`,
          [data],
          data.provider.name,
        );
        setTimeout(() => {
          this.setState(newState);
          this.setState(
            {
              loadingPrint: false,
              modalInvoiceIsOpen: false,
            },
            () => this.loadData(),
          );
        }, 2000);
      } catch (e) {
        console.log('Error update or printing request: ' + e.message);
      }
    } else {
      this.setState({
        loadingPrint: false,
        openValidationsModal: true,
      });
    }
  }

  async createReturnInvoice(event) {
    this.setState({ loadingPrint: true });
    await this.validateRequest(this.state.request);

    if (
      !this.state.validations.boxSizeError &&
      !this.state.validations.productsRowsError
    ) {
      try {
        //save request
        let submitedData = await apiHelpers.createReturnRequestDelivery(
          this.state.requestId,
        );
        //generate and print invoice
        const { data } = await apiHelpers.getReturnRequestInvoice(
          this.state.requestId,
        );
        let newState = this.state;
        newState.request.returnInvoice = data.invoice;
        await downloadReturnPDF(newState.request);
        setTimeout(() => {
          this.setState(newState);
          this.setState(
            {
              loadingPrint: false,
              modalReturnInvoiceIsOpen: false,
            },
            () => this.loadData(),
          );
        }, 2000);
      } catch (e) {
        console.log('Error update or printing request: ' + e.message);
      }
    } else {
      this.setState({
        loadingPrint: false,
        openValidationsModal: true,
      });
    }
  }

  closeAlert() {
    this.setState({ errorMessage: false });
  }

  openModalInvoice() {
    this.setState({ modalInvoiceIsOpen: true });
    let today = moment().format('DD/MM/YYYY');
    if (
      formatter.formatDate(this.state.request.invoice.prints[0].print_date) !==
        today &&
      !(
        apiHelpers.isAdmin() ||
        apiHelpers.isSupervisor() ||
        apiHelpers.isManager()
      ) &&
      this.state.request.ship_to_country !== 'CL'
    ) {
      this.setState({ disabledButtonPrintInvoice: true });
    }
  }

  closeModalInvoice() {
    this.setState({ modalInvoiceIsOpen: false });
  }

  openReturnModalInvoice() {
    this.setState({ modalReturnInvoiceIsOpen: true });
    let today = moment().format('DD/MM/YYYY');
    if (
      formatter.formatDate(this.state.request.invoice?.prints[0].print_date) !==
        today &&
      !(
        apiHelpers.isAdmin() ||
        apiHelpers.isSupervisor() ||
        apiHelpers.isManager()
      ) &&
      this.state.request.ship_to_country !== 'CL'
    ) {
      this.setState({ disabledButtonPrintInvoice: true });
    }
  }

  closeReturnModalInvoice() {
    this.setState({ modalReturnInvoiceIsOpen: false });
  }

  setUploadedFile(newFile) {
    if (newFile === null) {
      this.setState({
        request: {
          ...this.state.request,
          withdraw_pickup_note: '',
        },
      });
    }
    this.setState(
      {
        requestDocument: newFile,
      },
      () => this.validateRequest(this.state.request),
    );
  }

  render() {
    let title = 'Crear Pedido';
    let deleteButton = false;
    let cancelButton = false;
    let returnButton = false;
    let backwardDeliveryButton = false;
    let adminOptions;
    let balanceOptions;
    let pickupOptions;
    let callCenterOptions;
    let weightBalance;
    let modalInvoice;
    let modalReturnInvoice;
    let boxBalance;
    let alertProductQuantity;
    let buttonEnabled = false;
    const isAdmin = this.state.user
      ? this.state.user.role.userRole === 'ADMIN'
      : false;
    const isSupervisor = this.state.user
      ? this.state.user.role.userRole === 'SUPERVISOR'
      : false;
    const isInvoice = this.state.user
      ? this.state.user.role.userRole === 'INVOICE'
      : false;
    const isBalance = this.state.user
      ? this.state.user.role.userRole === 'BALANCE'
      : false;
    const isPickupPoint = this.state.user
      ? this.state.user.role.userRole === 'PICKUP'
      : false;
    const isManager = this.state.user
      ? this.state.user.role.userRole === 'MANAGER'
      : false;
    const isReturnUser = this.state.user
      ? this.state.user.role.userRole === 'RETURNS'
      : false;
    const isCallCenter = this.state.user
      ? this.state.user.role.userRole === 'CALLCENTER'
      : false;
    let number_invoice;

    if (this.state.request.invoice !== null) {
      number_invoice = '' + this.state.request.invoice.number_invoice;
      while (number_invoice.length < 8) {
        number_invoice = '0' + number_invoice;
      }
    }

    if (!this.state.isNew) {
      title = 'Pedido ' + this.state.request.order_number;
    }

    if (this.state.request.productRequestRows.length > 0) {
      this.state.request.productRequestRows.map(productRequestRow => {
        if (productRequestRow.material && productRequestRow.quantity < 1) {
          alertProductQuantity = (
            <div className="alert alert-warning ml-5 mr-5 text-center">
              Los cantidad minima de producto es 1{' '}
            </div>
          );
          buttonEnabled = true;
        }
      });
    }

    if (
      this.state.modalInvoiceIsOpen &&
      this.state.request.invoice.prints.length > 0
    ) {
      modalInvoice = (
        <Modal
          isOpen={this.state.modalInvoiceIsOpen}
          onRequestClose={this.closeModalInvoice.bind(this)}
          className="modal-dialog-email fadeInDown modal-dialog modal-lg"
          contentLabel="Nota de entrega">
          <div className="modal-content text-center">
            <div className="col-md-12 modal-header">
              <div className="col-md-11">
                <span>
                  <h2 className="fas fa-print fa-fw ml-5 text-primary"></h2>
                  <h3 className="ml-5">Nota de entrega</h3>
                </span>
              </div>
              <div className="col-md-1">
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModalInvoice.bind(this)}>
                  &times;
                </button>
              </div>
            </div>
            <div className="modal-body">
              <form method="post" action="" encType="multipart/form-data">
                {(this.state.request.isHeadLabelOfAPI ||
                  this.state.request.isHeadReturnLabelOfAPI) && (
                  <>
                    <div className="row p-2">
                      <div className="col-sm-7">
                        <h5 className="font-italic font-weight-bold text-info">
                          Esta etiqueta es cabecera de más pedidos
                        </h5>
                        <a
                          role="button"
                          className="btn btn-link"
                          data-toggle="collapse"
                          href="#linkedLabels">
                          Ver pedidos asociados
                        </a>
                      </div>
                      <div className="col-sm-5">
                        <button
                          className="btn btn-info btn-block"
                          onClick={() => {
                            this.onDownloadAsociatedLabels(
                              this.state.request.idLabelsOfAPI,
                            );
                          }}
                          disabled={this.state.loadingPrintAsociated}>
                          {this.state.loadingPrintAsociated
                            ? 'Imprimiendo etiquetas...'
                            : 'Imprimir etiquetas asociadas'}{' '}
                          <i className="fas fa-file-pdf"></i>
                        </button>
                      </div>
                    </div>

                    <div class="collapse" id="linkedLabels">
                      <div className="row p-2 ">
                        <ul className="list-group w-50 d-flex align-items-center">
                          {this.state.linkedLabels?.map(label => {
                            return (
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center p-2"
                                key={label.id}>
                                <p className="text-primary m-3">
                                  {`${label.order_number} (${label.delivery_number})`}
                                </p>
                                <a
                                  role="button"
                                  className="btn btn-outline-primary"
                                  href={`/request/${label.id}`}>
                                  Ir a pedido{' '}
                                  <i class="fas fa-external-link-alt ml-2"></i>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="dropdown-divider py-2"></div>
                  </>
                )}
                {this.state.request.headLabelRequestId > 0 && (
                  <>
                    <div className="row py-3 ">
                      <div className="col-4">
                        <h5 className="text-info">Etiqueta asociada</h5>
                      </div>
                      <div className="btn-group col-8" role="group">
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          disabled={this.state.request.pickup_received_date}
                          onClick={() =>
                            this.setState({
                              modalInvoiceIsOpen: false,
                              unlinkLabelsModal: true,
                            })
                          }>
                          Desasignar <i className="fas fa-window-close"></i>
                        </button>
                        <Tooltip
                          id={this.state.request.id + 'edit'}
                          tooltipText="Reasignación de etiquetas"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-warning"
                          disabled={this.state.request.pickup_received_date}
                          onClick={() =>
                            this.setState({
                              modalInvoiceIsOpen: false,
                              linkLabelsModal: true,
                            })
                          }>
                          Reasignar <i className="fas fa-sitemap"></i>
                        </button>
                        <Tooltip
                          id={this.state.request.id + 'edit'}
                          tooltipText="Reasignación de etiquetas"
                        />
                        <a
                          href={`/request/${this.state.request.headLabelRequestId}`}
                          className="btn btn-outline-info">
                          Ver cabecera{' '}
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                    {this.state.request.pickup_received_date && (
                      <h5>
                        <span className="badge badge-info">
                          Ya no es posible modificar la asociación de etiqueta
                        </span>
                      </h5>
                    )}
                    <div className="dropdown-divider p-2"></div>
                  </>
                )}

                <div className="row p-2">
                  <div className="col-4">
                    <span className="align-content-center">
                      {' '}
                      Nota de entrega Nº:{' '}
                      <b>
                        {this.state.request.provider &&
                        this.state.request.provider.shippingRequests === 'CSV'
                          ? number_invoice
                          : this.state.request.idLabelsOfAPI}
                      </b>
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="ml-4 align-content-center">
                      {' '}
                      Impresiones:{' '}
                      <b>{this.state.request.invoice.prints.length}</b>
                    </span>
                  </div>
                  <div className="col-5">
                    {this.state.loadingPrint ? (
                      <button
                        type="button"
                        className="pull-right btn btn-warning button-submit"
                        disabled>
                        Imprimiendo...{' '}
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="pull-right btn btn-warning button-submit"
                        onClick={() => {
                          this.onDownloadOneInvoiceSubmit(
                            this.state.request.id,
                          );
                        }}
                        disabled={this.state.disabledButtonPrintInvoice}>
                        Reimprimir nota de entrega{' '}
                        <i className="fas fa-file-pdf"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className="table-responsive overflow-auto table-bordered"
                  style={{ maxHeight: '300px' }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Usuario impresor</th>
                        <th>Fecha de impresión</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.request.invoice.prints.map(print => {
                        return (
                          <tr key={print.id}>
                            <td>{print.user}</td>
                            <td>{formatter.formatDate(print.print_date)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      );
    }

    if (this.state.modalReturnInvoiceIsOpen) {
      modalReturnInvoice = (
        <Modal
          isOpen={this.state.modalReturnInvoiceIsOpen}
          onRequestClose={
            !(this.state.loadingPrint || this.state.loadingLinkDelivery)
              ? this.closeReturnModalInvoice.bind(this)
              : () => {}
          }
          className="fadeInDown modal-dialog modal-lg"
          contentLabel="Nota de entrega (Logística inversa)">
          <div className="modal-content text-center">
            <div className="col-md-12 modal-header">
              <div className="col-md-11">
                <span>
                  <h2 className="fas fa-print fa-fw ml-5 text-primary"></h2>
                  <h3 className="ml-5">Nota de entrega (Logística inversa)</h3>
                </span>
              </div>
              <div className="col-md-1">
                {!(
                  this.state.loadingPrint || this.state.loadingLinkDelivery
                ) && (
                  <button
                    type="button"
                    className="close"
                    onClick={this.closeReturnModalInvoice.bind(this)}>
                    &times;
                  </button>
                )}
              </div>
            </div>
            {!this.state.request.returnIdLabelsOfAPI ? (
              <div className="modal-body d-flex flex-column">
                <button
                  type="button"
                  className="btn btn-outline-primary my-3 w-100"
                  disabled={
                    this.state.loadingPrint || this.state.loadingLinkDelivery
                  }
                  onClick={event => {
                    this.createReturnInvoice(event);
                  }}>
                  {this.state.loadingPrint ? 'Generando' : 'Generar'} nueva nota
                  de logística inversa{' '}
                  {this.state.loadingPrint ? (
                    <span
                      className="spinner-border spinner-border-sm ml-2 p-2"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    <i className="fas fa-file-pdf"></i>
                  )}
                </button>

                <div class="dropdown-divider w-100 my-3"></div>

                <button
                  className="btn btn-info"
                  disabled={
                    this.state.loadingPrint || this.state.loadingLinkDelivery
                  }
                  onClick={() =>
                    this.setState({
                      linkReturnLabelsModal: true,
                      modalReturnInvoiceIsOpen: false,
                    })
                  }>
                  Asociar etiquetas
                </button>

                {this.state.errorLinkingLabels && (
                  <AlertMessage
                    type="error"
                    title={this.state.errorLinkingLabels}
                  />
                )}
              </div>
            ) : (
              <div className="modal-body">
                {/* <form method="post" action="" encType="multipart/form-data"> */}
                {this.state.request.isHeadReturnLabelOfAPI && (
                  <>
                    <div className="row p-2">
                      <div className="col-sm-7">
                        <h5 className="font-italic font-weight-bold text-info">
                          Esta etiqueta es cabecera de más pedidos:{' '}
                        </h5>
                      </div>
                      <div className="col-sm-5">
                        <button
                          className="btn btn-info btn-block"
                          onClick={() => {
                            this.onDownloadAsociatedReturnLabels(
                              this.state.request.returnidLabelsOfAPI,
                            );
                          }}
                          disabled={this.state.loadingPrintAsociated}>
                          {this.state.loadingPrintAsociated
                            ? 'Imprimiendo etiquetas...'
                            : 'Imprimir etiquetas asociadas'}{' '}
                          <i className="fas fa-file-pdf"></i>
                        </button>
                      </div>
                    </div>
                    <div className="dropdown-divider py-2"></div>
                  </>
                )}
                {this.state.request.headReturnLabelRequestId && (
                  <>
                    <div className="row py-3 ">
                      <div className="col-4">
                        <h5 className="text-info">Etiqueta asociada</h5>
                      </div>
                      <div className="btn-group col-8" role="group">
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          disabled={
                            this.state.request.pick_up_state ===
                            'Devuelto a depósito'
                          }
                          onClick={() =>
                            this.setState({
                              modalReturnInvoiceIsOpen: false,
                              unlinkReturnLabelsModal: true,
                            })
                          }>
                          Desasignar <i className="fas fa-window-close"></i>
                        </button>
                        <Tooltip
                          id={this.state.request.id + 'edit'}
                          tooltipText="Reasignación de etiquetas"
                        />
                        <button
                          type="button"
                          className="btn btn-outline-warning"
                          disabled={
                            this.state.request.pick_up_state ===
                            'Devuelto a depósito'
                          }
                          onClick={() =>
                            this.setState({
                              modalReturnInvoiceIsOpen: false,
                              linkReturnLabelsModal: true,
                            })
                          }>
                          Reasignar <i className="fas fa-sitemap"></i>
                        </button>
                        <Tooltip
                          id={this.state.request.id + 'edit'}
                          tooltipText="Reasignación de etiquetas"
                        />
                        <a
                          href={`/request/${this.state.request.headReturnLabelRequestId}`}
                          className="btn btn-outline-info">
                          Ver cabecera{' '}
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                    </div>
                    {this.state.request.pick_up_state ===
                      'Devuelto a depósito' && (
                      <h5>
                        <span className="badge badge-info">
                          Ya no es posible modificar la asociación de etiqueta
                        </span>
                      </h5>
                    )}

                    <div className="dropdown-divider p-2"></div>
                  </>
                )}

                <div className="row p-2">
                  <div className="col-4">
                    <span className="align-content-center">
                      {' '}
                      Nota de entrega Nº:{' '}
                      <b>
                        {this.state.request.provider &&
                        this.state.request.provider.shippingRequests === 'CSV'
                          ? number_invoice
                          : this.state.request.returnIdLabelsOfAPI}
                      </b>
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="ml-4 align-content-center">
                      {' '}
                      Impresiones:{' '}
                      <b>
                        {this.state.request.returnInvoice?.prints.length ?? 0}
                      </b>
                    </span>
                  </div>
                  <div className="col-5">
                    {this.state.loadingPrint ? (
                      <button
                        type="button"
                        className="pull-right btn btn-warning button-submit"
                        disabled>
                        Loading...{' '}
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="pull-right btn btn-warning button-submit w-100"
                        onClick={() => {
                          this.onDownloadOneReturnInvoiceSubmit(
                            this.state.request.id,
                          );
                        }}
                        disabled={this.state.disabledButtonPrintInvoice}>
                        Reimprimir nota logística inversa{' '}
                        <i className="fas fa-file-pdf"></i>
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className="table-responsive overflow-auto"
                  style={{ maxHeight: '300px' }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Usuario impresor</th>
                        <th>Fecha de impresión</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.request.returnInvoice?.prints.map(print => {
                        return (
                          <tr key={print.id}>
                            <td>{print.user}</td>
                            <td>{formatter.formatDate(print.print_date)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* </form> */}
              </div>
            )}
          </div>
        </Modal>
      );
    }

    if (isInvoice) {
      weightBalance = (
        <div className="form-group col-md-6">
          <label htmlFor="weight"> Peso total </label>
          <input
            onChange={this.handleChange.bind(this)}
            name="weight"
            id="weight"
            className="form-control"
            placeholder="Peso total"
            value={this.state.request.weight}
            disabled
          />
        </div>
      );

      boxBalance = (
        <div className=" form-group">
          <label htmlFor="size"> Tamaño de caja </label>
          <SelectBoxSizeAsync
            name="size"
            value={this.state.request.size}
            placeholder=" Tamaño de caja "
            onChange={this.handleChange.bind(this)}
            isDisabled
          />
        </div>
      );
    } else {
      if (isBalance) {
        if (!this.state.request.cancelled) {
          weightBalance = (
            <div className="form-group col-md-6">
              <label htmlFor="weight"> Peso total </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="weight"
                id="weight"
                className="form-control"
                placeholder="Peso total"
                value={this.state.request.weight}
              />
            </div>
          );
          boxBalance = (
            <div className=" form-group">
              <label htmlFor="size"> Tamaño de caja </label>
              <SelectBoxSizeAsync
                name="size"
                value={this.state.request.size}
                placeholder=" Tamaño de caja "
                onChange={this.handleChange.bind(this)}
              />
            </div>
          );
        } else {
          weightBalance = (
            <div className="form-group col-md-6">
              <label htmlFor="weight"> Peso total </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="weight"
                id="weight"
                className="form-control"
                placeholder="Peso total"
                value={this.state.request.weight}
                disabled
              />
            </div>
          );
          boxBalance = (
            <div className=" form-group">
              <label htmlFor="size"> Tamaño de caja </label>
              <SelectBoxSizeAsync
                name="size"
                value={this.state.request.size}
                placeholder=" Tamaño de caja "
                onChange={this.handleChange.bind(this)}
                disabled
              />
            </div>
          );
        }
      }
    }
    if (isCallCenter) {
      callCenterOptions = (
        <div className="row col-sm-12">
          <div className="col-sm-12 col-lg-4 creation-info p-3">
            <h5 className="h5 text-center">Datos del cliente</h5>

            <div className="form-group">
              <label htmlFor="ship_to_name">Nombre del cliente</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_name"
                className="form-control"
                placeholder="A quien se envia"
                value={this.state.request.ship_to_name}
                required
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="home_phone"> Teléfono fijo </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="home_phone"
                className="form-control"
                placeholder=" Telefono fijo "
                value={this.state.request.home_phone}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="mobile_phone"> Teléfono móvil </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="mobile_phone"
                className="form-control"
                placeholder=" Telefono móvil "
                value={this.state.request.mobile_phone}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_email"> Email </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_email"
                className="form-control"
                placeholder=" Email "
                value={this.state.request.ship_to_email}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street"> Calle 1</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street"
                className="form-control"
                placeholder=" Calle 1"
                value={this.state.request.ship_to_street}
                disabled
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street_2"> Calle 2 </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street_2"
                className="form-control"
                placeholder=" Calle 2"
                value={this.state.request.ship_to_street_2}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="ship_to_district"> Distrito </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_district"
                className="form-control"
                placeholder=" Distrito"
                value={this.state.request.ship_to_district}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="formatted_address"> Direccion normalizada</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="formatted_address"
                className="form-control"
                placeholder=" Direccion normalizada "
                value={this.state.request.formatted_address}
                disabled
              />
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="ship_to_postal_code"> Código postal </label>
                <SelectPostalCodeRequest
                  onChange={this.handleChange.bind(this)}
                  name="ship_to_postal_code"
                  placeholder=" Código postal"
                  value={
                    this.state.request.ship_to_postal_code
                      ? this.state.request.ship_to_postal_code.id
                      : null
                  }
                  isDisabled
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="formatted_postal_code"> CP normalizado </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="formatted_postal_code"
                  className="form-control"
                  placeholder=" Código postal normalizado"
                  value={this.state.request.formatted_postal_code}
                  disabled
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_city"> Ciudad </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_city"
                className="form-control"
                placeholder=" Ciudad "
                value={this.state.request.ship_to_city}
                required
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_region"> Región </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_region"
                className="form-control"
                placeholder=" Región "
                value={this.state.request.ship_to_region}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_country"> País </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_country"
                className="form-control"
                placeholder=" País "
                value={this.state.request.ship_to_country}
                required
                disabled
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-8">
            <div className="row">
              <div className="col-md-6 creation-info p-3">
                <h5 className="h5 text-center">Datos del pedido</h5>

                <div className="form-group">
                  <label htmlFor="order_number"> Número de pedido </label>
                  <input
                    name="order_number"
                    value={this.state.request.order_number}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    required
                    disabled
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="sale_date"> Fecha de venta </label>
                  <input
                    className="form-control"
                    name="sale_date"
                    value={formatter.formatDate(this.state.request.sale_date)}
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="print_date"> Fecha de impresión </label>
                  <input
                    className="form-control"
                    name="print_date"
                    value={formatter.formatDate(this.state.request.print_date)}
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sold_to_id">
                    {' '}
                    Última fecha de expedición{' '}
                  </label>
                  <input
                    name="last_picked_date"
                    value={
                      this.state.request.last_picked_date
                        ? formatter.formatDate(
                            this.state.request.last_picked_date,
                          )
                        : 'Sin expedición'
                    }
                    className="form-control"
                    placeholder="Sin expedición"
                    disabled
                  />
                </div>

                <div className="row">
                  <div
                    className={`form-group col-md-${
                      !this.state.request.provider?.isPickupPoint ? '6' : '12'
                    }`}>
                    <label htmlFor="delivery_number"> Entrega </label>
                    <input
                      name="delivery_number"
                      value={this.state.request.delivery_number}
                      className="form-control"
                      placeholder="Entrega"
                      onChange={this.handleChange.bind(this)}
                      required
                      disabled
                    />
                  </div>

                  {!this.state.request.provider?.isPickupPoint && (
                    <div className="form-group col-md-6">
                      <label htmlFor="sent_to_provider">
                        {' '}
                        Enviado a proveedor{' '}
                      </label>
                      <SelectSentToProvider
                        name="sent_to_provider"
                        value={this.state.request.sent_to_provider}
                        placeholder=" Enviado a proveedor "
                        onChange={this.handleChange.bind(this)}
                        isDisabled
                      />
                    </div>
                  )}
                </div>

                <div className="row">{weightBalance}</div>

                {boxBalance}
                <div className="form-group ">
                  <label htmlFor="observations"> Observaciones </label>
                  <textarea
                    onChange={this.handleChange.bind(this)}
                    name="observations"
                    className="form-control width-observations"
                    placeholder=" Observaciones "
                    value={this.state.request.observations}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 creation-info p-3">
                <div>
                  <h5 className="h5 text-center">Validaciones</h5>
                  {!this.state.isNew && (
                    <ProductRequestValidation
                      render={(
                        addressValidation,
                        postalCodeValidation,
                        productsWeightValidation,
                        weightValidation,
                        sentToProviderValidation,
                        pickingValidation,
                      ) => {
                        return (
                          <div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-home ml-3 ' +
                                  addressValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'address'}>
                                <Tooltip
                                  id={this.state.request.id + 'address'}
                                />
                                {addressValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-mail-bulk ml-3 ' +
                                  postalCodeValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'postalCode'}>
                                <Tooltip
                                  id={this.state.request.id + 'postalCode'}
                                />
                                {postalCodeValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-box ml-3 ' +
                                  productsWeightValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'productsWeight'
                                }>
                                <Tooltip
                                  id={this.state.request.id + 'productsWeight'}
                                />
                                {
                                  productsWeightValidation(this.state.request)
                                    .text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-weight ml-3 ' +
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'weight'}>
                                <Tooltip
                                  id={this.state.request.id + 'weight'}
                                />
                                {
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fas fa-cubes ml-3 ' +
                                  pickingValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'picking'}>
                                <Tooltip
                                  id={this.state.request.id + 'picking'}
                                />
                                {pickingValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-envelope ml-3 ' +
                                  sentToProviderValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'sent_to_provider'
                                }>
                                <Tooltip
                                  id={
                                    this.state.request.id + 'sent_to_provider'
                                  }
                                />
                                {sentToProviderValidation(this.state.request)
                                  .text +
                                  (this.state.request.provider
                                    ? this.state.request.provider.name
                                    : 'Sin proveedor')}
                              </span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>

                <h5 className="h5 text-center">Datos del pago</h5>
                <div className="form-group">
                  <label htmlFor="order_total"> Precio </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="order_total"
                    className="form-control"
                    placeholder=" Precio "
                    value={this.state.request.order_total}
                    data-for={this.state.request.id + 'order_total'}
                    disabled
                  />
                  <Tooltip
                    id={this.state.request.id + 'order_total'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="ship_charge"> Costo de envío </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="ship_charge"
                    className="form-control"
                    placeholder=" Costo de envío "
                    value={this.state.request.ship_charge}
                    data-for={this.state.request.id + 'ship_charge'}
                    disabled
                  />
                  <Tooltip
                    id={this.state.request.id + 'ship_charge'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sold_to_id"> ID de facturación </label>
                  <input
                    name="sold_to_id"
                    value={this.state.request.sold_to_id}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    disabled
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="bill_number"> Número de factura </label>
                  <input
                    name="bill_number"
                    value={this.state.request.bill_number}
                    className="form-control"
                    placeholder="Número de factura "
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                {this.state.request?.provider?.isPickupPoint &&
                  this.state.request.pick_up_state && (
                    <>
                      <div className="dropdown-divider my-4"></div>
                      <div className="form-group ">
                        <label htmlFor="observations">
                          {' '}
                          Estado del Pick-Up{' '}
                        </label>
                        <SelectPickUpState
                          name="pick_up_state"
                          placeholder="Estado del Pick-Up"
                          hidePrevStates={this.state.prevPickUpState}
                          value={this.state.request.pick_up_state}
                          onChange={this.handleChange.bind(this)}
                          disabled={
                            !!this.state.request?.withdraw_pickup_note ||
                            (this.state.request.pick_up_state ===
                              'Entregado a cliente' &&
                              this.state.requestDocument !== null)
                          }
                        />
                      </div>
                      {this.state.request?.pick_up_state === 'Entregado a PP' &&
                        this.state.request?.pickup_received_date && (
                          <div className=" form-group">
                            <label htmlFor="sale_date">
                              {' '}
                              Fecha de recepción en PP
                            </label>
                            <input
                              className="form-control"
                              name="pickup_received_date"
                              value={formatter.formatDate(
                                this.state.request.pickup_received_date,
                              )}
                              onChange={this.handleChange.bind(this)}
                              disabled
                            />
                          </div>
                        )}
                    </>
                  )}
                {(!this.state.request?.pick_up_state ||
                  this.state.request?.pick_up_state ===
                    'Entregado a cliente') && (
                  <div className="form-group">
                    <label htmlFor="pick_up"> Remito de entrega </label>
                    <UploadPickupNote
                      requestId={this.state.request.id}
                      onChange={this.handleChange.bind(this)}
                      setUploadedFile={this.setUploadedFile.bind(this)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 creation-info p-3">
                {this.state.request.productRequestRows.map(productRequest => {
                  return (
                    <div className="row mb-2">
                      <div className="form-group col-md-9">
                        <SelectProductDescription
                          name="weight"
                          value={productRequest.material}
                          onChange={this.handleChange.bind(this)}
                          isDisabled={true}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <input
                          name="weight"
                          value={productRequest.quantity}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (isAdmin || isSupervisor || isManager) {
      if (!this.state.isNew) {
        deleteButton = (
          <button
            data-tip
            className="btn btn-danger pull-right some-top-air w-100"
            data-for={this.state.request.id + 'delete'}
            type="button"
            onClick={this.openModal.bind(this)}>
            Eliminar
            <span
              className=" ml-1 fa fa-trash-alt"
              data-tip
              data-for={this.state.request.id + 'delete'}>
              <Tooltip
                id={this.state.request.id + 'delete'}
                tooltipText="Elimina el pedido del sistema. No puede deshacerse."
              />
            </span>
          </button>
        );
      }

      if (!this.state.cancelButton) {
        cancelButton = (
          <button
            data-tip
            className="btn btn-warning pull-right some-top-air w-100"
            data-for={this.state.request.id + 'cancel'}
            type="button"
            onClick={this.cancelRequest.bind(this)}>
            Cancelar
            <span
              className=" ml-1 fa fa-window-close "
              data-tip
              data-for={this.state.request.id + 'cancel'}>
              <Tooltip
                id={this.state.request.id + 'cancel'}
                tooltipText="Cancela el pedido, descartándolo para su envío a proveedores.
                    Puede deshacerse posteriormente si debe ser enviado."
              />
            </span>
          </button>
        );
      } else {
        cancelButton = (
          <button
            data-tip
            className="btn btn-secondary pull-right some-top-air w-100"
            data-for={this.state.request.id + 'restore'}
            type="button"
            onClick={this.restoreRequest.bind(this)}>
            Restaurar
            <span
              className=" ml-1 fa fa-undo-alt "
              data-tip
              data-for={this.state.request.id + 'restore'}>
              <Tooltip
                id={this.state.request.id + 'restore'}
                tooltipText="Restaurar pedido cancelado."
              />
            </span>
          </button>
        );
      }

      if (this.state.returnButton) {
        returnButton = (
          <button
            className="btn btn-outline-warning pull-right some-top-air w-100"
            type="button"
            onClick={() =>
              this.setState(prev => {
                return {
                  ...prev,
                  showReturns: !prev.showReturns,
                };
              })
            }>
            {this.state.showReturns ? 'Ocultar' : 'Ver'}{' '}
            {this.state.returns?.length < 2 ? 'devolución' : 'devoluciones'}
            <span
              className={`ml-1 ${
                !this.state.showReturns
                  ? 'fas fa-sort-amount-down'
                  : 'fas fa-level-up-alt'
              }`}
              data-tip
              data-for={this.state.request.id + 'return'}>
              <Tooltip
                id={this.state.request.id + 'return'}
                tooltipText="Ver devolución"
              />
            </span>
          </button>
        );
      }

      if (this.state.backwardDeliveryButton) {
        backwardDeliveryButton = (
          <button
            className="btn btn-primary pull-right some-top-air w-100"
            type="button"
            disabled={this.state.loadingPrint}
            onClick={this.openReturnModalInvoice.bind(this)}>
            {'Nota logística inversa '} <i className="fas fa-file-pdf"></i>
          </button>
        );
      }

      adminOptions = (
        <div className="row">
          <div className="col-md-4 creation-info p-3">
            <h5 className="h5 text-center">Datos del cliente</h5>

            <div className="form-group">
              <label htmlFor="ship_to_name">Nombre del cliente</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_name"
                className="form-control"
                placeholder="A quien se envia"
                value={this.state.request.ship_to_name}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="home_phone"> Teléfono fijo </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="home_phone"
                className="form-control"
                placeholder=" Telefono fijo "
                value={this.state.request.home_phone}
              />
            </div>

            <div className="form-group">
              <label htmlFor="mobile_phone"> Teléfono móvil </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="mobile_phone"
                className="form-control"
                placeholder=" Telefono móvil "
                value={this.state.request.mobile_phone}
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_email"> Email </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_email"
                className="form-control"
                placeholder=" Email "
                value={this.state.request.ship_to_email}
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street"> Calle 1</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street"
                className="form-control"
                placeholder=" Calle 1"
                value={this.state.request.ship_to_street}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street_2"> Calle 2 </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street_2"
                className="form-control"
                placeholder=" Calle 2"
                value={this.state.request.ship_to_street_2}
              />
            </div>
            <div className="form-group">
              <label htmlFor="ship_to_district"> Distrito </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_district"
                className="form-control"
                placeholder=" Distrito"
                value={this.state.request.ship_to_district}
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street_2">
                {' '}
                Direcciones adicionales{' '}
              </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="shipping_instructions"
                className="form-control"
                placeholder=" Direcciones adicionales"
                value={this.state.request.shipping_instructions}
              />
            </div>

            <div className="form-group">
              <label htmlFor="formatted_address"> Direccion normalizada</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="formatted_address"
                className="form-control"
                placeholder=" Direccion normalizada "
                value={this.state.request.formatted_address}
                disabled
              />
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="ship_to_postal_code"> Código postal </label>
                <SelectPostalCodeRequest
                  onChange={this.handleChange.bind(this)}
                  name="ship_to_postal_code"
                  placeholder=" Código postal"
                  value={
                    this.state.request.ship_to_postal_code
                      ? this.state.request.ship_to_postal_code.id
                      : null
                  }
                  isClearable={true}
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="formatted_postal_code"> CP normalizado </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="formatted_postal_code"
                  className="form-control"
                  placeholder=" Código postal normalizado"
                  value={this.state.request.formatted_postal_code}
                />
              </div>
            </div>

            {this.state.request.ship_to_postal_code && !this.state.isNew ? (
              <div className="form-group ">
                <label htmlFor="provider"> Proveedor </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="provider"
                  className="form-control"
                  placeholder=" Proveedor "
                  value={
                    this.state.request.ship_to_postal_code &&
                    this.state.request.ship_to_postal_code.provider
                      ? this.state.request.ship_to_postal_code.provider.name
                      : null
                  }
                  disabled
                />
              </div>
            ) : (
              <div className="form-group">
                <label htmlFor="provider "> Proveedor </label>
                <SelectProviderRequest
                  onChange={this.handleChange.bind(this)}
                  name="provider"
                  placeholder=" Proveedor "
                  value={
                    this.state.request.provider
                      ? this.state.request.provider.id
                      : null
                  }
                />
              </div>
            )}

            <div className="form-group">
              <label htmlFor="ship_to_city"> Ciudad </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_city"
                className="form-control"
                placeholder=" Ciudad "
                value={this.state.request.ship_to_city}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_region"> Región </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_region"
                className="form-control"
                placeholder=" Región "
                value={this.state.request.ship_to_region}
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_country"> País </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_country"
                className="form-control"
                placeholder=" País "
                value={this.state.request.ship_to_country}
                required
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 creation-info p-3">
                <h5 className="h5 text-center">Datos del pedido</h5>

                <div className="form-group">
                  <label htmlFor="order_number"> Número de pedido </label>
                  <input
                    name="order_number"
                    value={this.state.request.order_number}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    required
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="sale_date"> Fecha de venta </label>
                  <SelectDates
                    name="sale_date"
                    value={this.state.request.sale_date}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="print_date"> Fecha de impresión </label>
                  <SelectDates
                    name="print_date"
                    value={this.state.request.print_date}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sold_to_id">
                    {' '}
                    Última fecha de expedición{' '}
                  </label>
                  <input
                    name="last_picked_date"
                    value={
                      this.state.request.last_picked_date
                        ? formatter.formatDate(
                            this.state.request.last_picked_date,
                          )
                        : 'Sin expedición'
                    }
                    className="form-control"
                    placeholder="Sin expedición"
                    disabled
                  />
                </div>
                <div className="row">
                  <div
                    className={`form-group col-md-${
                      !this.state.request.provider?.isPickupPoint ? '6' : '12'
                    }`}>
                    <label htmlFor="delivery_number"> Entrega </label>
                    <input
                      name="delivery_number"
                      value={this.state.request.delivery_number}
                      className="form-control"
                      placeholder="Entrega"
                      onChange={this.handleChange.bind(this)}
                      required
                      disabled
                    />
                  </div>

                  {!this.state.request.provider?.isPickupPoint && (
                    <div className="form-group col-md-6">
                      <label htmlFor="sent_to_provider">
                        {' '}
                        Enviado a proveedor{' '}
                      </label>
                      <SelectSentToProvider
                        name="sent_to_provider"
                        value={this.state.request.sent_to_provider}
                        placeholder=" Enviado a proveedor "
                        onChange={this.handleChange.bind(this)}
                        isDisabled
                      />
                    </div>
                  )}
                </div>

                {!this.state.request.cancelled ? (
                  <div>
                    <div className="mt-2 form-group">
                      <label htmlFor="size"> Tamaño de caja </label>
                      <SelectBoxSizeAsync
                        name="size"
                        value={this.state.request.size}
                        placeholder=" Tamaño de caja "
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="weight"> Peso total </label>
                        <input
                          onChange={this.handleChange.bind(this)}
                          name="weight"
                          id="weight"
                          className="form-control"
                          placeholder="Peso total"
                          value={this.state.request.weight}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="estimated_weight"> Peso estimado</label>
                        <input
                          onChange={this.handleChange.bind(this)}
                          name="estimated_weight"
                          className="form-control"
                          placeholder=" Peso estimado "
                          value={formatter.getEstimatedWeight(
                            this.state.request,
                            this.state.boxOptions,
                          )}
                          disabled
                        />
                      </div>
                    </div>
                    <div>
                      <div className="col-md-12 form-group">
                        <RequestPackages
                          name="packages"
                          value={this.state.request.packages}
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className=" form-group">
                      <label htmlFor="size"> Tamaño de caja </label>
                      <SelectBoxSizeAsync
                        name="size"
                        value={this.state.request.size}
                        placeholder=" Tamaño de caja "
                        onChange={this.handleChange.bind(this)}
                        isDisabled
                      />
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="weight"> Peso total </label>
                        <input
                          onChange={this.handleChange.bind(this)}
                          name="weight"
                          id="weight"
                          className="form-control"
                          placeholder="Peso total"
                          value={this.state.request.weight}
                          disabled
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="estimated_weight"> Peso estimado</label>
                        <input
                          onChange={this.handleChange.bind(this)}
                          name="estimated_weight"
                          className="form-control"
                          placeholder=" Peso estimado "
                          value={formatter.getEstimatedWeight(
                            this.state.request,
                            this.state.boxOptions,
                          )}
                          disabled
                        />
                      </div>
                    </div>
                    <div>
                      <div className="col-md-12 form-group">
                        <RequestPackages
                          name="packages"
                          value={this.state.request.packages}
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="  form-group">
                  <label htmlFor="observations"> Observaciones </label>
                  <textarea
                    onChange={this.handleChange.bind(this)}
                    name="observations"
                    className="form-control width-observations"
                    placeholder=" "
                    value={this.state.request.observations}
                  />
                </div>
              </div>

              <div className="col-md-6 creation-info p-3">
                <div>
                  <h5 className="h5 text-center">Validaciones</h5>
                  {!this.state.isNew && (
                    <ProductRequestValidation
                      render={(
                        addressValidation,
                        postalCodeValidation,
                        productsWeightValidation,
                        weightValidation,
                        sentToProviderValidation,
                        pickingValidation,
                      ) => {
                        return (
                          <div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-home ml-3 ' +
                                  addressValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'address'}>
                                <Tooltip
                                  id={this.state.request.id + 'address'}
                                />
                                {addressValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-mail-bulk ml-3 ' +
                                  postalCodeValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'postalCode'}>
                                <Tooltip
                                  id={this.state.request.id + 'postalCode'}
                                />
                                {postalCodeValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-box ml-3 ' +
                                  productsWeightValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'productsWeight'
                                }>
                                <Tooltip
                                  id={this.state.request.id + 'productsWeight'}
                                />
                                {
                                  productsWeightValidation(this.state.request)
                                    .text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-weight ml-3 ' +
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'weight'}>
                                <Tooltip
                                  id={this.state.request.id + 'weight'}
                                />
                                {
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fas fa-cubes ml-3 ' +
                                  pickingValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'picking'}>
                                <Tooltip
                                  id={this.state.request.id + 'picking'}
                                />
                                {pickingValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-envelope ml-3 ' +
                                  sentToProviderValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'sent_to_provider'
                                }>
                                <Tooltip
                                  id={
                                    this.state.request.id + 'sent_to_provider'
                                  }
                                />
                                {sentToProviderValidation(this.state.request)
                                  .text +
                                  (this.state.request.provider
                                    ? this.state.request.provider.name
                                    : 'Sin proveedor')}
                              </span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>

                <h5 className="h5 text-center">Datos del pago</h5>
                <div className="form-group">
                  <label htmlFor="order_total"> Precio </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="order_total"
                    className="form-control"
                    placeholder=" Precio "
                    value={this.state.request.order_total}
                    data-for={this.state.request.id + 'order_total'}
                  />
                  <Tooltip
                    id={this.state.request.id + 'order_total'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="ship_charge"> Costo de envío </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="ship_charge"
                    className="form-control"
                    placeholder=" Costo de envío "
                    value={this.state.request.ship_charge}
                    data-for={this.state.request.id + 'ship_charge'}
                  />
                  <Tooltip
                    id={this.state.request.id + 'ship_charge'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sold_to_id"> ID de facturación </label>
                  <input
                    name="sold_to_id"
                    value={this.state.request.sold_to_id}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="bill_number"> Número de factura </label>
                  <input
                    name="bill_number"
                    value={this.state.request.bill_number}
                    className="form-control"
                    placeholder="Número de factura "
                    onChange={this.handleChange.bind(this)}
                  />
                </div>

                {this.state.request?.provider?.isPickupPoint &&
                  this.state.request.pick_up_state && (
                    <>
                      <div className="dropdown-divider my-4"></div>
                      <div className="form-group ">
                        <label htmlFor="observations">
                          {' '}
                          Estado del Pick-Up{' '}
                        </label>
                        <SelectPickUpState
                          name="pick_up_state"
                          placeholder="Estado del Pick-Up"
                          hidePrevStates={this.state.prevPickUpState}
                          value={this.state.request.pick_up_state}
                          onChange={this.handleChange.bind(this)}
                          disabled={
                            !!this.state.request?.withdraw_pickup_note ||
                            (this.state.request.pick_up_state ===
                              'Entregado a cliente' &&
                              this.state.requestDocument !== null) ||
                            this.state.request.pick_up_state ===
                              'Pendiente de envío a PP'
                          }
                        />
                      </div>
                    </>
                  )}
                {this.state.request?.pick_up_state === 'Entregado a PP' &&
                  this.state.request?.pickup_received_date && (
                    <div className=" form-group">
                      <label htmlFor="sale_date">
                        {' '}
                        Fecha de recepción en PP
                      </label>
                      <input
                        className="form-control"
                        name="pickup_received_date"
                        value={formatter.formatDate(
                          this.state.request.pickup_received_date,
                        )}
                        onChange={this.handleChange.bind(this)}
                        disabled
                      />
                    </div>
                  )}
                {this.state.request?.provider?.isPickupPoint &&
                  (!this.state.request?.pick_up_state ||
                    this.state.request?.pick_up_state ===
                      'Entregado a cliente') && (
                    <div className="form-group">
                      <label htmlFor="pick_up"> Remito de entrega </label>
                      <UploadPickupNote
                        requestId={this.state.request.id}
                        onChange={this.handleChange.bind(this)}
                        setUploadedFile={this.setUploadedFile.bind(this)}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 creation-info p-3">
                <RequestRows
                  name="productRequestRows"
                  value={this.state.request.productRequestRows}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (isPickupPoint) {
      if (this.state.backwardDeliveryButton) {
        backwardDeliveryButton = (
          <button
            className="btn btn-primary pull-right some-top-air w-100"
            type="button"
            disabled={this.state.loadingPrint}
            onClick={this.openReturnModalInvoice.bind(this)}>
            {'Nota logística inversa '} <i className="fas fa-file-pdf"></i>
          </button>
        );
      }
      pickupOptions = (
        <div className="row col-sm-12">
          <div className="col-sm-12 col-lg-4 creation-info p-3">
            <h5 className="h5 text-center">Datos del cliente</h5>

            <div className="form-group">
              <label htmlFor="ship_to_name">Nombre del cliente</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_name"
                className="form-control"
                placeholder="A quien se envia"
                value={this.state.request.ship_to_name}
                required
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="home_phone"> Teléfono fijo </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="home_phone"
                className="form-control"
                placeholder=" Telefono fijo "
                value={this.state.request.home_phone}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="mobile_phone"> Teléfono móvil </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="mobile_phone"
                className="form-control"
                placeholder=" Telefono móvil "
                value={this.state.request.mobile_phone}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_email"> Email </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_email"
                className="form-control"
                placeholder=" Email "
                value={this.state.request.ship_to_email}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street"> Calle 1</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street"
                className="form-control"
                placeholder=" Calle 1"
                value={this.state.request.ship_to_street}
                disabled
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street_2"> Calle 2 </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street_2"
                className="form-control"
                placeholder=" Calle 2"
                value={this.state.request.ship_to_street_2}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="ship_to_district"> Distrito </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_district"
                className="form-control"
                placeholder=" Distrito"
                value={this.state.request.ship_to_district}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="formatted_address"> Direccion normalizada</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="formatted_address"
                className="form-control"
                placeholder=" Direccion normalizada "
                value={this.state.request.formatted_address}
                disabled
              />
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="ship_to_postal_code"> Código postal </label>
                <SelectPostalCodeRequest
                  onChange={this.handleChange.bind(this)}
                  name="ship_to_postal_code"
                  placeholder=" Código postal"
                  value={
                    this.state.request.ship_to_postal_code
                      ? this.state.request.ship_to_postal_code.id
                      : null
                  }
                  isDisabled
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="formatted_postal_code"> CP normalizado </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="formatted_postal_code"
                  className="form-control"
                  placeholder=" Código postal normalizado"
                  value={this.state.request.formatted_postal_code}
                  disabled
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_city"> Ciudad </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_city"
                className="form-control"
                placeholder=" Ciudad "
                value={this.state.request.ship_to_city}
                required
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_region"> Región </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_region"
                className="form-control"
                placeholder=" Región "
                value={this.state.request.ship_to_region}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_country"> País </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_country"
                className="form-control"
                placeholder=" País "
                value={this.state.request.ship_to_country}
                required
                disabled
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-8">
            <div className="row">
              <div className="col-md-6 creation-info p-3">
                <h5 className="h5 text-center">Datos del pedido</h5>

                <div className="form-group">
                  <label htmlFor="order_number"> Número de pedido </label>
                  <input
                    name="order_number"
                    value={this.state.request.order_number}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    required
                    disabled
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="sale_date"> Fecha de venta </label>
                  <input
                    className="form-control"
                    name="sale_date"
                    value={formatter.formatDate(this.state.request.sale_date)}
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="print_date"> Fecha de impresión </label>
                  <input
                    className="form-control"
                    name="print_date"
                    value={formatter.formatDate(this.state.request.print_date)}
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sold_to_id">
                    {' '}
                    Última fecha de expedición{' '}
                  </label>
                  <input
                    name="last_picked_date"
                    value={
                      this.state.request.last_picked_date
                        ? formatter.formatDate(
                            this.state.request.last_picked_date,
                          )
                        : 'Sin expedición'
                    }
                    className="form-control"
                    placeholder="Sin expedición"
                    disabled
                  />
                </div>

                <div className="row">
                  <div
                    className={`form-group col-md-${
                      !this.state.request.provider?.isPickupPoint ? '6' : '12'
                    }`}>
                    <label htmlFor="delivery_number"> Entrega </label>
                    <input
                      name="delivery_number"
                      value={this.state.request.delivery_number}
                      className="form-control"
                      placeholder="Entrega"
                      onChange={this.handleChange.bind(this)}
                      required
                      disabled
                    />
                  </div>

                  {!this.state.request.provider.isPickupPoint && (
                    <div className="form-group col-md-6">
                      <label htmlFor="sent_to_provider">
                        {' '}
                        Enviado a proveedor{' '}
                      </label>
                      <SelectSentToProvider
                        name="sent_to_provider"
                        value={this.state.request.sent_to_provider}
                        placeholder=" Enviado a proveedor "
                        onChange={this.handleChange.bind(this)}
                        isDisabled
                      />
                    </div>
                  )}
                </div>

                <div className="row">{weightBalance}</div>

                {boxBalance}
                <div className="form-group ">
                  <label htmlFor="observations"> Observaciones </label>
                  <textarea
                    onChange={this.handleChange.bind(this)}
                    name="observations"
                    className="form-control width-observations"
                    placeholder=" Observaciones "
                    value={this.state.request.observations}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 creation-info p-3">
                <div>
                  <h5 className="h5 text-center">Validaciones</h5>
                  {!this.state.isNew && (
                    <ProductRequestValidation
                      render={(
                        addressValidation,
                        postalCodeValidation,
                        productsWeightValidation,
                        weightValidation,
                        sentToProviderValidation,
                        pickingValidation,
                      ) => {
                        return (
                          <div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-home ml-3 ' +
                                  addressValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'address'}>
                                <Tooltip
                                  id={this.state.request.id + 'address'}
                                />
                                {addressValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-mail-bulk ml-3 ' +
                                  postalCodeValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'postalCode'}>
                                <Tooltip
                                  id={this.state.request.id + 'postalCode'}
                                />
                                {postalCodeValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-box ml-3 ' +
                                  productsWeightValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'productsWeight'
                                }>
                                <Tooltip
                                  id={this.state.request.id + 'productsWeight'}
                                />
                                {
                                  productsWeightValidation(this.state.request)
                                    .text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-weight ml-3 ' +
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'weight'}>
                                <Tooltip
                                  id={this.state.request.id + 'weight'}
                                />
                                {
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fas fa-cubes ml-3 ' +
                                  pickingValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'picking'}>
                                <Tooltip
                                  id={this.state.request.id + 'picking'}
                                />
                                {pickingValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-envelope ml-3 ' +
                                  sentToProviderValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'sent_to_provider'
                                }>
                                <Tooltip
                                  id={
                                    this.state.request.id + 'sent_to_provider'
                                  }
                                />
                                {sentToProviderValidation(this.state.request)
                                  .text +
                                  (this.state.request.provider
                                    ? this.state.request.provider.name
                                    : 'Sin proveedor')}
                              </span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>

                <h5 className="h5 text-center">Datos del pago</h5>
                <div className="form-group">
                  <label htmlFor="order_total"> Precio </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="order_total"
                    className="form-control"
                    placeholder=" Precio "
                    value={this.state.request.order_total}
                    data-for={this.state.request.id + 'order_total'}
                    disabled
                  />
                  <Tooltip
                    id={this.state.request.id + 'order_total'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="ship_charge"> Costo de envío </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="ship_charge"
                    className="form-control"
                    placeholder=" Costo de envío "
                    value={this.state.request.ship_charge}
                    data-for={this.state.request.id + 'ship_charge'}
                    disabled
                  />
                  <Tooltip
                    id={this.state.request.id + 'ship_charge'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sold_to_id"> ID de facturación </label>
                  <input
                    name="sold_to_id"
                    value={this.state.request.sold_to_id}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    disabled
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="bill_number"> Número de factura </label>
                  <input
                    name="bill_number"
                    value={this.state.request.bill_number}
                    className="form-control"
                    placeholder="Número de factura "
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                {this.state.request?.provider?.isPickupPoint &&
                  this.state.request.pick_up_state && (
                    <>
                      <div className="dropdown-divider my-4"></div>
                      <div className="form-group ">
                        <label htmlFor="observations">
                          {' '}
                          Estado del Pick-Up{' '}
                        </label>
                        <SelectPickUpState
                          name="pick_up_state"
                          placeholder="Estado del Pick-Up"
                          hidePrevStates={this.state.prevPickUpState}
                          value={this.state.request.pick_up_state}
                          onChange={this.handleChange.bind(this)}
                          disabled={
                            !!this.state.request?.withdraw_pickup_note ||
                            (this.state.request.pick_up_state ===
                              'Entregado a cliente' &&
                              this.state.requestDocument !== null)
                          }
                        />
                      </div>
                      {this.state.request?.pick_up_state === 'Entregado a PP' &&
                        this.state.request?.pickup_received_date && (
                          <div className=" form-group">
                            <label htmlFor="sale_date">
                              {' '}
                              Fecha de recepción en PP
                            </label>
                            <input
                              className="form-control"
                              name="pickup_received_date"
                              value={formatter.formatDate(
                                this.state.request.pickup_received_date,
                              )}
                              onChange={this.handleChange.bind(this)}
                              disabled
                            />
                          </div>
                        )}
                    </>
                  )}
                {(!this.state.request?.pick_up_state ||
                  this.state.request?.pick_up_state ===
                    'Entregado a cliente') && (
                  <div className="form-group">
                    <label htmlFor="pick_up"> Remito de entrega </label>
                    <UploadPickupNote
                      requestId={this.state.request.id}
                      onChange={this.handleChange.bind(this)}
                      setUploadedFile={this.setUploadedFile.bind(this)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 creation-info p-3">
                {this.state.request.productRequestRows.map(productRequest => {
                  return (
                    <div className="row mb-2">
                      <div className="form-group col-md-9">
                        <SelectProductDescription
                          name="weight"
                          value={productRequest.material}
                          onChange={this.handleChange.bind(this)}
                          isDisabled={true}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <input
                          name="weight"
                          value={productRequest.quantity}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (this.state.returnButton) {
        returnButton = (
          <button
            className="btn btn-outline-warning pull-right some-top-air w-100"
            type="button"
            onClick={() =>
              this.setState(prev => {
                return {
                  ...prev,
                  showReturns: !prev.showReturns,
                };
              })
            }>
            {this.state.showReturns ? 'Ocultar' : 'Ver'}{' '}
            {this.state.returns?.length < 2 ? 'devolución' : 'devoluciones'}
            <span
              className={`ml-1 ${
                !this.state.showReturns
                  ? 'fas fa-sort-amount-down'
                  : 'fas fa-level-up-alt'
              }`}
              data-tip
              data-for={this.state.request.id + 'return'}>
              <Tooltip
                id={this.state.request.id + 'return'}
                tooltipText="Ver devolución"
              />
            </span>
          </button>
        );
      }
      balanceOptions = (
        <div className="row col-sm-12">
          <div className="col-md-4  creation-info p-3">
            <h5 className="h5 text-center">Datos del cliente</h5>

            <div className="form-group">
              <label htmlFor="ship_to_name">Nombre del cliente</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_name"
                className="form-control"
                placeholder="A quien se envia"
                value={this.state.request.ship_to_name}
                required
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="home_phone"> Teléfono fijo </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="home_phone"
                className="form-control"
                placeholder=" Telefono fijo "
                value={this.state.request.home_phone}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="mobile_phone"> Teléfono móvil </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="mobile_phone"
                className="form-control"
                placeholder=" Telefono móvil "
                value={this.state.request.mobile_phone}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_email"> Email </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_email"
                className="form-control"
                placeholder=" Email "
                value={this.state.request.ship_to_email}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street"> Calle 1</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street"
                className="form-control"
                placeholder=" Calle 1"
                value={this.state.request.ship_to_street}
                disabled
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_street_2"> Calle 2 </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_street_2"
                className="form-control"
                placeholder=" Calle 2"
                value={this.state.request.ship_to_street_2}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="ship_to_district"> Distrito </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_district"
                className="form-control"
                placeholder=" Distrito"
                value={this.state.request.ship_to_district}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="formatted_address"> Direccion normalizada</label>
              <input
                onChange={this.handleChange.bind(this)}
                name="formatted_address"
                className="form-control"
                placeholder=" Direccion normalizada "
                value={this.state.request.formatted_address}
                disabled
              />
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="ship_to_postal_code"> Código postal </label>
                <SelectPostalCodeRequest
                  onChange={this.handleChange.bind(this)}
                  name="ship_to_postal_code"
                  placeholder=" Código postal"
                  value={
                    this.state.request.ship_to_postal_code
                      ? this.state.request.ship_to_postal_code.id
                      : null
                  }
                  isDisabled
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="formatted_postal_code"> CP normalizado </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="formatted_postal_code"
                  className="form-control"
                  placeholder=" Código postal normalizado"
                  value={this.state.request.formatted_postal_code}
                  disabled
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_city"> Ciudad </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_city"
                className="form-control"
                placeholder=" Ciudad "
                value={this.state.request.ship_to_city}
                required
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_region"> Región </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_region"
                className="form-control"
                placeholder=" Región "
                value={this.state.request.ship_to_region}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="ship_to_country"> País </label>
              <input
                onChange={this.handleChange.bind(this)}
                name="ship_to_country"
                className="form-control"
                placeholder=" País "
                value={this.state.request.ship_to_country}
                required
                disabled
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 creation-info p-3">
                <h5 className="h5 text-center">Datos del pedido</h5>

                <div className="form-group">
                  <label htmlFor="order_number"> Número de pedido </label>
                  <input
                    name="order_number"
                    value={this.state.request.order_number}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    required
                    disabled
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="sale_date"> Fecha de venta </label>
                  <input
                    className="form-control"
                    name="sale_date"
                    value={formatter.formatDate(this.state.request.sale_date)}
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                <div className=" form-group">
                  <label htmlFor="print_date"> Fecha de impresión </label>
                  <input
                    className="form-control"
                    name="print_date"
                    value={formatter.formatDate(this.state.request.print_date)}
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sold_to_id">
                    {' '}
                    Última fecha de expedición{' '}
                  </label>
                  <input
                    name="last_picked_date"
                    value={
                      this.state.request.last_picked_date
                        ? formatter.formatDate(
                            this.state.request.last_picked_date,
                          )
                        : 'Sin expedición'
                    }
                    className="form-control"
                    placeholder="Sin expedición"
                    disabled
                  />
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="delivery_number"> Entrega </label>
                    <input
                      name="delivery_number"
                      value={this.state.request.delivery_number}
                      className="form-control"
                      placeholder="Entrega"
                      onChange={this.handleChange.bind(this)}
                      required
                      disabled
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="sent_to_provider">
                      {' '}
                      Enviado a proveedor{' '}
                    </label>
                    <SelectSentToProvider
                      name="sent_to_provider"
                      value={this.state.request.sent_to_provider}
                      placeholder=" Enviado a proveedor "
                      onChange={this.handleChange.bind(this)}
                      isDisabled
                    />
                  </div>
                </div>

                <div className="row">{weightBalance}</div>

                {boxBalance}
                <div className="form-group ">
                  <label htmlFor="observations"> Observaciones </label>
                  <textarea
                    onChange={this.handleChange.bind(this)}
                    name="observations"
                    className="form-control width-observations"
                    placeholder=" Observaciones "
                    value={this.state.request.observations}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 creation-info p-3">
                <div>
                  <h5 className="h5 text-center">Validaciones</h5>
                  {!this.state.isNew && (
                    <ProductRequestValidation
                      render={(
                        addressValidation,
                        postalCodeValidation,
                        productsWeightValidation,
                        weightValidation,
                        sentToProviderValidation,
                        pickingValidation,
                      ) => {
                        return (
                          <div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-home ml-3 ' +
                                  addressValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'address'}>
                                <Tooltip
                                  id={this.state.request.id + 'address'}
                                />
                                {addressValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-mail-bulk ml-3 ' +
                                  postalCodeValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'postalCode'}>
                                <Tooltip
                                  id={this.state.request.id + 'postalCode'}
                                />
                                {postalCodeValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group ">
                              <span
                                className={
                                  'fa fa-box ml-3 ' +
                                  productsWeightValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'productsWeight'
                                }>
                                <Tooltip
                                  id={this.state.request.id + 'productsWeight'}
                                />
                                {
                                  productsWeightValidation(this.state.request)
                                    .text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-weight ml-3 ' +
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'weight'}>
                                <Tooltip
                                  id={this.state.request.id + 'weight'}
                                />
                                {
                                  weightValidation(
                                    this.state.request,
                                    this.state.boxOptions,
                                  ).text
                                }
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fas fa-cubes ml-3 ' +
                                  pickingValidation(this.state.request).class
                                }
                                data-tip
                                data-for={this.state.request.id + 'picking'}>
                                <Tooltip
                                  id={this.state.request.id + 'picking'}
                                />
                                {pickingValidation(this.state.request).text}
                              </span>
                            </div>
                            <div className="form-group">
                              <span
                                className={
                                  'fa fa-envelope ml-3 ' +
                                  sentToProviderValidation(this.state.request)
                                    .class
                                }
                                data-tip
                                data-for={
                                  this.state.request.id + 'sent_to_provider'
                                }>
                                <Tooltip
                                  id={
                                    this.state.request.id + 'sent_to_provider'
                                  }
                                />
                                {sentToProviderValidation(this.state.request)
                                  .text +
                                  (this.state.request.provider
                                    ? this.state.request.provider.name
                                    : 'Sin proveedor')}
                              </span>
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                </div>

                <h5 className="h5 text-center">Datos del pago</h5>
                <div className="form-group">
                  <label htmlFor="order_total"> Precio </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="order_total"
                    className="form-control"
                    placeholder=" Precio "
                    value={this.state.request.order_total}
                    data-for={this.state.request.id + 'order_total'}
                    disabled
                  />
                  <Tooltip
                    id={this.state.request.id + 'order_total'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="ship_charge"> Costo de envío </label>
                  <input
                    data-tip
                    onChange={this.handleChange.bind(this)}
                    name="ship_charge"
                    className="form-control"
                    placeholder=" Costo de envío "
                    value={this.state.request.ship_charge}
                    data-for={this.state.request.id + 'ship_charge'}
                    disabled
                  />
                  <Tooltip
                    id={this.state.request.id + 'ship_charge'}
                    tooltipText="Usar ' . ' como separador de decimales"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sold_to_id"> ID de facturación </label>
                  <input
                    name="sold_to_id"
                    value={this.state.request.sold_to_id}
                    className="form-control"
                    placeholder="Número de pedido"
                    onChange={this.handleChange.bind(this)}
                    disabled
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="bill_number"> Número de factura </label>
                  <input
                    name="bill_number"
                    value={this.state.request.bill_number}
                    className="form-control"
                    placeholder="Número de factura "
                    onChange={this.handleChange.bind(this)}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 creation-info p-3">
                {this.state.request.productRequestRows.map(productRequest => {
                  return (
                    <div className="row mb-2">
                      <div className="form-group col-md-9">
                        <SelectProductDescription
                          name="weight"
                          value={productRequest.material}
                          onChange={this.handleChange.bind(this)}
                          isDisabled={true}
                        />
                      </div>

                      <div className="form-group col-md-3">
                        <input
                          name="weight"
                          value={productRequest.quantity}
                          className="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div key={this.state.requestId} className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3">
          <h1 className=" col-sm-12 row text-position-title">
            <div className="col-lg-12">
              <i className="fas fa-box-open"></i>
              <span>
                {' '}
                {title} {this.state.cancelButton ? '(Cancelado)' : ' '}{' '}
              </span>
            </div>
          </h1>
          <div className="row m-4 justify-content-end">
            {returnButton && (
              <div className="col-sm-12 col-xl">{returnButton}</div>
            )}

            {backwardDeliveryButton && (
              <div className={`col-sm-12 col-xl`}>{backwardDeliveryButton}</div>
            )}

            {!this.state.loadingDetails && (
              <div
                className={`col-sm-12 ${
                  !returnButton && !backwardDeliveryButton
                    ? 'col-xl-3'
                    : 'col-xl'
                }`}>
                {this.state.request.invoice !== null && !this.state.isNew ? (
                  <button
                    type="button"
                    className="pull-right some-top-air btn btn-info w-100"
                    onClick={() => {
                      this.openModalInvoice();
                    }}>
                    Nota de entrega <i className="fas fa-file-pdf"></i>
                  </button>
                ) : (apiHelpers.isAdmin() ||
                    apiHelpers.isSupervisor() ||
                    apiHelpers.isManager()) &&
                  !this.state.isNew &&
                  this.state.request.provider &&
                  this.state.request.provider.printLabels === 'PDF' ? (
                  <span
                    data-tip
                    data-for={this.state.request.id + 'invoice_print'}>
                    {this.state.loadingPrint ? (
                      <button
                        type="button"
                        className="pull-right some-top-air btn btn-info w-100"
                        disabled>
                        Loading...{' '}
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                      </button>
                    ) : (
                      <button
                        data-tip
                        type="button"
                        className="pull-right some-top-air btn btn-info w-100"
                        data-for={this.state.request.id + 'invoice_print'}
                        onClick={event => {
                          this.createInvoice(event);
                        }}>
                        Generar nota de entrega{' '}
                        <i className="fas fa-file-pdf ml-2"></i>
                      </button>
                    )}
                    <Tooltip
                      id={this.state.request.id + 'invoice_print'}
                      tooltipText="Generar e imprimir nota de entrega"
                    />
                  </span>
                ) : (
                  <span
                    data-tip
                    data-for={this.state.request.id + 'invoice_print'}>
                    {!isCallCenter && (
                      <>
                        <button
                          data-tip
                          type="button"
                          className="pull-right some-top-air btn btn-info w-100"
                          data-for={this.state.request.id + 'invoice_print'}
                          disabled>
                          Nota de entrega <i className="fas fa-file-pdf"></i>
                        </button>
                        <Tooltip
                          id={this.state.request.id + 'invoice_print'}
                          tooltipText="No hay nota de entrega generada a la fecha"
                        />
                      </>
                    )}
                  </span>
                )}
              </div>
            )}

            {cancelButton && (
              <div
                className={`col-sm-12 col-xl-${
                  backwardDeliveryButton ? '2' : '3'
                }`}>
                {cancelButton}
              </div>
            )}
            {deleteButton && (
              <div
                className={`col-sm-12 col-xl-${
                  backwardDeliveryButton ? '2' : '3'
                }`}>
                {deleteButton}
              </div>
            )}
          </div>
          {this.state.showReturns &&
            this.state.returns?.map(returnRequest => {
              return (
                <ReturnDetails
                  returnRequest={returnRequest}
                  history={this.props.history}
                  readOnly={isReturnUser}
                />
              );
            })}
          {this.state.request.cancelled && (
            <AlertMessage
              type="error"
              title="Pedido cancelado no podrá cargar peso ni tamaño de caja"
            />
          )}

          {this.state.errorMessage && (
            <AlertMessage type="error" title={this.state.errorMessage} />
          )}

          {!this.state.isNew &&
            this.state.validations.boxSizeError &&
            !this.state.errorMessage && (
              <AlertMessage
                type="error"
                title="El pedido no tiene tamaño de caja asignado"
              />
            )}

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog fadeInDown">
            <div className="modal-content text-center">
              <div className="modal-header">
                <span>
                  <h3 className="text-center">
                    ¿Está seguro de querer eliminar esta orden?
                  </h3>
                </span>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <button
                  className="button-delete-modal btn btn-success some-top-air"
                  type="button"
                  onClick={this.deleteRequest.bind(this)}>
                  {' '}
                  Si
                </button>
                <button
                  className="button-delete-modal btn btn-danger some-top-air"
                  type="button"
                  onClick={this.closeModal.bind(this)}>
                  {' '}
                  No
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.openValidationsModal}
            className="modal-dialog fadeInDown"
            contentLabel="Error de Validacion">
            <div className="modal-content text-center">
              <div className="modal-header text-center">
                <h2 className="import-title">Atención</h2>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.closeValidationsModal()}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <h3>La nota de entrega no será impresa</h3>
                <div>
                  {!this.state.isNew &&
                    this.state.validations.formattedAddressError && (
                      <AlertMessage
                        type="error"
                        title="Direccion sin validar"
                      />
                    )}
                  {!this.state.isNew &&
                    this.state.validations.formattedPostalCodeError && (
                      <AlertMessage
                        type="error"
                        title="Codigo postal sin validar"
                      />
                    )}
                  {!this.state.isNew &&
                    this.state.validations.postalCodeError && (
                      <AlertMessage
                        type="error"
                        title="El pedido no tiene codigo postal asignado"
                      />
                    )}
                  {!this.state.isNew && this.state.validations.boxSizeError && (
                    <AlertMessage
                      type="error"
                      title="El pedido no tiene tamaño de caja asignado"
                    />
                  )}
                  {!this.state.isNew &&
                  this.state.validations.weightError &&
                  this.state.request.state_weight === 'PD' ? (
                    <AlertMessage
                      type="error"
                      title="El peso total del pedido registra diferencia con el estimado"
                    />
                  ) : (
                    <AlertMessage type="error" title="Peso total incompleto" />
                  )}
                  {!this.state.isNew &&
                    this.state.validations.productsRowsError && (
                      <AlertMessage
                        type="error"
                        title="El pedido debe contener al menos un producto"
                      />
                    )}
                </div>
                <button
                  className="button-delete-modal btn btn-danger some-top-air"
                  type="button"
                  onClick={() => this.closeValidationsModal()}>
                  {' '}
                  Cerrar
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.openModalPrevPickUpState}
            // onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog modal-lg fadeInDown">
            <div className="modal-content text-center">
              <div className="modal-header">
                <span className="w-100">
                  <h3 className="text-center p-2">Atención!</h3>
                  <div className="alert alert-warning w-100">
                    Se está modificando el estado del Pick-Up. <br /> Esta
                    acción no se puede deshacer y tambien notificará al cliente
                    del cambio de estado.
                  </div>
                  <h5 className="text-center text-justify p-2">
                    ¿Está seguro de guardar los cambios?
                  </h5>
                </span>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-between">
                  {this.state.loadingPrint ? (
                    <button
                      className="button-delete-modal btn btn-success some-top-air w-50"
                      type="button"
                      disabled>
                      Guardando...{' '}
                      <span
                        className="spinner-border spinner-border-sm ml-2 p-2"
                        role="status"
                        aria-hidden="true"></span>
                    </button>
                  ) : (
                    <button
                      className="button-delete-modal btn btn-success some-top-air w-50"
                      type="button"
                      onClick={this.submitRequest.bind(this)}>
                      Si
                    </button>
                  )}

                  <button
                    className="button-delete-modal btn btn-danger some-top-air w-50"
                    type="button"
                    disabled={this.state.loadingPrint}
                    onClick={this.closeModalPrevPickUpState.bind(this)}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {this.state.loadingDetails && (
            <div className="row col-sm-12 justify-content-center m-3">
              <h3 className="text-info p-2">Cargando detalles del pedido </h3>
              <ReactLoading
                className=""
                type={'spin'}
                color={'#55CDE4'}
                height={50}
                width={50}
              />
            </div>
          )}
          {modalInvoice}
          {modalReturnInvoice}
          {adminOptions}
          {balanceOptions}
          {pickupOptions}
          {alertProductQuantity}
          {!this.state.isNew && this.state.validations.pickupNoteError && (
            <AlertMessage
              type="error"
              title="Es necesario cargar un remito de entrega para guardar los cambios"
            />
          )}
          {!isCallCenter && (
            <>
              {!this.state.loadingPrint ? (
                <div className="text-center">
                  {function () {
                    return (
                      <button
                        className="button-submit btn btn-primary my-5"
                        onClick={
                          this.state.request.provider?.isPickupPoint &&
                          this.state.prevPickUpState !==
                            this.state.request.pick_up_state
                            ? this.handleOpenModalPrevPickUpState.bind(this)
                            : this.submitRequest.bind(this)
                        }
                        type="submit"
                        disabled={buttonEnabled}>
                        Guardar <i className="fas fa-save"></i>
                      </button>
                    );
                  }.bind(this)()}
                </div>
              ) : (
                <div className="text-center">
                  <button
                    className="button-submit btn btn-primary my-5"
                    type="button"
                    disabled>
                    Guardando...
                    <span
                      className="spinner-border spinner-border-sm ml-2 p-2"
                      role="status"
                      aria-hidden="true"></span>
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        <LinkLabels
          isOpen={this.state.linkLabelsModal}
          closeModal={() => this.setState({ linkLabelsModal: false })}
          requestId={this.state.requestId}
          currentRequest={this.state.request}
        />

        <LinkReturnLabels
          isOpen={this.state.linkReturnLabelsModal}
          closeModal={() => {
            this.setState({
              linkReturnLabelsModal: false,
              modalReturnInvoiceIsOpen: false,
            });
            this.loadData();
          }}
          requestId={this.state.requestId}
          currentRequest={this.state.request}
          weight={this.state.request.weight}
          size={this.state.request.size}
        />

        <UnlinkLabels
          isOpen={this.state.unlinkLabelsModal}
          closeModal={() => this.setState({ unlinkLabelsModal: false })}
          requestId={this.state.requestId}
          currentRequest={this.state.request}
        />

        <UnlinkReturnLabels
          isOpen={this.state.unlinkReturnLabelsModal}
          closeModal={() =>
            this.setState({
              unlinkReturnLabelsModal: false,
              modalReturnInvoiceIsOpen: false,
            })
          }
          requestId={this.state.requestId}
          currentRequest={this.state.request}
        />
        <iframe className="pdf-frame" id="pdf-frame"></iframe>
        <Footer />
      </div>
    );
  }
}
