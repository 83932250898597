import React from 'react';
import Select from 'react-select';
import config from '../../../config/config';

export default class SelectPickUpState extends React.Component {
  constructor(props) {
    super(props);

    this.options = [
      {
        order: 0,
        value: 'Pendiente de envío a PP',
        label: 'Pendiente de envío a PP',
      },
      { order: 1, value: 'Enviado a PP', label: 'Enviado a PP' },
      {
        order: 2,
        value: 'Entregado a PP',
        label: 'Entregado a PP (Listo para retiro por cliente)',
      },
      {
        order: 3,
        value: 'Entregado a cliente',
        label: 'Entregado a cliente (Carga de remito)',
      },
      {
        order: 4,
        value: 'Pendiente de retiro en PP',
        label:
          'Pendiente de retiro en PP (No entregado a cliente - logística inversa)',
      },
      { order: 5, value: 'Devuelto a depósito', label: 'Devuelto a depósito' },
    ];

    if (
      config &&
      config.countryVersion &&
      config.countryVersion.currentCountry == 'AR'
    ) {
      this.options = [
        {
          order: 0,
          value: 'Pendiente de envío a PP',
          label: 'Pendiente de envío a PP',
        },
        {
          order: 1,
          value: 'Entregado a PP',
          label: 'Entregado a PP (Listo para retiro por cliente)',
        },
        {
          order: 2,
          value: 'Entregado a cliente',
          label: 'Entregado a cliente (Carga de remito)',
        },
        {
          order: 3,
          value: 'Pendiente de retiro en PP',
          label:
            'Pendiente de retiro en PP (No entregado a cliente - logística inversa)',
        },
        {
          order: 4,
          value: 'Devuelto a depósito',
          label: 'Devuelto a depósito',
        },
      ];
    }
  }

  onChangeWrapper(value) {
    let event = { target: {} };
    event.target.name = this.props.name ? this.props.name : 'select-nameless';
    event.target.value = value ? value.value : null;
    event.target.type = 'react-select';
    this.props.onChange(event);
  }
  render() {
    let value = this.props.value;
    let options = this.options;

    if (value) {
      value = options.find(option => option.value === value);
    }

    if (this.props.hidePrevStates) {
      let currentStep = options.find(
        option => option.value === this.props.hidePrevStates,
      );
      if (
        config &&
        config.countryVersion &&
        config.countryVersion.currentCountry != 'AR'
      ) {
        options = options
          .filter(option => option.order >= currentStep.order)
          .slice(0, 2);
      }
    }
    return (
      <Select
        className={this.props.selectClassName}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={value || ''}
        isRequired={this.props.required}
        options={options}
        isClearable={this.props.clearable}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
        isDisabled={this.props.disabled}
      />
    );
  }
}
