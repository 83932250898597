import React from 'react';
import './ProductsFilters.css';
import SelectProductMaterial from '../common/selects/SelectProductMaterial';
import SelectInStock from '../common/selects/SelectInStock';

export default class ProductsFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getFiltersObject() {
    return {
      where: {
        id: this.state.id,
        inStock: this.state.inStock,
      },
    };
  }

  getInitialState() {
    return {
      id: null,
      inStock: null,
    };
  }

  onFiltersChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  onFiltersSubmit() {
    this.props.loadData(this.getFiltersObject());
  }

  onFiltersRefresh() {
    this.setState(this.getInitialState(), () => {
      this.props.loadData(this.getFiltersObject());
    });
  }

  render() {
    return (
      <div className="row d-flex justify-content-center product-filters">
        <div className="align-content-center row col-sm-12 col-lg-9">
          <div className="col-lg-5">
            <SelectProductMaterial
              name="id"
              placeholder="Código"
              value={this.state.id}
              onChange={this.onFiltersChange.bind(this)}
            />
          </div>
          <div className="col-lg-3">
            <SelectInStock
              name="inStock"
              placeholder="¿En stock?"
              value={this.state.inStock}
              onChange={this.onFiltersChange.bind(this)}
            />
          </div>
        </div>
        <div className=" row center request-filters col-sm-12 col-lg-3">
          <div>
            <button
              type="button"
              className="btn ml-2 btn-primary "
              onClick={this.onFiltersSubmit.bind(this)}>
              Filtrar<i className="ml-1 fas fa-search"></i>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn ml-1 btn-warning pull-right"
              onClick={this.onFiltersRefresh.bind(this)}>
              Reiniciar filtros<i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
