import React, { useState, useEffect } from 'react';
import config from '../../config/config';
const { io } = require('socket.io-client');

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);
  const [process, setProcess] = useState(null);

  useEffect(() => {
    // Establish the WebSocket connection
    let socket;
    while (!socket) {
      try {
        socket = io(config.backend.wsUrl, {
          path: config.backend.wsPath,
          transports: ['websocket'],
        });
        // Listen for message events
        socket.on('message', event => {
          const data = JSON.parse(event);
          setProgress(data.progress);
          setProcess(data.process);
        });
        return () => {
          socket.close();
        };
      } catch (e) {
        socket = undefined;
        delay(500);
      }
    }
  }, []);

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  return (
    <div className="py-2">
      {process && (
        <h5 className="text-justify text-center text-muted font-italic p-2">
          {process}
        </h5>
      )}
      <h4 className="text-justify text-center text-muted font-weight-light py-2">
        {Math.round(progress)}%
      </h4>
      <div
        style={{ width: `${progress}%`, height: '20px' }}
        className="bg-success rounded py-1"
      />
    </div>
  );
};

export default ProgressBar;
