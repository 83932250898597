import moment from 'moment';

export const returnsToExport = async returns => {
  // Input titles row
  let csv =
    '"Estado";"Motivo";"Detalles";"Resolución";"Detalles de Resolución";"# Caso";"# Documento";"Fecha de creación"' +
    '\n';

  for (let returnRequest of returns) {
    csv += `"${returnRequest.solved ? 'Resuelta' : 'No resuelta'}";`;
    csv += `"${returnRequest.reason}";`;
    csv += `"${returnRequest.details}";`;
    csv += `"${returnRequest.resolution ?? 'Sin resolución'}";`;
    csv += `"${returnRequest.resolutionDetails ?? ''}";`;
    csv += `"${returnRequest.caseNumber ?? ''}";`;
    csv += `"${returnRequest.documentNumber ?? ''}";`;
    csv += '"' + moment(returnRequest.createdAt).format('DD/MM/YYYY') + '";';

    csv += '\n'; // Line break
  }
  return csv;
};
