import React, { useState } from 'react';
import Modal from 'react-modal';
import Loading from '../../../common/Loading';

export const RegenInfoModal = ({
  isOpen,
  onClose,
  generateNewReport,
  loadData,
  loading,
}) => {
  const [success, setSuccess] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  const closeModalAndLoadData = async () => {
    setReportLoading(true);
    await loadData();
    setSuccess(false);
    setReportLoading(false);
    onClose();
  };

  const regenDailyReport = async () => {
    try {
      await generateNewReport();
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      appElement={document.getElementById('root')}
      // ⤴ to prevent this warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.
      isOpen={isOpen}
      className={`fadeInDown modal-dialog modal-dialog${
        loading || reportLoading ? '-centered' : ''
      } modal-lg`}>
      {loading ? (
        reportLoading ? (
          <section className="jumbotron text-center w-100">
            <div className="container">
              <p className="jumbotron-heading lead text-muted">
                Cargando los nuevos reportes ...
              </p>
              <Loading />
            </div>
          </section>
        ) : (
          <section className="jumbotron text-center">
            <div className="container">
              <h1 className="jumbotron-heading">
                Se estan generando los nuevos reportes
              </h1>
              <p className="lead text-muted">
                Este proceso puede tomar unos minutos...
              </p>
              <Loading />
            </div>
          </section>
        )
      ) : (
        <div className="modal-content p-4">
          {success ? (
            <>
              <h1 className="alert-heading jumbotron-heading text-center">
                <p className="text-success">
                  Se generaron nuevos reportes{' '}
                  <i className="fas fa-check-circle"></i>
                </p>
              </h1>
              <div className="alert alert-success" role="alert">
                <p className="lead text-muted p-4 text-center">
                  Todos los reportes solicitados fueron generados exitosamente.
                </p>
              </div>
              <div className="d-flex w-100 justify-content-center">
                <button
                  className="btn btn-success w-50"
                  onClick={() => closeModalAndLoadData()}>
                  Ver reportes
                </button>
              </div>
            </>
          ) : (
            <>
              <h1 className="alert-heading jumbotron-heading text-center text-warning">
                <p className="text-warning">
                  Atención <i className="fas fa-exclamation-circle"></i>
                </p>
              </h1>
              <div className="alert alert-warning" role="alert">
                <p className="lead text-muted p-4 text-center">
                  Los nuevos reportes reemplazarán los existentes. Desea
                  actualizar los reportes para la fecha?
                </p>
              </div>

              <div className="d-flex col-sm-12 justify-content-center">
                <button
                  className="btn btn-info btn-lg col-sm-6 m-2"
                  onClick={() => regenDailyReport()}>
                  Regenerar reportes <i className="fas fa-file-alt ml-2"></i>
                </button>
                <button
                  className="btn btn-danger btn-lg col-sm-4 m-2"
                  onClick={() => onClose()}>
                  Cerrar
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};
