import React, { Component } from 'react';
import { LatamBoardHelpers } from './LatamBoardHelpers';

class LatamBoxesBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      boxTitlesColors: [
        '#CA5B89',
        '#CA5B89',
        '#F56A62',
        '#F56A62',
        '#f7942a',
        '#f7942a',
      ],
      boxSizes: [],
      boxesData: null,
      provinceFilter: props.provinceFilter,
      dateRangeFilter: props.dateRangeFilter,
      filteredData: null,
    };
  }

  async componentDidMount() {
    let data = this.state.data;
    if (data) {
      this.getBoxTitles(data);
    }
  }

  getBoxTitles = requests => {
    let box_reports = requests.map(elem => JSON.parse(elem.boxes_report));
    let boxesTitles = box_reports.map(el => Object.keys(el)).flat();
    let titleWithoutReps = [...new Set(boxesTitles)];
    if (titleWithoutReps.length > 0) {
      this.setState(prev => {
        return {
          ...prev,
          boxSizes: titleWithoutReps,
        };
      });
    }
  };

  filterData = (province, date) => {
    if (province && date) {
      let newData;
      if (province === 'all' && date === 'all') {
        newData = LatamBoardHelpers.consolidateProvsAndDatesInBoxes(
          this.state.data,
        );
      } else if (province === 'all') {
        newData = this.state.data.filter(report => {
          return new Date(report.report_date).toLocaleDateString() === date;
        });
        newData = LatamBoardHelpers.consolidateProvsInBoxes(newData);
      } else if (date === 'all') {
        newData = this.state.data.filter(report => {
          return report.province === province;
        });
        newData = LatamBoardHelpers.consolidateDatesInBoxes(newData);
      } else {
        newData = this.state.data.filter(report => {
          return (
            report.province === province &&
            new Date(report.report_date).toLocaleDateString() === date
          );
        });
      }
      this.setState(
        prev => {
          return {
            ...prev,
            filteredData: newData,
          };
        },
        () => this.getBoxTitles(newData),
      );
    } else if (province) {
      let newData;
      if (province === 'all') {
        newData = LatamBoardHelpers.consolidateProvsInBoxes(this.state.data);
      } else {
        newData = this.state.data.filter(
          report => report.province === province,
        );
      }
      this.setState(
        prev => {
          return {
            ...prev,
            filteredData: newData,
          };
        },
        () => this.getBoxTitles(newData),
      );
    } else if (date) {
      let newData;
      if (date === 'all') {
        newData = LatamBoardHelpers.consolidateDatesInBoxes(this.state.data);
      } else {
        newData = this.state.data.filter(
          report => new Date(report.report_date).toLocaleDateString() === date,
        );
      }
      this.setState(
        prev => {
          return {
            ...prev,
            filteredData: newData,
          };
        },
        () => this.getBoxTitles(newData),
      );
    } else {
      this.setState(prev => {
        return {
          ...prev,
          filteredData: null,
        };
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.provinceFilter !== prevProps.provinceFilter ||
      this.props.dateRangeFilter !== prevProps.dateRangeFilter
    ) {
      this.filterData(this.props.provinceFilter, this.props.dateRangeFilter);
    }
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th
                scope="col"
                className={`rounded text-center text-light bg-secondary col-sm-2`}>
                Fecha
              </th>
              <th
                scope="col"
                className={`rounded text-center text-light bg-dark col-sm-2`}>
                Provincia
              </th>
              {this.state.boxSizes?.length < 1 && (
                <th scope="col" className={`rounded text-center`}>
                  Cajas
                </th>
              )}
              {this.state.boxSizes?.map((title, idx) => {
                return (
                  <th
                    key={title}
                    scope="col"
                    className={`rounded text-center ${
                      idx % 2 === 0 ? 'col-sm-1' : ''
                    }`}
                    style={{
                      background: this.state.boxTitlesColors[idx % 6],
                      color: '#DFE0DF',
                      width: '75px',
                    }}>
                    {title.includes('Peso') ? 'Peso medio' : title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {(this.state.filteredData ?? this.state.data)?.map(
              (report, idx) => {
                return (
                  <tr key={`${report.id}-${idx}`}>
                    <th
                      className="text-center"
                      style={{ width: '150px' }}
                      scope="row">
                      {/* {new Date(report.report_date).toLocaleDateString()} */}
                      {report.report_date.includes('período')
                        ? report.report_date
                        : new Date(report.report_date).toLocaleDateString()}
                    </th>
                    <th
                      className="font-weight-bold text-center text-truncate"
                      style={{ width: '150px' }}
                      scope="row">
                      {report.province.includes('Autónoma')
                        ? `CABA`
                        : report.province}
                    </th>
                    {this.state.boxSizes?.length < 1 && (
                      <>
                        <td className="font-weight-bold text-center col-sm-2 text-truncate">
                          <p className="text-danger"> - </p>
                        </td>
                      </>
                    )}
                    {this.state.boxSizes?.map((column, boxSizesIdx) => {
                      let box_report = JSON.parse(
                        (this.state.filteredData ?? this.state.data)[idx]
                          .boxes_report,
                      );
                      return (
                        <td
                          key={column}
                          style={{
                            width: column.includes('Peso') ? '100px' : '75px',
                            color: this.state.boxTitlesColors[boxSizesIdx % 6],
                          }}
                          className="font-weight-bold text-center text-truncate">
                          {`${
                            Number(box_report[column]) >= 0
                              ? box_report[column] > 0.5
                                ? new Intl.NumberFormat('es-AR').format(
                                    Number(box_report[column]),
                                  )
                                : new Intl.NumberFormat('es-AR').format(
                                    Number(box_report[column]) * 1000,
                                  )
                              : '-'
                          } 
                                                ${
                                                  column.includes('Peso') &&
                                                  box_report[column] >= 0
                                                    ? box_report[column] > 0.5
                                                      ? 'kg'
                                                      : 'g'
                                                    : ''
                                                }`}
                        </td>
                      );
                    })}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default LatamBoxesBoard;
