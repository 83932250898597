import React, { Component } from 'react';
import apiHelpers from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import NavBar from '../common/NavBar';
import Modal from 'react-modal';
import AlertMessage from '../common/AlertMessage';
import SelectDates from '../common/selects/SelectDates';
import SelectGIftConfigurationState from '../common/selects/SelectGIftConfigurationState';
import Gift from './Gift';
import ReactLoading from 'react-loading';
import formatter from '../../utils/formatter';
import { io } from 'socket.io-client';
import config from '../../config/config';
import ProgressBar from '../common/ProgressBar';

export class GiftConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftConfig: {
        gifts: [
          {
            material: '',
            quantity: 1,
          },
        ],
        promoPeriod: {
          min: null,
          max: null,
        },
        purchaseHistoryInMonths: 0,
        productsInPromo: [
          {
            material: '',
            quantity: 1,
          },
        ],
        active: false,
      },
      openSubmitErrorAlert: false,
      errorSubmitMessage: null,
      loadingSaveGiftConfig: false,
      deleteModalOpen: false,
      openLoader: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    if (this.props.match.params.id !== 'new') {
      this.getGifts();
    }
  }

  async getGifts() {
    this.setState(prev => {
      return {
        ...prev,
        loadingSaveGiftConfig: true,
      };
    });
    let { data } = await apiHelpers.getGiftDetails(this.props.match.params.id);

    if (data) {
      this.setState(prev => {
        return {
          ...prev,
          giftConfig: {
            gifts: data.gifts,
            productsInPromo: data.giftPromoProducts,
            promoPeriod: {
              min: data.min_sale_date,
              max: data.max_sale_date,
            },
            purchaseHistoryInMonths: data.purchaseHistoryInMonths,
            active: data.active,
          },
          loadingSaveGiftConfig: false,
        };
      });
    }
  }

  async getPotencialGiftsData(giftId) {
    this.setState({ openLoader: true });
    let socket;
    while (!socket) {
      try {
        socket = io(config.backend.wsUrl, {
          path: config.backend.wsPath,
          transports: ['websocket'],
        });
      } catch (e) {
        socket = undefined;
        this.delay(500);
      }
    }
    try {
      socket.emit('potencial-gifts', giftId, status => {
        console.log(status);
      });
      await apiHelpers.getPotencialGifts(giftId);
    } catch (error) {
      console.log(error);
    }
    this.setState({ openLoader: false });
    socket.close();
  }

  handleChangeGiftDateRequirements = event => {
    let newPeriod = this.state.giftConfig.promoPeriod;
    let newDate = formatter.formatFromTarget(event.target);
    newPeriod[event.target.name] = newDate;
    this.setState(prev => {
      return {
        ...prev,
        promoPeriod: newPeriod,
      };
    });
  };

  handleChangeCheckPuchases = event => {
    event.preventDefault();
    let newValue = event.target.value;
    this.setState(prev => {
      let newGiftConfig = this.state.giftConfig;
      newGiftConfig.purchaseHistoryInMonths = newValue;
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  addGift = event => {
    event.preventDefault();
    let newGifts = [
      ...this.state.giftConfig.gifts,
      {
        material: '',
        quantity: 1,
      },
    ];
    let newGiftConfig = this.state.giftConfig;
    newGiftConfig.gifts = newGifts;
    this.setState(prev => {
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  handleChangeGift = event => {
    let name = event.target.name;
    let value =
      name === 'quantity' ? Number(event.target.value) : event.target.value;
    let id = event.target.id;

    let newGifts = [...this.state.giftConfig.gifts];
    let newGift = newGifts[id];

    newGift[name] = value;
    newGifts[id] = newGift;

    let newGiftConfig = this.state.giftConfig;
    newGiftConfig.gifts = newGifts;

    this.setState(prev => {
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  handleRemoveGift = event => {
    event.preventDefault();
    let id = event.target.id;
    let newGifts = [...this.state.giftConfig.gifts];
    newGifts.splice(id, 1);
    let newGiftConfig = this.state.giftConfig;
    newGiftConfig.gifts = newGifts;
    this.setState(prev => {
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  addProductInPromo = event => {
    event.preventDefault();
    let newProdsInPromo = this.state.giftConfig.productsInPromo.concat({
      material: '',
      quantity: 1,
    });
    let newGiftConfig = this.state.giftConfig;
    newGiftConfig.productsInPromo = newProdsInPromo;
    this.setState(prev => {
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  handleChangeProductsInPromo = event => {
    let name = event.target.name;
    let value =
      name === 'quantity' ? Number(event.target.value) : event.target.value;
    let id = event.target.id;

    let newProds = [...this.state.giftConfig.productsInPromo];
    let newProd = newProds[id];

    newProd[name] = value;
    newProds[id] = newProd;

    let newGiftConfig = this.state.giftConfig;
    newGiftConfig.productsInPromo = newProds;

    this.setState(prev => {
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  handleRemoveProductInPromo = event => {
    event.preventDefault();
    let id = event.target.id;
    let newProds = [...this.state.giftConfig.productsInPromo];
    newProds.splice(id, 1);
    let newGiftConfig = this.state.giftConfig;
    newGiftConfig.productsInPromo = newProds;
    this.setState(prev => {
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  handleChangeStateOfPromo = event => {
    let newGiftConfig = this.state.giftConfig;
    newGiftConfig.active = event.target.value;
    this.setState(prev => {
      return {
        ...prev,
        giftConfig: newGiftConfig,
      };
    });
  };

  handleOpenDeleteDialog = event => {
    this.setState(prev => {
      return {
        ...prev,
        deleteModalOpen: true,
      };
    });
  };

  handleCloseDeleteDialog = event => {
    this.setState(prev => {
      return {
        ...prev,
        deleteModalOpen: false,
      };
    });
  };

  handleDeleteGiftConfig = async () => {
    if (this.props.match.params.id !== 'new') {
      await apiHelpers.deleteGiftConfig(this.props.match.params.id);
      this.props.history.push('/gifts');
    }
  };

  validateGiftConfigForm = () => {
    if (
      this.state.giftConfig.promoPeriod.min === null ||
      this.state.giftConfig.promoPeriod.max === null
    ) {
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage:
              'Debe seleccionar fechas de inicio y fin para el periodo de promoción',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
      return false;
    }

    if (
      new Date(this.state.giftConfig.promoPeriod.min).getMonth() !==
      new Date(this.state.giftConfig.promoPeriod.max).getMonth()
    ) {
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage:
              'Debe seleccionar fecha de inicio y fin en el mismo mes',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
      return false;
    }

    if (
      new Date(this.state.giftConfig.promoPeriod.min) >
      new Date(this.state.giftConfig.promoPeriod.max)
    ) {
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage:
              'La fecha de incio es mayor a la fecha de finalización',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
      return false;
    }

    if (
      this.state.giftConfig.productsInPromo.length < 1 ||
      this.state.giftConfig.productsInPromo[0].material === '' ||
      this.state.giftConfig.productsInPromo[0].material === null
    ) {
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage: 'Debe seleccionar al menos un proucto en promo',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
      return false;
    }

    if (
      this.state.giftConfig.gifts.length < 1 ||
      this.state.giftConfig.gifts[0].material === '' ||
      this.state.giftConfig.gifts[0].material === null
    ) {
      this.setState(
        prev => {
          return {
            ...prev,
            openSubmitErrorAlert: true,
            errorSubmitMessage: 'Debe seleccionar al menos un regalo',
          };
        },
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      );
      return false;
    }
    return true;
  };

  handleSubmitGiftConfig = async event => {
    this.setState(prev => {
      return {
        ...prev,
        loadingSaveGiftConfig: true,
      };
    });
    //validate fields ar correct
    if (this.validateGiftConfigForm()) {
      if (this.props.match.params.id !== 'new') {
        await apiHelpers.patchGiftDetails(
          this.props.match.params.id,
          this.state.giftConfig,
        );
        await this.getPotencialGiftsData(this.props.match.params.id);
        this.props.history.push('/gifts');
      } else {
        let { data } = await apiHelpers.postGiftDetails(this.state.giftConfig);
        await this.getPotencialGiftsData(data.id);
        this.props.history.push('/gifts');
      }
    } else {
      this.setState(prev => {
        return {
          ...prev,
          loadingSaveGiftConfig: false,
        };
      });
    }
  };

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3">
          <h1 className=" col-sm-12 row text-position-title">
            <div className="col-lg-7">
              <i className="fas fa-box-open"></i>
              <span>Configuración de regalo #{this.props.match.params.id}</span>
            </div>
          </h1>
          {this.props.match.params.id !== 'new' && (
            <div className="d-flex justify-content-end mx-4">
              <button
                className="btn btn-danger col-sm-12 col-md-4 col-lg-3"
                onClick={this.handleDeleteGiftConfig.bind(this)}>
                Eliminar promoción
              </button>
            </div>
          )}
          <div className="d-flex justify-content-center">
            {this.state.openSubmitErrorAlert && (
              <AlertMessage
                type="error"
                title={this.state.errorSubmitMessage}
              />
            )}
          </div>
          <div className="creation-info my-3 p-4">
            {this.state.giftConfig.gifts.length < 0 &&
            this.state.giftConfig.productsInPromo.length < 0 ? (
              <ReactLoading
                className="body-login"
                type={'spin'}
                color={'#55CDE4'}
                height={100}
                width={100}
              />
            ) : (
              <>
                <div className="row justify-content-center">
                  <div className="form-group col-sm-3">
                    <label className="flex-grow-1 text-justify">Estado</label>
                    <div className="col-md-12 py-2">
                      <SelectGIftConfigurationState
                        isClearable={false}
                        value={this.state.giftConfig.active}
                        onChange={this.handleChangeStateOfPromo.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="form-group col-sm-6">
                    <label
                      className="flex-grow-1 text-justify"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Periodo en el que debe efectuarse la compra">
                      Periodo de promoción
                    </label>
                    <div className="d-flex col-md-12 flex-column flex-md-row px-0">
                      <div className="col-md-6 py-2">
                        <SelectDates
                          onChange={this.handleChangeGiftDateRequirements.bind(
                            this,
                          )}
                          id="min_sale_date"
                          name="min"
                          value={this.state.giftConfig.promoPeriod.min}
                          placeholder="Desde"
                        />
                      </div>
                      <div className="col-md-6 py-2">
                        <SelectDates
                          onChange={this.handleChangeGiftDateRequirements.bind(
                            this,
                          )}
                          id="max_sale_date"
                          name="max"
                          value={this.state.giftConfig.promoPeriod.max}
                          placeholder="Hasta"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="form-group col-sm-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Meses con al menos una compra del producto en promo">
                    <label className="flex-grow-1 text-justify">
                      Historial
                    </label>
                    <div className="d-flex col-md-12 py-2">
                      <label className="mr-2 mt-2">Meses: </label>
                      <input
                        onChange={this.handleChangeCheckPuchases.bind(this)}
                        id="purchase_history"
                        name="purchase_history"
                        className="form-control pull-right"
                        style={{ maxWidth: '60px' }}
                        type="number"
                        min={0}
                        value={this.state.giftConfig.purchaseHistoryInMonths}
                        placeholder="Meses"
                      />
                    </div>
                  </div>
                </div>

                <div className="dropdown-divider"></div>

                <div className="d-flex flex-column justify-content-center flex-lg-row m-0">
                  <div className="form-group col-sm-12 col-lg-6">
                    <label className="flex-grow-1 text-justify">
                      Producto/s a en promo{' '}
                    </label>
                    <div className="col-md-12">
                      {this.state.giftConfig.productsInPromo?.map(
                        (gift, idx) => {
                          return (
                            <Gift
                              key={`gift-${idx}`}
                              gift={gift}
                              giftIdx={idx}
                              handleChangeGift={this.handleChangeProductsInPromo.bind(
                                this,
                              )}
                              handleRemoveGift={this.handleRemoveProductInPromo.bind(
                                this,
                              )}
                            />
                          );
                        },
                      )}
                    </div>
                    <div className="d-flex">
                      <button
                        className="btn btn-success"
                        onClick={this.addProductInPromo.bind(this)}>
                        Agregar producto
                      </button>
                    </div>
                  </div>

                  <div className="form-group col-sm-12 col-lg-6">
                    <label className="flex-grow-1 text-justify">
                      Producto/s a regalar{' '}
                    </label>
                    <div className="col-md-12">
                      {this.state.giftConfig.gifts?.map((gift, idx) => {
                        return (
                          <Gift
                            key={`gift-${idx}`}
                            gift={gift}
                            giftIdx={idx}
                            handleChangeGift={this.handleChangeGift.bind(this)}
                            handleRemoveGift={this.handleRemoveGift.bind(this)}
                          />
                        );
                      })}
                    </div>
                    <div className="d-flex">
                      <button
                        className="btn btn-success"
                        onClick={this.addGift.bind(this)}>
                        Agregar producto
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center ">
          <button
            className="btn btn-primary my-3 col-sm-9 col-md-6 col-lg-3"
            onClick={this.handleSubmitGiftConfig.bind(this)}
            disabled={this.state.loadingSaveGiftConfig}>
            {this.state.loadingSaveGiftConfig ? 'Guardando...' : 'Guardar'}{' '}
            <i className="fas fa-save"></i>
          </button>
        </div>

        <Modal
          isOpen={this.state.deleteModalOpen}
          className="modal-dialog-email fadeInDown modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h3 className="display-4">Eliminar la configuración</h3>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <div className="d-flex w-100 justify-content-center">
                <button
                  className="btn btn-danger btn-lg w-50"
                  onClick={this.handleDeleteGiftConfig.bind(this)}>
                  Eliminar
                </button>
                <button
                  className="btn btn-primary btn-lg w-50"
                  onClick={this.handleCloseDeleteDialog.bind(this)}>
                  Volver
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.openLoader}
          className="modal-dialog-email fadeInDown modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1>Calculando alcance de regalos</h1>
            </div>
            <div className="modal-body">
              <ProgressBar />
            </div>
          </div>
        </Modal>

        <Footer />
      </div>
    );
  }
}

export default GiftConfig;
