import React from 'react';
import moment from 'moment';
import { FiltersCacheContext } from '../../common/FiltersCacheContext';
import SelectDates from '../../common/selects/SelectDates';
import AlertMessage from '../../common/AlertMessage';

export default class LatamFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getFiltersObject() {
    return {
      where: {
        max_report_date: this.state.max_report_date,
        min_report_date: this.state.min_report_date,
      },
    };
  }

  getInitialState() {
    let date = moment().subtract(1, 'days');
    while (date.day() === 0 || date.day() === 6) {
      date = date.subtract(1, 'days');
    }

    return {
      min_report_date: date.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      max_report_date: date.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      filterErrorMessage: null,
    };
  }

  componentWillUnmount() {
    const [cacheFilters, setCacheFilters] = this.context;
    setCacheFilters({ ...cacheFilters, ['LatamFilters']: this.state });
  }

  componentDidMount() {
    this.validateDates();
    const cachedState = this.context[0]['LatamFilters'];
    if (cachedState) {
      this.setState({ ...cachedState }, () => {
        this.onFiltersSubmit();
      });
    } else {
      this.onFiltersSubmit();
    }
  }

  validateDates() {
    const min = moment(this.state.min_report_date);
    const max = moment(this.state.max_report_date);

    let filterError;
    if (min > max) {
      filterError = 'El mínimo debe ser menor al máximo';
    }
    if (max.diff(min, 'days') > 31) {
      filterError = 'El rango máximo para el filtro de fechas de 31 días';
    }

    return this.setState(prev => {
      return { ...prev, filterErrorMessage: filterError ?? null };
    });
  }

  onFiltersChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        [name]: value,
      },
      () => this.validateDates(),
    );
  }

  onFiltersRefresh() {
    this.setState(this.getInitialState(), () => {
      this.props.loadData(this.getFiltersObject());
    });
  }

  onFiltersSubmit() {
    this.props.loadData(this.getFiltersObject());
  }

  handleRegenInfo() {
    this.props.reportGenerator();
  }

  handleOpenRegenInfoModal() {
    this.props.toggleReportModal();
  }

  handleCloseRegenInfoModal() {
    this.props.toggleReportModal();
  }

  render() {
    return (
      <div className="creation-info p-3 bg-secondary">
        <div className="row col-sm-12 d-flex justify-content-between">
          {!this.props.hiddenRegenInfo && (
            <div className="row col-sm-12 col-lg-2 pr-0">
              <button
                disabled={this.props.disabled}
                className="btn btn-info"
                onClick={this.handleOpenRegenInfoModal.bind(this)}>
                Regenerar info<i className="fas fa-sync ml-2"></i>
              </button>
            </div>
          )}
          <div className="row col-sm-12 col-lg-6">
            <SelectDates
              className="col-sm-12 col-lg-6"
              onChange={this.onFiltersChange.bind(this)}
              name="min_report_date"
              value={this.state.min_report_date}
              placeholder="Reporte desde"
              isValidDate={current => {
                return current.day() !== 0 && current.day() !== 6;
              }}
            />{' '}
            <SelectDates
              className="col-sm-12 col-lg-6"
              onChange={this.onFiltersChange.bind(this)}
              name="max_report_date"
              value={this.state.max_report_date}
              placeholder="Reporte hasta"
              isValidDate={current => {
                return current.day() !== 0 && current.day() !== 6;
              }}
            />
          </div>
          <div className="row col-sm-12 col-lg-4">
            <div className="btn-group w-100">
              <button
                type="button"
                className="btn btn-warning w-50"
                disabled={
                  !!this.state.filterErrorMessage || this.props.disabled
                }
                onClick={this.onFiltersSubmit.bind(this)}>
                Aplicar filtro <i className="fas fa-search ml-1"></i>
              </button>
              <button
                disabled={this.props.disabled}
                type="button"
                className="btn btn-danger w-50"
                onClick={this.onFiltersRefresh.bind(this)}>
                Reiniciar filtro <i className="fas fa-backspace ml-1"></i>
              </button>
            </div>
          </div>
        </div>
        {this.state.filterErrorMessage && (
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-lg-10">
              <AlertMessage
                type="error"
                title={this.state.filterErrorMessage}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

LatamFilters.contextType = FiltersCacheContext;
