import React from 'react';
import './ControlPanel.css';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import apiHelper from '../../utils/apiHelpers';
import { FiltersCacheContext } from '../common/FiltersCacheContext';
import ReactLoading from 'react-loading';
import Loading from '../common/Loading';
import config from '../../config/config';
import MainBoard from './MainBoard';
import ProvidersBoard from './ProvidersBoard';
import CountriesBoard from './CountriesBoard';

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      openProviders: [],
      openCountries: [],
      isToday: false,
      loading: false,
      requestsProcessed: {
        totalImportedRequests: '',
        totalRequestWithWrongPostalCode: '',
        totalRequestWithPostalCode: '',
        totalRequestWithWrongWeight: '',
        requestWeightless: '',
        totalRequestWeightlessToday: '',
        totalRequestWeightlessPending: '',
        totalRequestWeightlessOnTime: '',
        totalRequestWeightlessDelayed: '',
        totalNotPicked: '',
        totalRequestNotPickedToday: '',
        totalRequestNotPickedPending: '',
        totalRequestNotPickedPendingOnTime: '',
        totalRequestNotPickedPendingDelayed: '',
        totalRequestNotSent: '',
        totalRequestNotSentToday: '',
        totalRequestNotSentPending: '',
        totalRequestNotSentPendingOnTime: '',
        totalRequestNotSentPendingDelayed: '',
        totalRequestOfOneProvider: [],
        totalRequestOfOneCountry: [],
      },
      filters: null,
    };
  }

  async getRequestsProcessed() {
    try {
      this.setState({ loading: true });
      const response = await apiHelper.getCountRequestProcessed();
      this.setState({
        requestsProcessed: response.data,
      });
      this.setState({ loading: false });
    } catch (e) {
      console.log('Error loading requests');
    }
  }

  componentDidMount() {
    apiHelper
      .getCurrentUser()
      .then(user => {
        let rolesIdRedirect = [4, 6, 7];
        if (rolesIdRedirect.includes(user.data.role.id)) {
          this.props.history.push('/request');
        }
        if (user.data.role.id === 3) {
          this.props.history.push('/latam');
        }
        this.setState({
          user: user.data,
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.getRequestsProcessed();
  }

  addOrRemoveProvider(providerName) {
    if (this.state.openProviders.includes(providerName)) {
      let providers = this.state.openProviders.filter(provider => {
        return provider != providerName;
      });

      this.setState({ openProviders: providers });
    } else {
      let newOpenProviders = this.state.openProviders;
      newOpenProviders.push(providerName);
      this.setState({ openProvider: newOpenProviders });
    }
  }

  addOrRemoveCountry(countryName) {
    if (this.state.openCountries.includes(countryName)) {
      let countries = this.state.openCountries.filter(country => {
        return country != countryName;
      });

      this.setState({ openCountries: countries });
    } else {
      let newOpenCountry = this.state.openCountries;
      newOpenCountry.push(countryName);
      this.setState({ openCountries: newOpenCountry });
    }
  }

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container container-width bottom-air mb-4">
          <h1 className="text-nuskin text-center">
            <i className="fas fa-table mt-3"></i>{' '}
            <span> Tablero de Control</span>
          </h1>
          {this.state.loading ? (
            <Loading />
          ) : (
            <div>
              <MainBoard
                data={this.state.requestsProcessed}
                history={this.props.history}
              />
              {this.state.requestsProcessed.totalRequestOfOneProvider.map(
                (totalRequestOfOneProvider, k) => {
                  return (
                    <div className="row" key={k}>
                      <div className="col-sm-12 margin-left provider margin-top">
                        <div className="margin-right provider-name">
                          <div
                            className="clickable postal-code col-sm-12 creation-info"
                            id="background"
                            onClick={e => {
                              this.addOrRemoveProvider(
                                totalRequestOfOneProvider.provider_name,
                              );
                            }}>
                            <span>
                              <i className="fas fa-truck ml-3"></i>
                              <b>
                                {' ' + totalRequestOfOneProvider.provider_name}
                              </b>
                            </span>
                            {this.state.openProviders.includes(
                              totalRequestOfOneProvider.provider_name,
                            ) ? (
                              <i className="pull-right fas fa-angle-double-up mt-1 mr-2"></i>
                            ) : (
                              <i className=" pull-right mt-1 fas fa-angle-double-down mr-2"></i>
                            )}
                          </div>
                        </div>
                        {this.state.openProviders.includes(
                          totalRequestOfOneProvider.provider_name,
                        ) && [
                          <ProvidersBoard
                            data={totalRequestOfOneProvider}
                            history={this.props.history}
                          />,
                        ]}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

ControlPanel.contextType = FiltersCacheContext;

export default ControlPanel;
