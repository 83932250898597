import React from 'react';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import Tooltip from '../common/Tooltip';
import 'react-image-crop/dist/ReactCrop.css';
import apiHelpers from '../../utils/apiHelpers';
const canvas = document.createElement('canvas');

class UploadPickupNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: null,
      modalIsOpen: false,
      deleteModalOpen: false,
      pickupNoteFile: null,
      src: null,
      crop: {
        unit: '%',
        width: 100,
        height: 100,
        x: 0,
        y: 0,
      },
      croppedImageUrl: '',
      imageRef: null,
      loadingFile: false,
      showDownload: false,
    };
  }

  async getURL() {
    try {
      let requestResponse = await apiHelpers.getRequest(this.props.requestId);
      if (
        requestResponse.data.withdraw_pickup_note &&
        requestResponse.data.withdraw_pickup_note.length > 1
      ) {
        this.setState({
          request: requestResponse.data,
          src: requestResponse.data.withdraw_pickup_note,
          showDownload: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.getURL();
  }

  onChangeFile(event) {
    event.preventDefault();

    let reader = new FileReader();
    this.setState({ loadingFile: true });
    window.setTimeout(
      function () {
        this.setState({ src: reader.result });
        this.setState({ loadingFile: false });
      }.bind(this),
      1000,
    );

    reader.readAsDataURL(event.target.files[0]);

    this.setState({ pickupNoteFile: event.target.files[0] });
  }

  onCropChange(crop, percentCrop) {
    this.setState({ crop: crop });
  }

  async onImageLoaded(event) {
    this.setState({ imageRef: event.currentTarget });
  }

  async uploadPickupNote() {
    if (this.state.croppedImageUrl) {
      let file;

      await new Promise(function (resolve, reject) {
        canvas.toBlob(function (blob) {
          file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
          resolve(blob);
        });
      });

      this.setState({
        modalIsOpen: false,
        pickupNoteFile: file,
        src: this.state.croppedImageUrl,
      });
    } else {
      this.setState({ modalIsOpen: false });
    }
    this.props.setUploadedFile(this.state.pickupNoteFile);
  }

  async makeClientCrop(crop) {
    if (this.state.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.state.imageRef,
        crop,
        'newFile.jpeg',
      );
      this.setState({ croppedImageUrl: croppedImageUrl });
    }
  }

  onCropComplete(crop) {
    this.makeClientCrop(crop);
  }

  getCroppedImg(image, crop, fileName) {
    try {
      const pixelRatio = window.devicePixelRatio;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');

      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
      const base64Image = canvas.toDataURL('image/jpeg', 1);
      return base64Image;
    } catch (error) {
      console.log(error);
    }
  }

  async redirectOpenFile() {
    const url = await apiHelpers.getRequestFileURL(this.props.requestId);
    window.open(url.data, '_blank', 'noopener,noreferrer');
  }

  closeDeleteModal() {
    this.setState({
      deleteModalOpen: false,
    });
  }

  render() {
    return (
      <div className="p-3">
        <div className="row justify-content-center">
          <div className="form-group w-100">
            {this.state.showDownload && (
              <button
                type="button"
                className="btn pull-right btn-warning w-100 mb-2"
                onClick={() => this.redirectOpenFile()}>
                {'Descargar remito'}{' '}
                <i className="ml-3 fas fa-file-download"></i>
              </button>
            )}

            <button
              type="button"
              className="btn pull-right btn-primary w-100 mb-2"
              onClick={() => this.setState({ modalIsOpen: true })}>
              {this.state.src || this.state.pickupNoteFile
                ? 'Reemplazar remito'
                : 'Adjuntar remito'}
              <i
                className={
                  this.state.src
                    ? 'ml-3 fas fa-sync'
                    : 'ml-3 fas fa-file-import'
                }></i>
            </button>

            {this.state.src && (
              <button
                type="button"
                className="btn pull-right btn-danger w-100"
                onClick={() => {
                  this.setState({
                    deleteModalOpen: true,
                  });
                }}>
                Eliminar <i className="ml-3 fas fa-file-excel"></i>
              </button>
            )}
          </div>
          {this.state.pickupNoteFile !== null && (
            <div className="col-md-12">
              {this.state.pickupNoteFile?.name.includes('pdf') && (
                <div className="col-md-12 alert alert-success text-center">
                  Documento: {this.state.pickupNoteFile.name}
                </div>
              )}
              {this.state.src &&
                !this.state.pickupNoteFile?.name.includes('pdf') && (
                  <img
                    alt="Crop"
                    style={{ maxWidth: '100%' }}
                    src={this.state.src}
                  />
                )}
            </div>
          )}
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.setState({ modalIsOpen: false })}
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-xl"
          contentLabel="Importar documentos">
          <div className="modal-content text-center">
            <div className="modal-header">
              <span className="import-title">Importar archivo</span>
              <button
                type="button"
                className="close"
                onClick={() => this.setState({ modalIsOpen: false })}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12">
                <input
                  type="file"
                  ref={ref => (this.inputFile = ref)}
                  id="file"
                  onChange={this.onChangeFile.bind(this)}
                  hidden
                />
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-between">
                    <button
                      disabled={this.state.loadingFile}
                      className="button-submit btn btn-primary col-md-5 mr-1"
                      onClick={() => {
                        this.inputFile.click();
                      }}>
                      Seleccionar documento{' '}
                      <span
                        data-tip
                        data-for={'Tooltip-document'}
                        className="fa fa-info-circle"></span>
                      <Tooltip
                        id={'Tooltip-document'}
                        tooltipText={
                          'Para recortar la imagen seleccione con el mouse la parte que desea importar.'
                        }
                      />
                    </button>
                    <input
                      type="button"
                      className="button-submit btn btn-info col-md-5 ml-1"
                      value="Importar documento"
                      id="attachedDocument"
                      onClick={this.uploadPickupNote.bind(this)}
                      disabled={this.state.loadingFile}
                    />
                  </div>
                </div>
                {this.state.pickupNoteFile && (
                  <div className="row">
                    <div className="col-md-12 alert alert-success text-center">
                      Documento: {this.state.pickupNoteFile.name}
                    </div>
                    {!this.state.pickupNoteFile.name.includes('pdf') && (
                      <>
                        <ReactCrop
                          className="col-md-6"
                          crop={this.state.crop}
                          src={this.state.src}
                          ruleOfThirds={true}
                          onComplete={this.onCropComplete.bind(this)}
                          onChange={this.onCropChange.bind(this)}>
                          <img
                            src={this.state.src}
                            style={{ maxWidth: '100%' }}
                            onLoad={this.onImageLoaded.bind(this)}
                          />
                        </ReactCrop>
                        <div className="col-md-6">
                          {this.state.croppedImageUrl && (
                            <img
                              alt="Crop"
                              style={{ maxWidth: '100%' }}
                              src={this.state.croppedImageUrl}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.deleteModalOpen}
          onRequestClose={this.closeDeleteModal.bind(this)}
          className="modal-dialog fadeInDown">
          <div className="modal-content text-center">
            <div className="modal-header">
              <span>
                <h3 className="text-center">
                  ¿Está seguro de querer eliminar este remito?
                </h3>
              </span>
              <button
                type="button"
                className="close"
                onClick={this.closeDeleteModal.bind(this)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <button
                className="button-delete-modal btn btn-success some-top-air"
                type="button"
                onClick={() => {
                  this.setState({
                    src: null,
                    croppedImageUrl: '',
                    pickupNoteFile: null,
                    deleteModalOpen: false,
                    showDownload: false,
                  });
                  this.props.setUploadedFile(null);
                  // this.props.setFileToDeleteFromAws(
                  //   this.state.request.withdraw_pickup_note,
                  // );
                }}>
                Si
              </button>
              <button
                className="button-delete-modal btn btn-danger some-top-air"
                type="button"
                onClick={this.closeDeleteModal.bind(this)}>
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default UploadPickupNote;
