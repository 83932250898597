import moment from 'moment';

export default {
  formatDateForAPI: date => {
    return moment(date).toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  formatNextDayForAPI: date => {
    return moment(date).add(1, 'days').toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  dateStringToDate: dateString => {
    return moment(dateString).toDate();
  },

  formatDate: date => {
    return moment(date).format('DD/MM/YYYY');
  },

  formatPaymentDate: date => {
    return moment(date).format('YYYY-MM-DD');
  },

  formatDateTime: date => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  },

  formatDateTimeHours: date => {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
  },

  formatWeight: weight => {
    return (weight * 1000).toString();
  },

  getColorByIndex: index => {
    const colors = [
      '#3cb44b',
      '#4363d8',
      '#e6194b',
      '#f58231',
      '#f032e6',
      '#bcf60c',
      '#008080',
      '#9a6324',
      '#800000',
      '#808000',
    ];
    return colors[index % colors.length];
  },

  formatFromTarget: target => {
    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = Number(target.value);
        break;
      case 'react-datetime':
        value = moment(target.value).toISOString();
        break;
      case 'file':
        value = target.files[0];
      default:
        value = target.value;
    }
    return value;
  },

  formatErrorType: type => {
    let value;
    switch (type) {
      case 'postalCode':
        value = 'Código postal';
        break;
      case 'duplicate':
        value = 'Pedido duplicado';
        break;
      case 'product':
        value = 'Producto';
        break;
      case 'provider':
        value = 'Proveedor';
        break;
      default:
        value = 'Desconocido';
    }
    return value;
  },

  getEstimatedWeight: (productRequest, boxOptions) => {
    if (!productRequest) return 0;

    let productsWeight = 0;
    if (
      productRequest.productRequestRows &&
      productRequest.productRequestRows.length > 0
    ) {
      productsWeight = productRequest.productRequestRows.reduce(
        (totalWeight, productRequestRow) => {
          if (productRequestRow.product) {
            return Number(
              totalWeight +
                productRequestRow.quantity * productRequestRow.product.weight,
            );
          }
          return Number(totalWeight);
        },
        0,
      );
    }
    // Add box weight
    if (productRequest.size) {
      const box = boxOptions.find(
        boxOption => boxOption.id == productRequest.size,
      );
      if (box) productsWeight += parseFloat(box.weight);
    }

    let weightPackages = 0;

    if (productRequest.packages) {
      weightPackages = productRequest.packages.reduce(
        (totalWeightPackage, packageRequest) => {
          if (packageRequest.size) {
            const box = boxOptions.find(
              boxOption => boxOption.id == packageRequest.size,
            );
            if (box) totalWeightPackage += parseFloat(box.weight);
          }
          return Number(totalWeightPackage);
        },
        0,
      );
    }

    let weight = productsWeight + weightPackages;

    return Number((Math.round(weight * 1000) / 1000).toFixed(3));
  },

  formatCash: n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return `$ ${(n / 1e3).toFixed(1)} K`;
    if (n >= 1e6 && n < 1e9) return `$ ${(n / 1e6).toFixed(1)} M`;
    // if (n >= 1e9 && n < 1e12) return `$ ${(n / 1e9).toFixed(1)} B`;
    // if (n >= 1e12) return `${(n / 1e12).toFixed(1)} T`;
  },
};
