import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import Pagination from '../common/Pagination';
import './Products.css';
import Modal from 'react-modal';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ProductsFilters from './ProductsFilters';
import { CSVLink } from 'react-csv';
import AlertMessage from '../common/AlertMessage';

export default class Products extends React.Component {
  download = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      modalIsOpen: false,
      count: 0,
      newProducts: null,
      pageSize: 25,
      currentPage: 1,
      loading: false,
      importErrors: null,
      importSuccess: false,
      CSVdata: '',
      filters: null,
      haveDocumentImport: false,
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  closeWarning() {
    this.setState({ importErrors: null });
  }

  closeSuccess() {
    this.setState({ importSuccess: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getProducts();
    });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getProducts();
    });
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  async getProducts() {
    const { filters, currentPage, pageSize } = this.state;

    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [response, productsCount] = await Promise.all([
        await apiHelper.getProducts(filtersWithPaging),
        await apiHelper.getProductsCount(filters),
      ]);
      this.setState({
        products: response.data,
        count: productsCount.data.count,
      });
    } catch (e) {
      console.log('Error loading products');
    }
  }

  async exportProducts() {
    const { filters, currentPage, pageSize } = this.state;

    try {
      const filter = {
        ...filters,
      };

      let response = await apiHelper.getExportProducts(filter);
      this.setState({ CSVdata: response.data }, () => {
        this.download.current.link.click();
      });
    } catch (e) {
      console.log('Error loading products');
    }
  }

  onChangeFile(event) {
    event.preventDefault();
    this.setState({
      newProducts: event.target.files[0],
      haveDocumentImport: true,
    });
  }

  async importProducts() {
    this.setState({ loading: true });
    const response = await apiHelper.importProducts(this.state.newProducts);
    if (response.data.success) {
      this.closeModal();
      this.setState({ importErrors: null, loading: false });
      this.setState({ importSuccess: true });
    } else {
      this.closeModal();
      const errors = response.data.errors;
      this.setState({ importErrors: errors, loading: false });
    }
    this.getProducts({ query: { limit: 10, skip: 0 } });
  }

  componentDidMount() {
    this.getProducts();
  }

  render() {
    let modalImportSuccess = '';

    if (this.state.importErrors !== null) {
      modalImportSuccess = (
        <AlertMessage
          type="warning"
          title="Error de importación"
          onClose={this.closeWarning.bind(this)}>
          {this.state.importErrors.map(importErrors => {
            return (
              <tr key={importErrors.line}>
                <td>
                  <p>
                    {'Error en la linea '}
                    {importErrors.line}:<b> {importErrors.message}</b>
                  </p>
                </td>
              </tr>
            );
          })}
        </AlertMessage>
      );
    } else {
      if (
        this.state.importErrors === null &&
        this.state.importSuccess === true
      ) {
        modalImportSuccess = (
          <AlertMessage
            type="success"
            title="Importación"
            onClose={this.closeSuccess.bind(this)}>
            <strong>Todos los productos se importaron correctamente</strong>
          </AlertMessage>
        );
      }
    }
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />

        <div className="container">
          <h1 className="text-nuskin row ">
            <div className=" title col-lg-6 col-sm-12">
              <i className="fas fa-boxes mt-3"></i>{' '}
              <span>Listado de productos</span>
            </div>
            <div className="row margin-import col-lg-6 col-sm-12 ">
              <div className=" col-sm-12 col-lg-4">
                <Link to={`${process.env.PUBLIC_URL}/product/new`}>
                  <button
                    type="button"
                    className="btn w-100 btn-success pull-right some-air">
                    Agregar <i className="fas fa-plus-square"></i>
                  </button>
                </Link>
              </div>
              <div className=" col-sm-12 col-lg-4">
                <button
                  type="button"
                  className="btn w-100 btn-success pull-right some-top-air"
                  onClick={this.openModal.bind(this)}>
                  Importar <i className="fas fa-file-import"></i>
                </button>
              </div>
              <div className=" col-sm-12 col-lg-4">
                <button
                  type="button"
                  className="btn w-100 btn-success pull-right some-air"
                  onClick={this.exportProducts.bind(this)}>
                  Exportar
                  <i className=" ml-1 fas fa-file-export"></i>
                </button>
                <CSVLink
                  ref={this.download}
                  data={this.state.CSVdata}
                  filename="Reporte de productos.csv"
                  className="hidden"></CSVLink>
              </div>
            </div>
          </h1>

          <Modal
            isOpen={this.state.modalIsOpen}
            onProductClose={this.closeModal.bind(this)}
            className="modal-dialog fadeInDown"
            contentLabel="Importar productos">
            <div className="modal-content text-center">
              <div className="modal-header">
                <span className="import-title">Importar productos</span>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <a
                  href="/docs/modelo_para_productos.csv"
                  className=" creation-info alert-dismissible dropdown-item"
                  target="_blank">
                  <i className="fas fa-file-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
                  Archivo modelo
                </a>

                <form method="post" action="" encType="multipart/form-data">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className=" mt-3 mb-3 import-field"
                    onChange={this.onChangeFile.bind(this)}
                  />
                  <button
                    type="button"
                    className="btn btn-info"
                    id="upload"
                    onClick={this.importProducts.bind(this)}
                    disabled={!this.state.haveDocumentImport}>
                    Importar productos{' '}
                    {this.state.loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </Modal>

          <ProductsFilters loadData={this.handleChangeFilters.bind(this)} />

          <div className="table-responsive">
            {modalImportSuccess}
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" className="col-2">
                    Codigo
                  </th>
                  <th scope="col" className="col-6">
                    Descripción
                  </th>
                  <th scope="col" className="col-2">
                    Peso
                  </th>
                  <th scope="col" className="col-1">
                    En stock
                  </th>
                  <th scope="col" className="col-1">
                    Editar
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.products.map(product => {
                  const actionColumn = (
                    <td>
                      <div className="text-center">
                        <Link
                          to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                          <button
                            type="button"
                            className="btn btn-primary btn-circle">
                            <span className="fa fa-edit"></span>
                          </button>
                        </Link>
                      </div>
                    </td>
                  );
                  const inStockColumn = (
                    <td>
                      <div className="text-center">
                        <button
                          type="button"
                          className={`btn btn-circle ${
                            product.inStock ? 'btn-success' : 'btn-danger'
                          }`}
                          onClick={async () => {
                            await apiHelper.patchProduct(product.id, {
                              ...product,
                              inStock: !product.inStock,
                            });
                            await this.getProducts();
                          }}>
                          {`${product.inStock ? 'Si' : 'No'}`}
                        </button>
                      </div>
                    </td>
                  );
                  return (
                    <tr key={product.id}>
                      <td>{product.code}</td>
                      <td>{product.description}</td>
                      <td>{product.weight}</td>
                      {inStockColumn}
                      {actionColumn}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-center">
          <Pagination
            onChangePage={this.handleChangePage.bind(this)}
            currentPage={this.state.currentPage}
            totalPages={this.getNumberOfPages()}
          />
        </div>
        <Footer />
      </div>
    );
  }
}
