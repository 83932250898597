export class LatamBoardHelpers {
  static consolidateProvinces = data => {
    let report_date_range = [...new Set(data.map(elem => elem.report_date))];
    return report_date_range.map(date_report => {
      return {
        report_date: date_report,
        province: 'Todo el país',
        imported_count: data
          .filter(elem => {
            return (
              elem.report_date === date_report &&
              elem.province === 'Todo el país'
            );
          })
          .reduce((total, currentValue) => {
            return total + currentValue.imported_count;
          }, 0),
        imported_amount: data
          .filter(elem => {
            return (
              elem.report_date === date_report &&
              elem.province === 'Todo el país'
            );
          })
          .reduce((total, currentValue) => {
            return total + currentValue.imported_amount;
          }, 0),
        weighed_count: data
          .filter(elem => {
            return (
              elem.report_date === date_report &&
              elem.province === 'Todo el país'
            );
          })
          .reduce((total, currentValue) => {
            return total + currentValue.weighed_count;
          }, 0),
        weighed_amount: data
          .filter(elem => {
            return (
              elem.report_date === date_report &&
              elem.province === 'Todo el país'
            );
          })
          .reduce((total, currentValue) => {
            return total + currentValue.weighed_amount;
          }, 0),
        sent_to_provider_count: data
          .filter(elem => {
            return (
              elem.report_date === date_report &&
              elem.province === 'Todo el país'
            );
          })
          .reduce((total, currentValue) => {
            return total + currentValue.sent_to_provider_count;
          }, 0),
        sent_to_provider_amount: data
          .filter(elem => {
            return (
              elem.report_date === date_report &&
              elem.province === 'Todo el país'
            );
          })
          .reduce((total, currentValue) => {
            return total + currentValue.sent_to_provider_amount;
          }, 0),
      };
    });
  };

  static consolidateDates = data => {
    let report_prov = [...new Set(data.map(elem => elem.province))];
    let newData = report_prov.map((report, idx) => {
      return {
        report_date: 'Todo el período',
        province: report,
        imported_count: data
          .filter(elem => elem.province === report)
          .reduce((total, currentValue) => {
            return total + currentValue.imported_count;
          }, 0),
        imported_amount: data
          .filter(elem => elem.province === report)
          .reduce((total, currentValue) => {
            return total + currentValue.imported_amount;
          }, 0),
        weighed_count: data
          .filter(elem => elem.province === report)
          .reduce((total, currentValue) => {
            return total + currentValue.weighed_count;
          }, 0),
        weighed_amount: data
          .filter(elem => elem.province === report)
          .reduce((total, currentValue) => {
            return total + currentValue.weighed_amount;
          }, 0),
        sent_to_provider_count: data
          .filter(elem => elem.province === report)
          .reduce((total, currentValue) => {
            return total + currentValue.sent_to_provider_count;
          }, 0),
        sent_to_provider_amount: data
          .filter(elem => elem.province === report)
          .reduce((total, currentValue) => {
            return total + currentValue.sent_to_provider_amount;
          }, 0),
      };
    });
    return newData;
  };

  static consolidateProvsAndDates = data => {
    return [
      {
        report_date: 'Todo el período',
        province: 'Todo el país',
        imported_count: data.reduce((total, currentValue) => {
          return total + currentValue.imported_count;
        }, 0),
        imported_amount: data.reduce((total, currentValue) => {
          return total + currentValue.imported_amount;
        }, 0),
        weighed_count: data.reduce((total, currentValue) => {
          return total + currentValue.weighed_count;
        }, 0),
        weighed_amount: data.reduce((total, currentValue) => {
          return total + currentValue.weighed_amount;
        }, 0),
        sent_to_provider_count: data.reduce((total, currentValue) => {
          return total + currentValue.sent_to_provider_count;
        }, 0),
        sent_to_provider_amount: data.reduce((total, currentValue) => {
          return total + currentValue.sent_to_provider_amount;
        }, 0),
      },
    ];
  };

  static consolidateProvsInBoxes = data => {
    let report_date_range = [...new Set(data.map(elem => elem.report_date))];

    return report_date_range.map(date_report => {
      let newReport = data
        .filter(elem => {
          return (
            elem.report_date === date_report && elem.province === 'Todo el país'
          );
        })
        .reduce((total, currentValue) => {
          let currentBoxReport = JSON.parse(currentValue.boxes_report);
          let boxesSizes = Object.keys(currentBoxReport);
          for (const boxSize of boxesSizes) {
            total[boxSize] = (total[boxSize] ?? 0) + currentBoxReport[boxSize];
          }
          return total;
        }, {});
      return {
        report_date: date_report,
        province: 'Todo el país',
        boxes_report: JSON.stringify(newReport),
      };
    });
  };

  static consolidateDatesInBoxes = data => {
    let report_prov = [...new Set(data.map(elem => elem.province))];
    return report_prov.map(province => {
      let newReport = data
        .filter(elem => elem.province === province)
        .reduce((total, currentValue) => {
          let currentBoxReport = JSON.parse(currentValue.boxes_report);
          let boxesSizes = Object.keys(currentBoxReport);
          for (const boxSize of boxesSizes) {
            total[boxSize] = (total[boxSize] ?? 0) + currentBoxReport[boxSize];
          }
          return total;
        }, {});
      return {
        report_date: 'Todo el período',
        province,
        boxes_report: JSON.stringify(newReport),
      };
    });
  };

  static consolidateProvsAndDatesInBoxes = data => {
    let newReport = data.reduce((total, currentValue) => {
      let currentBoxReport = JSON.parse(currentValue.boxes_report);
      let boxesSizes = Object.keys(currentBoxReport);
      for (const boxSize of boxesSizes) {
        total[boxSize] = (total[boxSize] ?? 0) + currentBoxReport[boxSize];
      }
      return total;
    }, {});
    return [
      {
        report_date: 'Todo el período',
        province: 'Todo el país',
        boxes_report: JSON.stringify(newReport),
      },
    ];
  };

  static generateCSVReportLATAM = reports => {
    // get boxes titles
    let box_reports = reports.map(elem => JSON.parse(elem.boxes_report));
    let boxesTitles = box_reports.map(el => Object.keys(el)).flat();
    let titleWithoutReps = [...new Set(boxesTitles)];

    let csv =
      [
        'Fecha de reporte',
        'Provincia',
        'Pedidos importados',
        '$ importado',
        'Pedidos pesados',
        '$ pesados',
        'Pedidos enviados',
        '$ enviados',
      ]
        .concat(titleWithoutReps)
        .join(';') + '\n';

    for (let report of reports) {
      csv += `"${
        report.report_date.includes(' - ')
          ? report.report_date
          : new Date(report.report_date).toLocaleDateString()
      }";`;
      csv += `"${report.province}";`;
      csv += `"${report.imported_count ?? ''}";`;
      csv += `"${report.imported_amount ?? ''}";`;
      csv += `"${report.weighed_count ?? ''}";`;
      csv += `"${report.weighed_amount ?? ''}";`;
      csv += `"${report.sent_to_provider_count ?? ''}";`;
      csv += `"${report.sent_to_provider_amount ?? ''}";`;

      // add boxes to report
      const boxesReport = JSON.parse(report.boxes_report);
      for (const boxTitle of titleWithoutReps) {
        csv += `"${boxesReport[boxTitle] ?? ''}";`;
      }

      csv += '\n'; // Line break
    }
    return csv;
  };
}
