import React from 'react';
import Select from 'react-select';

export default class SelectSendRequestsToProvider extends React.Component {
  constructor(props) {
    super(props);

    this.options = [
      { value: 'CSV', label: 'CSV' },
      { value: 'AlasExpress', label: 'AlasExpress' },
      { value: 'Coordinadora', label: 'Coordinadora' },
      { value: 'ServiEntrega', label: 'ServiEntrega' },
      { value: 'Estafeta', label: 'Estafeta' },
      { value: 'GLEColombia', label: 'GLEColombia' },
    ];
  }

  onChangeWrapper(value) {
    let event = { target: {} };
    event.target.name = this.props.name ? this.props.name : 'select-nameless';
    event.target.value = value.value;
    event.target.type = 'react-select';
    this.props.onChange(event);
  }

  render() {
    let value = this.props.value;

    if (value) {
      value = this.options.find(option => option.value === value);
    }

    return (
      <Select
        className={this.props.selectClassName}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={value || ''}
        required={this.props.required}
        options={this.options}
        clearable={false}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
        isDisabled={this.props.isDisabled}
        tabIndex={this.props.tabIndex}
      />
    );
  }
}
