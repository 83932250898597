import React from 'react';

export default () => {
  return (
    <footer className="footer mt-auto">
      <div className="container">
        <div className="text-center">
          <span>
            Made with <span className="text-red">♥</span> by VenTuring
          </span>
        </div>
      </div>
    </footer>
  );
};
