import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

function CountryFlag(props) {
  const sizes = ['sm', 'md', 'lg'];
  const measures = ['25px', '50px', '100px'];

  return (
    <div>
      <img
        src={
          process.env.PUBLIC_URL +
          `/img/country-flags/${
            props.country ?? config.countryVersion.currentCountry
          }.png`
        }
        alt="country-flag"
        height={
          measures[sizes.indexOf(props.size?.length > 0 ? props.size : 'md')]
        }
        className={props.className}
      />
    </div>
  );
}

CountryFlag.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
};

export default CountryFlag;
