import React, { Component } from 'react';
import moment from 'moment';
import apiHelper from '../../utils/apiHelpers';
import config from '../../config/config';
import { FiltersCacheContext } from '../common/FiltersCacheContext';

class CountriesBreakDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRequestOf: props.data,
    };
  }

  onClickRequestsProcessed(value) {
    if (!apiHelper.isSupervisor() || !apiHelper.isSupervisor()) {
      value.cancelled = false;
      const [cacheFilters, setCacheFilters] = this.context;
      setCacheFilters({ ...cacheFilters, ['RequestsFilters']: value });
      this.props.history.push('/request');
    }
  }

  render() {
    return (
      <div>
        <div className="row overlay margin-top">
          <div
            className={`col-sm-12 col-lg-${
              config.countryVersion?.backLogDashboard ? 3 : 4
            }`}>
            <div className="category-hidden creation-info postal-code p-3 mr-1">
              <div className={!apiHelper.isSupervisor() ? 'clickable' : ''}>
                <div>
                  <i className="fas fa-file-import text-success ml-3 "></i>
                  <span>
                    <b> Pedidos importados </b>
                  </span>
                </div>
              </div>
              <div className={!apiHelper.isSupervisor() ? 'clickable' : ''}>
                <div>
                  <i className="fas fa-weight text-danger ml-3 "></i>
                  <span>
                    <b> Pedidos sin pesar </b>
                  </span>
                </div>
              </div>
              <div className={!apiHelper.isSupervisor() ? 'clickable' : ''}>
                <div>
                  <i className="fas fa-cubes text-danger ml-3 "></i>
                  <span>
                    <b> Pedidos sin expedición </b>
                  </span>
                </div>
              </div>
              <div className={!apiHelper.isSupervisor() ? 'clickable' : ''}>
                <div>
                  <i className="fa fa-envelope text-danger ml-3"></i>
                  <span>
                    <b> Pedidos no enviados</b>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              config.countryVersion?.backLogDashboard
                ? 'col-sm-12 col-lg-1'
                : 'col-sm-12 col-lg-2'
            }>
            <div className=" creation-info postal-code p-3 ">
              <div className=" text-center ">
                <div>
                  <b>
                    <span className="mr-3 category-hidden-lg">
                      <i className="fas fa-file-import text-success category-hidden-lg"></i>
                      <span className="category-hidden-lg mr-2">
                        {' '}
                        Pedidos importados{' '}
                      </span>
                    </span>
                    {this.state.totalRequestOf.totalImportedRequests}
                  </b>
                </div>
                <div>
                  <b>
                    <span className="mr-4 category-hidden-lg ">
                      <i className="fas fa-weight text-danger category-hidden-lg"></i>
                      <span className="category-hidden-lg mr-3">
                        {' '}
                        Pedidos sin pesar{' '}
                      </span>
                    </span>
                    {this.state.totalRequestOf.totalRequestWeightlessToday}
                  </b>
                </div>

                <div>
                  <b>
                    <span className="category-hidden-lg ">
                      <i className="fas fa-cubes text-danger category-hidden-lg"></i>
                      <span className="category-hidden-lg mr-1">
                        {' '}
                        Pedidos sin expedición:
                      </span>
                    </span>
                    {this.state.totalRequestOf.totalRequestNotPickedToday}
                  </b>
                </div>

                <div>
                  <b>
                    <span className="mr-3 category-hidden-lg ">
                      <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                      <span> Pedidos no enviados: </span>
                    </span>
                    {this.state.totalRequestOf.totalRequestNotSentToday}
                  </b>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-sm-12">
            <div className="creation-info postal-code p-3">
              <div className=" text-center ">
                <div>
                  <b>---</b>
                </div>

                <div>
                  <b>
                    <span className="mr-4 category-hidden-lg ">
                      <i className="fas fa-weight text-danger category-hidden-lg"></i>
                      <span className="category-hidden-lg mr-3">
                        {' '}
                        Pedidos sin pesar{' '}
                      </span>
                    </span>
                    {config.countryVersion?.backLogDashboard
                      ? this.state.totalRequestOf.totalRequestWeightlessOnTime
                      : this.state.totalRequestOf.totalRequestWeightlessPending}
                  </b>
                </div>

                <div>
                  <b>
                    <span className="category-hidden-lg ">
                      <i className="fas fa-cubes text-danger category-hidden-lg"></i>
                      <span className="category-hidden-lg mr-1">
                        {' '}
                        Pedidos sin expedición:
                      </span>
                    </span>
                    {config.countryVersion?.backLogDashboard
                      ? this.state.totalRequestOf
                          .totalRequestNotPickedPendingOnTime
                      : this.state.totalRequestOf.totalRequestNotPickedPending}
                  </b>
                </div>

                <div>
                  <b>
                    <span className="mr-3 category-hidden-lg">
                      <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                      <span> Pedidos no enviados: </span>
                    </span>
                    {config.countryVersion?.backLogDashboard
                      ? this.state.totalRequestOf
                          .totalRequestNotSentPendingOnTime
                      : this.state.totalRequestOf.totalRequestNotSentPending}
                  </b>
                </div>
              </div>
            </div>
          </div>

          {config.countryVersion?.backLogDashboard && (
            <div className="col-lg-2 col-sm-12">
              <div className="creation-info postal-code p-3">
                <div className=" text-center ">
                  <div>
                    <b>---</b>
                  </div>

                  <div>
                    <b>
                      <span className="mr-4 category-hidden-lg ">
                        <i className="fas fa-weight text-danger category-hidden-lg"></i>
                        <span className="category-hidden-lg mr-3">
                          {' '}
                          Pedidos sin pesar{' '}
                        </span>
                      </span>
                      {this.state.totalRequestOf.totalRequestWeightlessDelayed}
                    </b>
                  </div>

                  <div>
                    <b>
                      <span className="category-hidden-lg">
                        <i className="fas fa-cubes text-danger category-hidden-lg"></i>
                        <span className="category-hidden-lg mr-1">
                          {' '}
                          Pedidos sin expedición:
                        </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestNotPickedPendingDelayed
                      }
                    </b>
                  </div>

                  <div>
                    <b>
                      <span className=" mr-3 category-hidden-lg">
                        <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                        <span> Pedidos no enviados: </span>
                      </span>
                      {
                        this.state.totalRequestOf
                          .totalRequestNotSentPendingDelayed
                      }
                    </b>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-2 col-sm-12">
            <div className="creation-info postal-code p-3">
              <div className=" text-center ">
                <div>
                  <b>---</b>
                </div>

                <div>
                  <b>
                    <span className="mr-4 category-hidden-lg ">
                      <i className="fas fa-weight text-danger category-hidden-lg"></i>
                      <span className="category-hidden-lg mr-3">
                        {' '}
                        Pedidos sin pesar{' '}
                      </span>
                    </span>
                    {this.state.totalRequestOf.requestWeightless}
                  </b>
                </div>

                <div>
                  <b>
                    <span className="category-hidden-lg">
                      <i className="fas fa-cubes text-danger category-hidden-lg"></i>
                      <span className="category-hidden-lg mr-1">
                        {' '}
                        Pedidos sin expedición:
                      </span>
                    </span>
                    {this.state.totalRequestOf.totalNotPicked}
                  </b>
                </div>

                <div>
                  <b>
                    <span className="mr-3 category-hidden-lg">
                      <i className="fa fa-envelope text-danger category-hidden-lg"></i>
                      <span> Pedidos no enviados: </span>
                    </span>
                    {this.state.totalRequestOf.totalRequestNotSent}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row overlay margin-top ">
          <div className="justify-content-center row provider-name provider col-lg-10">
            {!config.countryVersion?.noPostalCode && (
              <div className="col-lg-5 col-sm-12 postal-code creation-info p-3">
                <span>
                  <i className="fa fa-mail-bulk ml-3 text-danger "></i>
                  <b> Pedidos con error de código postal</b>
                </span>
                <div className="pull-right">
                  <b>
                    {this.state.totalRequestOf.totalRequestWithWrongPostalCode}
                  </b>
                </div>
              </div>
            )}

            <div
              className={`${
                config.countryVersion?.noPostalCode ? 'col-sm-12' : 'col margin'
              } margin provider postal-code creation-info p-3`}>
              <span>
                <i className="fa fa-weight text-warning ml-3"></i>
                <b> Pedidos con diferencia de peso</b>
              </span>
              <div className="pull-right">
                <b>{this.state.totalRequestOf.totalRequestWithWrongWeight}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CountriesBreakDown.contextType = FiltersCacheContext;

export default CountriesBreakDown;
