import React from 'react';
import formatter from '../../utils/formatter';
import apiHelper from '../../utils/apiHelpers';
import moment from 'moment';

const config = require('../../config/config');

export default class ProductRequestValidation extends React.Component {
  addressValidation(request) {
    if (
      request.formatted_address &&
      request.formatted_address.length &&
      request.formatted_address !== request.ship_to_street
    ) {
      return {
        class: 'text-success',
        text: ' Dirección validada',
      };
    } else {
      return {
        class: 'text-warning',
        text: ' Dirección sin validar',
      };
    }
  }

  postalCodeValidation(request) {
    if (
      request.ship_to_postal_code &&
      request.formatted_postal_code &&
      request.formatted_postal_code.includes(
        request.ship_to_postal_code.postalCode,
      )
    ) {
      return {
        class: 'text-success',
        text: ' Codigo postal valido',
        textTooltip: ' Codigo postal valido',
      };
    }
    if (
      !request.ship_to_postal_code ||
      (request.ship_to_postal_code &&
        request.formatted_postal_code &&
        !request.formatted_postal_code.includes(
          request.ship_to_postal_code.postalCode,
        ))
    ) {
      let text = ' Código postal con diferencia';
      let textTooltip = ' Código postal con diferencia';
      // Add all log messages
      request.logs
        .filter(log => log.type == 'postalCode')
        .map(log => {
          textTooltip += '  ';
          textTooltip += log.message;
        });

      return {
        class: 'text-danger',
        text: text,
        textTooltip: textTooltip,
      };
    }

    if (!request.formatted_postal_code) {
      let text = ' Código postal sin validar';
      let textTooltip = ' Código postal sin validar';
      // Add all log messages
      request.logs
        .filter(log => log.type == 'postalCode')
        .map(log => {
          textTooltip += '  ';
          textTooltip += log.message;
        });
      return {
        class: 'text-warning',
        text: text,
        textTooltip: textTooltip,
      };
    }
  }

  productsWeightValidation(request) {
    let validWeight = false;
    if (
      !request.productRequestRows ||
      request.productRequestRows === undefined ||
      request.productRequestRows.length <= 0
    ) {
      let text = ' Sin productos asociados';
      let textTooltip = ' Sin productos asociados';

      // Add all log messages
      request.logs
        .filter(log => log.type == 'product')
        .map(log => {
          textTooltip += '  ';
          textTooltip += log.message;
        });
      return {
        class: 'text-danger',
        text: text,
        textTooltip: textTooltip,
      };
    }
    if (
      request.productRequestRows !== undefined &&
      request.productRequestRows.length > 0
    ) {
      validWeight = request.productRequestRows.every(productRequestRow => {
        if (
          productRequestRow.product &&
          //  productRequestRow.product.weight !== '0.0000' &&
          productRequestRow.quantity !== 0
        ) {
          return true;
        } else {
          if (productRequestRow.quantity === 0) {
            return false;
          }
        }
      });
    }

    if (validWeight && request.productRequestRows.length) {
      return {
        class: 'text-success',
        text: ' Todos los productos tienen su peso cargado',
        textTooltip: ' Todos los productos tienen su peso cargado',
      };
    }
    if (!validWeight && request.productRequestRows.length) {
      let text = ' Hay productos sin su peso cargado';
      let textTooltip = ' Hay productos sin su peso cargado';

      // Add all log messages
      request.logs
        .filter(log => log.type == 'product')
        .map(log => {
          textTooltip += '  ';
          textTooltip += log.message;
        });
      return {
        class: 'text-warning',
        text: text,
        textTooltip: textTooltip,
      };
    }
  }

  weightValidation(request, boxOptions) {
    let weightPackages = 0;
    if (request.packages) {
      weightPackages = request.packages.reduce(
        (totalWeightPackage, packageRequest) => {
          if (packageRequest.weight) {
            return totalWeightPackage + Number(packageRequest.weight);
          }
          return totalWeightPackage;
        },
        0,
      );
    }

    let weightRequest = weightPackages + Number(request.weight);
    const estimatedWeight = formatter.getEstimatedWeight(request, boxOptions);
    let paseFloatWeight = parseFloat(weightRequest);
    let differenceOfWeight = Math.abs(estimatedWeight - paseFloatWeight);
    let tolerance = config.weight.toleranceValue;
    let percentTolerance =
      (estimatedWeight * config.weight.numberOfPercent) / 100;

    if (request.sent_to_provider && request.state_weight === 'PV') {
      // this will prevent false weight differences by post provider sending weight change
      return {
        class: 'text-success',
        text: ' Peso validado',
      };
    }

    if (paseFloatWeight >= 0) {
      if (tolerance <= percentTolerance) {
        if (
          differenceOfWeight <= percentTolerance &&
          tolerance < percentTolerance
        ) {
          return {
            class: 'text-success',
            text: ' Peso validado',
          };
        } else {
          if (apiHelper.isAdmin() || apiHelper.isSupervisor()) {
            return {
              class: 'text-warning',
              text: ' Peso con diferencia',
            };
          } else {
            return {
              class: 'text-danger',
              text: ' Peso con diferencia',
            };
          }
        }
      } else {
        if (differenceOfWeight <= tolerance && percentTolerance < tolerance) {
          return {
            class: 'text-success',
            text: ' Peso validado',
          };
        } else {
          if (apiHelper.isAdmin() || apiHelper.isSupervisor()) {
            return {
              class: 'text-warning',
              text: ' Peso con diferencia',
            };
          } else {
            return {
              class: 'text-danger',
              text: ' Peso con diferencia',
            };
          }
        }
      }
    } else {
      return {
        class: 'text-danger',
        text: ' Peso sin validar',
      };
    }
  }

  pickingValidation(request) {
    if (request.state_picking) {
      if (request.state_picking === 'PV') {
        return {
          class: 'text-success',
          text: ' Expedición validada',
        };
      } else {
        if (request.state_picking === 'SP') {
          return {
            class: 'text-danger',
            text: ' Sin expedición',
          };
        }
      }
    } else {
      return {
        class: 'text-danger',
        text: ' Sin expedición',
      };
    }
  }

  sentToProviderValidation(request) {
    if (request.sent_to_provider) {
      return {
        class: 'text-success',
        text: ' Enviado al operador logístico ',
      };
    } else {
      if (!request.sent_to_provider) {
        return {
          class: 'text-danger',
          text: ' No se envio al operador logístico ',
        };
      }
    }
  }

  deliveryStateValidation(request) {
    if (!request.ship_to_postal_code) {
      return {
        class: 'text-secondary',
        text: ` Sin datos de entrega `,
      };
    }
    // .request.ship_to_postal_code.estimatedDeliveryTime
    let isDelayed = moment(request.sent_to_provider_date)
      .add(request.ship_to_postal_code.estimatedDeliveryTime, 'days')
      .isBefore(moment());
    if (request.delivery_state === '404' || request.delivery_state === null) {
      return {
        class: 'text-secondary',
        text: ` Sin datos de entrega `,
      };
    }
    if (request.delivery_state === 'D') {
      return {
        class: 'text-success',
        text: ' Entrega exitosa ',
      };
    }
    if (request.delivery_state !== 'D' && !isDelayed) {
      return {
        class: 'text-warning',
        text: ' Entrega pendiente ',
      };
    }
    if (request.delivery_state !== 'D' && isDelayed) {
      return {
        class: 'text-danger',
        text: ' Entrega demorada ',
      };
    }
  }

  withdrawPickupNoteValidation(request) {
    if (request.withdraw_pickup_note) {
      return {
        class: 'text-success',
        text: ' Pedido con remito ',
        cursor: 'pointer',
        clickable: true,
      };
    } else {
      return {
        class: 'text-danger',
        text: ' Pedido sin remito ',
        cursor: 'default',
        clickable: false,
      };
    }
  }

  render() {
    return this.props.render(
      this.addressValidation,
      this.postalCodeValidation,
      this.productsWeightValidation,
      this.weightValidation,
      this.sentToProviderValidation,
      this.pickingValidation,
      this.withdrawPickupNoteValidation,
      this.deliveryStateValidation,
    );
  }
}
