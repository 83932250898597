import React from 'react';
import SelectDates from '../common/selects/SelectDates';
import { FiltersCacheContext } from '../common/FiltersCacheContext';

export default class ReturnFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getFiltersObject() {
    return {
      where: {
        max_create_date: this.state['request.max_create_date'],
        min_create_date: this.state['request.min_create_date'],
      },
    };
  }

  getInitialState() {
    return {
      'request.min_create_date': null,
      'request.max_create_date': null,
    };
  }

  componentWillUnmount() {
    const [cacheFilters, setCacheFilters] = this.context;
    setCacheFilters({ ...cacheFilters, ['ReturnFilters']: this.state });
  }

  componentDidMount() {
    const cachedState = this.context[0]['ReturnFilters'];
    if (cachedState) {
      this.setState({ ...cachedState }, () => {
        this.onFiltersSubmit();
      });
    } else {
      this.onFiltersSubmit();
    }
  }

  clearMax() {
    if (this.state.request.max_create_date) {
      this.setState({ 'request.max_create_date': null });
    }
  }

  clearMin() {
    if (this.state.request.min_create_date) {
      this.setState({ 'request.min_create_date': null });
    }
  }

  onFiltersChange(event) {
    const target = event.target;
    const id = target.type === 'checkbox' ? target.checked : target.id;
    const name = target.name;
    const value = target.value;

    if (target.type === 'react-datetime') {
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: id });
    }
  }

  onFiltersRefresh() {
    this.setState(this.getInitialState(), () => {
      this.props.loadData(this.getFiltersObject());
    });
  }

  onFiltersSubmit() {
    this.props.loadData(this.getFiltersObject());
  }

  render() {
    return (
      <div className="row m-4 w-100">
        <div className="row col-sm-12 col-lg-8">
          <SelectDates
            className="col-lg-6"
            onChange={this.onFiltersChange.bind(this)}
            name="request.min_create_date"
            value={this.state['request.min_create_date']}
            placeholder="Creadas desde"
          />{' '}
          <SelectDates
            className="col-lg-6"
            onChange={this.onFiltersChange.bind(this)}
            name="request.max_create_date"
            value={this.state['request.max_create_date']}
            placeholder="Creadas hasta"
          />
        </div>
        <div className="row col-sm-12 col-lg-4">
          <div className="btn-group w-100">
            <button
              type="button"
              className="btn btn-primary w-50"
              onClick={this.onFiltersSubmit.bind(this)}>
              Aplicar filtros <i className="fas fa-search"></i>
            </button>
            <button
              type="button"
              className="btn btn-warning w-50"
              onClick={this.onFiltersRefresh.bind(this)}>
              Reiniciar filtros <i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ReturnFilters.contextType = FiltersCacheContext;
