import React from 'react';
import Select from 'react-select';

export default class SelectDeliveryState extends React.Component {
  constructor(props) {
    super(props);

    this.options = [
      { value: 'D', label: 'Entrega exitosa' },
      { value: '404', label: 'Sin datos de entrega' },
      { value: 'NOT_D', label: 'Sin entregar' },
      { value: 'DELAYED', label: 'Entrega demorada' },
    ];
  }

  onChangeWrapper(value) {
    let event = { target: {} };
    event.target.name = this.props.name ? this.props.name : 'select-nameless';
    event.target.value = value ? value.value : null;
    event.target.type = 'react-select';
    this.props.onChange(event);
  }
  render() {
    let value = this.props.value;

    if (value) {
      value = this.options.find(option => option.value === value);
    }

    return (
      <Select
        className={this.props.selectClassName}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={value || ''}
        required={this.props.required}
        options={this.options}
        isClearable={true}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
      />
    );
  }
}
