import React from 'react';
import './PostalCodeFilters.css';
import SelectProvider from '../common/selects/SelectProvider';
import SelectSamePostalCodes from '../common/selects/SelectSamePostalCodes';
import { FiltersCacheContext } from '../common/FiltersCacheContext';

export default class PostalCodeFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getFiltersObject() {
    return {
      where: {
        postalCode: this.state.postalCode,
        providerId: this.state.providerId,
      },
    };
  }

  getInitialState() {
    return {
      postalCode: null,
      providerId: null,
    };
  }

  componentWillUnmount() {
    const [cacheFilters, setCacheFilters] = this.context;
    setCacheFilters({ ...cacheFilters, ['PostalCodeFilters']: this.state });
  }

  componentDidMount() {
    const cachedState = this.context[0]['PostalCodeFilters'];
    if (cachedState) {
      this.setState({ ...cachedState }, () => {
        this.onFiltersSubmit();
      });
    } else {
      this.onFiltersSubmit();
    }
  }

  onFiltersChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  onFiltersRefresh() {
    this.setState(this.getInitialState(), () => {
      this.props.loadData(this.getFiltersObject());
    });
  }

  onFiltersSubmit() {
    this.props.loadData(this.getFiltersObject());
  }

  render() {
    return (
      <div className="row postalCode-filters  d-flex justify-content-center   ">
        <div className="row  align-content-center postalCode-filters col-sm-12 col-lg-9 ">
          <div className="col-md-4 ">
            <SelectProvider
              name="providerId"
              placeholder="Proveedor"
              value={this.state.providerId}
              onChange={this.onFiltersChange.bind(this)}
            />
          </div>
          <div className="col-md-4 ">
            <SelectSamePostalCodes
              name="postalCode"
              placeholder=" Código postal "
              value={this.state.postalCode}
              onChange={this.onFiltersChange.bind(this)}
              clearable={true}
            />
          </div>
        </div>
        <div className="ml-2  row center request-filters col-sm-12 col-lg-3">
          <div>
            <button
              type="button"
              className="btn ml-2 btn-primary "
              onClick={this.onFiltersSubmit.bind(this)}>
              Filtrar<i className="ml-1 fas fa-search"></i>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn ml-2 btn-warning pull-right"
              onClick={this.onFiltersRefresh.bind(this)}>
              Reiniciar filtros<i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PostalCodeFilters.contextType = FiltersCacheContext;
