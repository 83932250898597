import React, { Component } from 'react';
import Modal from 'react-modal';
import apiHelpers from '../../../utils/apiHelpers';
import AlertMessage from '../../common/AlertMessage';
import { downloadReturnPDF } from '../../common/InvoicePDF';
import SelectHeadLabelToLink from '../../common/selects/SelectHeadLabelToLink';
import SelectReturnLabelToLink from '../../common/selects/SelectReturnLabelToLink';

export class LinkReturnLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: this.props.requestId,
      isOpen: this.props.isOpen,
      loading: false,
      newHeadLabelToLink: null,
      headLabelToLink: null,
      headLabelsAvailable: null,
      errorLinkingLabels: null,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.getHeadsLabel();
  }

  getHeadsLabel = async () => {
    const { data: headLabelsAvailable } = await apiHelpers.getRequests({
      where: {
        return_sent_to_provider: 1,
        // state_picking: 'SP',
        isHeadReturnLabelOfAPI: 1,
      },
    });
    this.setState(prev => {
      return {
        ...prev,
        headLabelsAvailable: headLabelsAvailable,
      };
    });
  };

  handleChangeInput = event => {
    this.setState(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  handleLinkIdLabelsOfAPI = async e => {
    this.setState({ loading: true });
    const newHead = e.target.name === 'newHead';
    try {
      //save request
      const { data: linkResponse } = await apiHelpers.linkReturnIdLabelsOfAPI(
        this.state.requestId,
        newHead ? this.state.newHeadLabelToLink : this.state.headLabelToLink,
      );
      //print label
      await downloadReturnPDF(linkResponse);
      //update request weight
      const { data: newRequest } = await apiHelpers.getRequest(
        this.state.requestId,
      );
      newRequest.weight = this.props.weight;
      newRequest.size = this.props.size;
      await apiHelpers.patchRequest(newRequest.id, newRequest);
      this.props.getRequestWithOrderNumber &&
        (await this.props.getRequestWithOrderNumber(newRequest.order_number));
      setTimeout(() => {
        this.props.closeModal();
        this.setState({
          loading: false,
        });
      }, 3000);
    } catch (e) {
      console.log('Error update or printing request: ' + e.message);
      this.setState({
        loading: false,
        errorLinkingLabels: 'Error asociando etiqueta',
      });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={!this.state.loading && this.props.closeModal}
        className="fadeInDown modal-dialog modal-lg"
        contentLabel="Nota de entrega">
        <div className="modal-content text-center">
          <div className="col-md-12 modal-header">
            <div className="col-md-11">
              <span>
                <h2 className="fas fa-print fa-fw ml-5"></h2>
                <h3 className="ml-5">Asociación de etiquetas</h3>
              </span>
            </div>
            <div className="col-md-1">
              {!this.state.loading && (
                <button
                  type="button"
                  className="close"
                  onClick={this.props.closeModal}>
                  &times;
                </button>
              )}
            </div>
          </div>
          <div className="modal-body d-flex flex-column py-4">
            <div id="accordion">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5
                    className="mb-0 collapsed text-primary"
                    data-toggle={this.state.loading ? '' : 'collapse'}
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    Generar nueva cabecera
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion">
                  <div className="card-body">
                    <div className="input-group my-3">
                      <div className="w-50">
                        <SelectReturnLabelToLink
                          className="border border-primary rounded-left"
                          placeholder="Ingrese etiqueta"
                          name="newHeadLabelToLink"
                          value={this.state.newHeadLabelToLink}
                          onChange={this.handleChangeInput}
                          isDisabled={this.state.loading}
                          currentRequestId={this.props.currentRequest.id}
                        />
                      </div>
                      <div className="input-group-append w-50">
                        <button
                          className="btn btn-primary btn-block"
                          type="button"
                          name="newHead"
                          onClick={this.handleLinkIdLabelsOfAPI}
                          disabled={
                            this.state.loading || !this.state.newHeadLabelToLink
                          }>
                          {this.state.loading
                            ? 'Generando cabecera y asociando pedido...'
                            : 'Generar nueva cabecera y asociar pedido'}
                          {this.state.loading && (
                            <span
                              className="spinner-border spinner-border-sm ml-2 p-2"
                              role="status"
                              aria-hidden="true"></span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.headLabelsAvailable && (
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5
                      className="mb-0 collapsed  text-info"
                      data-toggle={this.state.loading ? '' : 'collapse'}
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      Cabecera existente
                    </h5>
                  </div>

                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion">
                    <div className="card-body">
                      <div className="input-group my-3">
                        <div className="w-50">
                          <SelectHeadLabelToLink
                            options={this.state.headLabelsAvailable?.map(
                              req => {
                                if (
                                  req.id !==
                                  this.props.currentRequest
                                    .headReturnLabelRequestId
                                ) {
                                  return req.order_number;
                                }
                              },
                            )}
                            value={this.state.headLabelToLink}
                            name="headLabelToLink"
                            selectClassName="border border-info rounded-left"
                            onChange={this.handleChangeInput}
                            isDisabled={this.state.loading}
                          />
                        </div>
                        <div className="input-group-append w-50">
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            name="existingHead"
                            onClick={this.handleLinkIdLabelsOfAPI}
                            disabled={
                              this.state.loading || !this.state.headLabelToLink
                            }>
                            {this.state.loading
                              ? 'Asociando cabecera...'
                              : 'Asociar a cabecer'}
                            {this.state.loading && (
                              <span
                                className="spinner-border spinner-border-sm ml-2 p-2"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {this.state.errorLinkingLabels && (
              <AlertMessage
                type="error"
                title={this.state.errorLinkingLabels}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default LinkReturnLabels;
