import apiHelper from '../../utils/apiHelpers';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import formatter from '../../utils/formatter';
import './ProvidersDetails.css';
import Modal from 'react-modal';
import SelectSendRequestsToProvider from '../common/selects/SelectSendRequestsToProvider';
import SelectPrintLabels from '../common/selects/SelectPrintLabels';
import AlertMessage from '../common/AlertMessage';
import SelectProviderType from '../common/selects/SelectProviderType';
import SelectSMSNotifications from '../common/selects/SelectSMSNotifications';

export default class ProviderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      providerDocument: null,
      isNew: props.match.params.id === 'new',
      providerId: props.match.params.id,
      modalIsOpen: false,
      labelsModalIsOpen: false,
      provider: {
        name: '',
        email: '',
        cuit: '',
        denomination: '',
        apiKey: '',
        shippingRequests: '',
        printLabels: '',
        productRequets: {
          import_date: '',
        },
        labelsWidth: null,
        labelsHeight: null,
        trackingURL: null,
        notificationBySMS: false,
        isPickupPoint: false,
        maximum_days_of_storage: null,
        colorMark: '',
        isWarehouse: null,
      },
      importSuccess: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    apiHelper
      .getProvider(this.state.providerId)
      .then(response => {
        this.setState({
          provider: response.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this.deleteProvider.bind(this);
    this.submitProvider.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      labelsModalIsOpen: false,
    });
  }

  closeSuccess() {
    this.setState({ importSuccess: false });
  }

  handleChange(event) {
    const target = event.target;
    let value = formatter.formatFromTarget(target);
    const name = target.name;
    const provider = this.state.provider;
    provider[name] = value;
    if (name === 'shippingRequests' && value === 'CSV')
      provider['printLabels'] = 'PDF';
    if (name === 'labelsWidth' && value === 0) {
      provider['labelsWidth'] = null;
    }
    if (name === 'labelsHeight' && value === 0) {
      provider['labelsHeight'] = null;
    }
    if (name === 'trackingURL' && value === '') {
      provider['trackingURL'] = null;
    }
    if (name === 'isPickupPoint') {
      provider['isPickupPoint'] = value.value;
    }
    if (name === 'notificationBySMS') {
      provider['notificationBySMS'] = value.value;
    }
    this.setState({ provider: provider });
  }

  async deleteProvider() {
    apiHelper.deleteProvider(this.state.providerId);
    this.props.history.push('/provider');
  }

  async submitProvider(event) {
    event.preventDefault();
    let providerId = this.state.providerId;

    if (
      (this.state.provider.labelsWidth &&
        this.state.provider.labelsWidth < 40) ||
      (this.state.provider.labelsHeight &&
        this.state.provider.labelsHeight < 40)
    ) {
      return this.setState({
        labelsModalIsOpen: true,
      });
    }

    if (
      this.state.provider.isPickupPoint &&
      this.state.provider.maximum_days_of_storage < 1
    ) {
      return this.setState({
        errorMessage:
          'Es necesario incluir el máximo de días que el Pick Up Point almacenará los pedidos pendientes de retiro',
      });
    }

    try {
      //If providers already exists, update it. If not, create it
      const provider = this.state.provider;

      if (this.state.isNew) {
        await apiHelper.postProvider(provider);
      } else {
        await apiHelper.patchProvider(providerId, provider);
      }

      this.props.history.push('/provider');
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let title = 'Crear Proveedor';
    let deleteButton = false;
    let deleteProvider = '';

    if (!this.state.isNew) {
      title = 'Proveedor';
    }
    if (!this.state.isNew) {
      deleteButton = (
        <button
          className="btn btn-danger pull-right some-top-air"
          type="button"
          onClick={this.openModal.bind(this)}>
          Eliminar <span className="fa fa-trash-alt"></span>
        </button>
      );
    }

    if (!this.state.isNew) {
      deleteProvider = (
        <AlertMessage type="warning" title="Para eliminar un proveedor">
          <strong>
            Asegurese que todos los codigos postales asociados a él fueran
            eliminados o tengan otro proveedor asignado, de lo contrario el
            proveedor no sera eliminado
          </strong>
        </AlertMessage>
      );
    }

    return (
      <div key={this.state.providerId} className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container mt-3">
          <h1 className="text-position-title">
            <i className="fas fa-clipboard-list"></i>
            <span> {title} </span>
            {deleteButton}
          </h1>

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog fadeInDown">
            <div className="modal-content text-center">
              <div className="modal-header">
                {deleteProvider}
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal.bind(this)}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <span>
                  <h3 className="text-center">
                    ¿Está seguro de querer eliminar este proveedor?
                  </h3>
                </span>
                <button
                  className="button-delete-modal btn btn-success some-top-air"
                  type="button"
                  onClick={this.deleteProvider.bind(this)}>
                  {' '}
                  Si
                </button>
                <button
                  className="button-delete-modal btn btn-danger some-top-air"
                  type="button"
                  onClick={this.closeModal.bind(this)}>
                  {' '}
                  No
                </button>
              </div>
            </div>
          </Modal>

          <AlertMessage type="warning" title="Para agregar más de un email">
            Escribalos en el campo de "Email" separado por comas (,) con
            espacios en medio. Ejemplo: email_1@ejemplo.com ,
            email_2@ejemplo.com
          </AlertMessage>

          <Modal
            isOpen={this.state.labelsModalIsOpen}
            onRequestClose={this.closeModal.bind(this)}
            className="modal-dialog fadeInDown"
            ariaHideApp={false}>
            <AlertMessage
              type="error"
              title="Dimensiones de etiqueta demasiado pequeñas"
              onClose={this.closeModal.bind(this)}>
              <strong>El minimo para alto y ancho son 50 mm</strong>
            </AlertMessage>
          </Modal>

          <div className="col-md-12 creation-info margin-row margin-top-picking">
            <div className="col-md-12 row margin-row mt-2">
              <div className="col-md-2 form-group">
                <label htmlFor="name"> Proveedor </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="name"
                  className="form-control"
                  placeholder=" Proveedor "
                  value={this.state.provider.name}
                  required
                />
              </div>
              <div className="col-md-2 form-group">
                <label htmlFor="denomination"> Denominación </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="denomination"
                  className="form-control"
                  placeholder=" Denominación "
                  value={this.state.provider.denomination}
                />
              </div>
              <div className="col-md-1 form-group">
                <label htmlFor="denomination">Color</label>
                <input
                  type="color"
                  name="colorMark"
                  placeholder="#5bc0de"
                  onChange={this.handleChange.bind(this)}
                  className="form-control"
                  value={this.state.provider.colorMark}
                />
              </div>
              <div className="col-md-3 form-group">
                <label htmlFor="email"> Email </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="email"
                  className="form-control"
                  placeholder=" Email "
                  value={this.state.provider.email}
                  required
                />
              </div>
              <div className={`col-md-2 form-group`}>
                <label htmlFor="cuit"> CUIT </label>
                <input
                  type="number"
                  onChange={this.handleChange.bind(this)}
                  name="cuit"
                  className="form-control"
                  placeholder=" CUIT "
                  value={this.state.provider.cuit}
                />
              </div>
              <div className={`col-md-2 form-group`}>
                <label htmlFor="cuit"> Es depósito </label>
                <input
                  type="checkbox"
                  onChange={this.handleChange.bind(this)}
                  name="isWarehouse"
                  className="form-control"
                  checked={this.state.provider.isWarehouse}
                  value={this.state.provider.isWarehouse}
                />
              </div>
            </div>
            <div className="col-md-12 row margin-row">
              <div
                className={`col-md${
                  this.state.provider.isPickupPoint ? '' : '-3'
                } form-group`}>
                <label htmlFor="provider"> Tipo de proveedor </label>
                <SelectProviderType
                  name="isPickupPoint"
                  placeholder="Tipo de proveedor"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.provider.isPickupPoint}
                />
              </div>
              {this.state.provider.isPickupPoint && (
                <div className="col form-group">
                  <label htmlFor="maximum_days_of_storage">
                    {' '}
                    Almacenamiento{' '}
                  </label>
                  <input
                    type="number"
                    min={1}
                    max={14}
                    onChange={this.handleChange.bind(this)}
                    name="maximum_days_of_storage"
                    className="form-control"
                    placeholder="Días"
                    value={this.state.provider.maximum_days_of_storage}
                  />
                </div>
              )}
              <div className="col-md-3 form-group">
                <label htmlFor="clave de api"> Clave de API </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="apiKey"
                  className="form-control"
                  placeholder=" Clave de API "
                  value={this.state.provider.apiKey}
                  required
                />
              </div>
              <div className="col-md-3 form-group">
                <label htmlFor="tracking"> Seguimiento de envio </label>
                <input
                  onChange={this.handleChange.bind(this)}
                  name="trackingURL"
                  className="form-control"
                  placeholder=" Seguimiento de envio "
                  value={this.state.provider.trackingURL}
                />
              </div>
              <div
                className={`col-md-${
                  this.state.provider.isPickupPoint ? 2 : 3
                } form-group`}>
                <label htmlFor="SMS notification">
                  {' '}
                  {`${
                    !this.state.provider.isPickupPoint
                      ? 'Notificación por '
                      : ''
                  }SMS`}{' '}
                </label>
                <SelectSMSNotifications
                  onChange={this.handleChange.bind(this)}
                  name="notificationBySMS"
                  className="form-control"
                  placeholder=" Notificación por SMS "
                  value={this.state.provider.notificationBySMS}
                />
              </div>
            </div>
            <div className="col-md-12 row margin-row">
              <div
                className={`col-md-${
                  this.state.provider.printLabels === 'PDF' ? 4 : 6
                } form-group`}>
                <label htmlFor="shippingRequests"> Envio de pedidos </label>
                <SelectSendRequestsToProvider
                  onChange={this.handleChange.bind(this)}
                  name="shippingRequests"
                  className="form-control"
                  placeholder=" Envio de pedidos "
                  value={this.state.provider.shippingRequests}
                  required
                />
              </div>
              <div
                className={`col-md-${
                  this.state.provider.printLabels === 'PDF' ? 4 : 6
                } form-group`}>
                <label htmlFor="printLabels">
                  {' '}
                  Impresión de nota de entrega{' '}
                </label>
                <SelectPrintLabels
                  onChange={this.handleChange.bind(this)}
                  name="printLabels"
                  className="form-control"
                  isDisabled={
                    this.state.provider.shippingRequests == 'CSV' ? true : false
                  }
                  placeholder=" Nota de entrega "
                  value={this.state.provider.printLabels}
                  required
                />
              </div>
              {this.state.provider.printLabels == 'PDF' && (
                <>
                  <div className="col-md-2 form-group">
                    <label htmlFor="labelsWidth"> Ancho (mm) </label>
                    <input
                      type="number"
                      onChange={this.handleChange.bind(this)}
                      name="labelsWidth"
                      className="form-control"
                      placeholder="Ancho"
                      value={this.state.provider.labelsWidth}
                    />
                  </div>
                  <div className="col-md-2 form-group">
                    <label htmlFor="labelsHeight"> Alto (mm) </label>
                    <input
                      type="number"
                      onChange={this.handleChange.bind(this)}
                      name="labelsHeight"
                      className="form-control"
                      placeholder="Alto"
                      value={this.state.provider.labelsHeight}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {this.state.errorMessage && (
            <AlertMessage
              type="error"
              title={this.state.errorMessage}></AlertMessage>
          )}

          <div className="text-center">
            {function () {
              return (
                <button
                  className="button-submit btn btn-primary my-5"
                  onClick={this.submitProvider.bind(this)}
                  type="submit">
                  Guardar <i className="fas fa-save"></i>
                </button>
              );
            }.bind(this)()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
