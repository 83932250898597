import React from 'react';
import './LogFilters.css';
import SelectRequestOrderNumber from '../common/selects/SelectRequestOrderNumber';
import SelectDates from '../common/selects/SelectDates';
import { FiltersCacheContext } from '../common/FiltersCacheContext';

export default class LogFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getFiltersObject() {
    let sentToProvider = null;
    return {
      where: {
        'productRequest.id': this.state['request.id'],
        max_import_date: this.state['request.max_import_date'],
        min_import_date: this.state['request.min_import_date'],
      },
    };
  }

  getInitialState() {
    return {
      'request.id': null,
      'request.min_import_date': null,
      'request.max_import_date': null,
    };
  }

  componentWillUnmount() {
    const [cacheFilters, setCacheFilters] = this.context;
    setCacheFilters({ ...cacheFilters, ['LogFilters']: this.state });
  }

  componentDidMount() {
    const cachedState = this.context[0]['LogFilters'];
    if (cachedState) {
      this.setState({ ...cachedState }, () => {
        this.onFiltersSubmit();
      });
    } else {
      this.onFiltersSubmit();
    }
  }

  clearMax() {
    if (this.state.request.max_import_date) {
      this.setState({ 'request.max_import_date': null });
    }
  }

  clearMin() {
    if (this.state.request.min_import_date) {
      this.setState({ 'request.min_import_date': null });
    }
  }

  onFiltersChange(event) {
    const target = event.target;
    const id = target.type === 'checkbox' ? target.checked : target.id;
    const name = target.name;
    const value = target.value;

    if (target.type === 'react-datetime') {
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: id });
    }
  }

  onFiltersRefresh() {
    this.setState(this.getInitialState(), () => {
      this.props.loadData(this.getFiltersObject());
    });
  }

  onFiltersSubmit() {
    this.props.loadData(this.getFiltersObject());
  }

  render() {
    return (
      <div className="row historyError-filters align-content-center ">
        <div className="row historyError-filters margin-filter col-lg-8">
          <div className="col-lg-5">
            <SelectRequestOrderNumber
              name="request.id"
              placeholder="Número de orden"
              onChange={this.onFiltersChange.bind(this)}
              value={this.state['request.id']}
            />
          </div>
          <div className="col-lg-3">
            <SelectDates
              onChange={this.onFiltersChange.bind(this)}
              name="request.min_import_date"
              value={this.state['request.min_import_date']}
              placeholder="Importados desde"
            />{' '}
          </div>
          <div className="col-lg-3">
            <SelectDates
              onChange={this.onFiltersChange.bind(this)}
              name="request.max_import_date"
              value={this.state['request.max_import_date']}
              placeholder="Importados hasta"
            />
          </div>
        </div>
        <div className=" ml-2 row  pull-right col-sm-12 col-lg-4">
          <div>
            <button
              type="button"
              className="btn ml-2 btn-primary pull-right"
              onClick={this.onFiltersSubmit.bind(this)}>
              Aplicar filtros <i className="fas fa-search"></i>
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-warning ml-2"
              onClick={this.onFiltersRefresh.bind(this)}>
              Reiniciar filtros <i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

LogFilters.contextType = FiltersCacheContext;
