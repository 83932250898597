import React, { Component } from 'react';
import Loading from '../../../common/Loading';
import { LatamBoardHelpers } from './LatamBoardHelpers';

class LatamRequestsBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: this.sortReports(props.data),
      requestTitles: [
        'Fecha',
        'Provincia',
        'Importados',
        '$',
        'Pesados',
        '$',
        'Enviados',
        '$',
      ],
      titleColors: [
        'light bg-secondary',
        'light bg-dark',
        'light bg-primary',
        'light bg-primary',
        'light bg-info',
        'light bg-info',
        'light bg-success',
        'light bg-success',
      ],
      provinceFilter: props.provinceFilter,
      dateRangeFilter: props.dateRangeFilter,
      filteredData: null,
    };
  }

  sortReports = newData => {
    if (!newData) return newData;
    return newData.sort((a, b) => {
      if (a.report_date !== b.report_date) {
        return b.report_date > a.report_date;
      } else if (a.imported_count !== b.imported_count) {
        return b.imported_count - a.imported_count;
      } else {
        return a.province.localeCompare(b.province);
      }
    });
  };

  filterData = (province, date) => {
    let newData;
    if (province && date) {
      if (province === 'all' && date === 'all') {
        newData = LatamBoardHelpers.consolidateProvsAndDates(
          this.state.initialData,
        );
      } else if (province === 'all') {
        newData = this.state.initialData.filter(report => {
          return new Date(report.report_date).toLocaleDateString() === date;
        });
        newData = LatamBoardHelpers.consolidateProvinces(newData);
      } else if (date === 'all') {
        newData = this.state.initialData.filter(report => {
          return report.province === province;
        });
        newData = LatamBoardHelpers.consolidateDates(newData);
      } else {
        newData = this.state.initialData.filter(report => {
          return (
            report.province === province &&
            new Date(report.report_date).toLocaleDateString() === date
          );
        });
      }
      this.setState(prev => {
        return {
          ...prev,
          filteredData: this.sortReports(newData),
        };
      });
    } else if (province) {
      if (province === 'all') {
        newData = LatamBoardHelpers.consolidateProvinces(
          this.state.initialData,
        );
      } else {
        newData = this.state.initialData.filter(
          report => report.province === province,
        );
      }
      this.setState(prev => {
        return {
          ...prev,
          filteredData: this.sortReports(newData),
        };
      });
    } else if (date) {
      if (date === 'all') {
        newData = LatamBoardHelpers.consolidateDates(this.state.initialData);
      } else {
        newData = this.state.initialData.filter(
          report => new Date(report.report_date).toLocaleDateString() === date,
        );
      }
      this.setState(prev => {
        return {
          ...prev,
          filteredData: this.sortReports(newData),
        };
      });
    } else {
      this.setState(prev => {
        return {
          ...prev,
          filteredData: this.state.initialData,
        };
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.provinceFilter !== prevProps.provinceFilter ||
      this.props.dateRangeFilter !== prevProps.dateRangeFilter
    ) {
      this.filterData(this.props.provinceFilter, this.props.dateRangeFilter);
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loading />
        ) : (
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  {this.state.requestTitles.map((title, idx) => {
                    return (
                      <th
                        key={`${title}_${idx}`}
                        scope="col"
                        title={
                          title === 'Enviados'
                            ? 'Pedidos enviados a operador logístico'
                            : undefined
                        }
                        className={`rounded text-${this.state.titleColors[idx]} text-center text-justify`}>
                        {title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {(this.state.filteredData ?? this.state.initialData)?.map(
                  (req, idx) => {
                    return (
                      <tr
                        key={`${req.id ?? 'req'}_${idx}`}
                        className="col-sm-12">
                        <th className="text-center col-sm-3" scope="row">
                          {req.report_date.includes('período')
                            ? req.report_date
                            : new Date(req.report_date).toLocaleDateString()}
                        </th>
                        <td className="font-weight-bold text-center col-sm-2 text-truncate">
                          {req.province.includes('Autónoma')
                            ? `CABA`
                            : req.province}
                        </td>
                        <td className="text-primary text-center">
                          {req.imported_count > 0 ? req.imported_count : `-`}
                        </td>
                        <td className="text-primary text-center col-sm-2">
                          {req.imported_amount > 0
                            ? req.imported_amount.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                            : `$ -`}
                        </td>
                        <td className="text-info text-center">
                          {req.weighed_count > 0 ? req.weighed_count : `-`}
                        </td>
                        <td className="text-info text-center col-sm-2">
                          {req.weighed_amount > 0
                            ? req.weighed_amount.toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                            : `$ -`}
                        </td>
                        <td className="text-success text-center">
                          {req.sent_to_provider_count > 0
                            ? req.sent_to_provider_count
                            : `-`}
                        </td>
                        <td className="text-success text-center col-sm-2">
                          {req.sent_to_provider_amount > 0
                            ? req.sent_to_provider_amount.toLocaleString(
                                'es-AR',
                                {
                                  style: 'currency',
                                  currency: 'ARS',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                },
                              )
                            : `$ -`}
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}

export default LatamRequestsBoard;
