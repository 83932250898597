import React, { Component } from 'react';
import ReturnsBoard from './ReturnsBoard';
import ReactLoading from 'react-loading';

class ProviderReturnsBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnByOneProvider: props.data,
      openBoard: false,
    };
  }

  handleToggleBoard = e => {
    const isCollapsed = !e.target.className.includes('collapsed');
    this.setState(prev => {
      return {
        ...prev,
        openBoard: isCollapsed,
      };
    });
  };

  render() {
    return (
      <>
        <div
          className="creation-info p-3 my-4 d-flex justify-content-between align-items-center"
          onClick={this.handleToggleBoard}
          style={{ cursor: 'pointer', background: '#d6eaf8' }}
          data-toggle="collapse"
          href={`#${this.props.provider.slice(0, 2)}-${this.props.providerId}`}>
          <h5 className="font-weight-bold text-muted">
            {`${this.props.provider}`}
          </h5>
          <div className="mx-4">
            {this.state.openBoard ? (
              <i className={`fas fa-angle-double-up`}></i>
            ) : (
              <i className={`fas fa-angle-double-down`}></i>
            )}
          </div>
        </div>
        <div
          className="collapse"
          id={`${this.props.provider.slice(0, 2)}-${this.props.providerId}`}>
          {this.state.returnByOneProvider === null ? (
            <ReactLoading
              className="m-auto"
              type={'spin'}
              color={'#55CDE4'}
              height={80}
              width={80}
            />
          ) : (
            <ReturnsBoard data={this.state.returnByOneProvider} />
          )}
        </div>
      </>
    );
  }
}

export default ProviderReturnsBoard;
