import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React from 'react';
import PropTypes from 'prop-types';

export default class RequestBarChartOfProductsSentToProvider extends React.Component {
  componentDidUpdate(data) {
    if (this.chart) {
      this.chart.dispose();
    }
    if (data !== this.props.data) {
      // Apply chart themes
      am4core.useTheme(am4themes_animated);

      // Create chart instance
      let chart = am4core.create('barchartProducts', am4charts.XYChart);

      // Add data
      chart.data = this.props.data;

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dataFields.date = 'sent_to_provider_date';
      dateAxis.dateFormats.setKey('day', 'dd MMM');
      dateAxis.title.text = 'Día de procesamiento';
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 80;
      dateAxis.skipEmptyPeriods = true;

      // First value axis
      let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = 'Total de productos';

      // First series
      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = 'total_products';
      series1.dataFields.dateX = 'sent_to_provider_date';
      series1.name = 'Total de productos';
      series1.tooltipText = '{name}: [bold]{valueY}[/]';

      let labelBullet = series1.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = '{valueY}';
      labelBullet.label.dy = -20;

      // Add cursor
      chart.cursor = new am4charts.XYCursor();

      // Add Scroll
      chart.scrollbarX = new am4core.Scrollbar();

      this.chart = chart;
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div
        id="barchartProducts"
        style={{ width: '100%', height: '500px' }}></div>
    );
  }
}

RequestBarChartOfProductsSentToProvider.propTypes = {
  data: PropTypes.array,
};
