import React from 'react';
import Select from 'react-select';
import apiHelper from '../../../utils/apiHelpers';
import formatter from '../../../utils/formatter';

export default class SelectBoxSizeAsync extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };

    apiHelper.getProducts({ where: { category: 'box' } }).then(options => {
      let newOptions = [];
      newOptions.push({ value: 0, label: 'Sin caja' });
      if (apiHelper.isAdmin() || apiHelper.isSupervisor()) {
        options.data.map(boxOption => {
          newOptions.push({
            value: boxOption.id,
            label:
              boxOption.description +
              ' (' +
              formatter.formatWeight(boxOption.weight) +
              ' gr)',
          });
        });
      } else {
        options.data.map(boxOption => {
          newOptions.push({
            value: boxOption.id,
            label: boxOption.description,
          });
        });
      }
      this.setState({ options: newOptions });
    });
  }

  onChangeWrapper(value) {
    let event = { target: {} };
    event.target.name = this.props.name ? this.props.name : 'select-nameless';
    event.target.value = value.value;
    event.target.type = 'react-select';
    this.props.onChange(event);
  }

  render() {
    let value = this.props.value;

    if (value != undefined) {
      value = this.state.options.find(option => option.value === value);
    }

    return (
      <Select
        className={this.props.selectClassName}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={value || ''}
        required={this.props.required}
        options={this.state.options}
        clearable={false}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
        isDisabled={this.props.isDisabled}
        tabIndex={this.props.tabIndex}
        getOptionValue={option => `${option['label']}`}
      />
    );
  }
}
