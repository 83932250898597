import React, { Component } from 'react';

class ReturnsBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnsProcessed: props.data,
    };
  }

  render() {
    return (
      <div className="row">
        <div className={`col-sm-12 col-lg-3 category-hidden category`}>
          <h5 className="h5 text-lg-center mt-2">Motivo</h5>
          <div className="creation-info p-3">
            <div className="my-2">
              <i className="fas fa-boxes text-primary ml-3"></i>
              <span>
                <b> Total devoluciones </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Anulado por NS </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Rechazado por el cliente </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Codigo postal erróneo </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Imposible entrega </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Falta de información </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Código postal no corresponde </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Duplicados </b>
              </span>
            </div>

            <div className="my-2">
              <i className="fas fa-truck-loading text-info ml-3 "></i>
              <span>
                <b> Otros </b>
              </span>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-lg">
          <h5 className="h5 text-center mt-2 text-secondary">Abiertas</h5>
          <div className="creation-info p-3 h5 ">
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Total devoluciones: </span>
              <b>{this.state.returnsProcessed?.notSolved.total}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Anulado por NS: </span>
              <b>{this.state.returnsProcessed?.notSolved.totalCanceledByNS}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Rechazado por el cliente:{' '}
              </span>
              <b>
                {this.state.returnsProcessed?.notSolved.totalRejectedByCustomer}
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Codigo postal erróneo:{' '}
              </span>
              <b>
                {this.state.returnsProcessed?.notSolved.totalWrongPostalCode}
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Imposible entrega: </span>
              <b>
                {this.state.returnsProcessed?.notSolved.totalImpossibleDelivery}
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Falta de información: </span>
              <b>
                {this.state.returnsProcessed?.notSolved.totalLackOfInformation}
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Código postal no corresponde:{' '}
              </span>
              <b>
                {
                  this.state.returnsProcessed?.notSolved
                    .totalPostalCodeNotCorrespondToOperator
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Duplicados: </span>
              <b>{this.state.returnsProcessed?.notSolved.totalDuplicate}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Otros: </span>
              <b>{this.state.returnsProcessed?.notSolved.totalOthersReturns}</b>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-lg">
          <h5 className="h5 text-center mt-2">Cerradas</h5>
          <div className="creation-info p-3 h5 text-primary">
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2 text-primary">
              <span className="category-hidden-lg">Total devoluciones: </span>
              <b>{this.state.returnsProcessed?.totalSolved?.total}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Anulado por NS: </span>
              <b>
                {this.state.returnsProcessed?.totalSolved?.totalCanceledByNS}
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Rechazado por el cliente:{' '}
              </span>
              <b>
                {
                  this.state.returnsProcessed?.totalSolved
                    ?.totalRejectedByCustomer
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Codigo postal erróneo:{' '}
              </span>
              <b>
                {this.state.returnsProcessed?.totalSolved?.totalWrongPostalCode}
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Imposible entrega: </span>
              <b>
                {
                  this.state.returnsProcessed?.totalSolved
                    ?.totalImpossibleDelivery
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Falta de información: </span>
              <b>
                {
                  this.state.returnsProcessed?.totalSolved
                    ?.totalLackOfInformation
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Código postal no corresponde:{' '}
              </span>
              <b>
                {
                  this.state.returnsProcessed?.totalSolved
                    ?.totalPostalCodeNotCorrespondToOperator
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Duplicados: </span>
              <b>{this.state.returnsProcessed?.totalSolved?.totalDuplicate}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Otros: </span>
              <b>
                {this.state.returnsProcessed?.totalSolved?.totalOthersReturns}
              </b>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-lg">
          <h5 className="h5 text-center mt-2 text-info">RMA</h5>
          <div className="creation-info p-3 h5 text-info">
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2 text-info">
              <span className="category-hidden-lg">Total devoluciones: </span>
              <b>{this.state.returnsProcessed?.RMA.total}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Anulado por NS: </span>
              <b>{this.state.returnsProcessed?.RMA.totalCanceledByNS}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Rechazado por el cliente:{' '}
              </span>
              <b>{this.state.returnsProcessed?.RMA.totalRejectedByCustomer}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Codigo postal erróneo:{' '}
              </span>
              <b>{this.state.returnsProcessed?.RMA.totalWrongPostalCode}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Imposible entrega: </span>
              <b>{this.state.returnsProcessed?.RMA.totalImpossibleDelivery}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Falta de información: </span>
              <b>{this.state.returnsProcessed?.RMA.totalLackOfInformation}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Código postal no corresponde:{' '}
              </span>
              <b>
                {
                  this.state.returnsProcessed?.RMA
                    .totalPostalCodeNotCorrespondToOperator
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Duplicados: </span>
              <b>{this.state.returnsProcessed?.RMA.totalDuplicate}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Otros: </span>
              <b>{this.state.returnsProcessed?.RMA.totalOthersReturns}</b>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg">
          <h5 className="h5 text-center mt-2 text-info">RPL</h5>
          <div className="creation-info p-3 h5 text-info">
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2 text-info">
              <span className="category-hidden-lg">Total devoluciones: </span>
              <b>{this.state.returnsProcessed?.RPL.total}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Anulado por NS: </span>
              <b>{this.state.returnsProcessed?.RPL.totalCanceledByNS}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Rechazado por el cliente:{' '}
              </span>
              <b>{this.state.returnsProcessed?.RPL.totalRejectedByCustomer}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Codigo postal erróneo:{' '}
              </span>
              <b>{this.state.returnsProcessed?.RPL.totalWrongPostalCode}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Imposible entrega: </span>
              <b>{this.state.returnsProcessed?.RPL.totalImpossibleDelivery}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Falta de información: </span>
              <b>{this.state.returnsProcessed?.RPL.totalLackOfInformation}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Código postal no corresponde:{' '}
              </span>
              <b>
                {
                  this.state.returnsProcessed?.RPL
                    .totalPostalCodeNotCorrespondToOperator
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Duplicados: </span>
              <b>{this.state.returnsProcessed?.RPL.totalDuplicate}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Otros: </span>
              <b>{this.state.returnsProcessed?.RPL.totalOthersReturns}</b>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg">
          <h5 className="h5 text-center mt-2 text-info">SAR</h5>
          <div className="creation-info p-3 h5 text-info">
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2 text-info">
              <span className="category-hidden-lg">Total devoluciones: </span>
              <b>{this.state.returnsProcessed?.SAR.total}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Anulado por NS: </span>
              <b>{this.state.returnsProcessed?.SAR.totalCanceledByNS}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Rechazado por el cliente:{' '}
              </span>
              <b>{this.state.returnsProcessed?.SAR.totalRejectedByCustomer}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Codigo postal erróneo:{' '}
              </span>
              <b>{this.state.returnsProcessed?.SAR.totalWrongPostalCode}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Imposible entrega: </span>
              <b>{this.state.returnsProcessed?.SAR.totalImpossibleDelivery}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Falta de información: </span>
              <b>{this.state.returnsProcessed?.SAR.totalLackOfInformation}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">
                Código postal no corresponde:{' '}
              </span>
              <b>
                {
                  this.state.returnsProcessed?.SAR
                    .totalPostalCodeNotCorrespondToOperator
                }
              </b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Duplicados: </span>
              <b>{this.state.returnsProcessed?.SAR.totalDuplicate}</b>
            </div>
            <div className="d-flex justify-content-between justify-content-lg-center text-lg-center my-2">
              <span className="category-hidden-lg">Otros: </span>
              <b>{this.state.returnsProcessed?.SAR.totalOthersReturns}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReturnsBoard;
