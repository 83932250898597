import React from 'react';
import AsyncSelect from 'react-select/async';
import apiHelper from '../../../utils/apiHelpers';
import PropTypes from 'prop-types';

export default class SelectProductMaterial extends React.Component {
  constructor(props) {
    super(props);

    this.wto = null;

    this.state = {
      selectedOption: null,
    };

    this.getOptions = (input, callback) => {
      // if (!input || input.length === 0) return;
      clearTimeout(this.wto);
      this.wto = setTimeout(() => {
        let filters = { where: { code: input } };

        apiHelper
          .getProducts(filters)
          .then(products => {
            let productsForSelect = products.data.map(product => {
              return {
                value: product.id,
                label: product.code + ' (' + product.description + ')',
              };
            });

            callback(productsForSelect);
          })
          .catch(err => {
            callback(null, err);
          });
      }, 1000);
    };
  }

  onChangeWrapper(selectedValue) {
    let event = { target: {} };
    event.target.name = this.props.name
      ? this.props.name
      : 'select-product-nameless';
    event.target.value = selectedValue ? selectedValue.value : null;
    event.target.type = 'react-select';

    this.setState({
      selectedOption: selectedValue,
    });

    this.props.onChange(event);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.getDefaultOptions(this.props.value);
    }
  }
  getDefaultOptions(input) {
    if (!input || input.length === 0) return;
    let filters = { where: { id: input } };

    apiHelper
      .getProducts(filters)
      .then(products => {
        let productsForSelect = products.data.map(product => {
          return {
            value: product.id,
            label: product.code + ' (' + product.description + ')',
          };
        });

        this.setState({
          selectedOption: productsForSelect[0],
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    let { value } = this.props;
    let { selectedOption } = this.state;

    if (value && !selectedOption) {
      this.getDefaultOptions(value);
    }

    if (!value) {
      selectedOption = null;
    }

    return (
      <AsyncSelect
        className={this.props.className}
        name={this.props.name}
        onChange={this.onChangeWrapper.bind(this)}
        value={selectedOption || ''} //Workaround to clear value with null. See https://github.com/JedWatson/react-select/issues/3066
        required={this.props.required}
        loadOptions={this.getOptions}
        placeholder={this.props.placeholder}
        menuContainerStyle={{ zIndex: 999 }}
        noOptionsMessage={() => 'Ingrese un valor para buscar'}
        isClearable={true}
        isDisabled={this.props.isDisabled}
        defaultOptions={true}
      />
    );
  }
}

SelectProductMaterial.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
};
