import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import apiHelper from '../../utils/apiHelpers';
import config from '../../config/config';
import CountryFlag from '../common/ContryFlag';

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    apiHelper
      .getCurrentUser()
      .then(response => {
        this.setState({
          user: response.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const displayName = this.state.user
      ? `${this.state.user.first_name} ${this.state.user.last_name}`
      : '';
    let userBalanceOptions = '';
    let userAdminOptions = '';
    let userPickUpOptions = '';
    let userReturnsOptions = '';
    let userCallCenterOptions = '';

    if (this.state.user) {
      if (this.state.user.role.userRole === 'BALANCE') {
        userBalanceOptions = (
          <div>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/`}>
              <i className=" fas fa-table fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Tablero de Control
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/orderWeight`}>
              <i className=" fas fa-weight fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Balanza
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/picking`}>
              <i className=" fas fa-cubes fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Expedición
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/provider`}>
              <i className=" fas fa-truck fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Proveedores
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/change-password`}>
              <i className=" fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Configuración
            </Link>
            <Link
              to="/login"
              className="dropdown-item clickable"
              onClick={apiHelper.logout}>
              <i className=" fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Cerrar sesión
            </Link>
          </div>
        );
      }
      if (
        this.state.user.role.userRole === 'ADMIN' ||
        this.state.user.role.userRole === 'SUPERVISOR' ||
        this.state.user.role.userRole === 'MANAGER'
      ) {
        userAdminOptions = (
          <div>
            <Link
              className="dropdown-item clickable "
              to={`${process.env.PUBLIC_URL}/`}>
              <i className=" fas fa-table fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Tablero de Control
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/request`}>
              <i className=" fas fa-user-check fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Pedidos
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/log`}>
              <i className=" fas fa-exclamation-triangle fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Historial de errores
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/orderWeight`}>
              <i className="fas fa-weight fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Balanza
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/picking`}>
              <i className=" fas fa-cubes fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Expedición
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/product`}>
              <i className=" fas fa-boxes fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Productos
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/provider`}>
              <i className=" fas fa-truck fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Proveedores
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/postalCode`}>
              <i className=" fas fa-map-marked-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Códigos postales
            </Link>
            {config.giftsFeature && config.giftsFeature.active && (
              <Link
                className="dropdown-item clickable"
                to={`${process.env.PUBLIC_URL}/gifts`}>
                <i className=" fas fa-gift mr-2 text-gray-400"></i>{' '}
                Configuración de regalos
              </Link>
            )}
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/returns`}>
              <i className=" fas fa-undo fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Devoluciones
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/new-return`}>
              <i className=" fas fa-plus fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Nueva devolución
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/change-password`}>
              <i className=" fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Configuración
            </Link>
            <Link
              to="/login"
              className="dropdown-item clickable"
              onClick={apiHelper.logout}>
              <i className=" fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Cerrar sesión
            </Link>
          </div>
        );
      }
      if (this.state.user.role.userRole === 'PICKUP') {
        userPickUpOptions = (
          <div>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/request`}>
              <i className=" fas fa-user-check fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Pedidos
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/change-password`}>
              <i className=" fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Configuración
            </Link>
            <Link
              to="/login"
              className="dropdown-item clickable"
              onClick={apiHelper.logout}>
              <i className=" fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Cerrar sesión
            </Link>
          </div>
        );
      }
      if (this.state.user.role.userRole === 'RETURNS') {
        userReturnsOptions = (
          <div>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/request`}>
              <i className=" fas fa-user-check fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Pedidos
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/new-return`}>
              <i className=" fas fa-plus fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Nueva devolución
            </Link>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/change-password`}>
              <i className=" fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Configuración
            </Link>
            <Link
              to="/login"
              className="dropdown-item clickable"
              onClick={apiHelper.logout}>
              <i className=" fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Cerrar sesión
            </Link>
          </div>
        );
      }
      if (this.state.user.role.userRole === 'CALLCENTER') {
        userCallCenterOptions = (
          <div>
            <Link
              className="dropdown-item clickable"
              to={`${process.env.PUBLIC_URL}/request`}>
              <i className=" fas fa-user-check fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Pedidos
            </Link>
            <Link
              to="/login"
              className="dropdown-item clickable"
              onClick={apiHelper.logout}>
              <i className=" fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>{' '}
              Cerrar sesión
            </Link>
          </div>
        );
      }
    }
    return (
      <div className="nav-container">
        <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
          <span>
            <div
              className=" clickable mr-3 align-content-lg-start  pull-left fas fa-chevron-left fa-2x"
              onClick={() => this.props.history.goBack()}></div>
          </span>
          <span>
            <img
              className="align-content-lg-start logo-Navbar"
              src={process.env.PUBLIC_URL + '/img/logoInvoice.png'}
            />
          </span>
          {config.countryVersion.currentCountry && (
            <span className="w-100 d-flex justify-content-end">
              <CountryFlag className="m-4" />
            </span>
          )}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                id="navbardrop"
                data-toggle="dropdown">
                <img
                  src={process.env.PUBLIC_URL + '/img/defaultuser.svg'}
                  alt="User Dropdown"
                  className="user-dropdown"
                />
              </span>
              <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in">
                <span className="dropdown-item clickable-text text-center">
                  <img
                    src={process.env.PUBLIC_URL + '/img/defaultuser.svg'}
                    alt="User Dropdown"
                    className="user-dropdown"
                  />
                </span>
                <span className="dropdown-item clickable  text-center">
                  {displayName}
                </span>
                <hr />
                {userBalanceOptions}
                {userAdminOptions}
                {userPickUpOptions}
                {userReturnsOptions}
                {userCallCenterOptions}
              </div>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
