import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export default function AlertMessage(props) {
  return (
    <div className="my-3 w-100">
      <Alert
        className="p-3 m-0 align-items-center justify-content-center"
        severity={props.type || 'success'}
        action={
          props.onClose && (
            <IconButton
              className="close"
              size={props.size || 'small'}
              aria-label="close"
              onClick={props.onClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        {...props}>
        <AlertTitle className="mx-0 my-3">{props.title}</AlertTitle>
        {props.children}
      </Alert>
    </div>
  );
}
