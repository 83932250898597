const axios = require('axios');
const config = require('../config/config');
const qs = require('qs');

/* APIs ENDPOINTS */
const SESSION_STORAGE_SESSION = `${window.location.hostname}`;
const SERVER_URL = config.backend.apiUrl;
const LOGIN_URL = SERVER_URL + '/auth/login';
const CHANGE_PASSWORD_URL = SERVER_URL + '/auth/change-password';
const RESET_URL = SERVER_URL + '/auth/reset';
const CURRENT_USER_URL = SERVER_URL + '/user/me';
const USERS_URL = SERVER_URL + '/user';
const USERS_INDEX_URL = SERVER_URL + '/user/{id}';
const REQUESTS_URL = SERVER_URL + '/request';
const REQUESTS_URL_LABELS = SERVER_URL + '/request/labels/{id}';
const REQUESTS_URL_RETURN_LABELS = SERVER_URL + '/request/return-labels/{id}';
const REQUEST_NEW_BACKWARD_DELIVERY_URL =
  SERVER_URL + '/request/create-return-delivery/{id}';
const REQUESTS_LINK_LABELS = SERVER_URL + '/request/labels/{id}/link/{idLabel}';
const REQUESTS_UNLINK_LABELS = SERVER_URL + '/request/labels/{id}/unlink';
const REQUESTS_LINK_RETURN_LABELS =
  SERVER_URL + '/request/return-labels/{id}/link/{idLabel}';
const REQUESTS_UNLINK_RETURN_LABELS =
  SERVER_URL + '/request/return-labels/{id}/unlink';
const REQUESTS_URL_FILE = SERVER_URL + '/request/file/{id}';
const REQUESTS_PICKING_URL = SERVER_URL + '/request/picking';
const REQUESTS_INDEX_URL = SERVER_URL + '/request/{id}';
const REQUESTS_IMPORT_URL = SERVER_URL + '/request/import';
const REQUESTS_EXPORT_URL = SERVER_URL + '/request/export';
const REQUESTS_EXPORT_SAP_URL = SERVER_URL + '/request/export-SAP';
const REQUESTS_EXPORT_BY_BOXSIZE_URL = SERVER_URL + '/request/export-boxes';
const REQUESTS_EXPORT_TOTAL_PRODUCTS_URL =
  SERVER_URL + '/request/export-total-products';
const REQUESTS_OPEN_WS_URL = SERVER_URL + '/request/ws/open';
const REQUESTS_CLOSE_WS_URL = SERVER_URL + '/request/ws/close';
const PRODUCTS_URL = SERVER_URL + '/product';
const PRODUCTS_INDEX_URL = SERVER_URL + '/product/{id}';
const PRODUCTS_IMPORT_URL = SERVER_URL + '/product/import';
const PRODUCTS_EXPORT_URL = SERVER_URL + '/product/export';
const POSTALCODES_URL = SERVER_URL + '/postalCode';
const POSTALCODES_INDEX_URL = SERVER_URL + '/postalCode/{id}';
const POSTALCODES_IMPORT_URL = SERVER_URL + '/postalCode/import';
const POSTALCODES_EXPORT_URL = SERVER_URL + '/postalCode/export';
const PROVIDERS_URL = SERVER_URL + '/provider';
const PROVIDERS_INDEX_URL = SERVER_URL + '/provider/{id}';
const PROVIDERS_IMPORT_URL = SERVER_URL + '/provider/import';
const REQUESTS_SEND_EMAIL_URL = SERVER_URL + '/request/sendEmail';
const COUNT_REQUESTS_PROCESSED_URL =
  SERVER_URL + '/request/count-requests-processed';
const REQUESTS_INVOICE_URL = SERVER_URL + '/request/requests-invoice';
const REQUEST_INVOICE_URL = SERVER_URL + '/request/request-invoice/{id}';
const REQUEST_RETURN_INVOICE_URL =
  SERVER_URL + '/request/request-return-invoice/{id}';
const REQUESTS_PICKUP_NOTE_URL = SERVER_URL + '/request/{id}/pickup-note';
const REQUEST_EXPORT_YEAR_REQUEST_REPORT =
  SERVER_URL + '/request/export-year-request-report';
const REQUEST_EXPORT_12_MONTHS_SENT_TO_PROVIDER_COUNT =
  SERVER_URL + '/request/export-12-months-sent-to-provider-count';
const INVOICES_URL = SERVER_URL + '/invoice';
const INVOICES_INDEX_URL = SERVER_URL + '/invoice/{id}';
const LOGS_URL = SERVER_URL + '/log';
const RETURNS_URL = SERVER_URL + '/return';
const GIFTS_URL = SERVER_URL + '/gift';
const LATAM_REPORT_URL = SERVER_URL + '/latam-report';

function logout() {
  sessionStorage.clear(SESSION_STORAGE_SESSION);
}

function getUsers() {
  return axios.get(USERS_URL, buildHeaders());
}

function getUsersCount(parameters) {
  return axios.get(
    `${USERS_URL + '/count'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

async function checkQueryToAuth() {
  let queryToken = new URLSearchParams(window.location.search).get('token');
  // if there is a token in URL
  if (queryToken) {
    sessionStorage.setItem(
      `${window.location.hostname}`,
      JSON.stringify({ token: queryToken }),
    );
    //get user full data
    let decodeToken = parseJwt(queryToken);
    if (decodeToken.userId) {
      let user = await getUser(decodeToken.userId);
      if (user.data) {
        var sessionData = {
          ...user.data,
          token: queryToken,
        };
      }
      // update session storage
      sessionStorage.setItem(
        `${window.location.hostname}`,
        JSON.stringify(sessionData),
      );
    }
    // redirect to home to clear de token from query (remove ':3008' after testing)
    window.location.replace(`http://${window.location.hostname}`);
  }
}

function isAuthenticated() {
  checkQueryToAuth();

  return (
    sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token != null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token != ''
  );
}

function isAdmin() {
  return (
    sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role
      .userRole === 'ADMIN'
  );
}

function isSupervisor() {
  return (
    sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role
      .userRole === 'SUPERVISOR'
  );
}

function isPickupPoint() {
  return (
    sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role
      .userRole === 'PICKUP'
  );
}

function isManager() {
  return (
    sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role
      .userRole === 'MANAGER'
  );
}

function buildHeaders() {
  if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
    return {
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token,
      },
    };
  } else {
    return null;
  }
}
function login(payload) {
  return axios
    .post(LOGIN_URL, {
      username: payload.username,
      password: payload.password,
      key: payload.key,
    })
    .then(function (response) {
      const token = response.data.token;
      sessionStorage.setItem(
        SESSION_STORAGE_SESSION,
        JSON.stringify({ token: response.data.token }),
      );
      getCurrentUser().then(function (userResponse) {
        let userData = userResponse.data;
        userData.token = token;
        sessionStorage.setItem(
          SESSION_STORAGE_SESSION,
          JSON.stringify(userData),
        );
        return userData;
      });
    });
}

function resetPassword(payload) {
  return axios
    .post(RESET_URL, {
      username: payload.email,
    })
    .then(function (response) {
      return response;
    });
}

function changePassword(payload) {
  return axios
    .post(
      CHANGE_PASSWORD_URL,
      {
        username: payload.username,
        password: payload.password,
        newPassword: payload.newPassword,
      },
      buildHeaders(),
    )
    .then(function (response) {
      return response;
    });
}

function getCurrentUser() {
  return axios.get(CURRENT_USER_URL, buildHeaders());
}

function getUser(userId) {
  return axios.get(USERS_INDEX_URL.replace('{id}', userId), buildHeaders());
}

function deleteUser(UserId) {
  return axios.delete(USERS_INDEX_URL.replace('{id}', UserId), buildHeaders());
}

function postUser(payload) {
  return axios.post(USERS_URL, payload, buildHeaders());
}

function patchUser(userId, payload) {
  return axios.patch(
    USERS_INDEX_URL.replace('{id}', userId),
    payload,
    buildHeaders(),
  );
}

function getRequest(requestId) {
  return axios.get(
    REQUESTS_INDEX_URL.replace('{id}', requestId),
    buildHeaders(),
  );
}

function postSendEmail(payload) {
  return axios.post(REQUESTS_SEND_EMAIL_URL, payload, buildHeaders());
}

function getRequests(parameters = [], isReport = false) {
  return axios.get(
    `${REQUESTS_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}&is_report=${isReport}`,
    buildHeaders(),
  );
}

function getRequestsLabels(id) {
  let headers = buildHeaders();
  headers.responseType = 'blob';
  return axios.get(REQUESTS_URL_LABELS.replace('{id}', id), headers);
}

function getReturnRequestsLabels(id) {
  let headers = buildHeaders();
  headers.responseType = 'blob';
  return axios.get(REQUESTS_URL_RETURN_LABELS.replace('{id}', id), headers);
}

function getLogs(parameters = []) {
  return axios.get(
    `${LOGS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getLogsCount(parameters) {
  return axios.get(
    `${LOGS_URL + '/count'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getRequestsPicking(parameters = []) {
  return axios.get(
    `${REQUESTS_PICKING_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function deleteRequest(RequestId) {
  return axios.delete(
    REQUESTS_INDEX_URL.replace('{id}', RequestId),
    buildHeaders(),
  );
}

function postRequest(payload) {
  return axios.post(REQUESTS_URL, payload, buildHeaders());
}

function patchRequest(requestId, payload) {
  let headers = buildHeaders();
  headers.responseType = 'blob';
  return axios.patch(
    REQUESTS_INDEX_URL.replace('{id}', requestId),
    payload,
    headers,
  );
}

function createReturnRequestDelivery(requestId) {
  let headers = buildHeaders();
  headers.responseType = 'blob';
  return axios.get(
    REQUEST_NEW_BACKWARD_DELIVERY_URL.replace('{id}', requestId),
    headers,
  );
}

function postFile(requestId, newFile) {
  const data = new FormData();
  data.append('file', newFile);
  return axios.post(
    REQUESTS_URL_FILE.replace('{id}', requestId),
    data,
    buildHeaders(),
  );
}

function getRequestFileURL(requestId) {
  return axios.get(
    REQUESTS_URL_FILE.replace('{id}', requestId),
    buildHeaders(),
  );
}

function getRequestsCount(parameters) {
  return axios.get(
    `${REQUESTS_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function importRequests(payload, verifyDeliveryNro) {
  const data = new FormData();
  data.append('file', payload);
  data.append('verifyDeliveryNro', verifyDeliveryNro);
  return axios.post(REQUESTS_IMPORT_URL, data, buildHeaders());
}

function getExportRequests(parameters = [], isReport = false) {
  return axios.get(
    `${REQUESTS_EXPORT_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}&is_report=${isReport}`,
    buildHeaders(),
  );
}

function getExportSapRequests(parameters = [], isReport = false) {
  return axios.get(
    `${REQUESTS_EXPORT_SAP_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}&is_report=${isReport}`,
    buildHeaders(),
  );
}

function getExportBoxesBySize(parameters = []) {
  return axios.get(
    `${REQUESTS_EXPORT_BY_BOXSIZE_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getExportTotalProducts(parameters = []) {
  return axios.get(
    `${REQUESTS_EXPORT_TOTAL_PRODUCTS_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getExportProducts(parameters = []) {
  return axios.get(
    `${PRODUCTS_EXPORT_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getExportYearRequestReport(parameters = []) {
  return axios.get(`${REQUEST_EXPORT_YEAR_REQUEST_REPORT}`, buildHeaders());
}

function get12MonthsSentToProviderCount(parameters = []) {
  return axios.get(
    `${REQUEST_EXPORT_12_MONTHS_SENT_TO_PROVIDER_COUNT}`,
    buildHeaders(),
  );
}

function getProduct(productId) {
  return axios.get(
    PRODUCTS_INDEX_URL.replace('{id}', productId),
    buildHeaders(),
  );
}

function getProducts(parameters = []) {
  return axios.get(
    `${PRODUCTS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function deleteProduct(ProductId) {
  return axios.delete(
    PRODUCTS_INDEX_URL.replace('{id}', ProductId),
    buildHeaders(),
  );
}

function postProduct(payload) {
  return axios.post(PRODUCTS_URL, payload, buildHeaders());
}

function patchProduct(productId, payload) {
  return axios.patch(
    PRODUCTS_INDEX_URL.replace('{id}', productId),
    payload,
    buildHeaders(),
  );
}

function getProductsCount(parameters) {
  return axios.get(
    `${PRODUCTS_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function importProducts(payload) {
  const data = new FormData();
  data.append('file', payload);
  return axios.post(PRODUCTS_IMPORT_URL, data, buildHeaders());
}

function getPostalCode(postalCodeId) {
  return axios.get(
    POSTALCODES_INDEX_URL.replace('{id}', postalCodeId),
    buildHeaders(),
  );
}

function getPostalCodes(parameters = []) {
  return axios.get(
    `${POSTALCODES_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getExportPostalCodes(parameters = []) {
  return axios.get(
    `${POSTALCODES_EXPORT_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function deletePostalCode(PostalCodeId) {
  return axios.delete(
    POSTALCODES_INDEX_URL.replace('{id}', PostalCodeId),
    buildHeaders(),
  );
}

function postPostalCode(payload) {
  return axios.post(POSTALCODES_URL, payload, buildHeaders());
}

function patchPostalCode(postalCodeId, payload) {
  return axios.patch(
    POSTALCODES_INDEX_URL.replace('{id}', postalCodeId),
    payload,
    buildHeaders(),
  );
}

function getPostalCodesCount(parameters) {
  return axios.get(
    `${POSTALCODES_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function importPostalCodes(payload) {
  const data = new FormData();
  data.append('file', payload);
  return axios.post(POSTALCODES_IMPORT_URL, data, buildHeaders());
}

function getProvider(providerId) {
  return axios.get(
    PROVIDERS_INDEX_URL.replace('{id}', providerId),
    buildHeaders(),
  );
}

function getProviders(parameters = []) {
  return axios.get(
    `${PROVIDERS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function deleteProvider(ProviderId) {
  return axios.delete(
    PROVIDERS_INDEX_URL.replace('{id}', ProviderId),
    buildHeaders(),
  );
}

function postProvider(payload) {
  return axios.post(PROVIDERS_URL, payload, buildHeaders());
}

function patchProvider(providerId, payload) {
  return axios.patch(
    PROVIDERS_INDEX_URL.replace('{id}', providerId),
    payload,
    buildHeaders(),
  );
}

function getProvidersCount(parameters) {
  return axios.get(
    `${PROVIDERS_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function importProviders(payload) {
  const data = new FormData();
  data.append('file', payload);
  return axios.post(PROVIDERS_IMPORT_URL, data, buildHeaders());
}

function getCountRequestProcessed(parameters = []) {
  return axios.get(
    `${COUNT_REQUESTS_PROCESSED_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getRequestsInvoice(parameters = []) {
  return axios.get(
    `${REQUESTS_INVOICE_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getRequestInvoice(requestId) {
  return axios.get(
    REQUEST_INVOICE_URL.replace('{id}', requestId),
    buildHeaders(),
  );
}

function getReturnRequestInvoice(requestId) {
  return axios.get(
    REQUEST_RETURN_INVOICE_URL.replace('{id}', requestId),
    buildHeaders(),
  );
}

function postReturn(payload) {
  return axios.post(RETURNS_URL + '/create', payload, buildHeaders());
}

function getReturnsByRequestId(requestId) {
  return axios.get(RETURNS_URL + `/request/${requestId}`, buildHeaders());
}

function getReturns(parameters = []) {
  return axios.get(
    `${RETURNS_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function patchReturnRequest(returnRequestId, payload) {
  return axios.patch(
    RETURNS_URL + `/${returnRequestId}`,
    payload,
    buildHeaders(),
  );
}

function getCountReturnsProcessed(parameters = []) {
  return axios.get(
    `${RETURNS_URL}/count-return-processed?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getCountReturnsProcessedByProvider(parameters = []) {
  return axios.get(
    `${RETURNS_URL}/count-return-processed/providers?${qs.stringify(
      parameters,
      {
        skipNulls: true,
      },
    )}`,
    buildHeaders(),
  );
}

function postGiftDetails(payload) {
  return axios.post(GIFTS_URL, payload, buildHeaders());
}

function getGiftDetails(id) {
  return axios.get(GIFTS_URL + `/${id}`, buildHeaders());
}

function getGiftConfigs(parameters = []) {
  return axios.get(
    `${GIFTS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getGiftConfigsCount(parameters) {
  return axios.get(
    `${GIFTS_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function patchGiftDetails(id, payload) {
  return axios.patch(GIFTS_URL + `/${id}`, payload, buildHeaders());
}

function deleteGiftConfig(id) {
  return axios.delete(GIFTS_URL + `/${id}`, buildHeaders());
}

function getPotencialGifts(id) {
  return axios.get(GIFTS_URL + `/${id}/potencials`, buildHeaders());
}

function getQualifiedCustomersByGift(id) {
  return axios.get(GIFTS_URL + `/${id}/qualified`, buildHeaders());
}

function getRequestByCaseNumber(caseNumber) {
  return axios.get(
    REQUESTS_URL + `/returnRequests/${caseNumber}`,
    buildHeaders(),
  );
}

function linkIdLabelsOfAPI(requestId, labelIdToLink) {
  return axios.get(
    REQUESTS_LINK_LABELS.replace('{id}', requestId).replace(
      '{idLabel}',
      labelIdToLink,
    ),
    buildHeaders(),
  );
}

function unlinkIdLabelsOfAPI(requestId) {
  return axios.get(
    REQUESTS_UNLINK_LABELS.replace('{id}', requestId),
    buildHeaders(),
  );
}

function linkReturnIdLabelsOfAPI(requestId, labelIdToLink) {
  return axios.get(
    REQUESTS_LINK_RETURN_LABELS.replace('{id}', requestId).replace(
      '{idLabel}',
      labelIdToLink,
    ),
    buildHeaders(),
  );
}

function unlinkReturnIdLabelsOfAPI(requestId) {
  return axios.get(
    REQUESTS_UNLINK_RETURN_LABELS.replace('{id}', requestId),
    buildHeaders(),
  );
}

function getLatamReports(parameters = []) {
  return axios.get(
    `${LATAM_REPORT_URL}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getOtherCountryReports(url, parameters = []) {
  return axios.get(
    `${url}/latam-report?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function generateLatamReports(parameters = []) {
  return axios.get(
    `${LATAM_REPORT_URL}/generate?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

export default {
  SERVER_URL,
  logout,
  login,
  resetPassword,
  getCurrentUser,
  getUsers,
  getUsersCount,
  isAuthenticated,
  getUser,
  deleteUser,
  postUser,
  patchUser,
  changePassword,
  getRequest,
  getRequestsPicking,
  deleteRequest,
  postRequest,
  patchRequest,
  getExportRequests,
  getExportSapRequests,
  getExportBoxesBySize,
  getRequests,
  getRequestsCount,
  postSendEmail,
  importRequests,
  getProduct,
  deleteProduct,
  postProduct,
  patchProduct,
  getProducts,
  getExportProducts,
  getExportYearRequestReport,
  get12MonthsSentToProviderCount,
  getProductsCount,
  importProducts,
  getPostalCode,
  deletePostalCode,
  postPostalCode,
  patchPostalCode,
  getPostalCodes,
  getPostalCodesCount,
  importPostalCodes,
  getExportPostalCodes,
  getProvider,
  deleteProvider,
  postProvider,
  patchProvider,
  getProviders,
  getProvidersCount,
  importProviders,
  getCountRequestProcessed,
  getRequestsInvoice,
  getRequestInvoice,
  isAdmin,
  isSupervisor,
  getLogs,
  getLogsCount,
  getRequestsLabels,
  getExportTotalProducts,
  isPickupPoint,
  getRequestFileURL,
  postFile,
  isManager,
  postReturn,
  getReturnsByRequestId,
  getReturns,
  patchReturnRequest,
  getCountReturnsProcessed,
  getCountReturnsProcessedByProvider,
  getGiftDetails,
  patchGiftDetails,
  postGiftDetails,
  getGiftConfigsCount,
  getGiftConfigs,
  deleteGiftConfig,
  getPotencialGifts,
  getRequestByCaseNumber,
  getReturnRequestsLabels,
  getReturnRequestInvoice,
  createReturnRequestDelivery,
  linkIdLabelsOfAPI,
  unlinkIdLabelsOfAPI,
  linkReturnIdLabelsOfAPI,
  unlinkReturnIdLabelsOfAPI,
  getQualifiedCustomersByGift,
  getLatamReports,
  generateLatamReports,
  getOtherCountryReports,
};
