import React, { useState, useEffect } from 'react';
import './Picking.css';
import NavBar from '../common/NavBar';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import { useAlert } from 'react-alert';
import Tooltip from '../common/Tooltip';
import ProductRequestValidation from '../requests/productRequestValidation';
import formatter from '../../utils/formatter';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import AlertMessage from '../common/AlertMessage';

const initialState = {
  request: {
    order_number: '',
    ship_to_name: '',
    ship_to_street: '',
    import_date: '',
    weight: '',
    provider: {
      name: '',
    },
    productRequestRows: [],
    state_picking: '',
  },
};

export default function Picking() {
  const [state, setState] = useState(initialState);
  const [box, setBox] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [isPickedModal, setIsPickedModal] = useState(false);
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    Modal.setAppElement('body');
    getBox();
  }, []);

  useEffect(() => {
    if (orderNumber.length >= 6) {
      let currOrderNumber = orderNumber;
      setTimeout(function () {
        if (currOrderNumber == orderNumber)
          getRequestWithOrderNumber(orderNumber);
      }, 500);
    }
  }, [orderNumber]);

  const isOrderPicked = async order_number => {
    let filters = {
      where: [
        {
          order_number: order_number,
        },
        {
          internalIdProvider: order_number,
        },
      ],
    };
    let { data } = await apiHelper.getRequests(filters);
    if (data[0]?.state_picking === 'PV') {
      setIsPickedModal(true);
    }
  };

  const getBox = async () => {
    let boxResponse = [];

    try {
      boxResponse = await apiHelper.getProducts({
        where: { category: 'box' },
      });

      setBox(oldState => ({
        ...oldState,
        boxOptions: boxResponse.data,
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const getRequestWithOrderNumber = order_number => {
    let filters = {
      where: [
        {
          order_number: order_number,
        },
        {
          internalIdProvider: order_number,
        },
      ],
    };

    apiHelper
      .getRequestsPicking(filters)
      .then(requests => {
        if (requests.data.length > 0) {
          let requestToPicking = requests.data.find(
            request =>
              request.order_number == order_number ||
              request.internalIdProvider.includes(order_number),
          );
          if (requests.data.length > 1) {
            let importDateRequestOne = requestToPicking.import_date;
            {
              requests.data.map(request => {
                let import_date = request.import_date;
                if (
                  moment(import_date).isAfter(importDateRequestOne) &&
                  (request.order_number == order_number ||
                    request.internalIdProvider.includes(order_number))
                ) {
                  requestToPicking = request;
                }
              });
            }
          }
          if (requestToPicking) {
            setOrderNumber('');
            if (requestToPicking.state_picking === 'PV') {
              setIsPickedModal(true);
            }
            setState(oldState => ({
              ...oldState,
              request: requestToPicking,
            }));
          }
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400 || statusCode === 422 || statusCode === 204) {
            alert.error('Error al cargar datos de los pedidos');
          } else if (error.request) {
            alert.error('Sin respuesta del Servidor');
          } else {
            alert.error(error.message);
          }
        }
      });
  };

  const handleChange = e => {
    setOrderNumber(e.target.value.toString().trim());
  };

  const closeIsPickedModal = () => {
    setIsPickedModal(false);
  };

  const renderPick = () => {
    return (
      <>
        {state.request.order_number.length > 0 && (
          <>
            <div className="col-12">
              {state.request.cancelled && (
                <AlertMessage
                  type="error"
                  title="Pedido cancelado, no se marcará como expedido"
                />
              )}
            </div>
            <div className="col-6 ">
              <div className="text-picking-titles">
                Numero de pedido:{' '}
                <span className="text-picking">
                  {state.request.order_number}
                </span>
              </div>
              <div className="text-picking-titles">
                Nombre:{' '}
                <span className="text-picking">
                  {' '}
                  {state.request.ship_to_name}
                </span>
              </div>
              <div className="text-picking-titles">
                Dirección:{' '}
                <span className="text-picking">
                  {state.request.ship_to_street}{' '}
                </span>
              </div>
              <div className="text-picking-titles">
                Fecha de importación al sistema:{' '}
                <span className="text-picking">
                  {formatter.formatDate(state.request.import_date)}{' '}
                </span>
              </div>
              <div className="text-picking-titles">
                Peso:{' '}
                <span className="text-picking">{state.request.weight} </span>
              </div>
            </div>
            <div className="col-6 ">
              <h5 className="text-picking-titles">Validaciones:</h5>
              <ProductRequestValidation
                render={(
                  addressValidation,
                  postalCodeValidation,
                  productsWeightValidation,
                  weightValidation,
                  sentToProviderValidation,
                ) => {
                  return (
                    <div>
                      <div className="form-group ">
                        <span
                          className={
                            'fa fa-home ml-3 ' +
                            addressValidation(state.request).class
                          }
                          data-tip
                          data-for={state.request.id + 'address'}>
                          <Tooltip id={state.request.id + 'address'} />
                          {addressValidation(state.request).text}
                        </span>
                      </div>
                      <div className="form-group ">
                        <span
                          className={
                            'fa fa-mail-bulk ml-3 ' +
                            postalCodeValidation(state.request).class
                          }
                          data-tip
                          data-for={state.request.id + 'postalCode'}>
                          <Tooltip id={state.request.id + 'postalCode'} />
                          {postalCodeValidation(state.request).text}
                        </span>
                      </div>
                      <div className="form-group ">
                        <span
                          className={
                            'fa fa-box ml-3 ' +
                            productsWeightValidation(state.request).class
                          }
                          data-tip
                          data-for={state.request.id + 'productsWeight'}>
                          <Tooltip id={state.request.id + 'productsWeight'} />
                          {productsWeightValidation(state.request).text}
                        </span>
                      </div>
                      <div className="form-group">
                        <span
                          className={
                            'fa fa-weight ml-3 ' +
                            weightValidation(state.request, box.boxOptions)
                              .class
                          }
                          data-tip
                          data-for={state.request.id + 'weight'}>
                          <Tooltip id={state.request.id + 'weight'} />
                          {weightValidation(state.request, box.boxOptions).text}
                        </span>
                      </div>
                      <div className="form-group">
                        <span
                          className={
                            'fa fa-envelope ml-3 ' +
                            sentToProviderValidation(state.request).class
                          }
                          data-tip
                          data-for={state.request.id + 'sent_to_provider'}>
                          <Tooltip id={state.request.id + 'sent_to_provider'} />
                          {sentToProviderValidation(state.request).text}
                        </span>
                      </div>
                    </div>
                  );
                }}
              />
              {state.request.provider ? (
                <div className="text-picking-titles">
                  Operador logístico:{' '}
                  <span className="text-picking">
                    {state.request.provider.name}{' '}
                  </span>
                </div>
              ) : (
                <div className="text-picking-titles">
                  <span className="text-picking">
                    {' '}
                    No tiene operador logistico asignado
                  </span>
                </div>
              )}
            </div>
          </>
        )}
        {state.request.order_number.length == 0 && (
          <>
            <div className="col-12">
              <div className="text-picking-titles">
                Sin datos para el pedido{' '}
                <span className="text-picking">{orderNumber}</span>
              </div>
            </div>
          </>
        )}
        <Modal
          isOpen={isPickedModal}
          className="modal-dialog fadeInDown"
          contentLabel="Error de Validacion">
          <div className="modal-content text-center">
            <div className="modal-header text-center">
              <h2 className="import-title">Atención</h2>
              <button
                type="button"
                className="close"
                onClick={() => closeIsPickedModal()}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div>
                <AlertMessage
                  type="warning"
                  title="Verifique que no se trate de un pedido duplicado.">
                  {`El pedido ${state.request.order_number} ya fue
                    procesado para su expedición con anterioridad.`}
                </AlertMessage>
              </div>
              <button
                className="button-delete-modal btn btn-danger some-top-air"
                type="button"
                onClick={() => closeIsPickedModal()}>
                {' '}
                Cerrar
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <div className="d-flex flex-column h-100">
      <NavBar history={history} />
      <div className="container">
        <h1 className="text-position-title text-center">
          <i className="fas fa-cubes"></i>
          <u> Ingrese un número de pedido</u>
        </h1>

        <div className="d-flex flex-column h-100">
          <div className="form-group">
            <input
              type="text"
              name="order_number"
              className="form-control"
              placeholder="No definido"
              value={orderNumber}
              onChange={handleChange}
              required
            />
          </div>

          <div className="row">{renderPick()}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
