import { Link } from 'react-router-dom';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import React from 'react';
import Pagination from '../common/Pagination';
import Modal from 'react-modal';
import apiHelpers from '../../utils/apiHelpers';
import ReactLoading from 'react-loading';
import { CSVLink } from 'react-csv';
import { customersQualifiedForGift } from '../reports/ExportReportHelpers';
import formatter from '../../utils/formatter';

export class GiftConfigs extends React.Component {
  download = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      giftConfigs: [],
      modalIsOpen: false,
      count: 0,
      pageSize: 25,
      currentPage: 1,
      filters: null,
      openPotentialGiftsModal: false,
      potentialGiftsModalData: null,
      giftsLoading: false,
      potentialGiftsModalLoading: false,
      loadingExportClients: false,
      CSVdata: '',
      CSVtitle: '',
    };
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChangePage(pageOfItems) {
    this.setState({ currentPage: pageOfItems }, () => {
      this.getConfigs();
    });
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters, currentPage: 1 }, () => {
      this.getConfigs();
    });
  }

  getNumberOfPages() {
    return Math.ceil(this.state.count / this.state.pageSize);
  }

  async getConfigs() {
    this.setState({ giftsLoading: true });
    const { filters, currentPage, pageSize } = this.state;

    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [response, giftConfigsCount] = await Promise.all([
        await apiHelpers.getGiftConfigs(filtersWithPaging),
        await apiHelpers.getGiftConfigsCount(),
      ]);
      this.setState({
        giftConfigs: response.data,
        count: giftConfigsCount.data.count,
        giftsLoading: false,
      });
    } catch (e) {
      console.log(e);
      console.log('Error loading giftConfigs');
    }
  }

  componentDidMount() {
    this.getConfigs();
  }

  async handleViewPotencialGiftRecivers(event) {
    this.setState({ potentialGiftsModalLoading: true });
    const id = event.target.id;
    let currentConfig = this.state.giftConfigs.find(
      config => config.id === Number(id),
    );
    let qualifiedCustomersResponse =
      await apiHelpers.getQualifiedCustomersByGift(id);
    let qualifiedClients =
      qualifiedCustomersResponse.data.giftQualifiedCustomers;
    this.setState(prev => {
      return {
        ...prev,
        openPotentialGiftsModal: id,
        potentialGiftsModalData:
          qualifiedClients.length > 0
            ? qualifiedClients
            : currentConfig.purchaseHistoryInMonths === 0
            ? 'Sin historial'
            : 'Sin alcance',
        potentialGiftsModalLoading: false,
      };
    });
  }

  async exportClients() {
    this.setState({ loadingExportClients: true });
    let csv = await customersQualifiedForGift(
      this.state.potentialGiftsModalData,
    );
    this.setState(
      {
        loadingExportClients: false,
        CSVdata: csv,
        CSVtitle: `Reporte de clientes - configuración #${this.state.openPotentialGiftsModal}.csv`,
      },
      () => this.download.current.link.click(),
    );
  }

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />

        <div className="container">
          <h1 className="text-nuskin row ">
            <div className=" title col-lg-9 col-sm-12">
              <i className="fas fa-gift mt-3"></i>{' '}
              <span>Listado de configuraciones para regalos</span>
            </div>
            <div className="row margin-import col-lg-3 col-sm-12 ">
              <div className=" col-sm-12 col-lg-12">
                <Link to={`${process.env.PUBLIC_URL}/gifts/new`}>
                  <button
                    type="button"
                    className="btn col-sm-12 btn-success pull-right my-4">
                    Agregar <i className="fas fa-plus-square"></i>
                  </button>
                </Link>
              </div>
            </div>
          </h1>

          <div className="d-flex justify-content-center">
            <div className="table-responsive col-sm-12 col-lg-9">
              {this.state.giftsLoading ? (
                <div className="d-flex flex-column align-items-center m-5">
                  <h4 className="m-4" style={{ color: '#55CDE4' }}>
                    Cargando configuraciones
                  </h4>
                  <ReactLoading
                    type={'spin'}
                    color={'#55CDE4'}
                    height={100}
                    width={100}
                  />
                </div>
              ) : (
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        Configuración #
                      </th>
                      <th scope="col" className="text-center">
                        Estado
                      </th>
                      <th scope="col" className="text-center">
                        Vigencia
                      </th>
                      <th scope="col" className="text-center">
                        Regalos potenciales
                      </th>
                      <th scope="col" className="text-center">
                        Clientes alcanzados
                      </th>
                      <th scope="col" className="text-center">
                        Editar
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.giftConfigs.map(giftConfig => {
                      const actionColumn = (
                        <td>
                          <div className="text-center">
                            <Link
                              to={`${process.env.PUBLIC_URL}/gifts/${giftConfig.id}`}>
                              <button
                                type="button"
                                className="btn btn-primary btn-circle">
                                <span className="fa fa-edit"></span>
                              </button>
                            </Link>
                          </div>
                        </td>
                      );
                      const calculateGiftsColumn = (
                        <td>
                          <div className="text-center">
                            {this.state.loadingPromoPreview ===
                            giftConfig.id ? (
                              <div
                                className="spinner-border text-warning"
                                role="status"
                                id={giftConfig.id}>
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <button
                                type="button"
                                id={giftConfig.id}
                                onClick={this.handleViewPotencialGiftRecivers.bind(
                                  this,
                                )}
                                className="btn btn-outline-warning btn-circle">
                                <span
                                  id={giftConfig.id}
                                  className={'fas fa-search'}></span>
                              </button>
                            )}
                          </div>
                        </td>
                      );
                      return (
                        <tr key={giftConfig.id}>
                          <td className="text-center"># {giftConfig.id}</td>
                          <td
                            className={`text-center ${
                              giftConfig.active ? 'text-success' : 'text-danger'
                            }`}>
                            {giftConfig.active ? (
                              <i className="fas fa-circle"></i>
                            ) : (
                              <i className="far fa-circle"></i>
                            )}
                          </td>
                          <td className={`text-center`}>
                            {formatter.formatDate(giftConfig.min_sale_date) +
                              ' al ' +
                              formatter.formatDate(giftConfig.max_sale_date)}
                          </td>
                          <td
                            className={`text-center font-weight-light ${
                              giftConfig.purchaseHistoryInMonths < 1
                                ? 'font-italic'
                                : giftConfig.giftQualified < 1
                                ? ' text-danger'
                                : 'font-weight-bold'
                            }`}>
                            {giftConfig.purchaseHistoryInMonths < 1
                              ? 'No es posible calcular regalos'
                              : giftConfig.giftQualified < 1
                              ? 'Sin alcance'
                              : giftConfig.giftQualified}
                          </td>
                          {calculateGiftsColumn}
                          {actionColumn}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {this.state.giftConfigs.length < 1 && (
                <div className="row col-sm-12 d-flex justify-content-center p-4">
                  <h2>Sin configuraciones</h2>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.giftConfigs.length > 0 && (
          <div className="text-center">
            <Pagination
              onChangePage={this.handleChangePage.bind(this)}
              currentPage={this.state.currentPage}
              totalPages={this.getNumberOfPages()}
            />
          </div>
        )}
        <Footer />

        <Modal
          isOpen={this.state.openPotentialGiftsModal}
          className="fadeInDown modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              {this.state.potentialGiftsModalData === 'Sin historial' ? (
                <h3 className="my-4 text-warning text-center">
                  Si no se indica ningún historial de compra, todos los clientes
                  que adquieran los productos necesarios recibirán el regalo
                </h3>
              ) : this.state.potentialGiftsModalData &&
                this.state.potentialGiftsModalData[0].ship_to_name?.length >
                  0 ? (
                <h3>Clientes que califican para esta configuración</h3>
              ) : (
                <h3 className="my-4 text-danger text-center">
                  Ningun cliente califica para esta configuración
                </h3>
              )}
            </div>
            {this.state.potentialGiftsModalLoading ? (
              <div className=" d-flex justify-content-center align-items-center">
                <ReactLoading
                  className="body-login m-4"
                  type={'spin'}
                  color={'#55CDE4'}
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              this.state.potentialGiftsModalData &&
              this.state.potentialGiftsModalData[0].ship_to_name && (
                <div className="modal-body">
                  <h4 className="d-flex justify-content-center align-items-center">
                    Potenciales regalos de la configuración:{' '}
                    <h3 className="text-center border border-info rounded p-2 m-2">
                      {this.state.potentialGiftsModalData?.length}
                    </h3>
                  </h4>
                  <div className="d-flex flex-column align-items-center w-100">
                    <div className="row justify-content-end pb-3 col-sm-12">
                      <CSVLink
                        ref={this.download}
                        data={this.state.CSVdata}
                        filename={this.state.CSVtitle}
                        className="hidden"></CSVLink>
                      {!this.state.loadingExportClients ? (
                        <button
                          type="button"
                          className="btn btn-success export-button col-sm-12 col-lg-3"
                          disabled={this.state.loadingExportClients}
                          onClick={this.exportClients.bind(this)}>
                          Exportar clientes
                          <i className="fas fa-file-export pl-1"></i>
                        </button>
                      ) : (
                        <button
                          className="btn btn-success export-button col-sm-12 col-lg-3"
                          type="button"
                          disabled>
                          Exportando reporte...
                          <span
                            className="spinner-border spinner-border-sm m-1"
                            role="status"
                            aria-hidden="true"></span>
                        </button>
                      )}
                    </div>
                    <div className="row col-sm-12">
                      <span className="row col-sm-3 d-flex justify-content-center font-weight-bold">
                        Id Cliente
                      </span>
                      <span className="row col-sm-6 d-flex justify-content-center font-weight-bold">
                        Nombres
                      </span>
                      <span className="row col-sm-3 d-flex justify-content-center font-weight-bold"></span>
                    </div>
                    {this.state.potentialGiftsModalData?.map(client => {
                      return (
                        <div className="row d-flex flex-column flex-md-row align-items-center col-sm-12 my-2">
                          <ul className="list-group list-group-horizontal col-sm-12">
                            <li className="list-group-item col-sm-3 ">
                              {client.sold_to_id}
                            </li>
                            <li className="list-group-item col-sm-6 ">
                              {client.ship_to_name}
                            </li>
                            <li
                              className={`list-group-item col-sm-3 ${
                                client.with_gift &&
                                'font-italic font-weight-bold text-success'
                              }`}>
                              {client.with_gift
                                ? 'Recibió regalo'
                                : 'No recibió regalo'}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            )}
            <div className="modal-footer">
              <div className="d-flex w-100 justify-content-center">
                <button
                  className="btn btn-primary w-50"
                  onClick={() => {
                    this.setState(prev => {
                      return {
                        ...prev,
                        potentialGiftsModalData: null,
                        openPotentialGiftsModal: false,
                      };
                    });
                  }}>
                  Volver
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default GiftConfigs;
