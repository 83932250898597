import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../common/Tooltip';
import SelectBoxSizeAsync from '.././common/selects/SelectBoxSizeAsync';

function RequestPackage(props) {
  let buttonRemove;
  buttonRemove = (
    <button
      className="btn btn-danger"
      type="button"
      onClick={props.onRemoveProductRequestPackage}>
      <i className="fas fa-minus"></i>
    </button>
  );

  return (
    <div className=" mb-2">
      <div className="row ml-0 mr-0 ">
        <div className="col-md-5 col-5 pl-0">
          <label htmlFor="weight"> Peso </label>
        </div>
        <div className="col-md-5 ml-1 col-5 pl-0">
          <label htmlFor="size"> Tamaño de caja </label>
        </div>
      </div>
      <div className="row ">
        <div className=" w-100 pl-0 col-5">
          <input
            data-tip
            onChange={props.onUpdateProductRequestPackage.bind(this)}
            name="weight"
            data-for={props.productRequestPackage.weight + 'packages'}
            className="form-control"
            placeholder="Peso total"
            value={props.productRequestPackage.weight}
          />
          <Tooltip
            id={props.productRequestPackage.weight + 'packages'}
            tooltipText="Usar '.' como separador de miles"
          />
        </div>
        <div className=" w-100 pl-0 col-5">
          <SelectBoxSizeAsync
            name="size"
            value={props.productRequestPackage.size}
            placeholder=" Caja "
            onChange={props.onUpdateProductRequestPackage.bind(this)}
          />
        </div>
        <div className="col-2">{buttonRemove}</div>
      </div>
    </div>
  );
}

export default class RequestPackages extends React.Component {
  newProductRequestPackage() {
    return { weight: 0, size: 0 };
  }

  handleAddProductRequestPackage(event) {
    const productRequestPackages = this.props.value.slice();
    productRequestPackages.push(this.newProductRequestPackage());
    this.updateOnChange(productRequestPackages);
  }

  handleRemoveProductRequestPackage(index, event) {
    const productRequestPackages = this.props.value.slice();
    productRequestPackages.splice(index, 1);
    this.updateOnChange(productRequestPackages);
  }

  handleUpdateProductRequestPackage(index, event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let productRequestPackage = this.props.value.slice();
    productRequestPackage[index][name] = value;
    this.updateOnChange(productRequestPackage);
  }

  updateOnChange(productRequestPackage) {
    if (this.props.onChange) {
      let event = { target: {} };
      event.target.name = this.props.name || 'package-nameless';
      event.target.value = productRequestPackage.slice();
      event.target.type = 'venturing-package';
      this.props.onChange(event);
    }
  }

  render() {
    const items = this.props.value;

    return (
      <>
        <div className="form-group">
          <label htmlFor="productRequestPackages"> Agregar bulto </label>
        </div>

        {items &&
          items.map((productRequestPackage, index, productRequestPackages) => {
            let isLast = index === productRequestPackages.length - 1;
            let isFirst = index === 0;
            return (
              <RequestPackage
                productRequestPackage={productRequestPackage}
                key={index}
                isLast={isLast}
                isFirst={isFirst}
                onAddProductRequestPackage={this.handleAddProductRequestPackage.bind(
                  this,
                  index,
                )}
                onRemoveProductRequestPackage={this.handleRemoveProductRequestPackage.bind(
                  this,
                  index,
                )}
                onUpdateProductRequestPackage={this.handleUpdateProductRequestPackage.bind(
                  this,
                  index,
                )}
              />
            );
          })}
        <div className="pl-0">
          <button
            className=" btn btn-success w-100"
            type="button"
            onClick={this.handleAddProductRequestPackage.bind(this)}>
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </>
    );
  }
}

RequestPackages.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
};
