import React from 'react';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';
import apiHelper from '../../utils/apiHelpers';
import { FiltersCacheContext } from '../common/FiltersCacheContext';
import ReactLoading from 'react-loading';
import ReturnsBoard from './ReturnsBoard';
import ReturnFilters from './ReturnFilters';
import ProviderReturnsBoard from './ProviderReturnsBoard';
import { CSVLink } from 'react-csv';
import { returnsToExport } from './ExportReturnsHelpers';
import apiHelpers from '../../utils/apiHelpers';
import moment from 'moment';
import Loading from '../common/Loading';

class ReturnsControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: false,
      returnsProcessed: null,
      returnsProcessedByProvider: null,
      filters: null,
      loadingExportReturns: false,
      CSVdata: '',
      CSVtitle: '',
    };
  }

  download = React.createRef();

  async getRequestsProcessed() {
    const { filters } = this.state;
    try {
      this.setState({ loading: true });
      const { data: returnsProcessed } =
        await apiHelper.getCountReturnsProcessed(filters);

      // request returns counted and split by providers
      const { data: returnsProcessedByProvider } =
        await apiHelper.getCountReturnsProcessedByProvider(filters);

      this.setState({
        returnsProcessed,
        returnsProcessedByProvider,
        loading: false,
      });
    } catch (e) {
      console.log('Error loading requests');
    }
  }

  componentDidMount() {
    apiHelper
      .getCurrentUser()
      .then(user => {
        this.setState({
          user: user.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
    this.getRequestsProcessed();
  }

  handleChangeFilters(filters) {
    this.setState({ filters: filters }, () => {
      this.getRequestsProcessed();
    });
  }

  async exportReturns() {
    this.setState({
      loadingExportReturns: true,
    });

    let returnsData;
    try {
      const { data } = await apiHelpers.getReturns(this.state.filters);
      returnsData = data;
    } catch (error) {
      console.log(error);
      this.setState({
        loadingExportReturns: false,
      });
    }

    let csv = await returnsToExport(returnsData);

    this.setState(
      {
        loadingExportReturns: false,
        CSVdata: csv,
        CSVtitle: `Reporte de devoluciones - ${moment().format(
          'DD-MM-YYYY',
        )}.csv`,
      },
      () => {
        this.download.current.link.click();
      },
    );
  }

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <NavBar history={this.props.history} />
        <div className="container bottom-air mb-4 px-0">
          <h1 className="text-nuskin text-center">
            <i className="fas fa-table mt-3"></i>{' '}
            <span> Tablero de Devoluciones</span>
          </h1>

          <div className="row m-4 w-100">
            <div className="row col-sm-12 col-lg-12 d-flex justify-content-end">
              <CSVLink
                ref={this.download}
                data={this.state.CSVdata}
                filename={this.state.CSVtitle}
                className="hidden"></CSVLink>
              <button
                type="button"
                className="btn btn-success col-lg-3 mr-4"
                onClick={this.exportReturns.bind(this)}>
                Exportar devoluciones{' '}
                <i className="fas fa-searchfas fa-file-export"></i>
              </button>
            </div>
          </div>
          <ReturnFilters loadData={this.handleChangeFilters.bind(this)} />

          {this.state.loading ? (
            <Loading />
          ) : (
            <div>
              <ReturnsBoard
                data={this.state.returnsProcessed}
                history={this.props.history}
              />
              <div>
                {this.state.returnsProcessedByProvider?.length > 0 &&
                  this.state.returnsProcessedByProvider?.map(
                    returnProcessedByOneProvider => {
                      return (
                        <ProviderReturnsBoard
                          provider={returnProcessedByOneProvider.name}
                          providerId={returnProcessedByOneProvider.id}
                          data={returnProcessedByOneProvider.data}
                          history={this.props.history}
                        />
                      );
                    },
                  )}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

ReturnsControlPanel.contextType = FiltersCacheContext;

export default ReturnsControlPanel;
